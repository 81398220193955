import React from 'react'
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import NavBar from "./miniComponents/subcomponents/NavBar";
import { styled } from 'styled-components';
import CourseContainer from './secondaryContainersForPages/CourseContainer';

function CoursePage({selected}) {

  return (
    <CourseListContainer>
      <MiniNavBar selected={selected} />
      <NavBar selected={selected} />
      <CourseContainer/>
    </CourseListContainer>
  );
}
const CourseListContainer = styled.div`
  display: flex;
  background-color: var(--background);
`;
export default CoursePage