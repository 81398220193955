import React from "react";

import NavBar from "./miniComponents/subcomponents/NavBar";
// import BranchTable from './miniComponents/BranchTable';
import { styled } from "styled-components";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import BranchContainer from "./secondaryContainersForPages/BranchContainer";

function BranchesPage({ selected }) {
  return (
    <BranchContaine>
      <MiniNavBar selected={"branches"} />
      <NavBar selected={selected} />
      <BranchContainer selected={selected} />
    </BranchContaine>
  );
}
const BranchContaine = styled.div`
  background-color: var(--background);
  display: flex;
`;

export default BranchesPage;
