import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { fetchData, handleImageSubmit, postData } from "../../../Api";
import { URL as url } from "../../../assets/mocData/config";
import { validateStudentFormData } from "../../../assets/contex/ValidationFuntions";
import { enqueueSnackbar } from "notistack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Country, State, City } from "country-state-city";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { InputLabel, Select, MenuItem } from "@mui/material";
import { IconButton } from "@mui/material";
import { Close, ResetTvOutlined } from "@mui/icons-material"; // Importing the close icon

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputBase,
  Radio,
  RadioGroup,
  Paper,
  Autocomplete,
  TextField,
} from "@mui/material";
import Icon from "react-icons-kit";
import { cross } from "react-icons-kit/icomoon/cross";
function AddStudentForm({ studentData }) {
  let {
    conceptData,
    setConceptData,
    courseData,
    setCourseData,
    branchDataAll,
    setBranchData,
    userRole,
    userToken,
    profileData,
  } = useGlobalContext();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#f00d88", // Pink color
      },
      text: {
        primary: "#ffffff", // White color for input text
      },
    },
    components: {
      MuiFilledInput: {
        styleOverrides: {
          root: {
            color: "#ffffff", // White color for input text
            backgroundColor: "black", // Background color for filled input
            "&:before": {
              borderColor: "white", // Initial border color white
            },
            "&:hover:before": {
              borderColor: "#f00d88", // Border color on hover
            },
            "&.Mui-focused:before": {
              borderColor: "#f00d88", // Border color when focused
            },
            "&.Mui-focused": {
              backgroundColor: "black", // Background color when focused
            },
          },
          input: {
            color: "#ffffff", // White color for input text
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "gray", // Gray color for label
            "&.Mui-focused": {
              color: "#f00d88", // Pink color for label when focused
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "white", // White color for the dropdown icon
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "#25272d", // Dark gray background for dropdown
            color: "#ffffff", // White text color for dropdown items
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "#ffffff",// Pink color for the dropdown icon
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: "#25272d", // Gray background
            color: "#ffffff", // White text color
            "&:hover": {
              backgroundColor: "#f00d88", // Pink background on hover
            },
          },
        },
      },
    },
  });
  const [open, SetOpen] = useState(false);
  const [checkedTC, setcheckedTC] = React.useState(false);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const handleChecked = (e) => {
    console.log(e.target.checked);

    SetOpen(true);
  };
  const handleClose = () => {
    setcheckedTC(false);
    SetOpen(false);
  };
  const handleOK = () => {
    setcheckedTC(true);
    SetOpen(false);
  };

  const [discountData, setDiscountData] = useState({
    discountCode: "",
    discountPercentage: "",
    afterDiscount: "",
    finalAmount: "",
  });

  const [formData, setFormData] = useState({
    personalInfo: {
      studentName: "",
      phoneNumber: "",
      age: "",
      dob: "",
      bloodGroup: "",
      classCourse: "",
      schoolCollege: "",
      gender: "",
    },
    familyDetails: {
      fathersName: "",
      fathersPhone: "",
      fathersWhatsAppNumber: "",
      mothersName: "",
      mothersPhone: "",
      mothersWhatsAppNumber: "",
    },
    address: {
      flatNo: "",
      street: "",
      district: "",
      state: "",
      pinCode: "",
      landmark: "",
      country: "India", // Assuming a default country
    },
    courseDetail: {
      courseName: {},
      conceptName: {},
      branchName: {
        branchOwnerEmail: profileData.profileData.email,
      },
    },
    transactionsRecieved: [],
    referenceId: "",
    referenceName: "",
    status: "pending",
    imageACCESSKEY: "",
  });
  console.log(formData);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [gst, setGST] = useState(0);
  /**
   * Calculates the discounted price after applying a discount percentage.
   * @param {number} originalPrice - The original price of the item.
   * @param {number} discountPercentage - The discount percentage to be applied.
   * @returns {number} - The discounted price.
   */
  const calculateDiscountPercentage = (originalPrice, discountPercentage) => {
    const discountAmount = (originalPrice * discountPercentage) / 100;
    const discountedPrice = originalPrice - discountAmount;
    return discountedPrice.toFixed(2);
  };

  /**
   * Calculates the price after applying an 18% GST.
   * @param {number} discountedPrice - The price after discount.
   * @returns {number} - The price after applying GST.
   */

  const [courseAndSchool, setCourseAndSchool] = useState([]);
  const [classCourse, setClassCourse] = useState("");
  const [schoolCollege, setSchoolCollege] = useState("");
  const [allSiblings, setAllSiblings] = useState([]);
  const [applyGst, setApplyGst] = useState(false);

  const [Siblings, setSiblings] = useState({
    age: "",
    classCourse: "",
    gender: "",
    name: "",
    schoolCollege: "",
  });

  console.log(allSiblings);
  const addSiblings = (data) => {
    if (
      data.name === "" ||
      data.age === "" ||
      data.classCourse === "" ||
      data.gender === "" ||
      data.schoolCollege === ""
    ) {
      alert("Please fill in all fields.");
      return;
    }
    setAllSiblings([...allSiblings, data]);
    setSiblings({
      age: "",
      classCourse: "",
      gender: "",
      name: "",
      schoolCollege: "",
    });
  };
  // Function to add the Class/Course and School/College
  const addCourseAndSchool = () => {
    if (classCourse === "" || schoolCollege === "") {
      alert("Please fill in both Class/Course and School/College fields.");
      return;
    }
    // Add the new entry to the array
    setCourseAndSchool([...courseAndSchool, { classCourse, schoolCollege }]);
    // Clear the input fields
    setClassCourse("");
    setSchoolCollege("");
  };
  const FormData = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split(".");
    setFormData((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field]: value,
      },
    }));
  };

  const removeCourseAndSchool = (indexToRemove) => {
    const updatedList = courseAndSchool.filter(
      (_, index) => index !== indexToRemove
    );
    setCourseAndSchool(updatedList);
  };

  const generateDiscount = async (e, code) => {
    e.preventDefault();
    const headers = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let data = await fetchData(
        `${url}/students/discount?code=${code}&gmail=${profileData.profileData.email}`,
        headers
      );
      console.log(data);
      const discountPercentage = calculateDiscountPercentage(
        parseInt(formData.courseDetail.courseName.courseFee),
        parseInt(data[0].percentage)
      );
      const finalAmount = applyGST(parseInt(discountPercentage));
      console.log(finalAmount);
      setDiscountData({
        ...discountData,
        discountPercentage: data[0].percentage,
        afterDiscount: discountPercentage,
        finalAmount: finalAmount,
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Not Found", { variant: "error" });
    }
  };
  const noDiscount = async (e) => {
    e.preventDefault();
    const discountPercentage = calculateDiscountPercentage(
      parseInt(formData.courseDetail.courseName.courseFee),
      parseInt(0)
    );
    const finalAmount = applyGST(parseInt(discountPercentage));
    console.log(finalAmount);
    setDiscountData({
      ...discountData,
      discountPercentage: 0,
      afterDiscount: discountPercentage,
      finalAmount: finalAmount,
    });
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const objectUrl = URL.createObjectURL(file);
      console.log(objectUrl);
      setPreviewUrl(objectUrl);
    }
    await handleImageSubmit(file, setFormData, formData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split(".");
    console.log(field);
    if (field == "courseName") {
      const data = courseData.filter((f) => f._id == value);
      //console.log(data);
      setFormData((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [field]: data[0],
        },
      }));
      return true;
    } else if (field == "conceptName") {
      const data = conceptData.filter((f) => f._id == value);
      //console.log(data);
      setFormData((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [field]: data[0],
        },
      }));
      return true;
    } else if (field == "branchName") {
      const data = branchDataAll.filter((f) => f._id == value);
      //console.log(data);
      setFormData((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [field]: data[0],
        },
      }));
      return true;
    } else if (field == "") {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };
  const [checked, setChecked] = useState();
  const Checked = (event) => {
    setChecked(event.target.value);
  };

  const [optionData, setOptionData] = useState({
    concept: [],
    branch: [],
    course: [],
  });
  const handleSiblingsChange = (e) => {
    const { name, value } = e.target;
    setSiblings((prevSiblings) => ({
      ...prevSiblings,
      [name]: value,
    }));
  };
  const fetchDatas = async (url) => {
    const headers = {
      userRole: "admin",
      userToken: userToken,
    };
    let response = await fetchData(url, headers);

    return response;
  };
  console.log(applyGst);
  const fetchGST = async () => {
    const headers = {
      userRole: userRole,
      userToken: userToken,
    };
    let response = await fetchData(`${url}/franchise/gst`, headers);
    setGST(response.data.GSTPercentage);
  };
  const handleGstChange = (event) => {
    setApplyGst(event.target.checked);
  };

  // Function to apply GST on the discounted price
  const applyGST = (discountedPrice) => {
    const gstPercentage = applyGst ? gst : 0; // Use GST if checkbox is checked
    const gstAmount = (discountedPrice * gstPercentage) / 100;
    const finalPrice = discountedPrice + gstAmount;
    return finalPrice.toFixed(2); // Return price rounded to two decimal places
  };

  // Recalculate final amount when applyGst or afterDiscount changes
  useEffect(() => {
    const updatedFinalAmount = applyGST(parseFloat(discountData.afterDiscount));
    setDiscountData((prevData) => ({
      ...prevData,
      finalAmount: updatedFinalAmount,
    }));
  }, [applyGst, discountData.afterDiscount]);
  const addStudent = async (e) => {
    e.preventDefault();
    if (checkedTC || userRole == "vip") {
      const headers = {
        userRole: userRole,
        userToken: userToken,
      };
      if (selectedCity) {
        formData.address.district = selectedCity.name;
        formData.address.country = selectedCountry.name;
        formData.address.state = selectedState.name;
      }
      if (applyGst) {
        formData.courseDetail.courseName.gst = gst;
      } else {
        formData.courseDetail.courseName.gst = 0;
      }
      if (userRole == "vip") {
        if (validateStudentFormData(formData, true)) {
          try {
            console.log(formData);
            formData.familyDetails.sibilings = allSiblings;
            await postData(`${url}/students/`, formData, headers);
            window.location.reload();
          } catch (error) {
            console.log(error);
          }
        }
      } else if (userRole == "franchise" || userRole == "branchstaff") {
        if (validateStudentFormData(formData, false)) {
          if (formData._id == undefined) {
            let response = await postData(
              `${url}/students/`,
              formData,
              headers
            );
            let res = await postData(
              `${url}/accounts/add/student/${profileData.profileData._id}`,
              {
                ...response,
                otherDetails: {
                  amountToPay: discountData.finalAmount,
                  amountPaid: 0,
                },
              },
              headers
            );
            console.log(res);
            console.log("id not found", response);
            window.location.reload();
          } else {
            await postData(
              `${url}/accounts/add/student/${profileData.profileData._id}`,
              {
                ...formData,
                otherDetails: {
                  amountToPay: discountData.finalAmount,
                  amountPaid: 0,
                },
              },
              headers
            );
            console.log("id found", formData._id);
            window.location.reload();
          }
        }
      }
    } else {
      enqueueSnackbar("Agree Terms and conditions to proceed", {
        variant: "warning",
      });
    }
  };
  function removeElementByCondition(name) {
    // Create a new array with elements that do not match the specified company name
    const filteredArray = allSiblings.filter(
      (element) => element.name !== name
    );

    console.log(filteredArray); // This will show the new array without the specified company

    setAllSiblings(filteredArray);
  }
  useEffect(() => {
    (async () => {
      if (!branchDataAll) {
        let b = await fetchDatas(`${url}/franchise/branch`);
        if (userRole == "branchstaff") {
          b = b.filter(
            (branchD) =>
              branchD.branchOwnerEmail ==
              profileData.profileData.otherDetails.email
          );
        }
        setBranchData(b);
      }
      if (!courseData) {
        let concD = await fetchDatas(`${url}/franchise/courselist`);
        setCourseData(concD);
      }
      if (!conceptData) {
        let courseD = await fetchDatas(`${url}/admin/concept`);
        setConceptData(courseD);
      }
      await fetchGST();
    })();
    if (studentData._id) {
      console.log("yes---");
      setFormData({ ...studentData });
    } else {
      setFormData({ ...formData });
    }
  }, []);

  useEffect(() => {
    if (userRole == "vip") {
      setFormData({
        ...formData,
        referenceId: profileData.profileData._id,
        referenceName: profileData.profileData.userName,
      });
    }
  }, []);
  
 
  return (
    <AddStudentFormContainer id="add-student-container">
      <ThemeProvider theme={theme}>
        {/* <MiniNavBar selected={"vipaddstudent"}/> */}
        <form
          onChange={FormData}
          class="container"
          style={{ position: "relative" }}
        >
          <div class="row d-md-flex flex-column-reverse flex-md-row">
            <div class="col col-12 col-md-9">
              <div id="form-container" class="m-1">
                <form
                  class="scroll"
                  style={{
                    height: "85vh",
                    overflowY: "scroll",
                    paddingTop: "1rem",
                    paddingBottom: "5rem",
                  }}
                  action=""
                >
                  <div class="container"></div>
                  <div className="container">
                    <div className="row">
                      <hr />
                      <span class="col-3">Personal Information</span>
                      <div class="col-9  d-flex flex-column px-3">
                        <div style={{ width: "100%" }}>
                          <span
                            style={{ width: "100%" }}
                            class="d-flex justify-content-between"
                          >
                            <TextField
                              fullWidth
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                margin: "0.5rem", // Default margin
                                marginBottom: "1rem", // Default spacing below
                              }}
                              variant="filled"
                              name="personalInfo.studentName"
                              value={formData.personalInfo.studentName}
                              onChange={handleChange}
                              label="Student Name"
                            />
                          </span>
                          <span className="d-flex flex-column flex-sm-row justify-content-between">
                            <TextField
                              fullWidth
                              variant="filled"
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                margin: "0.5rem",
                                marginBottom: "1rem",
                              }}
                              name="personalInfo.age"
                              value={formData.personalInfo.age}
                              onChange={handleChange}
                              label="Age"
                              type="text"
                            />

                            <TextField
                              fullWidth
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                margin: "0.5rem",
                                marginBottom: "1rem",
                              }}
                              variant="filled"
                              name="personalInfo.bloodGroup"
                              value={formData.personalInfo.bloodGroup}
                              onChange={handleChange}
                              label="Blood Group"
                              type="text"
                            />
                          </span>
                          <span className="d-flex flex-column flex-md-row justify-content-between">
                            <TextField
                              fullWidth
                              variant="filled"
                              name="personalInfo.dob"
                              value={formData.personalInfo.dob}
                              onChange={handleChange}
                              label="Date of Birth (DD/MM/YY)"
                              type="date"
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                margin: "0.5rem",
                                marginBottom: "1rem",
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                style: {
                                  colorScheme: "dark", // For dark mode styling
                                },
                              }}
                            />

                            <FormControl
                              fullWidth
                              variant="filled"
                              style={{
                                margin: "0.5rem",
                                marginBottom: "1rem",
                              }}
                            >
                              <InputLabel>Gender</InputLabel>
                              <Select
                                name="personalInfo.gender"
                                value={formData.personalInfo.gender}
                                onChange={handleChange} // Ensure this updates the `data` state correctly
                              >
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                              </Select>
                            </FormControl>
                          </span>
                          <span className="d-flex flex-column flex-md-row justify-content-between">
                            {" "}
                            <TextField
                              fullWidth
                              variant="filled"
                              name="personalInfo.classCourse"
                              value={formData.personalInfo.classCourse}
                              onChange={(e) => setClassCourse(e.target.value)}
                              label="Class/Course"
                              style={{
                                backgroundColor: "#1C1C1C",
                                color: "#fff",
                                width: "100%",
                                maxWidth: "100%",
                                margin: "0.5rem",
                                marginBottom: "1rem",
                              }}
                            />{" "}
                            <TextField
                              fullWidth
                              variant="filled"
                              name="personalInfo.schoolCollege"
                              value={formData.personalInfo.schoolCollege}
                              onChange={(e) => setSchoolCollege(e.target.value)}
                              label="School/College"
                              style={{
                                backgroundColor: "#1C1C1C",
                                color: "#fff",
                                width: "100%",
                                maxWidth: "100%",
                                margin: "0.5rem",
                                marginBottom: "1rem",
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="container mt-4">
                    <div className="row">
                      <span className="col-3">Family details</span>
                      <div className="col-9 d-flex flex-column px-3">
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              fullWidth
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              name="familyDetails.fathersName"
                              value={formData.familyDetails.fathersName}
                              onChange={handleChange}
                              label="Father's Name"
                            />
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              fullWidth
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              name="familyDetails.fathersPhone"
                              value={formData.familyDetails.fathersPhone}
                              onChange={handleChange}
                              label="Phone"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              fullWidth
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              name="familyDetails.fathersWhatsAppNumber"
                              value={
                                formData.familyDetails.fathersWhatsAppNumber
                              }
                              onChange={handleChange}
                              label="Whatsapp"
                            />
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              fullWidth
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              name="familyDetails.mothersName"
                              value={formData.familyDetails.mothersName}
                              onChange={handleChange}
                              label="Mother's Name"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              fullWidth
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              name="familyDetails.mothersPhone"
                              value={formData.familyDetails.mothersPhone}
                              onChange={handleChange}
                              label="Phone"
                            />
                          </div>

                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              name="familyDetails.mothersWhatsAppNumber"
                              value={
                                formData.familyDetails.mothersWhatsAppNumber
                              }
                              onChange={handleChange}
                              label="Whatsapp"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="container mt-4">
                    <div className="row">
                      <span className="col-md-3 col-12 mb-2">
                        <strong>Siblings Name</strong>
                      </span>
                      <div className="col-md-9 col-12 d-flex flex-column px-3">
                        <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
                          {formData.familyDetails.sibilings?.map((sibling) => (
                            <div className="m-2">
                              <Button
                                variant="contained"
                                color="success"
                                style={{ padding: "0.5rem 1rem" }}
                              >
                                {sibling.name}

                                <Icon
                                  onClick={() => {
                                    removeElementByCondition(sibling.name);
                                  }}
                                  style={{ padding: "0.5rem" }}
                                  icon={cross}
                                />
                              </Button>
                            </div>
                          ))}
                          {allSiblings?.map((sibling) => (
                            <div className="m-2">
                              <Button
                                variant="contained"
                                color="success"
                                style={{ padding: "0.5rem 1rem" }}
                              >
                                {sibling.name}

                                <Icon
                                  onClick={() => {
                                    removeElementByCondition(sibling.name);
                                  }}
                                  style={{ padding: "0.5rem" }}
                                  icon={cross}
                                />
                              </Button>
                            </div>
                          ))}
                        </div>

                        <div className="row g-3">
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              variant="filled"
                              type="text"
                              label="Student Name"
                              name="name"
                              value={Siblings.name}
                              onChange={handleSiblingsChange}
                            />
                          </div>
                          <div className="col-12 col-md-3">
                            <TextField
                              fullWidth
                              variant="filled"
                              type="text"
                              label="Age"
                              name="age"
                              value={Siblings.age}
                              onChange={handleSiblingsChange}
                            />
                          </div>
                          <div className="col-12 col-md-3">
                            <TextField
                              fullWidth
                              variant="filled"
                              type="text"
                              label="Gender"
                              name="gender"
                              value={Siblings.gender}
                              onChange={handleSiblingsChange}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              variant="filled"
                              type="text"
                              label="Class/Course"
                              name="classCourse"
                              value={Siblings.classCourse}
                              onChange={handleSiblingsChange}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              variant="filled"
                              type="text"
                              label="School/College"
                              name="schoolCollege"
                              value={Siblings.schoolCollege}
                              onChange={handleSiblingsChange}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mb-3  mt-3 p-2">
                          <Button
                            onClick={() => addSiblings(Siblings)}
                            variant="outlined"
                            color="success"
                            size="small"
                            sx={{
                              borderColor: "green",
                              color: "green",
                              padding: "2px 8px",
                              minHeight: "24px",
                              fontSize: "0.75rem",
                              lineHeight: "1",
                              "&:hover": {
                                borderColor: "darkgreen",
                                backgroundColor: "rgba(0, 128, 0, 0.1)",
                              },
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="container">
                    <div className="row">
                      <span className="col-3">Address</span>
                      <div className="col-12 col-md-9 d-flex flex-column px-3">
                        <div className="row ">
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              fullWidth
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              type="text"
                              label="Street No/Door No"
                              name="address.flatNo"
                              value={formData.address.flatNo}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              fullWidth
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              type="text"
                              label="Street Name"
                              name="address.street"
                              value={formData.address.street}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 mb-3">
                            <TextField
                              fullWidth
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              type="text"
                              label="Landmark"
                              name="address.landmark"
                              value={formData.address.landmark}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          {formData.address.district ? (
                            <>
                              <div className="col-12 col-md-6 mb-3">
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="Country"
                                  name="country"
                                  value={formData.address.country}
                                  fullWidth
                                />
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="District"
                                  name="district"
                                  value={formData.address.district}
                                  fullWidth
                                />
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="State"
                                  name="state"
                                  value={formData.address.state}
                                  fullWidth
                                />
                                <TextField
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  label="Pincode"
                                  name="address.pincode"
                                  value={formData.address.pinCode}
                                  fullWidth
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="row">
                                <div className="col-12 col-md-6 mb-3">
                                  <Autocomplete
                                    options={Country.getAllCountries()}
                                    getOptionLabel={(option) => option.name}
                                    value={selectedCountry}
                                    onChange={(event, newValue) =>
                                      setSelectedCountry(newValue)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="filled"
                                        label="Country"
                                        placeholder="Country"
                                        InputLabelProps={{
                                          style: { color: "gray" },
                                        }}
                                        sx={{
                                          "& .MuiFilledInput-root": {
                                            backgroundColor: "black",
                                            color: "white",
                                          },
                                          "& .MuiFilledInput-root:hover:before":
                                            {
                                              borderColor: "#f00d88",
                                            },
                                          "& .MuiFilledInput-root.Mui-focused:before":
                                            {
                                              borderColor: "#f00d88",
                                            },
                                        }}
                                      />
                                    )}
                                    PaperComponent={(props) => (
                                      <Paper
                                        {...props}
                                        sx={{
                                          backgroundColor: "white",
                                          color: "black",
                                        }}
                                      />
                                    )}
                                    sx={{
                                      "& .MuiAutocomplete-option": {
                                        "&:hover": {
                                          backgroundColor: "#f00d88",
                                          color: "white",
                                        },
                                        "&[aria-selected='true']": {
                                          backgroundColor: "#f00d88",
                                          color: "white",
                                        },
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                  <Autocomplete
                                    options={
                                      State?.getStatesOfCountry(
                                        selectedCountry?.isoCode
                                      ) || []
                                    }
                                    getOptionLabel={(option) => option.name}
                                    value={selectedState}
                                    onChange={(event, newValue) =>
                                      setSelectedState(newValue)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="filled"
                                        label="State"
                                        placeholder="State"
                                        InputLabelProps={{
                                          style: { color: "gray" },
                                        }}
                                        sx={{
                                          "& .MuiFilledInput-root": {
                                            backgroundColor: "black",
                                            color: "white",
                                          },
                                          "& .MuiFilledInput-root:hover:before":
                                            {
                                              borderColor: "#f00d88",
                                            },
                                          "& .MuiFilledInput-root.Mui-focused:before":
                                            {
                                              borderColor: "#f00d88",
                                            },
                                        }}
                                      />
                                    )}
                                    PaperComponent={(props) => (
                                      <Paper
                                        {...props}
                                        sx={{
                                          backgroundColor: "white",
                                          color: "black",
                                        }}
                                      />
                                    )}
                                    sx={{
                                      "& .MuiAutocomplete-option": {
                                        "&:hover": {
                                          backgroundColor: "#f00d88",
                                          color: "white",
                                        },
                                        "&[aria-selected='true']": {
                                          backgroundColor: "#f00d88",
                                          color: "white",
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>

                              {/* Fourth Row with Autocomplete and TextField */}
                              <div className="row">
                                <div className="col-12 col-md-6 mb-3">
                                  <Autocomplete
                                    options={
                                      selectedState
                                        ? City.getCitiesOfState(
                                            selectedState?.countryCode,
                                            selectedState?.isoCode
                                          )
                                        : []
                                    }
                                    getOptionLabel={(option) => option.name}
                                    value={selectedCity}
                                    onChange={(event, newValue) =>
                                      setSelectedCity(newValue)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="filled"
                                        label="District"
                                        placeholder="District"
                                        InputLabelProps={{
                                          style: { color: "gray" },
                                        }}
                                        sx={{
                                          "& .MuiFilledInput-root": {
                                            backgroundColor: "black",
                                            color: "white",
                                          },
                                          "& .MuiFilledInput-root:hover:before":
                                            {
                                              borderColor: "#f00d88",
                                            },
                                          "& .MuiFilledInput-root.Mui-focused:before":
                                            {
                                              borderColor: "#f00d88",
                                            },
                                        }}
                                      />
                                    )}
                                    PaperComponent={(props) => (
                                      <Paper
                                        {...props}
                                        sx={{
                                          backgroundColor: "white",
                                          color: "black",
                                        }}
                                      />
                                    )} 
                                    sx={{
                                      "& .MuiAutocomplete-option": {
                                        "&:hover": {
                                          backgroundColor: "#f00d88",
                                          color: "white",
                                        },
                                        "&[aria-selected='true']": {
                                          backgroundColor: "#f00d88",
                                          color: "white",
                                        },
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                  <TextField
                                    style={{
                                      width: "100%",
                                      border: "1px solid black",
                                    }}
                                    variant="filled"
                                    value={formData.address.pinCode}
                                    label="Pincode"
                                    name="address.pinCode"
                                    onChange={handleChange}
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="container mt-4">
                    <div className="row">
                      <span className="col-3">Course Details</span>
                      <div className="col-12 col-md-9 d-flex flex-column px-3">
                        <span className="w-100 p-2 d-flex flex-column flex-md-row justify-content-between">
                          <FormControl
                            variant="filled"
                            className="w-100 w-md-45 ms-md-3"
                          >
                            <InputLabel>Select Concept</InputLabel>
                            <Select
                              name="courseDetail.conceptName"
                              value={
                                formData.courseDetail.conceptName._id || ""
                              }
                              onChange={handleChange}
                              style={{
                                border: "1px solid black",
                                color: "gray",
                              }}
                            >
                              <MenuItem value="">
                                <em>Select Concept</em>
                              </MenuItem>
                              {conceptData?.map((concept, index) => (
                                <MenuItem key={index} value={concept._id}>
                                  {concept.conceptName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            variant="filled"
                            className="w-100 w-md-45 mb-3 mb-md-0 px-3"
                          >
                            <InputLabel>Select Course</InputLabel>
                            <Select
                              name="courseDetail.courseName"
                              value={formData.courseDetail.courseName._id || ""}
                              onChange={handleChange}
                              style={{
                                border: "1px solid black",
                                color: "gray",
                              }}
                            >
                              <MenuItem value="">
                                <em>Select Course</em>
                              </MenuItem>
                              {courseData?.map((course, index) => (
                                <MenuItem key={index} value={course._id}>
                                  {course.courseName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </span>

                        {userRole !== "franchise" && (
                          <span className="w-100 mt-4">
                            <FormControl variant="filled" className="w-100">
                              <InputLabel>Select Branch</InputLabel>
                              <Select
                                name="courseDetail.branchName"
                                value={
                                  formData.courseDetail.branchName._id || ""
                                }
                                onChange={handleChange}
                                style={{
                                  border: "1px solid black",
                                  color: "gray",
                                }}
                              >
                                <MenuItem value="">
                                  <em>Select Branch</em>
                                </MenuItem>
                                {branchDataAll?.map((branch, index) => (
                                  <MenuItem key={index} value={branch._id}>
                                    {branch.branchName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {userRole == "franchise" &&
                    formData.courseDetail.courseName.courseFee && (
                      <>
                        <div class="mt-5" style={{ position: "relative" }}>
                          <div style={{ border: "1px dashed black" }}></div>
                          <div
                            style={{
                              backgroundColor: "#18171b",
                              height: "2.5rem",
                              width: "2.5rem",
                              borderRadius: "100%",
                              position: "absolute",
                              left: "-16px",
                              top: "-19px",
                            }}
                          ></div>
                          <div
                            style={{
                              backgroundColor: "#18171b",
                              height: "2.5rem",
                              width: "2.5rem",
                              borderRadius: "100%",
                              position: "absolute",
                              right: "-16px",
                              top: "-19px",
                            }}
                          ></div>
                        </div>
                        <div class="pt-5">
                          <span style={{ marginLeft: "1rem" }}>
                            Payment Details
                          </span>
                          <div class="p-3 mb-5">
                            <div class="d-flex flex-row-reverse">
                              <span
                                style={{ width: "25rem" }}
                                class="d-flex justify-content-between"
                              >
                                <p>Course Cost</p>
                                <h6 class="p-2">
                                  <b>
                                    Rs.
                                    {formData.courseDetail.courseName.courseFee}
                                  </b>
                                </h6>
                              </span>
                            </div>
                            <div
                              style={{ backgroundColor: "#18171b" }}
                              class="d-flex flex-row-reverse rounded-3"
                            >
                              <span
                                style={{ width: "25rem" }}
                                class="d-flex align-items-center"
                              >
                                <span>Discount</span>

                                <input
                                  disabled
                                  value={discountData.discountPercentage}
                                  style={{
                                    width: "22%",
                                    height: "60%",
                                    margin: "0.5rem",
                                    border: "1px solid #212529",
                                    backgroundColor: "#212529",
                                    color: "white",
                                  }}
                                  class=" p-3 rounded-3"
                                />

                                <span>Discount Code</span>
                                <input
                                  style={{
                                    width: "25%",
                                    height: "60%",
                                    margin: "0.5rem",
                                    border: "1px solid #212529",
                                    backgroundColor: "#212529",
                                    color: "white",
                                  }}
                                  class=" p-3 rounded-3"
                                  type="text"
                                  name=""
                                  id=""
                                  onChange={(e) =>
                                    setDiscountData({
                                      ...discountData,
                                      discountCode: e.target.value,
                                    })
                                  }
                                />
                              </span>
                            </div>
                            <br></br>
                            <button
                              onClick={(e) =>
                                generateDiscount(e, discountData.discountCode)
                              }
                              style={{
                                background: "transparent",
                                border: "1px solid #f00d88",
                                padding: "0.2rem",
                                color: "#f00d88",
                                cursor: "pointer",
                                marginRight: "1rem",
                              }}
                            >
                              Generate Discount
                            </button>

                            <button
                              onClick={(e) => noDiscount(e)}
                              style={{
                                background: "transparent",
                                border: "1px solid #f00d88",
                                padding: "0.2rem",
                                color: "#f00d88",
                                cursor: "pointer",
                              }}
                            >
                              No Discount
                            </button>

                            <div class="d-flex flex-row-reverse">
                              <span
                                style={{ width: "25rem" }}
                                class="d-flex justify-content-between"
                              >
                                <p>Course after Discount</p>
                                <h6 class="p-2">
                                  <b>Rs.{discountData.afterDiscount}</b>
                                </h6>
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  fontSize: "14px",
                                  position: "relative",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={applyGst}
                                  onChange={handleGstChange}
                                  style={{
                                    appearance: "none",
                                    width: "16px",
                                    height: "16px",
                                    backgroundColor: "transparent",
                                    border: "1px solid #ccc",
                                    borderRadius: "2px",
                                    position: "relative",
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                  }}
                                />
                                <span
                                  style={{
                                    position: "absolute",
                                    left: "0px",
                                    width: "24px", // Increased width
                                    height: "24px", // Increased height
                                    display: applyGst ? "block" : "none",
                                  }}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4 12L9 17L20 6"
                                      stroke="#00ff0a"
                                      strokeWidth="3"
                                    />
                                  </svg>
                                </span>
                                Apply
                              </label>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  fontSize: "14px",
                                }}
                              >
                                <p style={{ margin: 0 }}>GST</p>
                                <h6 style={{ margin: 0 }}>
                                  <b>{applyGst ? `${gst}%` : "0%"}</b>
                                </h6>
                              </div>
                            </div>
                            <hr />
                            <div class="d-flex flex-row-reverse">
                              <span
                                style={{ width: "25rem" }}
                                class="d-flex flex-row-reverse"
                              >
                                <span class="d-flex align-items-center">
                                  <h6>
                                    <b>TOTAL AMOUNT</b>
                                  </h6>
                                  <h6 class="p-2" style={{ color: "green" }}>
                                    <b>Rs.{discountData.finalAmount}</b>
                                  </h6>
                                </span>
                              </span>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#18171b",
                                height: "4rem",
                              }}
                              class="d-flex  align-items-center ml-5 rounded-3 mt-5"
                            >
                              <div
                                style={{ width: "10%", position: "relative" }}
                                class="d-flex "
                              >
                                <div>
                                  <div style={{ position: "relative" }}>
                                    <div
                                      id="check-box"
                                      style={{ left: "4rem", bottom: "0.8rem" }}
                                    >
                                      <div
                                        className="checkbox-bx"
                                        style={{ position: "relative" }}
                                      >
                                        <label
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            position: "relative",
                                          }}
                                        >
                                          <input
                                            checked={checkedTC}
                                            onChange={(e) => handleChecked(e)}
                                            type="checkbox"
                                            style={{
                                              appearance: "none",
                                              width: "16px",
                                              height: "16px",
                                              backgroundColor: "transparent",
                                              border: "1px solid #ccc",
                                              borderRadius: "2px",
                                              position: "relative",
                                              display: "inline-block",
                                              verticalAlign: "middle",
                                              cursor: "pointer",
                                              marginLeft: "1vw",
                                            }}
                                          />
                                          <span
                                            style={{
                                              position: "absolute",
                                              left: "0px",
                                              bottom: "1px",
                                              width: "24px", // Increased width
                                              height: "24px", // Increased height
                                              marginLeft: "1vw",
                                              display: checkedTC
                                                ? "block"
                                                : "none",
                                            }}
                                          >
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M4 12L9 17L20 6"
                                                stroke="#00ff0a"
                                                strokeWidth="3"
                                              />
                                            </svg>
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{ width: "90%" }}
                                class="d-flex justify-content-between align-items-center"
                              >
                                <span>Accept to the terms and conditions</span>
                                <button
                                  id="pay-btn"
                                  style={{
                                    background: "transparent",
                                    border: "1px solid #f00d88",
                                    padding: "0.2rem",
                                    color: "#f00d88",
                                    cursor: "pointer",
                                    marginRight: "1vw",
                                  }}
                                  onClick={(e) => addStudent(e)}
                                >
                                  Add Student
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  {userRole == "branchstaff" &&
                    formData.courseDetail.courseName.courseFee && (
                      <>
                      <div class="mt-5" style={{ position: "relative" }}>
                        <div style={{ border: "1px dashed black" }}></div>
                        <div
                          style={{
                            backgroundColor: "#18171b",
                            height: "2.5rem",
                            width: "2.5rem",
                            borderRadius: "100%",
                            position: "absolute",
                            left: "-16px",
                            top: "-19px",
                          }}
                        ></div>
                        <div
                          style={{
                            backgroundColor: "#18171b",
                            height: "2.5rem",
                            width: "2.5rem",
                            borderRadius: "100%",
                            position: "absolute",
                            right: "-16px",
                            top: "-19px",
                          }}
                        ></div>
                      </div>
                      <div class="pt-5">
                        <span style={{ marginLeft: "1rem" }}>
                          Payment Details
                        </span>
                        <div class="p-3 mb-5">
                          <div class="d-flex flex-row-reverse">
                            <span
                              style={{ width: "25rem" }}
                              class="d-flex justify-content-between"
                            >
                              <p>Course Cost</p>
                              <h6 class="p-2">
                                <b>
                                  Rs.
                                  {formData.courseDetail.courseName.courseFee}
                                </b>
                              </h6>
                            </span>
                          </div>
                          <div
                            style={{ backgroundColor: "#18171b" }}
                            class="d-flex flex-row-reverse rounded-3"
                          >
                            <span
                              style={{ width: "25rem" }}
                              class="d-flex align-items-center"
                            >
                              <span>Discount</span>

                              <input
                                disabled
                                value={discountData.discountPercentage}
                                style={{
                                  width: "22%",
                                  height: "60%",
                                  margin: "0.5rem",
                                  border: "1px solid #212529",
                                  backgroundColor: "#212529",
                                  color: "white",
                                }}
                                class=" p-3 rounded-3"
                              />

                              <span>Discount Code</span>
                              <input
                                style={{
                                  width: "25%",
                                  height: "60%",
                                  margin: "0.5rem",
                                  border: "1px solid #212529",
                                  backgroundColor: "#212529",
                                  color: "white",
                                }}
                                class=" p-3 rounded-3"
                                type="text"
                                name=""
                                id=""
                                onChange={(e) =>
                                  setDiscountData({
                                    ...discountData,
                                    discountCode: e.target.value,
                                  })
                                }
                              />
                            </span>
                          </div>
                          <br></br>
                          <button
                            onClick={(e) =>
                              generateDiscount(e, discountData.discountCode)
                            }
                            style={{
                              background: "transparent",
                              border: "1px solid #f00d88",
                              padding: "0.2rem",
                              color: "#f00d88",
                              cursor: "pointer",
                              marginRight: "1rem",
                            }}
                          >
                            Generate Discount
                          </button>

                          <button
                            onClick={(e) => noDiscount(e)}
                            style={{
                              background: "transparent",
                              border: "1px solid #f00d88",
                              padding: "0.2rem",
                              color: "#f00d88",
                              cursor: "pointer",
                            }}
                          >
                            No Discount
                          </button>

                          <div class="d-flex flex-row-reverse">
                            <span
                              style={{ width: "25rem" }}
                              class="d-flex justify-content-between"
                            >
                              <p>Course after Discount</p>
                              <h6 class="p-2">
                                <b>Rs.{discountData.afterDiscount}</b>
                              </h6>
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "14px",
                                position: "relative",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={applyGst}
                                onChange={handleGstChange}
                                style={{
                                  appearance: "none",
                                  width: "16px",
                                  height: "16px",
                                  backgroundColor: "transparent",
                                  border: "1px solid #ccc",
                                  borderRadius: "2px",
                                  position: "relative",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  left: "0px",
                                  width: "24px", // Increased width
                                  height: "24px", // Increased height
                                  display: applyGst ? "block" : "none",
                                }}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4 12L9 17L20 6"
                                    stroke="#00ff0a"
                                    strokeWidth="3"
                                  />
                                </svg>
                              </span>
                              Apply
                            </label>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "14px",
                              }}
                            >
                              <p style={{ margin: 0 }}>GST</p>
                              <h6 style={{ margin: 0 }}>
                                <b>{applyGst ? `${gst}%` : "0%"}</b>
                              </h6>
                            </div>
                          </div>
                          <hr />
                          <div class="d-flex flex-row-reverse">
                            <span
                              style={{ width: "25rem" }}
                              class="d-flex flex-row-reverse"
                            >
                              <span class="d-flex align-items-center">
                                <h6>
                                  <b>TOTAL AMOUNT</b>
                                </h6>
                                <h6 class="p-2" style={{ color: "green" }}>
                                  <b>Rs.{discountData.finalAmount}</b>
                                </h6>
                              </span>
                            </span>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#18171b",
                              height: "4rem",
                            }}
                            class="d-flex  align-items-center ml-5 rounded-3 mt-5"
                          >
                            <div
                              style={{ width: "10%", position: "relative" }}
                              class="d-flex "
                            >
                              <div>
                                <div style={{ position: "relative" }}>
                                  <div
                                    id="check-box"
                                    style={{ left: "4rem", bottom: "0.8rem" }}
                                  >
                                    <div
                                      className="checkbox-bx"
                                      style={{ position: "relative" }}
                                    >
                                      <label
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          position: "relative",
                                        }}
                                      >
                                        <input
                                          checked={checkedTC}
                                          onChange={(e) => handleChecked(e)}
                                          type="checkbox"
                                          style={{
                                            appearance: "none",
                                            width: "16px",
                                            height: "16px",
                                            backgroundColor: "transparent",
                                            border: "1px solid #ccc",
                                            borderRadius: "2px",
                                            position: "relative",
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            cursor: "pointer",
                                            marginLeft: "1vw",
                                          }}
                                        />
                                        <span
                                          style={{
                                            position: "absolute",
                                            left: "0px",
                                            bottom: "1px",
                                            width: "24px", // Increased width
                                            height: "24px", // Increased height
                                            marginLeft: "1vw",
                                            display: checkedTC
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M4 12L9 17L20 6"
                                              stroke="#00ff0a"
                                              strokeWidth="3"
                                            />
                                          </svg>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{ width: "90%" }}
                              class="d-flex justify-content-between align-items-center"
                            >
                              <span>Accept to the terms and conditions</span>
                              <button
                                id="pay-btn"
                                style={{
                                  background: "transparent",
                                  border: "1px solid #f00d88",
                                  padding: "0.2rem",
                                  color: "#f00d88",
                                  cursor: "pointer",
                                  marginRight: "1vw",
                                }}
                                onClick={(e) => addStudent(e)}
                              >
                                Add Student
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    )}
                  <React.Fragment>
                    <Dialog
                      open={open}
                      onClose={handleChecked}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Use Google's location service?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Terms and Conditions and Privacy Policy Summary
                          <br></br>
                          Tia is a leading edtech company, prioritizes your
                          trust by adhering to ethical standards in the
                          collection and use of your information. This policy
                          governs your use of the Tia application and website.
                          By using our services, you accept this policy and our
                          Terms of Use. The information collected may include
                          personal and sensitive data, transaction details, and
                          usage patterns, which are used to enhance user
                          experience and provide personalized content. We may
                          automatically collect data about your device and app
                          usage. You have the right to access and modify your
                          information, and cookies are utilized to track
                          preferences. We may communicate with you about new
                          offerings and services and encourage caution when
                          sharing personal information in public forums. While
                          we implement security measures, no system can
                          guarantee complete protection. Users must be competent
                          to contract; minors must have parental consent. By
                          using our services, you agree to indemnify Tia against
                          any claims arising from your use. We reserve the right
                          to modify services and terms without notice, and all
                          disputes are governed by Indian law, under the
                          jurisdiction of Bangalore courts. Services are
                          provided “as is” without warranties, and you are
                          responsible for ensuring they meet your needs.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={handleOK} autoFocus>
                          Agree
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                  {userRole == "vip" && (
                    <div class="container mt-3">
                      <div class="row justify-content-center">
                        <div class="col-12 d-flex justify-content-center">
                          <div class="d-flex flex-column flex-sm-row align-items-center">
                            <button
                              onClick={(e) => {
                                addStudent(e);
                              }}
                              class="btn btn-outline-success btn-sm me-2 add-course-btn"
                            >
                              Refer Student
                            </button>
                            <button class="btn btn-outline-danger btn-sm cancel-btn">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <>
                  <div class="mt-5" style={{ position: "relative" }}>
                    <div style={{ border: "1px dashed black" }}></div>
                    <div
                      style={{
                        backgroundColor: "#18171b",
                        height: "2.5rem",
                        width: "2.5rem",
                        borderRadius: "100%",
                        position: "absolute",
                        left: "-16px",
                        top: "-19px",
                      }}
                    ></div>
                    <div
                      style={{
                        backgroundColor: "#18171b",
                        height: "2.5rem",
                        width: "2.5rem",
                        borderRadius: "100%",
                        position: "absolute",
                        right: "-16px",
                        top: "-19px",
                      }}
                    ></div>
                  </div>
                  <div class="pt-5">
                    <span style={{ marginLeft: "1rem" }}>Payment Details</span>
                    <div class="p-3 mb-5">
                      <div class="d-flex flex-row-reverse">
                        <span
                          style={{ width: "25rem" }}
                          class="d-flex justify-content-between"
                        >
                          <p>Course Cost</p>
                          <h6 class="p-2">
                            <b>Rs.30,000</b>
                          </h6>
                        </span>
                      </div>
                      <div
                        style={{ backgroundColor: "#18171b" }}
                        class="d-flex flex-row-reverse rounded-3"
                      >
                        <span
                          style={{ width: "25rem" }}
                          class="d-flex align-items-center"
                        >
                          <span>Discount</span>
                          <input
                            style={{
                              width: "22%",
                              height: "60%",
                              margin: "0.5rem",
                              border: "1px solid #212529",
                              backgroundColor: "#212529",
                              color: "white",
                            }}
                            class=" p-3 rounded-3"
                            type="text"
                            name=""
                            id=""
                          />

                          <span>Discount Code</span>
                          <input
                            style={{
                              width: "25%",
                              height: "60%",
                              margin: "0.5rem",
                              border: "1px solid #212529",
                              backgroundColor: "#212529",
                              color: "white",
                            }}
                            class=" p-3 rounded-3"
                            type="text"
                            name=""
                            id=""
                          />
                        </span>
                      </div>
                      <div class="d-flex flex-row-reverse">
                        <span
                          style={{ width: "25rem" }}
                          class="d-flex justify-content-between"
                        >
                          <p>Course after Discount</p>
                          <h6 class="p-2">
                            <b>Rs.27,000</b>
                          </h6>
                        </span>
                      </div>
                      <div class="d-flex flex-row-reverse">
                        <span
                          style={{ width: "25rem" }}
                          class="d-flex justify-content-between"
                        >
                          <p>GST</p>
                          <h6 class="p-2">
                            <b>18%</b>
                          </h6>
                        </span>
                      </div>
                      <hr />
                      <div class="d-flex flex-row-reverse">
                        <span
                          style={{ width: "25rem" }}
                          class="d-flex flex-row-reverse"
                        >
                          <span class="d-flex align-items-center">
                            <h6>
                              <b>TOTAL AMOUNT</b>
                            </h6>
                            <h6 class="p-2" style={{ color: "green" }}>
                              <b>Rs.32,000</b>
                            </h6>
                          </span>
                        </span>
                      </div>
                      <div
                        style={{ backgroundColor: "#18171b", height: "4rem" }}
                        class="d-flex  align-items-center ml-5 rounded-3 mt-5"
                      >
                        <div
                          style={{ width: "10%", position: "relative" }}
                          class="d-flex "
                        >
                          <div>
                            <div style={{ position: "relative" }}>
                              <div
                                id="check-box"
                                style={{ left: "4rem", bottom: "0.8rem" }}
                              >
                                <div class="checkbox-bx">
                                  <label>
                                    <input type="checkbox" name="" id="" />
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ width: "90%" }}
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span>Accept to the terms and conditions</span>
                          <button id="pay-btn" style={{ marginRight: "1rem" }}>
                            Pay Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </> */}
                </form>
              </div>
            </div>
              {userRole == "franchise" && (
                <div class="col col-12 col-md-3">
                  <div id="profile-container" class="m-1">
                    <div id="profile-img">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*"
                        name="profileImage"
                      />
                      {previewUrl !== "" ? (
                        <img id="image" src={previewUrl} alt="" />
                      ) : (
                        <img
                          src="https://www.pngkey.com/png/full/72-729716_user-avatar-png-graphic-free-download-icon.png"
                          alt=""
                        />
                      )}
                    </div>
                    <div class="upld">
                      <b>Upload</b>
                    </div>
                    <h5>Rakesh</h5>
                    <h5 style={{ textAlign: "center" }}>
                      <i class="fa-solid fa-id-badge"></i> Set as <b>Active</b>
                    </h5>
                  </div>
                </div>
              )}
              {userRole == "branchstaff" && (
                <div class="col col-12 col-md-3">
                  <div id="profile-container" class="m-1">
                    <div id="profile-img">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*"
                        name="profileImage"
                      />
                      {previewUrl !== "" ? (
                        <img id="image" src={previewUrl} alt="" />
                      ) : (
                        <img
                          src="https://www.pngkey.com/png/full/72-729716_user-avatar-png-graphic-free-download-icon.png"
                          alt=""
                        />
                      )}
                    </div>
                    <div class="upld">
                      <b>Upload</b>
                    </div>
                    <h5>Rakesh</h5>
                    <h5 style={{ textAlign: "center" }}>
                      <i class="fa-solid fa-id-badge"></i> Set as <b>Active</b>
                    </h5>
                  </div>
                </div>
              )}
              {/* <div class="col col-3">
            <div id="profile-container" class="m-1">
              <div id="profile-img">
                <input type="file" />
                <img
                  src="https://www.pngkey.com/png/full/72-729716_user-avatar-png-graphic-free-download-icon.png"
                  alt=""
                />
              </div>
              <div class="upld">
                <b>Upload</b>
              </div>
              <h5>Rakesh</h5>
              <h5 style={{ textAlign: "center" }}>
                <i class="fa-solid fa-id-badge"></i> Set as <b>Active</b>
              </h5>
            </div>
          </div> */}
            </div>
      
        </form>
      </ThemeProvider>
    </AddStudentFormContainer>
  );
}

const AddStudentFormContainer = styled.div`

  width: 100%;
  padding: 1rem;

  
  .btn {
    background-color: var(--navbar-dark-primary);
    padding: 1rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  #line {
    background-color: var(--icon-color);
    height: 3px;
    width: 100%;
  }

  margin-top: 2rem;
  #otp-container {
    position: absolute;
    top: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #313131;
    height: 20rem;
    width: 20rem;
    top: 30%;
    left: 40%;
  }
  .otp-input {
    color: black;
    border-radius: 8px;
  }
  #otp ::placeholder {
    color: black;
  }
  .verify-btn {
    background-color: lightgrey;
    color: #1d1e22;
    border-radius: 1rem;
    padding: 1rem;
  }
  input::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  #add-student-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: gray !important;
  }

  h2,
  p,
  div,
  h6 {
    color: white;
  }
  .add-course-btn:hover {
    background-color: green;
  }
  .cancel-btn:hover {
    background-color: red;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }

  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }

  h5 {
    text-align: center;
    color: white;
  }

  input {
    color: white;
  }

  /* Status Section Styles */
  .status-section {
    margin-top: 1rem;
  }

  .status-label {
    color: white;
  }

  .status-options {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .active-dot {
    background-color: green;
  }

  .inactive-dot {
    background-color: red;
  }

  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #add-student-container {
      padding: 1rem;
    }

    #profile-container {
      margin-top: 1rem;
    }
  }
  /* Style for active radio button */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }
  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }
  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }
  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  .add-course-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }
  .mini-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
  }
  .checkbox-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #1d1e22;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to individual boxes within containers */
  }
  .checkbox-container {
    display: flex; /* Display checkboxes horizontally */
    justify-content: flex-start; /* Align checkboxes to the start */
    flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
    margin-bottom: 20px; /* Add margin at the bottom */
  }
  .checkbox-container div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px; /* Add margin at the bottom of each checkbox */
  }

  .checkbox-container div:last-child {
    margin-right: 0; /* Remove margin for the last checkbox */
  }
  .pink-btn {
    border-color: rgb(201, 66, 149); /* Change the outline color to pink */
    color: rgb(201, 66, 149); /* Change text color to ensure visibility */
  }
  .generate-btn:hover {
    background-color: green;
  }
  .pink-btn:hover {
    background-color: #313131;
    color: rgb(255, 255, 255);
  }
  .btn-verify {
    background-color: green;
    color: white;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    cursor: pointer;
  }

  .add-course-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }
  @media (max-width: 575px) {
    .add-course-btn,
    .cancel-btn {
      align-items: center;
      width: 100%; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
    }
  }
`;
export default AddStudentForm;
