import { enqueueSnackbar } from "notistack";

const validateFormBranchStaffData = (formData) => {
  // Define an array to hold error messages.
  const errors = [];
  console.log(formData);
  // Function to add an error message and return false.
  const addError = (message) => {
    errors.push(message);
    enqueueSnackbar(message, { variant: "error" });
    return false; // Immediately return false to stop further validation.
  };

  // Validate age
  if (!/^\d+$/.test(formData.age)) return addError("Age must be a number.");
  if (!/^\d+$/.test(formData.monthlySalary)) return addError("Salary must be a number.");

  // Validate bank details
  if (!formData.bankAccountName.trim())
    return addError("Bank Account Name is required.");
  if (!/^\d{9,18}$/.test(formData.bankAccountNumber))
    return addError("Bank Account Number must be between 9 and 18 digits.");
  if (!formData.bankBranchName.trim())
    return addError("Bank Branch Name is required.");

  // Validate blood group
  if (!/^[A-Za-z0-9+-]+$/.test(formData.bloodGroup))
    return addError("Invalid Blood Group format.");

  // Validate college name
 
  // Validate country
  if (!formData.country.trim()) return addError("Country is required.");

  // Validate date of birth
  if (!formData.dateOfBirth || isNaN(new Date(formData.dateOfBirth).getTime()))
    return addError("Valid Date of Birth is required.");

  // Validate district
  if (!formData.district.trim()) return addError("District is required.");

  // Validate address details
  if (!formData.doorFlatNumber.trim())
    return addError("Door/Flat Number is required.");
  if (!formData.streetName.trim()) return addError("Street Name is required.");
  if (!/^\d{6}$/.test(formData.pinCode))
    return addError("Pincode must be 6 digits.");

  // Validate email
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
    return addError("Valid Email is required.");

  // Validate father's name
  if (!formData.fatherName.trim()) return addError("Father Name is required.");

  // Validate gender
  if (!["Male", "Female", "Other"].includes(formData.gender))
    return addError("Gender is required.");

  // Validate Instagram URL
  if (formData.instagram && !formData.instagram.startsWith("https://"))
    return addError("Instagram link must start with https://");

  // Validate job description

  // Validate landmark
  if (!formData.landMark.trim()) return addError("Landmark is required.");

  // Validate monthly salary
  if (!/^\d+$/.test(formData.monthlySalary))
    return addError("Monthly Salary must be a valid number.");

  // Validate mother's name
  if (!formData.motherName.trim()) return addError("Mother Name is required.");

  // Validate PAN card number
  if (!formData.panCardNumber)
    return addError("Valid PAN Card Number is required.");

  // Validate password
  if (!formData.password.trim()) return addError("Password is required.");

  // Validate phone number
  if (!formData.phoneNumber) return addError("Phone Number must be 10 digits.");

  // Validate state
  if (!formData.state.trim()) return addError("State is required.");

  // Validate username
  if (!formData.userName.trim()) return addError("Username is required.");

  // Validate WhatsApp number
  if (!/^\d{10}$/.test(formData.whatsappNumber))
    return addError("WhatsApp Number must be 10 digits.");

  return true;
};

const validateStudentFormData = (formData, refer) => {
  if (refer) {
    if (!formData.personalInfo.studentName) {
      enqueueSnackbar("Student name is required", { variant: "warning" });
      return false;
    }
    if (!formData.personalInfo.age) {
      enqueueSnackbar("Age is required", { variant: "warning" });
      return false;
    } else if (isNaN(formData.personalInfo.age) || formData.personalInfo.age <= 0) {
      enqueueSnackbar("Please enter a valid age", { variant: "warning" });
      return false;
    }    

   

    // Validate familyDetails
    if (!formData.familyDetails.fathersName) {
      enqueueSnackbar("Father's name is required", {
        variant: "warning",
      });
      return false;
    }
    if (!formData.familyDetails.fathersPhone) {
      enqueueSnackbar("Father's phone number is required", {
        variant: "warning",
      });
      return false;
    }
    if (!formData.familyDetails.mothersName) {
      enqueueSnackbar("Mother's name is required", {
        variant: "warning",
      });
      return false;
    }
    if (!formData.familyDetails.mothersPhone) {
      enqueueSnackbar("Mother's phone number is required", {
        variant: "warning",
      });
      return false;
    }

    // Validate courseDetail
    if (!formData.courseDetail.courseName._id) {
      enqueueSnackbar("Course name is required", { variant: "warning" });
      return false;
    }
    if (!formData.courseDetail.conceptName._id) {
      enqueueSnackbar("Concept name is required", { variant: "warning" });
      return false;
    }
    if (!formData.courseDetail.branchName._id) {
      enqueueSnackbar("Branch name is required", { variant: "warning" });
      return false;
    }

    return true;
  } else {
    // Validate address
    if (!formData.personalInfo.studentName) {
      enqueueSnackbar("Student name is required", { variant: "warning" });
      return false;
    }
    if (!formData.personalInfo.age) {
      enqueueSnackbar("Age is required", { variant: "warning" });
      return false;
    } else if (isNaN(formData.personalInfo.age) || formData.personalInfo.age <= 0) {
      enqueueSnackbar("Please enter a valid age", { variant: "warning" });
      return false;
    }   
    if (!formData.personalInfo.dob) {
      enqueueSnackbar("Date of birth is required", {
        variant: "warning",
      });
      return false;
    }
    if (!formData.personalInfo.bloodGroup) {
      enqueueSnackbar("bloodGroup is required", { variant: "warning" });
      return false;
    }
    if (!formData.personalInfo.gender) {
      enqueueSnackbar("Gender is required", { variant: "warning" });
      return false;
    }
    if (!formData.personalInfo.classCourse) {
      enqueueSnackbar("class is required", { variant: "warning" });
      return false;
    }
    if (!formData.personalInfo.schoolCollege) {
      enqueueSnackbar("school is required", { variant: "warning" });
      return false;
    }
    if (!formData.address.flatNo) {
      enqueueSnackbar("Flat number is required", { variant: "warning" });
      return false;
    }
    if (!formData.address.street) {
      enqueueSnackbar("Street is required", { variant: "warning" });
      return false;
    }
    if (!formData.address.country) {
      enqueueSnackbar("Country is required", { variant: "warning" });
      return false;
    }
    if (!formData.address.state) {
      enqueueSnackbar("State is required", { variant: "warning" });
      return false;
    }
    if (!formData.address.district) {
      enqueueSnackbar("District is required", { variant: "warning" });
      return false;
    }


    if (!formData.address.pinCode) {
      enqueueSnackbar("Pincode is required", { variant: "warning" });
      return false;
    }
    if (!/^\d{6}$/.test(formData.address.pinCode)) {
      enqueueSnackbar("Pincode must be a 6-digit number", {
        variant: "warning",
      });
      return false;
    }
    // Validate familyDetails
    if (!formData.familyDetails.fathersName) {
      enqueueSnackbar("Father's name is required", {
        variant: "warning",
      });
      return false;
    }
    if (!formData.familyDetails.fathersPhone) {
      enqueueSnackbar("Father's phone number is required", {
        variant: "warning",
      });
      return false;
    }
    if (!formData.familyDetails.mothersName) {
      enqueueSnackbar("Mother's name is required", {
        variant: "warning",
      });
      return false;
    }
    if (!formData.familyDetails.mothersPhone) {
      enqueueSnackbar("Mother's phone number is required", {
        variant: "warning",
      });
      return false;
    }

    // Validate courseDetail
    if (!formData.courseDetail.courseName._id) {
      enqueueSnackbar("Course name is required", { variant: "warning" });
      return false;
    }
    if (!formData.courseDetail.conceptName._id) {
      enqueueSnackbar("Concept name is required", { variant: "warning" });
      return false;
    }
    if(!formData.imageACCESSKEY){
       enqueueSnackbar("Image is required", { variant: "warning" });
       return false;
    }
    return true;
  }
  // Validate personalInfo
};
const validateHosForm=(data) =>{
  // Validate email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(data.email)) {
      enqueueSnackbar("Invalid email format.", { variant: 'error' });
      return false;
  }

  // Validate password (at least 8 characters, 1 uppercase, 1 lowercase, 1 number)
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
  if (!passwordRegex.test(data.password)) {
      enqueueSnackbar("Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, and 1 number.", { variant: 'error' });
      return false;
  }

  // Validate age (should be a number and at least 18)
  const age = parseInt(data.age);
  if (isNaN(age) || age < 18) {
      enqueueSnackbar("Age must be a number and at least 18.", { variant: 'error' });
      return false;
  }

  // Validate phone number (example: should be 10 digits)
  const phoneRegex = /^\d{10}$/;
  if (!phoneRegex.test(data.phoneNumber)) {
      enqueueSnackbar("Phone number must be 10 digits.", { variant: 'error' });
      return false;
  }

  // Validate bank account details
  if (!data.bankAccountName) {
      enqueueSnackbar("Bank account name is required.", { variant: 'error' });
      return false;
  }
  if (!data.bankAccountNumber) {
      enqueueSnackbar("Bank account number is required.", { variant: 'error' });
      return false;
  }
  if (!data.bankBranchName) {
      enqueueSnackbar("Bank branch name is required.", { variant: 'error' });
      return false;
  }

  // Validate other individual fields
  if (!data.fatherName) {
      enqueueSnackbar("Father's name is required.", { variant: 'error' });
      return false;
  }
  if (!data.motherName) {
      enqueueSnackbar("Mother's name is required.", { variant: 'error' });
      return false;
  }
  if (!data.bloodGroup) {
      enqueueSnackbar("Blood group is required.", { variant: 'error' });
      return false;
  }
  if (!data.country) {
      enqueueSnackbar("Country is required.", { variant: 'error' });
      return false;
  }
  if (!data.state) {
      enqueueSnackbar("State is required.", { variant: 'error' });
      return false;
  }
  if (!data.district) {
      enqueueSnackbar("District is required.", { variant: 'error' });
      return false;
  }
  if (!data.pinCode) {
      enqueueSnackbar("Pin code is required.", { variant: 'error' });
      return false;
  }
  if (!data.streetName) {
      enqueueSnackbar("Street name is required.", { variant: 'error' });
      return false;
  }
  if (!data.monthlySalary) {
      enqueueSnackbar("Monthly salary is required.", { variant: 'error' });
      return false;
  }
  if (!data.panCardNumber) {
      enqueueSnackbar("PAN card number is required.", { variant: 'error' });
      return false;
  }
  if (!data.ifscCode) {
      enqueueSnackbar("IFSC code is required.", { variant: 'error' });
      return false;
  }

  return true;
};

const validateFormFranchiseMemberData = (formData) => {
  // Define an array to hold error messages.
  const errors = [];
  console.log(formData);
  // Function to add an error message and return false.
  const addError = (message) => {
    errors.push(message);
    enqueueSnackbar(message, { variant: "error" });
    return false; // Immediately return false to stop further validation.
  };

  // Validate age
  if (!/^\d+$/.test(formData.age)) return addError("Age must be a number.");
 

  // Validate bank details
  if (!formData.bankAccountName)
    return addError("Bank Account Name is required.");
  if (!/^\d{9,18}$/.test(formData.bankAccountNumber))
    return addError("Bank Account Number must be between 9 and 18 digits.");
  if (!formData.bankBranchName)
    return addError("Bank Branch Name is required.");

  // Validate blood group
  if (!/^[A-Za-z0-9+-]+$/.test(formData.bloodGroup))
    return addError("Invalid Blood Group format.");

  // Validate college name
 
  // Validate country
  if (!formData.country) return addError("Country is required.");

  // Validate date of birth
  if (!formData.dateOfBirth || isNaN(new Date(formData.dateOfBirth).getTime()))
    return addError("Valid Date of Birth is required.");

  // Validate district
  if (!formData.district) return addError("District is required.");

  // Validate address details
  if (!formData.doorFlatNumber)
    return addError("Door/Flat Number is required.");
  if (!formData.streetName) return addError("Street Name is required.");
  if (!/^\d{6}$/.test(formData.pinCode))
    return addError("Pincode must be 6 digits.");

  // Validate email
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
    return addError("Valid Email is required.");

  // Validate father's name
  if (!formData.fatherName) return addError("Father Name is required.");

  // Validate gender
  if (!["Male", "Female", "Other"].includes(formData.gender))
    return addError("Gender is required.");

  // Validate Instagram URL
  if (formData.instagram && !formData.instagram.startsWith("https://"))
    return addError("Instagram link must start with https://");

  // Validate job description

  // Validate landmark
  if (!formData.landMark) return addError("Landmark is required.");

  // Validate monthly salary


  // Validate mother's name
  if (!formData.motherName) return addError("Mother Name is required.");

  // Validate PAN card number
  if (!formData.panCardNumber)
    return addError("Valid PAN Card Number is required.");

  // Validate password
  if (!formData.password) return addError("Password is required.");

  // Validate phone number
  if (!formData.phoneNumber) return addError("Phone Number must be 10 digits.");

  // Validate state
  if (!formData.state) return addError("State is required.");

  // Validate username
  if (!formData.userName) return addError("Username is required.");

  // Validate WhatsApp number
  if (!/^\d{10}$/.test(formData.whatsappNumber))
    return addError("WhatsApp Number must be 10 digits.");

  return true;
};

export { validateStudentFormData, validateFormBranchStaffData,validateHosForm, validateFormFranchiseMemberData};
