import React, { useEffect, useState } from "react";
import {
  DialogTitle,    // Ensure this is imported
  DialogContent,  // Ensure this is imported
  DialogActions,  // Ensure this is imported
} from "@mui/material";
import axios from "axios";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  LinearProgress,
  Stack,
  Box,
  ButtonBase
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Carousel } from 'react-responsive-carousel';
import {Viewstats} from "../Tables/StudentsTable.jsx"
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import InfoIcon from "@mui/icons-material/Info";
import MiniNavBar from "./MiniNavBar";
import { deleteData, fetchData, imageURL } from "../../../Api";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { URL as url} from "../../../assets/mocData/config";
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';

const getIcon = (medium) => {
  const iconSize = 22; // You can adjust this value to change the size
  switch (medium) {
    case 'facebook':
      return <FaFacebook size={iconSize} />;
    case 'instagram':
      return <FaInstagram size={iconSize} />;
    case 'whatsapp':
      return <FaWhatsapp size={iconSize} />;
    default:
      return medium; // Return the text for unrecognized mediums
  }
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const UserDetails = ({
    setEditDataContainer,
  age,
  bankAccountName,
  bankAccountNumber,
  bankBranchName,
  bloodGroup,
  collegeName,
  country,
  createdAt,
  dateOfBirth,
  degree,
  district,
  doorFlatNumber,
  email,
  facebook,
  fatherName,
  gender,
  ifscCode,
  imageACCESSKEY,
  instagram,
  jobDescription,educationDetails,
  joinedDate,
  landMark,
  monthlySalary,
  motherName,
  newForWorking,
  otherDetails,
  panCardNumber,
  phoneNumber,
  pinCode,
  state,
  status,
  streetName,
  updatedAt,
  userName,
  userType,
  user_id,
  verified,
  whatsappNumber,
  workingOption,
  refferalID,
  _id,
}) => {
  const navigate = useNavigate();
  const {userRole,userToken,setProfileViewData, profileViewData} = useGlobalContext();
 
  const [open, setOpen] = useState(false);

  const handleBack = () => {
    navigate(-1); // Navigates back to the previous page
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [marketingDatas,setMarketingDatas]=useState([]);
  const [data,setData]=useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const header={userRole:userRole,userToken:userToken}
// VIP table routes
const [vipStudentReferralTable, setVipStudentReferralTable] = useState([]);
const [vipVipReferralTable, setVipVipReferralTable] = useState([]);
const [vipFranchiseReferralTable, setVipFranchiseReferralTable] = useState([]);
const [vipVipFranchiseReferralTable, setVipVipFranchiseReferralTable] = useState([]);
const [vipStudentRevenueTable, setVipStudentRevenueTable] = useState([]);
const [vipVipRevenueTable, setVipVipRevenueTable] = useState([]);
const [vipFranchiseRevenueTable, setVipFranchiseRevenueTable] = useState([]);
const [vipVipFranchiseRevenueTable, setVipVipFranchiseRevenueTable] = useState([]);

const CashAmountByPercentage = (amount, percentage) => {
  const vipCashAmount = (amount * percentage) / 100;
  return vipCashAmount;
};

const formatDate = (dateOfBirth) => {
  if (!dateOfBirth) return ""; // Handle undefined or null dates
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateOfBirth));
};


useEffect(() => {
  const fetchVipApis = async (_id) => {
    try {
      const studentReferralTable = await fetchData(`${url}/students/pending/${_id}`, header);
      setVipStudentReferralTable(studentReferralTable);
    } catch (error) {
    //  console.error('Error fetching student referral table:', error);
    }

    try {
      const vipReferralTable = await fetchData(`${url}/vip/refer/vip/${_id}`, header);
      setVipVipReferralTable(vipReferralTable.data);
    } catch (error) {
    //  console.error('Error fetching VIP referral table:', error);
    }

    try {
      const vipFranchiseReferralTable = await fetchData(`${url}/vip/refer/vipfranchise/${_id}`, header);
      setVipVipFranchiseReferralTable(vipFranchiseReferralTable.data);
    } catch (error) {
    //  console.error('Error fetching VIP franchise referral table:', error);
    }

    try {
      const franchiseReferralTable = await fetchData(`${url}/vip/refer/franchise/${_id}`, header);
      setVipFranchiseReferralTable(franchiseReferralTable.data);
    } catch (error) {
    //  console.error('Error fetching franchise referral table:', error);
    }

    try {
      const vipFranchiseRevenueTable = await fetchData(`${url}/vip/revenue/vipfranchise/${_id}`, header);
      setVipVipFranchiseRevenueTable(vipFranchiseRevenueTable.data);
    } catch (error) {
    //  console.error('Error fetching VIP franchise revenue table:', error);
    }

    try {
      const franchiseRevenueTable = await fetchData(`${url}/vip/revenue/franchise/${_id}`, header);
      setVipFranchiseRevenueTable(franchiseRevenueTable.data);
    } catch (error) {
    //  console.error('Error fetching franchise revenue table:', error);
    }

    try {
      const vipRevenueTable = await fetchData(`${url}/vip/revenue/vip/${_id}`, header);
      setVipVipRevenueTable(vipRevenueTable.data);
    } catch (error) {
    // console.error('Error fetching VIP revenue table:', error);
    }

    try {
      const studentRevenueTable = await fetchData(`${url}/vip/revenue/student/${_id}`, header);
      setVipStudentRevenueTable(studentRevenueTable.data);
    } catch (error) {
    //  console.error('Error fetching student revenue table:', error);
    }
  };

  if (_id) {
    fetchVipApis(_id);
  }
}, [_id]);

// VIP franchise table routes
const [pincodeReferral, setPincodeReferral] = useState([]);
const [pincodeRevenue, setPincodeRevenue] = useState([]);
const [values, setValues] = useState({
  franchiseBusinessCommission: 0,
  vipReferralCommission: 0,
  vipFranchiseReferralRevenue: 0,
  franchiseReferralRevenue: 0
});
const fetchSettings = async () => {
  const headers = {
    userRole: "admin",
    userToken: userToken,
  };
  try {
    let data = await fetchData(`${url}/admin/settings`, headers);
  
   
   

    setValues({
      franchiseBusinessCommission:parseInt(data.data.franchiseBusinessCommission)/100,
      vipReferralCommission:parseInt(data.data.vipReferralCommission),
      vipFranchiseReferralRevenue:parseInt(data.data.vipFranchiseReferralRevenue),
      franchiseReferralRevenue:parseInt(data.data.franchiseReferralRevenue)
    });
  } catch (error) {
    console.log(error);
  }
};
useEffect(() => {
  fetchSettings();
}, []);
useEffect(() => {
  const fetchVipFranchiseApis = async (_id) => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      // Fetching referral data
      const referralResponse = await axios.post(`
        ${url}/vip/vipfranchise/pending`,
        { pinCodes: otherDetails.pinCodes }, // Assuming this is an array of pincode
        {
          headers: {
            Authorization: `Bearer ${header.userToken}`,
            role: "vipFranchise",
          },
        }
      );
      console.log('Referral Data:pincode ', referralResponse.data); // Debug: Log referral data
      setPincodeReferral(referralResponse.data); // Set the referral data
    } catch (error) {
      console.log('Error fetching pincode referral data:', error);
    }

    try {
      // Fetching revenue data
      const revenueResponse = await axios.post(
        `${url}/vip/vipfranchise/revenue`,
        { pinCodes: otherDetails.pinCodes },
        {
          headers: {
            Authorization: `Bearer ${header.userToken}`,
            role: header.userRole,
          },
        }
      );
      console.log('Revenue Data: pincode:', revenueResponse.data); // Debug: Log revenue data
      setPincodeRevenue(revenueResponse.data); // Set the revenue data
    } catch (error) {
      console.log('Error fetching pincode revenue data:', error);
    }
  };


  if (_id) {
    fetchVipFranchiseApis(_id); // Fetch API data when _id is available
  }
}, [_id]);

// Franchise table routes
const [franchiseStudentReferrals, setFranchiseStudentReferrals] = useState([]);
const [franchiseStudentAdmission, setFranchiseStudentAdmission] = useState([]);
const [franchiseStudentCompleted, setFranchiseStudentCompleted] = useState([]);
const [franchiseBranchStaffset, setfranchiseBranchStaff] = useState([]);
const [viewStats, setViewStatsContainer] = useState(false);
const paymentPending = (totalPayment, paymentPending) => {
  return parseInt(totalPayment) - parseInt(paymentPending);
};
useEffect(() => {
  const fetchFranchiseApis = async (_id) => {
    try {
      const franchiseStudentReferrals = await fetchData(
        `${url}/students/pending/franchise/${email}`,
        header
      );
      setFranchiseStudentReferrals(franchiseStudentReferrals);
    } catch (error) {
     // console.error('Error fetching student referral table:', error);
    }

    try {
      const franchiseStudentAdmission = await fetchData(
        `${url}/students/studying/franchise/${email}`,
        header
      );
      setFranchiseStudentAdmission(franchiseStudentAdmission);
    } catch (error) {
    //  console.error('Error fetching student admission table:', error);
    }

    try {
      const franchiseStudentCompleted = await fetchData(
        `${url}/students/completed/franchise/${email}`,
        header
      );
      setFranchiseStudentCompleted(franchiseStudentCompleted);
    } catch (error) {
     // console.error('Error fetching student completed table:', error);
    }

    try {
      let headers = {
        userRole: userRole,
        userToken: userToken,
      };
      const franchiseBranchStaff = await fetchData(
        `${url}/franchise/branchstaff/${_id}`,
        headers
      );
      
      // Set the state to the correct array of branch staff
      setfranchiseBranchStaff(franchiseBranchStaff.data);
     // console.log('Data fetched: ', franchiseBranchStaff.data);
    } catch (error) {
   //   console.error('Error fetching branch staff:', error);
    }
  };

  if (_id) {
    fetchFranchiseApis(_id);
  }
}, [_id]);

  const deleteUser = async (id) => {
    let headers = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      await deleteData(`${url}/admin/users/${id}`, headers);
      enqueueSnackbar("User deleted successfully", {
        autoHideDuration: 1000,
        variant: "success",
      });
      navigate(-1); // Navigate back after successful deletion
    } catch (error) {
      enqueueSnackbar("Failed to delete user", {
        autoHideDuration: 3000,
        variant: "error",
      });
    //  console.error("Error deleting user:", error);
    }
  };

  
  const handleClickOpen = () => {
    setDialogOpen(true); // Open the delete confirmation dialog
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Close the dialog without deleting
  };

  const handleDeleteConfirm = () => {
    deleteUser(_id); // Call delete function if confirmed
    setDialogOpen(false); // Close dialog after confirming
  };


  const fetchMarketingData = async () => {
    let headers = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      const response = await fetchData(`${url}/marketting/getdata?user_mail=${email}`, headers);
      //console.log(response)
      setData(response);
    } catch (error) {
     // console.error("Failed Fetching data:", error);
     // enqueueSnackbar("Failed Fetching data", { autoHideDuration: 3000,variant: "error",});
    }
  };

  useEffect(() => {
    fetchMarketingData();
  }, []);

  

  const openModal = (images) => {
    setCurrentImages(images);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImages([]);
  };
  const handleIconClickinsta = () => {
    window.location.href = instagram; // Redirect to the Instagram link
  };
  const handleIconClickfb = () => {
    window.location.href = facebook; // Redirect to the Instagram link
  };
  const Component = ({ facebook}) => {

    handleIconClickfb ()};
  const YourComponent = ({ instagram }) => {

   handleIconClickinsta ()};
   const formattedDate = formatDate(dateOfBirth);
if (userType == "vip") {  
  return (
    <ProfileContainer style={{ backgroundColor: "#18171b", color: "#fff" }}>
      <NavBar />
      <MiniNavBar />
      <Container className="scroll" maxWidth="lg" sx={{ py: 5 }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ mb: 4 ,mt:4, color: "#fff" }}
        >
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
          <div>
            <Button
              onClick={() => setEditDataContainer(true)}
              variant="outlined"
              color="secondary"
            >
              Edit
            </Button>
            {userRole == "admin" && (
              <Button
              style={{ marginLeft: "1em" }}
              variant="outlined"
              color="error"
              onClick={handleClickOpen} // Open confirmation dialog when delete button is clicked
            >
              Delete
            </Button>
            )}
          </div>
        </Stack>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card sx={{ mb: 4, bgcolor: "var(--navbar-dark-primary)" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <CardMedia
                  component="img"
                  image={imageURL(imageACCESSKEY)}
                  sx={{
                    width: 150,
                    height: 150,
                    borderRadius: "50%",
                    mx: "auto",
                  }}
                />
                            <Text variant="h6" sx={{ mt: 2 }}>
                {workingOption.includes("vipFranchise") ? (
                  <b>VIP / VIP FRANCHISE</b>
                ) : (
                  <b>{userType.toUpperCase()}</b>
                )}
              </Text>
              <span
  style={{
    display: 'inline-block', // Allows for proper height/width control
    padding: '8px 20px', // Comfortable padding for the span element
    fontSize: '1rem', // Adjust font size
    fontWeight: 'bold', // Bold text for emphasis
    color: '#ffffff', // White text color for contrast
    background: 'linear-gradient(45deg, #f00d88, #ff6f9f)', // Gradient background with shades of #f00d88
    borderRadius: '30px', // Rounded pill-like design
    boxShadow: '0 4px 10px rgba(240, 13, 136, 0.3)', // Soft shadow with a tint of #f00d88
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transitions for hover effects
   // Pointer cursor for interactivity
  }}
  
 >
      {otherDetails.vip_status}
    </span>
                <br/>
                <Button
                  onClick={() => setOpen(true)}
                  variant="outlined"
                  sx={{ mt: 2 }}
                >
                  View Tables
                </Button>
                {/* <Button variant="outlined" sx={{ mt: 2 }}>
                  Message
                </Button> */}
              </CardContent>
            </Card>
            <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    overflowX: "scroll",
                    scrollbarWidth: "none" /* Firefox */,
                    msOverflowStyle: "none" /* IE and Edge */,
                    "&::-webkit-scrollbar": {
                      display: "none" /* Chrome, Safari */,
                    },
                  }}
                >
                  <ListItem>
                    <ListItemIcon sx={{ color: "#fff" }}>
                      <WhatsAppIcon />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: "white" }}
                      primary={whatsappNumber}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon
                      sx={{ color: "#fff", cursor: "pointer" }}
                      onClick={handleIconClickinsta}
                    >
                      <InstagramIcon />
                    </ListItemIcon>
                    {/* Hiding the text part */}
                    <ListItemText
                      sx={{ display: "none" }}
                      primary={instagram}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon
                      sx={{ color: "#fff", cursor: "pointer" }}
                      onClick={handleIconClickfb}
                    >
                      <FacebookIcon />
                    </ListItemIcon>
                    {/* Hiding the text part */}
                    <ListItemText sx={{ display: "none" }} primary={facebook} />
                  </ListItem>
                </Box>
              </CardContent>
            </Card>
            <Card
              sx={{ bgcolor: "var(--navbar-dark-primary)", marginTop: "1rem" }}
            >
              <CardContent>
                <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                  <b>Bank Details</b>
                </Text>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text variant="body2">Bank Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {bankAccountName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Bank Account Number</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {bankAccountNumber}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Branch Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {bankBranchName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">IFSC Code</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {ifscCode}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">PanCard Number</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {panCardNumber}
                    </Text>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card sx={{ mb: 4, bgcolor: "var(--navbar-dark-primary)" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text variant="body2">Full Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {userName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Email</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {email}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Phone</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {phoneNumber}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Vip Total Collection</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                    {profileViewData.totalCollection}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Vip Total Revenue</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                    {profileViewData.totalRevenue}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  
                </Grid>
              </CardContent>
            </Card>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
                  <CardContent>
                    <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                      <b>Personal Information</b>
                    </Text>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Text variant="body2">Age</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {age}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Date of Birth</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {formattedDate}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Gender</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {gender}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Blood Group</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {bloodGroup}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Degree & College</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {educationDetails?.map((educationDetail) => {
                            return (
                              <p>
                                <b>
                                  {educationDetail.degree}-
                                  {educationDetail.collegeName}
                                </b>
                              </p>
                            );
                          })}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Father Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {fatherName}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Mother Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {motherName}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Currently Unemployed</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {newForWorking}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2"> Old Job Description</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {jobDescription?.map((job) => {
                            return (
                              <p>
                                <b>
                                  {job.jobDescription}-{job.companyName}
                                </b>
                              </p>
                            );
                          })}
                        </Text>
                      </Grid>

                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary"></Text>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
                  <CardContent>
                    <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                      <b>Address Details</b>
                    </Text>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Text variant="body2">Door/Flat no</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {doorFlatNumber}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Street Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {streetName}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Landmark</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {landMark}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">District</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {district}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">State</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {state}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Country</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {country}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Pin Code</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {pinCode}
                        </Text>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" ,  backgroundColor: "var(--background)" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              TABLES
            </Typography>
          </Toolbar>
        </AppBar>
        <ImageList>
        <CrmAdminFranchiseViewContainer>

        <div id="table-container">
  <div className="scroll">
    <h6>STUDENT REFERRALS</h6>
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="t-head rounded-start-3" scope="col">S.no</th>
          <th className="t-head" scope="col">Student Name</th>
          <th className="t-head" scope="col">Age</th>
          <th className="t-head" scope="col">Concept Name</th>
          <th className="t-head" scope="col">Course Name</th>
          <th className="t-head" scope="col">Father Name</th>
          <th className="t-head" scope="col">Father Phone Number</th>
          <th className="t-head" scope="col">Father WhatsApp Number</th>
          <th className="t-head" scope="col">Mother Name</th>
          <th className="t-head" scope="col">Mother Phone Number</th>
          <th className="t-head" scope="col">Mother WhatsApp Number</th>
          <th className="t-head" scope="col">Status</th>
        </tr>
      </thead>
      <tbody className="table-dark">
        {Array.isArray(vipStudentReferralTable) && vipStudentReferralTable.length > 0 ? (
          vipStudentReferralTable.map((user, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{user.personalInfo.studentName}</td>
              <td>{user.personalInfo.age}</td>
              <td>{user.courseDetail.courseName.conceptName}</td>
              <td>{user.courseDetail.courseName.courseName}</td>
              <td>{user.familyDetails.fathersName}</td>
              <td>{user.familyDetails.fathersPhone}</td>
              <td>{user.familyDetails.fathersWhatsAppNumber}</td>
              <td>{user.familyDetails.mothersName}</td>
              <td>{user.familyDetails.mothersPhone}</td>
              <td>{user.familyDetails.mothersWhatsAppNumber}</td>
              <td>
                <span className="badge" style={{
                  height: "80%", width: "100%", color: 'white',
                  backgroundColor: '#F6C324',
                  border: `1px solid #F6C324`,
                  padding: "0.6rem", textAlign: 'center'
                }}>
                  {user.status}
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="12">No student referrals available.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>

{/* Second table */}
<div id="table-container">
  <div className="scroll">
    <h6>REFERRED VIP</h6>
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="t-head rounded-start-3" scope="col">S.no</th>
          <th className="t-head" scope="col">VIP Name</th>
          <th className="t-head" scope="col">Phone Number</th>
          <th className="t-head" scope="col">Email</th>
          <th className="t-head" scope="col">Referral Status</th>
        </tr>
      </thead>
      <tbody className="table-dark">
        {Array.isArray(vipVipReferralTable) && vipVipReferralTable.length > 0 ? (
          vipVipReferralTable.map((user, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{user.userName}</td>
              <td>{user.phoneNumber}</td>
              <td>{user.email}</td>
              <td>{user.status}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No VIP referrals available.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>

{/* VIP Franchise table */}
<div id="table-container">
  <div className="scroll">
    <h6>REFERRED VIP FRANCHISE</h6>
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="t-head rounded-start-3" scope="col">S.no</th>
          <th className="t-head" scope="col">VIP Franchise Name</th>
          <th className="t-head" scope="col">Phone Number</th>
          <th className="t-head" scope="col">Email</th>
          <th className="t-head" scope="col">Referral Status</th>
        </tr>
      </thead>
      <tbody className="table-dark">
        {Array.isArray(vipVipFranchiseReferralTable) && vipVipFranchiseReferralTable.length > 0 ? (
          vipVipFranchiseReferralTable.map((user, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{user.userName}</td>
              <td>{user.phoneNumber}</td>
              <td>{user.email}</td>
              <td>{user.status}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No VIP franchise referrals available.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>

{/* Franchise table */}
<div id="table-container">
  <div className="scroll">
    <h6>REFERRED FRANCHISE</h6>
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="t-head rounded-start-3" scope="col">S.no</th>
          <th className="t-head" scope="col">Franchise Name</th>
          <th className="t-head" scope="col">Phone Number</th>
          <th className="t-head" scope="col">Email</th>
          <th className="t-head" scope="col">Referral Status</th>
        </tr>
      </thead>
      <tbody className="table-dark">
        {Array.isArray(vipFranchiseReferralTable) && vipFranchiseReferralTable.length > 0 ? (
          vipFranchiseReferralTable.map((user, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{user.userName}</td>
              <td>{user.phoneNumber}</td>
              <td>{user.email}</td>
              <td>{user.status}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No franchise referrals available.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>

{/* Revenue VIP Table */}
<div id="table-container">
  <div className="scroll">
    <h6>REVENUE VIP</h6>
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="t-head rounded-start-3" scope="col">S.no</th>
          <th className="t-head" scope="col">VIP Name</th>
          <th className="t-head" scope="col">Revenue</th>
        </tr>
      </thead>
      <tbody className="table-dark">
        {Array.isArray(vipVipRevenueTable) && vipVipRevenueTable.length > 0 ? (
          vipVipRevenueTable.map((user, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{user.vipReferralCommission}</td>
              <td>{values.vipFranchiseReferralRevenue}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="3">No revenue data for VIPs available.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>       
</div>

{/* Revenue VIP Franchise Table */}
<div id="table-container">
  <div className="scroll">
    <h6>REVENUE VIP FRANCHISE</h6>
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="t-head rounded-start-3" scope="col">S.no</th>
          <th className="t-head" scope="col">VIP Franchise Name</th>
          <th className="t-head" scope="col">Revenue</th>
        </tr>
      </thead>
      <tbody className="table-dark">
        {Array.isArray(vipVipFranchiseRevenueTable) && vipVipFranchiseRevenueTable.length > 0 ? (
          vipVipFranchiseRevenueTable.map((user, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{user.userName}</td>
              <td>{values.vipFranchiseReferralRevenue}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="3">No revenue data for VIP franchises available.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>       
</div>

{/* Revenue Franchise Table */}
<div id="table-container">
  <div className="scroll">
    <h6>REVENUE FRANCHISE</h6>
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="t-head rounded-start-3" scope="col">S.no</th>
          <th className="t-head" scope="col">Franchise Name</th>
          <th className="t-head" scope="col">Revenue</th>
        </tr>
      </thead>
      <tbody className="table-dark">
        {Array.isArray(vipFranchiseRevenueTable) && vipFranchiseRevenueTable.length > 0 ? (
          vipFranchiseRevenueTable.map((user, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{user.userName}</td>
              <td>{values.franchiseReferralRevenue}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="3">No revenue data for franchises available.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>       
</div>

{/* Revenue Student Table */}
<div id="table-container">
  <div className="scroll">
    <h6>REVENUE STUDENT</h6>
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="t-head rounded-start-3" scope="col">S.no</th>
          <th className="t-head" scope="col">Student Name</th>
          <th className="t-head" scope="col">Concept Name</th>
          <th className="t-head" scope="col">Course Name</th>
          <th className="t-head" scope="col">Course Fee</th>
          <th className="t-head" scope="col">Revenue</th>
        </tr>
      </thead>
      <tbody className="table-dark">
        {Array.isArray(vipStudentRevenueTable) && vipStudentRevenueTable.length > 0 ? (
          vipStudentRevenueTable.map((user, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{user.personalInfo.studentName}</td>
              <td>{user.courseDetail.conceptName.conceptName}</td>
              <td>{user.courseDetail.courseName.courseName}</td>
              <td>{user.courseDetail.courseName.courseFee}</td>
              <td>
                {CashAmountByPercentage(
                  user.courseDetail.courseName.courseFee,
                  user.courseDetail.courseName.vipPercentage
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6">No revenue data for students available.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>       
</div>
    {workingOption.includes("vipFranchise") ? (
    <>
      {/* PINCODE REFERRALS */}
      <div id="table-container">
        <div className="scroll">
          <h6>PINCODE REFERRALS</h6>
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">S.no</th>
                <th className="t-head" scope="col">Pincode</th>
                <th className="t-head" scope="col">Student Name</th>
                <th className="t-head" scope="col">Course Name</th>
                <th className="t-head" scope="col">Course Fee</th>
                <th className="t-head" scope="col">Status</th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {Array.isArray(pincodeReferral) && pincodeReferral.length > 0 ? (
                pincodeReferral.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.studentPincode.pincode}</td>
                    <td>{item.studentName.studentName}</td>
                    <td>{item.studentCourseStatus.courseName.courseName}</td>
                    <td>{item.studentCourseStatus.courseName.courseFee}</td>
                    <td>{item.status}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No pincode referral data available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* PINCODE REVENUE */}
      <div id="table-container">
        <div className="scroll">
          <h6>PINCODE REVENUE</h6>
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">S.no</th>
                <th className="t-head" scope="col">Pincode</th>
                <th className="t-head" scope="col">Student Name</th>
                <th className="t-head" scope="col">Course Name</th>
                <th className="t-head" scope="col">Course Fee</th>
                <th className="t-head" scope="col">My Revenue</th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {Array.isArray(pincodeRevenue) && pincodeRevenue.length > 0 ? (
                pincodeRevenue.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.studentPincode.pincode}</td>
                    <td>{item.studentName.studentName}</td>
                    <td>{item.studentCourseStatus.courseName.courseName}</td>
                    <td>{item.studentCourseStatus.courseName.courseFee}</td>
                    <td>
                    {item.studentCourseStatus.courseName.courseFee * values.franchiseBusinessCommission}
                  </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No pincode revenue data available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  ) : null}

<div id="viewvip-table-container">
      <div id="table-container" style={{ height: "60vh" }}>
        <h5 className="text-white">MARKETING UPDATES</h5>
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">S.no</th>
                <th className="t-head" scope="col">DATE</th>
                <th className="t-head" scope="col">TIME</th>
                <th className="t-head" scope="col">IMAGES</th>
                <th className="t-head" scope="col">UPLOAD MEDIUM</th>
                <th className="t-head" scope="col">DAILY ACTIVITY</th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => {
                  const date = new Date(item.createdAt).toLocaleDateString();
                  const time = new Date(item.createdAt).toLocaleTimeString();
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{date}</td>
                      <td>{time}</td>
                      <td>
                        <button className="elegant-button" onClick={() => openModal(item.imagekey)}>
                          View Images
                        </button>
                      </td>
                    <td>
    {Array.isArray(item.medium) ? (
      item.medium.map((med, idx) => (
        <span key={idx} style={{ marginRight: '8px' }}>
          {getIcon(med)}
        </span>
      ))
    ) : (
      getIcon(item.medium)
    )}
  </td>
                      <td>{item.desc}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">No marketing activity data available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>

    {/* Modal Dialog for Viewing Images */}
    {isModalOpen && (
     <ModalBackground onClick={closeModal}>
     <ModalContainer onClick={(e) => e.stopPropagation()}>
       {currentImages.length > 0 ? (
         <Carousel showArrows={true} showThumbs={false} showStatus={false} showIndicators={false}>
           {currentImages.map((image, index) => (
             <ImageWrapper key={index}>
               <img src={imageURL(image)} alt={`img-${index}`} />
             </ImageWrapper>
           ))}
         </Carousel>
       ) : (
         <p>No images uploaded.</p>
       )}
       <CloseButton onClick={closeModal}>Close</CloseButton>
     </ModalContainer>
   </ModalBackground>
    )}



</CrmAdminFranchiseViewContainer>

        </ImageList>
      </Dialog>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this user?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ProfileContainer>
  );
}else if(userType == "franchise"){
  if (viewStats) {
    return (
      <Viewstats
        viewStats={viewStats}
        setViewStatsContainer={setViewStatsContainer}
      />
    );
  }
  return (
    <ProfileContainer style={{ backgroundColor: "#18171b", color: "#fff" }}>
      <NavBar />
      <MiniNavBar />
      <Container className="scroll" maxWidth="lg" sx={{ py: 5 }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ mb: 4,mt:4, color: "#fff" }}
        >
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
          <div>
            <Button
              onClick={() => setEditDataContainer(true)}
              variant="outlined"
              color="secondary"
            >
              Edit
            </Button>
            {userRole == "admin" && (
              <Button
              style={{ marginLeft: "1em" }}
              variant="outlined"
              color="error"
              onClick={handleClickOpen} // Open confirmation dialog when delete button is clicked
            >
              Delete
            </Button>
            )}
          </div>
        </Stack>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card sx={{ mb: 4, bgcolor: "var(--navbar-dark-primary)" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <CardMedia
                  component="img"
                  image={imageURL(imageACCESSKEY)}
                  sx={{
                    width: 150,
                    height: 150,
                    borderRadius: "50%",
                    mx: "auto",
                  }}
                />
                <Text variant="h6" sx={{ mt: 2 }}>
                  <b>{userType.toUpperCase()}</b>
                </Text>
                <span
  style={{
    display: 'inline-block', // Allows for proper height/width control
    padding: '8px 20px', // Comfortable padding for the span element
    fontSize: '1rem', // Adjust font size
    fontWeight: 'bold', // Bold text for emphasis
    color: '#ffffff', // White text color for contrast
    background: 'linear-gradient(45deg, #f00d88, #ff6f9f)', // Gradient background with shades of #f00d88
    borderRadius: '30px', // Rounded pill-like design
    boxShadow: '0 4px 10px rgba(240, 13, 136, 0.3)', // Soft shadow with a tint of #f00d88
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transitions for hover effects
   // Pointer cursor for interactivity
  }}
  
 >
  {workingOption}
</span>
<br/>
                <Button
                  onClick={() => setOpen(true)}
                  variant="outlined"
                  sx={{ mt: 2 }}
                >
                  View Tables
                </Button>
                {/* <Button variant="outlined" sx={{ mt: 2 }}>
                  Message
                </Button> */}
              </CardContent>
            </Card>
            <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    overflowX: "scroll",
                    scrollbarWidth: "none" /* Firefox */,
                    msOverflowStyle: "none" /* IE and Edge */,
                    "&::-webkit-scrollbar": {
                      display: "none" /* Chrome, Safari */,
                    },
                  }}
                >
                  <ListItem>
                    <ListItemIcon sx={{ color: "#fff" }}>
                      <WhatsAppIcon />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: "white" }}
                      primary={whatsappNumber}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon
                      sx={{ color: "#fff", cursor: "pointer" }}
                      onClick={handleIconClickinsta}
                    >
                      <InstagramIcon />
                    </ListItemIcon>
                    {/* Hiding the text part */}
                    <ListItemText
                      sx={{ display: "none" }}
                      primary={instagram}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon
                      sx={{ color: "#fff", cursor: "pointer" }}
                      onClick={handleIconClickfb}
                    >
                      <FacebookIcon />
                    </ListItemIcon>
                    {/* Hiding the text part */}
                    <ListItemText sx={{ display: "none" }} primary={facebook} />
                  </ListItem>
                </Box>
              </CardContent>
            </Card>
            <Card
              sx={{ bgcolor: "var(--navbar-dark-primary)", marginTop: "1rem" }}
            >
              <CardContent>
                <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                  <b>Bank Details</b>
                </Text>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text variant="body2">Bank Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {bankAccountName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Bank Account Number</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {bankAccountNumber}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Branch Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {bankBranchName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">IFSC Code</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {ifscCode}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">PanCard Number</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {panCardNumber}
                    </Text>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card sx={{ mb: 4, bgcolor: "var(--navbar-dark-primary)" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text variant="body2">Full Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {userName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Email</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {email}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Phone</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {phoneNumber}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Franchise Royalty</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {otherDetails.amount}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
                  <CardContent>
                    <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                      <b>Personal Information</b>
                    </Text>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Text variant="body2">Age</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {age}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Date of Birth</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {formattedDate}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Gender</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {gender}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Blood Group</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {bloodGroup}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Degree</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {educationDetails?.map((educationDetail) => {
                            return (
                              <p>
                                <b>
                                  {educationDetail.degree}-
                                  {educationDetail.collegeName}
                                </b>
                              </p>
                            );
                          })}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Father Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {fatherName}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Mother Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {motherName}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Currently Unemployed</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {newForWorking}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Job Description</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {jobDescription?.map((job) => {
                            return (
                              <p>
                                <b>
                                  {job.jobDescription}-{job.companyName}
                                </b>
                              </p>
                            );
                          })}
                        </Text>
                      </Grid>

                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary"></Text>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
                  <CardContent>
                    <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                      <b>Address Details</b>
                    </Text>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Text variant="body2">Door/Flat no</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {doorFlatNumber}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Street Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {streetName}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Landmark</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {landMark}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">District</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {district}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">State</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {state}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Country</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {country}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Pin Code</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {pinCode}
                        </Text>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative",  backgroundColor: "var(--background)" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              TABLES
            </Typography>
          </Toolbar>
        </AppBar>
        <ImageList>
          <CrmAdminFranchiseViewContainer>
<>
 {/* Branch Staff List Table */}
 <div id="table-container">
  <div className="scroll">
    <h6>BRANCH STAFF LIST</h6>
    <table className="table table-hover">
      <thead>
        <tr>
          <th className="t-head rounded-start-3" scope="col">S.no</th>
          <th className="t-head" scope="col">Name</th>
          <th className="t-head" scope="col">Email</th>
          <th className="t-head" scope="col">Phone Number</th>
          <th className="t-head" scope="col">Status</th>
          <th className="t-head" scope="col">Profile</th>
        </tr>
      </thead>
      <tbody className="table-dark">
        {Array.isArray(franchiseBranchStaffset) && franchiseBranchStaffset.length > 0 ? (
          franchiseBranchStaffset.map((data, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{data.userName}</td>
              <td>{data.email}</td>
              <td>{data.phoneNumber}</td>
              <td>
                <span className="badge" style={{
                  height: "80%", width: "100%", color: 'white',
                  backgroundColor: '#F6C324', // Status color
                  border: `1px solid #F6C324`, // Border color matches the background
                  padding: "0.6rem", textAlign: 'center'
                }}>
                  {data.status}
                </span>
              </td>
              <td>
                <button
                  className="badge"
                  style={{
                    height: "80%", width: "100%", color: "white",
                    border: "1px solid white",
                    padding: "0.6rem",
                    textAlign: "center",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setProfileViewData(data);
                    navigate("/profile");
                  }}
                >
                  View Profile
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6">No branch staff data available.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>


  {/* Student Referrals Table */}
  <div id="table-container">
    <div className="scroll">
      <h6>STUDENT REFERRALS</h6>
      <table className="table table-hover">
        <thead>
          <tr>
            <th className="t-head rounded-start-3" scope="col">S.no</th>
            <th className="t-head" scope="col">Student Name</th>
            <th className="t-head" scope="col">Age</th>
            <th className="t-head" scope="col">Referred By</th>
            <th className="t-head" scope="col">Concept Name</th>
            <th className="t-head" scope="col">Course Name</th>
            <th className="t-head" scope="col">Father Name</th>
            <th className="t-head" scope="col">Father Phone Number</th>
            <th className="t-head" scope="col">Father Whatsapp Number</th>
            <th className="t-head" scope="col">Mother Name</th>
            <th className="t-head" scope="col">Mother Phone Number</th>
            <th className="t-head" scope="col">Mother Whatsapp Number</th>
            <th className="t-head" scope="col">Status</th>
          </tr>
        </thead>
        <tbody className="table-dark">
          {Array.isArray(franchiseStudentReferrals) && franchiseStudentReferrals.length > 0 ? (
            franchiseStudentReferrals.map((user, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{user.personalInfo.studentName}</td>
                <td>{user.personalInfo.age}</td>
                <td>{user.referenceName}</td>
                <td>{user.courseDetail.courseName.conceptName}</td>
                <td>{user.courseDetail.courseName.courseName}</td>
                <td>{user.familyDetails.fathersName}</td>
                <td>{user.familyDetails.fathersPhone}</td>
                <td>{user.familyDetails.fathersWhatsAppNumber}</td>
                <td>{user.familyDetails.mothersName}</td>
                <td>{user.familyDetails.mothersPhone}</td>
                <td>{user.familyDetails.mothersWhatsAppNumber}</td>
                <td>
                  <span className="badge" style={{
                    height: "80%", width: "100%", color: 'white',
                    backgroundColor: '#F6C324', // Status color
                    border: `1px solid #F6C324`, // Border color matches the background
                    padding: "0.6rem", textAlign: 'center'
                  }}>
                    {user.status}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="13">No student referral data available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>

  {/* Student Admission Table */}
  <div id="table-container">
    <div className="scroll">
      <h6>STUDENT ADMISSION</h6>
      <table className="table table-hover">
        <thead>
          <tr>
            <th className="t-head rounded-start-3" scope="col">S.no</th>
            <th className="t-head" scope="col">Student Name</th>
            <th className="t-head" scope="col">Parents Number</th>
            <th className="t-head" scope="col">Concept Name</th>
            <th className="t-head" scope="col">Course Name</th>
            <th className="t-head" scope="col">Course Fee + GST</th>
            <th className="t-head" scope="col">Payment Paid</th>
            <th className="t-head" scope="col">Payment Pending</th>
            <th className="t-head" scope="col">View Stats</th>
            <th className="t-head" scope="col">Payment Status</th>
            <th className="t-head" scope="col">Profile</th>
         
          </tr>
        </thead>
        <tbody className="table-dark">
          {Array.isArray(franchiseStudentAdmission) && franchiseStudentAdmission.length > 0 ? (
            franchiseStudentAdmission.map((user, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{user.personalInfo.studentName}</td>
                <td>{user.familyDetails.fathersPhone}, {user.familyDetails.mothersPhone}</td>
                <td>{user.courseDetail.conceptName.conceptName}</td>
                <td>{user.courseDetail.courseName.courseName}</td>
                <td>₹{user.otherDetails.amountToPay}</td>
                <td>₹{user.otherDetails.amountPaid}</td>
                <td>₹{paymentPending(user.otherDetails.amountToPay, user.otherDetails.amountPaid)}</td>
                <td>
                  <button
                    className="badge"
                    onClick={() => setViewStatsContainer(user)}
                    style={{
                      height: "80%", width: "100%", color: "white",
                      border: "1px solid white", padding: "0.6rem",
                      textAlign: "center", background: "transparent", cursor: "pointer"
                    }}
                  >
                    View Stats
                  </button>
                </td>
                <td>
                  <span className="badge" style={{
                    height: "80%", width: "100%",
                    color: user.otherDetails.status === "paid" ? "#31c127" : "#d6eb3a",
                    border: `1px solid ${user.otherDetails.status === "paid" ? "#31c127" : "#d6eb3a"}`,
                    padding: "0.6rem", textAlign: "center"
                  }}>
                    {user.otherDetails.status.charAt(0).toUpperCase() + user.otherDetails.status.slice(1)}
                  </span>
                </td>
                <td>
                  <button
                    onClick={() => {
                      setProfileViewData(user);
                      navigate("/studentprofile");
                    }}
                    className="badge"
                    style={{
                      height: "80%", width: "100%", color: "white",
                      border: "1px solid white", padding: "0.6rem",
                      textAlign: "center", background: "transparent", cursor: "pointer"
                    }}
                  >
                    View Profile
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12">No student admission data available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>

  {/* Student Completed Table */}
  <div id="table-container">
    <div className="scroll">
      <h6>STUDENT COMPLETED</h6>
      <table className="table table-hover">
        <thead>
          <tr>
            <th className="t-head rounded-start-3" scope="col">S.no</th>
            <th className="t-head" scope="col">Student Name</th>
            <th className="t-head" scope="col">Parents Number</th>
            <th className="t-head" scope="col">Concept Name</th>
            <th className="t-head" scope="col">Course Name</th>
            <th className="t-head" scope="col">Course Fee</th>
            <th className="t-head" scope="col">Payment Status</th>
            
          </tr>
        </thead>
        <tbody className="table-dark">
          {Array.isArray(franchiseStudentCompleted) && franchiseStudentCompleted.length > 0 ? (
            franchiseStudentCompleted.map((user, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{user.personalInfo.studentName}</td>
                <td>{user.familyDetails.fathersPhone}, {user.familyDetails.mothersPhone}</td>
                <td>{user.courseDetail.conceptName.conceptName}</td>
                <td>{user.courseDetail.courseName.courseName}</td>
                <td>₹{user.otherDetails.amountToPay}</td>
                <td>
                  <span className="badge" style={{
                    height: "80%", width: "100%",
                    color: user.otherDetails.status === "paid" ? "#31c127" : "#d6eb3a",
                    border: `1px solid ${user.otherDetails.status === "paid" ? "#31c127" : "#d6eb3a"}`,
                    padding: "0.6rem", textAlign: "center"
                  }}>
                    {user.otherDetails.status.charAt(0).toUpperCase() + user.otherDetails.status.slice(1)}
                  </span>
                </td>
                
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">No completed student data available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
</>

<div id="viewvip-table-container">
      <div id="table-container" style={{ height: "60vh" }}>
        <h5 className="text-white">MARKETING UPDATES</h5>
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">S.no</th>
                <th className="t-head" scope="col">DATE</th>
                <th className="t-head" scope="col">TIME</th>
                <th className="t-head" scope="col">IMAGES</th>
                <th className="t-head" scope="col">UPLOAD MEDIUM</th>
                <th className="t-head" scope="col">DAILY ACTIVITY</th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => {
                  const date = new Date(item.createdAt).toLocaleDateString();
                  const time = new Date(item.createdAt).toLocaleTimeString();
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{date}</td>
                      <td>{time}</td>
                      <td>
                        <button className="elegant-button" onClick={() => openModal(item.imagekey)}>
                          View Images
                        </button>
                      </td>
                    <td>
    {Array.isArray(item.medium) ? (
      item.medium.map((med, idx) => (
        <span key={idx} style={{ marginRight: '8px' }}>
          {getIcon(med)}
        </span>
      ))
    ) : (
      getIcon(item.medium)
    )}
  </td>
                      <td>{item.desc}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">No marketing activity data available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>

    {/* Modal Dialog for Viewing Images */}
    {isModalOpen && (
     <ModalBackground onClick={closeModal}>
     <ModalContainer onClick={(e) => e.stopPropagation()}>
       {currentImages.length > 0 ? (
         <Carousel showArrows={true} showThumbs={false} showStatus={false} showIndicators={false}>
           {currentImages.map((image, index) => (
             <ImageWrapper key={index}>
               <img src={imageURL(image)} alt={`img-${index}`} />
             </ImageWrapper>
           ))}
         </Carousel>
       ) : (
         <p>No images uploaded.</p>
       )}
       <CloseButton onClick={closeModal}>Close</CloseButton>
     </ModalContainer>
   </ModalBackground>
    )}

          </CrmAdminFranchiseViewContainer>
        </ImageList>
      </Dialog>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this user?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ProfileContainer>
  );
}else if(userType == "branchstaff"){
  return (
    <ProfileContainer style={{ backgroundColor: "#18171b", color: "#fff" }}>
      <NavBar />
      <MiniNavBar />
      <Container className="scroll" maxWidth="lg" sx={{ py: 5 }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ mb: 4 ,mt:4, color: "#fff" }}
        >
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
          <div>
            <Button
              onClick={() => setEditDataContainer(true)}
              variant="outlined"
              color="secondary"
            >
              Edit
            </Button>
            {userRole == "admin" && (
              <Button
              style={{ marginLeft: "1em" }}
              variant="outlined"
              color="error"
              onClick={handleClickOpen} // Open confirmation dialog when delete button is clicked
            >
              Delete
            </Button>
            )}
          </div>
        </Stack>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card sx={{ mb: 4, bgcolor: "var(--navbar-dark-primary)" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <CardMedia
                  component="img"
                  image={imageURL(imageACCESSKEY)}
                  sx={{
                    width: 150,
                    height: 150,
                    borderRadius: "50%",
                    mx: "auto",
                  }}
                />
               <span
  style={{
    display: 'inline-block', // Allows for proper height/width control
    padding: '8px 20px', // Comfortable padding for the span element
    fontSize: '1rem', // Adjust font size
    fontWeight: 'bold', // Bold text for emphasis
    color: '#ffffff', // White text color for contrast
    background: 'linear-gradient(45deg, #f00d88, #ff6f9f)', // Gradient background with shades of #f00d88
    borderRadius: '30px', // Rounded pill-like design
    boxShadow: '0 4px 10px rgba(240, 13, 136, 0.3)', // Soft shadow with a tint of #f00d88
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transitions for hover effects
   // Pointer cursor for interactivity
  }}
  
 >
                  <b>{userType.toUpperCase()}</b>
                </span>
                <br/>
                {/* {userRole == "admin" && (
                <Button
                  onClick={() => setOpen(true)}
                  variant="outlined"
                  sx={{ mt: 2 }}
                >
                  View Tables
                </Button>
              )} */}
                {/* <Button variant="outlined" sx={{ mt: 2 }}>
                  Message
                </Button> */}
              </CardContent>
            </Card>
            <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    overflowX: "scroll",
                    scrollbarWidth: "none" /* Firefox */,
                    msOverflowStyle: "none" /* IE and Edge */,
                    "&::-webkit-scrollbar": {
                      display: "none" /* Chrome, Safari */,
                    },
                  }}
                >
                  <ListItem>
                    <ListItemIcon sx={{ color: "#fff" }}>
                      <WhatsAppIcon />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: "white" }}
                      primary={whatsappNumber}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon
                      sx={{ color: "#fff", cursor: "pointer" }}
                      onClick={handleIconClickinsta}
                    >
                      <InstagramIcon />
                    </ListItemIcon>
                    {/* Hiding the text part */}
                    <ListItemText
                      sx={{ display: "none" }}
                      primary={instagram}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon
                      sx={{ color: "#fff", cursor: "pointer" }}
                      onClick={handleIconClickfb}
                    >
                      <FacebookIcon />
                    </ListItemIcon>
                    {/* Hiding the text part */}
                    <ListItemText sx={{ display: "none" }} primary={facebook} />
                  </ListItem>
                </Box>
              </CardContent>
            </Card>
            <Card
              sx={{ bgcolor: "var(--navbar-dark-primary)", marginTop: "1rem" }}
            >
              <CardContent>
                <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                  <b>Bank Details</b>
                </Text>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text variant="body2">Bank Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {bankAccountName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Bank Account Number</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {bankAccountNumber}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Branch Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {bankBranchName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">IFSC Code</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {ifscCode}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">PanCard Number</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {panCardNumber}
                    </Text>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card sx={{ mb: 4, bgcolor: "var(--navbar-dark-primary)" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text variant="body2">Full Name</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {userName}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Email</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {email}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Text variant="body2">Phone</Text>
                  </Grid>
                  <Grid item xs={8}>
                    <Text variant="body2" color="textSecondary">
                      {phoneNumber}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                    <Grid item xs={4}>
                      <Text variant="body2">Monthly Salary</Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text variant="body2" color="textSecondary">
                        {monthlySalary}
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
                  <CardContent>
                    <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                      <b>Personal Information</b>
                    </Text>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Text variant="body2">Age</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {age}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Date of Birth</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {formattedDate}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Gender</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {gender}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Blood Group</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {bloodGroup}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Degree</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {educationDetails?.map((educationDetail) => {
                            return (
                              <p>
                                <b>
                                  {educationDetail.degree}-
                                  {educationDetail.collegeName}
                                </b>
                              </p>
                            );
                          })}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Father Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {fatherName}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Mother Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {motherName}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">New for working</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {newForWorking}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Job Description</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {jobDescription?.map((job) => {
                            return (
                              <p>
                                <b>
                                  {job.jobDescription}-{job.companyName}
                                </b>
                              </p>
                            );
                          })}
                        </Text>
                      </Grid>

                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary"></Text>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
                  <CardContent>
                    <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                      <b>Address Details</b>
                    </Text>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Text variant="body2">Door/Flat no</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {doorFlatNumber}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Street Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {streetName}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Landmark</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {landMark}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">District</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {district}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">State</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {state}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Country</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {country}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Pin Code</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {pinCode}
                        </Text>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" ,  backgroundColor: "var(--background)" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              TABLES
            </Typography>
          </Toolbar>
        </AppBar>
        <ImageList>
          <CrmAdminFranchiseViewContainer>
          <div id="viewvip-table-container">
      <div id="table-container" style={{ height: "60vh" }}>
        <h5 className="text-white">MARKETING UPDATES</h5>
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">S.no</th>
                <th className="t-head" scope="col">DATE</th>
                <th className="t-head" scope="col">TIME</th>
                <th className="t-head" scope="col">IMAGES</th>
                <th className="t-head" scope="col">UPLOAD MEDIUM</th>
                <th className="t-head" scope="col">DAILY ACTIVITY</th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => {
                  const date = new Date(item.createdAt).toLocaleDateString();
                  const time = new Date(item.createdAt).toLocaleTimeString();
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{date}</td>
                      <td>{time}</td>
                      <td>
                        <button className="elegant-button" onClick={() => openModal(item.imagekey)}>
                          View Images
                        </button>
                      </td>
                    <td>
    {Array.isArray(item.medium) ? (
      item.medium.map((med, idx) => (
        <span key={idx} style={{ marginRight: '8px' }}>
          {getIcon(med)}
        </span>
      ))
    ) : (
      getIcon(item.medium)
    )}
  </td>
                      <td>{item.desc}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">No marketing activity data available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>

    {/* Modal Dialog for Viewing Images */}
    {isModalOpen && (
     <ModalBackground onClick={closeModal}>
     <ModalContainer onClick={(e) => e.stopPropagation()}>
       {currentImages.length > 0 ? (
         <Carousel showArrows={true} showThumbs={false} showStatus={false} showIndicators={false}>
           {currentImages.map((image, index) => (
             <ImageWrapper key={index}>
               <img src={imageURL(image)} alt={`img-${index}`} />
             </ImageWrapper>
           ))}
         </Carousel>
       ) : (
         <p>No images uploaded.</p>
       )}
       <CloseButton onClick={closeModal}>Close</CloseButton>
     </ModalContainer>
   </ModalBackground>
    )}
          </CrmAdminFranchiseViewContainer>
        </ImageList>
      </Dialog>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this user?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ProfileContainer>
  );}else if(userType == "hos"){
    return (
      <ProfileContainer style={{ backgroundColor: "#18171b", color: "#fff" }}>
        <NavBar />
        <MiniNavBar />
        <Container className="scroll" maxWidth="lg" sx={{ py: 5 }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ mb: 4 ,mt:4, color: "#fff" }}
          >
            <Button variant="outlined" onClick={handleBack}>
              Back
            </Button>
            <div>
              <Button
                onClick={() => setEditDataContainer(true)}
                variant="outlined"
                color="secondary"
              >
                Edit
              </Button>
              {userRole == "admin" && (
                <Button
                style={{ marginLeft: "1em" }}
                variant="outlined"
                color="error"
                onClick={handleClickOpen} // Open confirmation dialog when delete button is clicked
              >
                Delete
              </Button>
              )}
            </div>
          </Stack>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card sx={{ mb: 4, bgcolor: "var(--navbar-dark-primary)" }}>
                <CardContent sx={{ textAlign: "center" }}>
                  <CardMedia
                    component="img"
                    image={imageURL(imageACCESSKEY)}
                    sx={{
                      width: 150,
                      height: 150,
                      borderRadius: "50%",
                      mx: "auto",
                    }}
                  />
                  <Text variant="h6" sx={{ mt: 2 }}>
                    <b>{userType.toUpperCase()}</b>
                  </Text>
                  <Box
  sx={{
    display: 'flex',
    justifyContent: 'center', // Horizontally centers the button
    alignItems: 'center',      // Vertically centers the button
           // Makes sure the container takes the full viewport height
  }}
>
<span
  style={{
    padding: '8px 20px', // Comfortable padding for the span element
    fontSize: '0.85rem', // Adjust font size
    fontWeight: 'bold', // Bold text for emphasis
    color: '#ffffff', // White text color for contrast
    background: 'linear-gradient(45deg, #f00d88, #ff6f9f)', // Gradient background with shades of #f00d88
    borderRadius: '30px', // Rounded pill-like design
    boxShadow: '0 4px 10px rgba(240, 13, 136, 0.3)', // Soft shadow with a tint of #f00d88
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transitions for hover effects
    display: 'inline-flex', // Flex layout for span
    flexWrap: 'wrap', // Allows content to wrap
    alignItems: 'center', // Aligns text/content vertically
    justifyContent: 'center', // Centers content horizontally
    gap: '10px', // Space between the wrapped elements
     textAlign: 'center', // Centers text
    wordBreak: 'break-word', // Breaks words for wrapping
    whiteSpace: 'normal', // Allows text to break into new lines
      '@media (maxWidth: 600px)': {
      fontSize: '0.6rem', // Adjust font size for small screens
      padding: '2px 8px', // Adjust padding for small screens
    },
    '@media (minWidth: 600px)': {
      fontSize: '0.75rem', // Adjust font size for larger screens
      padding: '4px 12px', // Adjust padding for larger screens
    },
  }} 
>
  {Array.isArray(workingOption)
    ? workingOption.map((option, index) => (
        <span key={index}>{option}</span>
      ))
    : workingOption}
</span>

</Box>

                  {/* <Button variant="outlined" sx={{ mt: 2 }}>
                    Message
                  </Button> */}
                </CardContent>
              </Card>
              <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      overflowX: "scroll",
                      scrollbarWidth: "none" /* Firefox */,
                      msOverflowStyle: "none" /* IE and Edge */,
                      "&::-webkit-scrollbar": {
                        display: "none" /* Chrome, Safari */,
                      },
                    }}
                  >
                    <ListItem>
                      <ListItemIcon sx={{ color: "#fff" }}>
                        <WhatsAppIcon />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: "white" }}
                        primary={whatsappNumber}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon
                        sx={{ color: "#fff", cursor: "pointer" }}
                        onClick={handleIconClickinsta}
                      >
                        <InstagramIcon />
                      </ListItemIcon>
                      {/* Hiding the text part */}
                      <ListItemText
                        sx={{ display: "none" }}
                        primary={instagram}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon
                        sx={{ color: "#fff", cursor: "pointer" }}
                        onClick={handleIconClickfb}
                      >
                        <FacebookIcon />
                      </ListItemIcon>
                      {/* Hiding the text part */}
                      <ListItemText sx={{ display: "none" }} primary={facebook} />
                    </ListItem>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{ bgcolor: "var(--navbar-dark-primary)", marginTop: "1rem" }}
              >
                <CardContent>
                  <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                    <b>Bank Details</b>
                  </Text>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Text variant="body2">Bank Name</Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text variant="body2" color="textSecondary">
                        {bankAccountName}
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4}>
                      <Text variant="body2">Bank Account Number</Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text variant="body2" color="textSecondary">
                        {bankAccountNumber}
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4}>
                      <Text variant="body2">Branch Name</Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text variant="body2" color="textSecondary">
                        {bankBranchName}
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4}>
                      <Text variant="body2">IFSC Code</Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text variant="body2" color="textSecondary">
                        {ifscCode}
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4}>
                      <Text variant="body2">PanCard Number</Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text variant="body2" color="textSecondary">
                        {panCardNumber}
                      </Text>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card sx={{ mb: 4, bgcolor: "var(--navbar-dark-primary)" }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Text variant="body2">Full Name</Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text variant="body2" color="textSecondary">
                        {userName}
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4}>
                      <Text variant="body2">Email</Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text variant="body2" color="textSecondary">
                        {email}
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4}>
                      <Text variant="body2">Phone</Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text variant="body2" color="textSecondary">
                        {phoneNumber}
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4}>
                      <Text variant="body2">Monthly Salary</Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text variant="body2" color="textSecondary">
                        {monthlySalary}
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
                  <CardContent>
                    <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                      <b>Personal Information</b>
                    </Text>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Text variant="body2">Age</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {age}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Date of Birth</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {formattedDate}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Gender</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {gender}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Blood Group</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {bloodGroup}
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Degree</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {educationDetails?.map((educationDetail) => {
                            return (
                              <p>
                                <b>
                                  {educationDetail.degree}-
                                  {educationDetail.collegeName}
                                </b>
                              </p>
                            );
                          })}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Father Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {fatherName}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Mother Name</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {motherName}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">New for working</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {newForWorking}
                        </Text>
                      </Grid>
                      <Grid item xs={4}>
                        <Text variant="body2">Job Description</Text>
                      </Grid>
                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary">
                          {jobDescription?.map((job) => {
                            return (
                              <p>
                                <b>
                                  {job.jobDescription}-{job.companyName}
                                </b>
                              </p>
                            );
                          })}
                        </Text>
                      </Grid>

                      <Grid item xs={8}>
                        <Text variant="body2" color="textSecondary"></Text>
                      </Grid>
                    </Grid>
                  </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ bgcolor: "var(--navbar-dark-primary)" }}>
                    <CardContent>
                      <Text variant="body2" sx={{ mb: 2, color: "#1976d2" }}>
                        <b>Address Details</b>
                      </Text>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Text variant="body2">Door/Flat no</Text>
                        </Grid>
                        <Grid item xs={8}>
                          <Text variant="body2" color="textSecondary">
                            {doorFlatNumber}
                          </Text>
                        </Grid>
                        <Grid item xs={4}>
                          <Text variant="body2">Street Name</Text>
                        </Grid>
                        <Grid item xs={8}>
                          <Text variant="body2" color="textSecondary">
                            {streetName}
                          </Text>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={4}>
                          <Text variant="body2">Landmark</Text>
                        </Grid>
                        <Grid item xs={8}>
                          <Text variant="body2" color="textSecondary">
                            {landMark}
                          </Text>
                        </Grid>
                        <Grid item xs={4}>
                          <Text variant="body2">District</Text>
                        </Grid>
                        <Grid item xs={8}>
                          <Text variant="body2" color="textSecondary">
                            {district}
                          </Text>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={4}>
                          <Text variant="body2">State</Text>
                        </Grid>
                        <Grid item xs={8}>
                          <Text variant="body2" color="textSecondary">
                            {state}
                          </Text>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={4}>
                          <Text variant="body2">Country</Text>
                        </Grid>
                        <Grid item xs={8}>
                          <Text variant="body2" color="textSecondary">
                            {country}
                          </Text>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={4}>
                          <Text variant="body2">Pin Code</Text>
                        </Grid>
                        <Grid item xs={8}>
                          <Text variant="body2" color="textSecondary">
                            {pinCode}
                          </Text>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" ,  backgroundColor: "var(--background)" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                TABLES
              </Typography>
            </Toolbar>
          </AppBar>
          <ImageList>
            <CrmAdminFranchiseViewContainer>
            {/* <div id="viewvip-table-container">
      <div id="table-container" style={{ height: "60vh" }}>
        <h5 className="text-white">MARKETING UPDATES</h5>
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">S.no</th>
                <th className="t-head" scope="col">DATE</th>
                <th className="t-head" scope="col">TIME</th>
                <th className="t-head" scope="col">IMAGES</th>
                <th className="t-head" scope="col">UPLOAD MEDIUM</th>
                <th className="t-head" scope="col">DAILY ACTIVITY</th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => {
                  const date = new Date(item.createdAt).toLocaleDateString();
                  const time = new Date(item.createdAt).toLocaleTimeString();
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{date}</td>
                      <td>{time}</td>
                      <td>
                        <button className="elegant-button" onClick={() => openModal(item.imagekey)}>
                          View Images
                        </button>
                      </td>
                      <td>
    {Array.isArray(item.medium) ? (
      item.medium.map((med, idx) => (
        <span key={idx} style={{ marginRight: '8px' }}>
          {getIcon(med)}
        </span>
      ))
    ) : (
      getIcon(item.medium)
    )}
  </td>
                      <td>{item.desc}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">No marketing activity data available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div> */}

    {/* Modal Dialog for Viewing Images */}
    {isModalOpen && (
       <ModalBackground onClick={closeModal}>
       <ModalContainer onClick={(e) => e.stopPropagation()}>
         {currentImages.length > 0 ? (
           <Carousel showArrows={true} showThumbs={false} showStatus={false} showIndicators={false}>
             {currentImages.map((image, index) => (
               <ImageWrapper key={index}>
                 <img src={imageURL(image)} alt={`img-${index}`} />
               </ImageWrapper>
             ))}
           </Carousel>
         ) : (
           <p>No images uploaded.</p>
         )}
         <CloseButton onClick={closeModal}>Close</CloseButton>
       </ModalContainer>
     </ModalBackground>
    )}
            </CrmAdminFranchiseViewContainer>
          </ImageList>
        </Dialog>
  
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this user?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </ProfileContainer>
    );}
};
const ProfileContainer=styled.div`
    display: flex;
    color: white;
    height: 100vh;
`
const Text = styled(Typography)`
  color: white;
`;

export default UserDetails;

const CrmAdminFranchiseViewContainer = styled.div`

 background-color: var(--background);
  width: 100vw;
  height: 100vh;
  padding: 3rem;
  overflow-x: hidden; // Hide horizontal overflow
  box-sizing: border-box; // Ensure width includes padding



 h2,
  p,
  span,
  h6 {
    color: white;
  }

  hr {
    border: 1.4px solid black;
  }

  .container {
    margin-top: 1rem;
  }

  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }

  td {
    text-align: center;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
    overflow-y: scroll;
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }

  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .elegant-button {
  background-color: #f0f0f0; /* Light background color */
  border: none; /* Remove default border */
  border-radius: 15px; /* Round corners */
  padding: 8px 12px; /* Padding for a nicer look */
  color: #333; /* Text color */
  font-size: 13px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.elegant-button:hover {
  background-color: #f00d88; /* Background color on hover */
}

    
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.8); /* Dark gray background */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalContainer = styled.div`
  background-color:  var(--background);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 70%; /* Increase modal width */
  max-height: 70%; /* Increase modal height */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px; /* Add margin to create space between images */
  
  
  img {
    max-width: 60%; /* Keep image size increased */
    max-height: 50%;
    object-fit: cover;
  }

    @media (max-width: 780px)  {
    margin: 5px; 
  
      img {
    max-width: 100%; /* Keep image size increased */
    max-height: 100%;
    object-fit: cover;
  }
`;


const CloseButton = styled.button`
  background-color: #f00d88;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  transition: background-color 0.3s;
  margin-top: 1rem;

  &:hover {
    background-color: #d00d88;
  }
`;




