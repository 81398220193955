import React from 'react'
import { styled } from 'styled-components';
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import NavBar from "./miniComponents/subcomponents/NavBar";
import CompanyBusinessContainer from './secondaryContainersForPages/CompanyBusinessContainer.jsx';

function CompanyBusiness() {
  return (
    <ConceptDetailsContainer>
      <MiniNavBar selected={"companybusiness"} />
      <NavBar selected={"companybusiness"} />
      <CompanyBusinessContainer/>
    </ConceptDetailsContainer>
  );
}
const ConceptDetailsContainer = styled.div`
  display: flex;
  background-color: var(--background);
  
  @media only screen and (max-width:768px) {
  
    #miniNav {
      display: block !important;
    }
    #title {
      justify-content: center !important;
    }
  
  }
  #miniNav {
    display: none;
  }
  #title {
    justify-content: space-between;
  }
  #searchfield {
    display: block;
  }
`;
export default CompanyBusiness