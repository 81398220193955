
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { URL as url } from "../../../assets/mocData/config";
import { enqueueSnackbar } from "notistack";
import {
  deleteImage,
  fetchData,
  handleImageSubmit,
  imageURL,
  postData,
  putData,
} from "../../../Api";
import axios from "axios";
import { styled } from "styled-components";
import { Button } from "@mui/material";
import Icon from "react-icons-kit";
import { cross } from "react-icons-kit/icomoon/cross";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { Country, State, City } from "country-state-city";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputBase,
  Radio,
  RadioGroup,createTheme,Autocomplete,Paper,ThemeProvider,
  TextField,
  InputLabel, Select, MenuItem 
} from "@mui/material";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f00d88", // Pink color
    },
    text: {
      primary: "#ffffff", // White color for input text
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: "#ffffff", // White color for input text
          backgroundColor: "black", // Background color for filled input
          "&:before": {
            borderColor: "white", // Initial border color white
          },
          "&:hover:before": {
            borderColor: "#f00d88", // Border color on hover
          },
          "&.Mui-focused:before": {
            borderColor: "#f00d88", // Border color when focused
          },
          "&.Mui-focused": {
            backgroundColor: "black", // Background color when focused
          },
        },
        input: {
          color: "#ffffff", // White color for input text
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "gray", // Gray color for label
          "&.Mui-focused": {
            color: "#f00d88", // Pink color for label when focused
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "white", // White color for the dropdown icon
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#25272d", // Dark gray background for dropdown
          color: "#ffffff", // White text color for dropdown items
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#ffffff",// Pink color for the dropdown icon
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "#25272d", // Gray background
          color: "#ffffff", // White text color
          "&:hover": {
            backgroundColor: "#f00d88", // Pink background on hover
          },
        },
      },
    },
  },
});
function EditFranchise({ editHosData, setEditHosData, setEditHosContainer }) {
  const [previewUrl, setPreviewUrl] = useState("");
  const [allEducation, setAllEducation] = useState([
    ...editHosData?.educationDetails,
  ]);
  const [education, setEducation] = useState({
    degree: "",
    collegeName: "",
  });
  const [modal, setModel] = useState(false);
  const [conceptSelected, setConceptSelected] = useState("");
  const [concept, setConcept] = useState([]);
  const [pin, setPin] = useState("");
  const [selectedValue, setSelectedValue] = useState(editHosData.status);
  const [jobDataAll, setJobDataAll] = useState([...editHosData.jobDescription]);
  const [jobData, setJobData] = useState({
    jobDescription: "",
    companyName: "",
  });
  const { generateRandomPassword, userToken, userRole } = useGlobalContext();
  const [selectedImage, setSelectedImage] = useState(null);
  
  const fetchConcepts = async (user) => {
    let header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(`${url}/${userRole}/concept`, header);
      console.log(response);
      setConcept(response);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  useEffect(() => {
    (async () => {
      await fetchConcepts();
    })();
  }, []);
  const addJobData = (dataJ) => {
    if (dataJ.jobDescription == "" || dataJ.companyName == "") {
      enqueueSnackbar("Fill the detail job description and company name", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return null;
    }
    setJobDataAll([...jobDataAll, dataJ]);
    setEditHosData({
      ...editHosData,
      jobDescription: [...jobDataAll, dataJ],
    });
    setJobData({
      jobDescription: "",
      companyName: "",
    });
  };
  const getData = (isoDate) => {
    if (isoDate == null) {
      return null;
    } else {
      const dateObject = parseISO(isoDate);
      const formattedDate = format(dateObject, "yyyy-MM-dd");
      return formattedDate;
    }
  };
  const addEducationData = (dataJ) => {
    if (dataJ.degree == "" || dataJ.collegeName == "") {
      enqueueSnackbar("Fill the detail degree and college Name", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return null;
    }
    setAllEducation([...allEducation, dataJ]);
    setEditHosData({
      ...editHosData,
      educationDetails: [...allEducation, dataJ],
    });
    setEducation({
      degree: "",
      collegeName: "",
    });
  };
  function removeElementByCondition(jobDescription) {
    // Create a new array with elements that do not match the specified company name
    console.log(jobDataAll[0].jobDescription, jobDescription);
    const filteredArray = jobDataAll.filter(
      (element) => element.jobDescription !== jobDescription
    );

    console.log("d", filteredArray); // This will show the new array without the specified company
    setEditHosData({
      ...editHosData,
      jobDescription: [...filteredArray],
    });
    setJobDataAll([...filteredArray]);
  }
  function removeElementByConditionEducation(degree) {
    // Create a new array with elements that do not match the specified company name
    const filteredArray = allEducation.filter(
      (element) => element.degree !== degree
    );

    console.log(filteredArray); // This will show the new array without the specified company
    setEditHosData({
      ...editHosData,
      educationDetails: [...filteredArray],
    });
    setAllEducation(filteredArray);
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const objectUrl = URL.createObjectURL(file);
      console.log(objectUrl);
      setPreviewUrl(objectUrl);
    }
    await deleteImage(editHosData.imageACCESSKEY);
    await handleImageSubmit(file, setEditHosData, editHosData);
  };
  const handlePinCodeChange = (pincode) =>
    !editHosData.otherDetails.pinCodes.includes(pincode);

  const handleRemovePincode = (pinCode) => {
    console.log(pinCode, editHosData.otherDetails.pinCodes);
    const updatedPinCodes = editHosData.otherDetails.pinCodes.filter(
      (e) => e !== pinCode.element
    );

    setEditHosData({
      ...editHosData,
      otherDetails: { pinCodes: updatedPinCodes },
    });
  };

  const handleAddPinCode = (pincode) => {
    if (handlePinCodeChange(pincode)) {
      setEditHosData({
        ...editHosData,
        otherDetails: {
          pinCodes: [...editHosData.otherDetails.pinCodes, pincode],
        },
      });
      setPin("");
    } else {
      enqueueSnackbar("Already entered pincode", { variant: "warning" });
    }
  };
  const handleVerify = async () => {
    // Send 'otp' to your server for validation
    // If validation is successful, proceed; otherwise, show an error message
    try {
      let response = await axios.post(`${url}/send-otp`, {
        email: editHosData.email,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };


  const handleClickGender = (e) => {
    setEditHosData({
      ...editHosData,
      [e.target.name]: e.target.value,
    });
  };
  


const data = editHosData;
  const validateFormFranchiseMemberData = (data) => {
    // Validation for Staff Name
    if (!data.userName || data.userName.trim() === "") {
      enqueueSnackbar("Staff Name is required.", { variant: "warning" });
      return false;
    }
  
    // Validation for Age
    const age = parseInt(data.age, 10);
    if (isNaN(age) || age < 18 || age > 100) {
      enqueueSnackbar("Please enter a valid age between 18 and 100.", { variant: "warning" });
      return false;
    }
  
    // Validation for Blood Group
    const validBloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
    if (!validBloodGroups.includes(data.bloodGroup)) {
      enqueueSnackbar("Please enter a valid blood group.", { variant: "warning" });
      return false;
    }
  
    // Validation for Date of Birth
    if (!data.dateOfBirth || isNaN(new Date(data.dateOfBirth).getTime())) {
      enqueueSnackbar("Please enter a valid Date of Birth.", { variant: "warning" });
      return false;
    }
  
    const validGenders = ["Male", "Female", "Other"];

  // Validation for Gender
  if (!data.gender || data.gender === "" || !validGenders.includes(data.gender)) {
    enqueueSnackbar("Please select a valid gender.", { variant: "warning" });
    return false;
  }
  
    // Validation for Father Name
    if (!data.fatherName || data.fatherName.trim() === "") {
      enqueueSnackbar("Father's Name is required.", { variant: "warning" });
      return false;
    }
  
    // Validation for Mother Name
    if (!data.motherName || data.motherName.trim() === "") {
      enqueueSnackbar("Mother's Name is required.", { variant: "warning" });
      return false;
    }
  
    // Validation for Degree
    if ((education.degree === "" || education.collegeName === "")&&allEducation.length === 0) {
      enqueueSnackbar("Please fill in both Degree and College Name fields.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;  // Stop validation if fields are empty
    }
  
    // Ensure at least one education entry is added
    if (allEducation.length === 0) {
      enqueueSnackbar("Please add at least one education entry.", {
        variant: "warning",
        autoHideDuration: 1500,
      });
      return false;  // Prevent form submission if no education entry exists
    }
    
    if (!data.newForWorking) {
      enqueueSnackbar("Please select whether the person is new for working.", { variant: "warning" });
      return false;
    }
  
    const { jobDescription, companyName } = jobData;  // If the user is not new for working, validate job details
   // Validation for Job Details (if the user is not new for working)
if (data.newForWorking === "no") {
  if ((jobDescription.trim() === "" || companyName.trim() === "") && jobDataAll.length === 0) {
    enqueueSnackbar("Please fill in both Job Description and Company Name fields.", {
      variant: "warning",
      autoHideDuration: 1000,
    });
    return false; // Stop further execution
  }
  if (jobDataAll.length === 0) {
    enqueueSnackbar("Please add at least one job description entry.", {
      variant: "warning",
      autoHideDuration: 1500,
    });
    return false;  // Prevent form submission if no education entry exists
  }
    
}
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    if (!data.email || !emailRegex.test(data.email)) {
      enqueueSnackbar("Please enter a valid email address.", { variant: "warning" });
      return false;
    }
  
    // Contact Phone Number validation
    const phoneRegex = /^[0-9]{10}$/; // Adjust as per your requirement
    if (!data.phoneNumber || !phoneRegex.test(data.phoneNumber)) {
      enqueueSnackbar("Please enter a valid phone number.", { variant: "warning" });
      return false;
    }
  
    // Whatsapp Number validation
    if (!data.whatsappNumber || !phoneRegex.test(data.whatsappNumber)) {
      enqueueSnackbar("Please enter a valid WhatsApp number.", { variant: "warning" });
      return false;
    }
    if (!data.doorFlatNumber) {
      enqueueSnackbar("Please enter your Door/Flat number.", { variant: "warning" });
      return false;
    }
  
    // Street Name validation
    if (!data.streetName) {
      enqueueSnackbar("Please enter the street name.", { variant: "warning" });
      return false;
    }
  
    // Landmark validation (Optional)
    // if (!data.landMark) {
    //   enqueueSnackbar("Please enter a landmark.", { variant: "warning" });
    //   return false;
    // }
  
    // Country validation
    if (!data.country) {
      enqueueSnackbar("Please select a country.", { variant: "warning" });
      return false;
    }
  
    // State validation
    if (!data.state) {
      enqueueSnackbar("Please select a state.", { variant: "warning" });
      return false;
    }
  
    // District validation
    if (!data.district) {
      enqueueSnackbar("Please select a district.", { variant: "warning" });
      return false;
    }
  
    // Pincode validation
    const pinCodeRegex = /^[0-9]{6}$/; // Adjust the regex based on country-specific formats
    if (!data.pinCode || !pinCodeRegex.test(data.pinCode)) {
      enqueueSnackbar("Please enter a valid pincode.", { variant: "warning" });
      return false;
    }
    if (!data.bankAccountName) {
      enqueueSnackbar("Please enter your Bank Account Name.", { variant: "warning" });
      return false;
    }
  
    // Branch Name validation
    if (!data.bankBranchName) {
      enqueueSnackbar("Please enter your Branch Name.", { variant: "warning" });
      return false;
    }
  
    // IFSC Code validation
    const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/; // Adjust based on actual IFSC code format
    if (!data.ifscCode ) {
      enqueueSnackbar("Please enter a valid IFSC Code.", { variant: "warning" });
      return false;
    }
  
    // Bank Account Number validation
    if (!data.bankAccountNumber) {
      enqueueSnackbar("Please enter your Bank Account Number.", { variant: "warning" });
      return false;
    }
  
    // PAN Card Number validation
    const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // Adjust based on actual PAN card format
    if (!data.panCardNumber || !panCardRegex.test(data.panCardNumber)) {
      enqueueSnackbar("Please enter a valid PAN Card Number.", { variant: "warning" });
      return false;
    }
   
    if (!data.workingOption || data.workingOption.length === 0) {
      enqueueSnackbar("Please select at least one concept.", { variant: "warning" });
      return false;
    }
  
    // Check for duplicates in workingOption
    const uniqueOptions = new Set(data.workingOption);
    if (uniqueOptions.size !== data.workingOption.length) {
      enqueueSnackbar("Duplicate concepts are not allowed.", { variant: "warning" });
      return false;
    }
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?([\\w\\d\\-]+\\.)+[a-z]{2,}(\\/[^\\s]*)?$", 
      "i"
    );
  
    const invalidUrls = [];
    if (data.instagram && !urlPattern.test(data.instagram)) {
      invalidUrls.push("Instagram");
    }
    if (data.facebook && !urlPattern.test(data.facebook)) {
      invalidUrls.push("Facebook");
    }
    if (!data.instagram) {
      enqueueSnackbar("Please provide a instagram link.", { variant: "warning" });
      return false;
    }
    if (!data.facebook) {
      enqueueSnackbar("Please provide a facebook link.", { variant: "warning" });
      return false;
    }
  
    if (invalidUrls.length > 0) {
      enqueueSnackbar(`Invalid URL(s) for: ${invalidUrls.join(", ")}`, { variant: "warning" });
      return false;
    }
    const amount = data.amount;
    if (amount) {
      const amountNumber = parseFloat(amount);
      if (isNaN(amountNumber) || amountNumber <= 0) {
        enqueueSnackbar("Royalty Amount must be a positive number", { variant: "warning" });
        return false;
      }
    }

    if (!data.status || (data.status !== "active" && data.status !== "inactive")) {
      enqueueSnackbar("Please select a valid status (Active or Inactive).", { variant: "warning" });
      return false;
    }
  
    return true;
  };

  const editHos = async (e) => {
    e.preventDefault();
    if (editHosData.imageACCESSKEY == "") {
      enqueueSnackbar("Upload the image", { variant: "warning" });
      return null;
    }
    let headers = {
      userToken: userToken,
      userRole: userRole,
    };
 

    const isValid = validateFormFranchiseMemberData(data);

    if (!isValid){
      return
    }
    //deleteImage("01.05.2024.jpg");

    try {
      let response = await putData(
        `${url}/admin/users/${editHosData._id}`,
        editHosData,
        headers
      );
      setEditHosContainer(false);
      console.log(response);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`Error`, {
        variant: "error",
      });

      console.log(error);
    }
  };

  const FormData = (e) => {
    e.preventDefault();
    if (e.target.name == "profileImage") {
    } else if (
      e.target.name == "jobDescription" ||
      e.target.name == "companyName"
    ) {
      setJobData({ ...jobData, [e.target.name]: e.target.value });
    } else if (e.target.name == "degree" || e.target.name == "collegeName") {
      setEducation({ ...education, [e.target.name]: e.target.value });
    } else if (e.target.name == "") {
      return;
    } else {
      setEditHosData({
        ...editHosData,
        [e.target.name]: e.target.value,
      });
    }
  };

  
  const removeWorkingOption = async (value) => {
     let workingOpt = editHosData.workingOption?.filter((ele) => {
       if (ele !== value.element) {
         return ele;
       }
     });
     setEditHosData({
       ...editHosData,
       workingOption: [...workingOpt],
     });
     console.log(workingOpt);
    };
    const setWorkingOption = (value) => {
      let concept = value.split("-");
      let val = true;

      editHosData.workingOption.map((wp) => {
        if (wp.split("-")[0] == concept[0]) {
          enqueueSnackbar("You have already choosen the option", {
            variant: "info",
            autoHideDuration: 1000,
          });
          val = false;
        }
      });
      if (val) {
        if (editHosData.workingOption.includes(value)) {
          enqueueSnackbar("You have already choosen the option", {
            variant: "info",
            autoHideDuration: 1000,
          });
        } else {
          setEditHosData({
            ...editHosData,
            workingOption: [...editHosData.workingOption, value],
          });
        }
      }
    };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "status",
    inputProps: { "aria-label": item },
  });
  const [selectedCountry, setSelectedCountry] = useState(editHosData.country);
  const [selectedState, setSelectedState] = useState(editHosData.state);
  const [selectedCity, setSelectedCity] = useState(editHosData.district);

  useEffect(() => {
    State?.getStatesOfCountry(selectedCountry?.isoCode);
  }, [selectedCountry]);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#000", // Dark background for the input
      color: "#fff",
      border: "1px solid black",
      borderRadius: "0.375rem",
      margin: "0.25rem",
      width: "100%",
      padding: "0.75rem",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333", // Dark background for the dropdown
      color: "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#555" : "#333", // Darker when selected
      color: "#fff",
      padding: 10,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // Ensures the selected option text is white
    }),
  };

  return (
    <AddVipContainer>
      <ThemeProvider theme={theme}>
      <form
        onChange={FormData}
        class="container"
        style={{ position: "relative" }}
        className="scroll"
      >
        <div class="row d-md-flex flex-column-reverse flex-md-row">
          <div class="col col-12 col-md-9">
            <div id="form-container" class="m-1">
              <form
                class="scroll"
                style={{
                  height: "85vh",
                  overflowY: "scroll",
                  paddingTop: "1rem",
                  paddingBottom: "5rem",
                }}
                action=""
              >
                <div class="container"></div>

                <div class="container">
                            <div class="row">
                              <hr />
                              <span class="col-md-3 col-sm-12 col-sm-12">Personal Information</span>
                              <div class="col-12 d-flex flex-column px-3">
                                <div style={{ width: "100%" }}>
                                  <span
                                    
                                    class="d-flex flex-column flex-md-row justify-content-between"
                                  >
                                    <TextField
                                          style={{
                                            width: "100%",
                                            maxWidth: "100%",
                                            margin: "0.5rem", // Default margin
                                            marginBottom: "1rem", // Default spacing below
                                          }}
                                          id="filled-basic"
                                          variant="filled"
                                          name="userName"
                                      
                                      type="text"
                                      label="Staff Name"
                                      value={editHosData.userName}
                                          fullWidth
                                        />
                                        </span>
                                    <span className="d-flex flex-column flex-sm-row justify-content-between">
                                          <TextField
                                            style={{
                                              width: "100%",
                                              maxWidth: "100%",
                                              margin: "0.5rem",
                                              marginBottom: "1rem",
                                            }}
                                            variant="filled"
                                            type="text"
                                        name="age"
                                        label="Age"
                                        value={editHosData.age}
                                            fullWidth
                                          />
                                          <TextField
                                            style={{
                                              width: "100%",
                                              maxWidth: "100%",
                                              margin: "0.5rem",
                                              marginBottom: "1rem",
                                            }}
                                            variant="filled"
                                            type="text"
                                        name="bloodGroup"
                                        label="Blood Group"
                                        value={editHosData.bloodGroup}
                                            fullWidth
                                          />
                                            
                                          </span>
                                          <span className="d-flex flex-column flex-md-row justify-content-between">
                                          <TextField
                                            label="Date of Birth (dd-mm-yyyy)"
                                            variant="filled"
                                            id="filled-basic"
                                            name="dateOfBirth"
                                            type="date"
                                            value={getData(editHosData.dateOfBirth)}
                                            style={{
                                              width: "100%",
                                              maxWidth: "100%",
                                              margin: "0.5rem",
                                              marginBottom: "1rem",
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            fullWidth
                                            className="form-field"
                                          />
 <FormControl
  variant="filled"
  fullWidth
  style={{
    width: "100%",
    maxWidth: "100%",
    margin: "0.5rem",
    marginBottom: "1rem",
  }}
>
  <InputLabel>Gender</InputLabel>
  <Select
    label="Gender"
    name="gender"
    value={editHosData.gender}
    onChange={handleClickGender} // Make sure handleChange updates `editHosData`
  >
    <MenuItem value="Male">Male</MenuItem>
    <MenuItem value="Female">Female</MenuItem>
    <MenuItem value="Other">Other</MenuItem>
  </Select>
</FormControl>

                                          
                                        </span>
                                        <span className="d-flex flex-column flex-md-row justify-content-between">
                                          <TextField
                                            style={{
                                              width: "100%",
                                              maxWidth: "100%",
                                              margin: "0.5rem",
                                              marginBottom: "1rem",
                                            }}
                                            variant="filled"
                                            label="Father Name"
                                            name="fatherName"
                                            value={editHosData.fatherName}
                                            fullWidth
                                          />
                                          <TextField
                                            style={{
                                              width: "100%",
                                              maxWidth: "100%",
                                              margin: "0.5rem",
                                              marginBottom: "1rem",
                                            }}
                                            variant="filled"
                                            label="Mother Name"
                                      name="motherName"
                                      value={editHosData.motherName}
                                            fullWidth
                                          />
                                        </span>
                                  
                                        <span className="d-flex flex-column flex-md-row justify-content-between">
                                          {allEducation?.map((education) => {
                                            return (
                                              <div className="m-2">
                                                <Button variant="contained">
                                                  {education.degree}--
                                                  {education.collegeName}
                                                  <Icon
                                                    onClick={() => {
                                                      removeElementByConditionEducation(
                                                        education.degree
                                                      );
                                                    }}
                                                    style={{
                                                      padding: "0.5rem",
                                                    }}
                                                    icon={cross}
                                                  />
                                                </Button>
                                              </div>
                                            );
                                          })}
                                        </span>
                                      <span className="d-flex flex-column flex-md-row justify-content-between">

                                      <TextField
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            margin: "0.5rem",
                            marginBottom: "1rem",
                          }}
                          variant="filled"
                          label="Degree"
                          name="degree"
                          value={education.degree}
                          fullWidth
                        />
                        <TextField
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            margin: "0.5rem",
                            marginBottom: "1rem",
                          }}
                          variant="filled"
                          label="College Name"
                          name="collegeName"
                          value={education.collegeName}
                          fullWidth
                        />
                                     <span className="m-2 d-flex justify-content-center align-items-center">
                                     <Button
                                         onClick={() => addEducationData(education)}
                                         variant="outlined"
                                         color="success"
                                         size="small"
                                         sx={{
                                           borderColor: "green",
                                           color: "green",
                                           padding: "2px 8px",
                                           minHeight: "24px",
                                           fontSize: "0.75rem",
                                           lineHeight: "1",
                                           "&:hover": {
                                             borderColor: "darkgreen",
                                             backgroundColor: "rgba(0, 128, 0, 0.1)",
                                           },
                                         }}
                                      >
                                        Add
                                      </Button>
                                    </span>
                                  </span>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                <hr />
                <div className="container mt-4">
  <div className="row">
    <span className="col-md-3 col-12">
      Career Details
    </span>
    <div className="col-md-9 col-12 d-flex flex-column px-3">
      <div className="d-flex flex-column flex-md-row align-items-center mb-3">
        <p>Currently Unemployed</p>
        <div className="d-flex align-items-center me-3">
          <input
            style={{ margin: "1rem", width: "1rem", height: "1rem", border: "1px solid black" }}
            className="me-2"
            type="radio"
            id="yes"
            value="yes"
            name="newForWorking"
            checked={editHosData.newForWorking === "yes"} // Check if "yes" is selected
            onChange={(e) => setEditHosData({ ...editHosData, newForWorking: e.target.value })} // Update state
          />
          <label htmlFor="yes" className="mb-0">Yes</label>
        </div>
        <div className="d-flex align-items-center me-3">
          <input
            style={{ margin: "1rem", width: "1rem", height: "1rem", border: "1px solid black" }}
            className="me-2"
            type="radio"
            id="no"
            value="no"
            name="newForWorking"
            checked={editHosData.newForWorking === "no"} // Check if "no" is selected
            onChange={(e) => setEditHosData({ ...editHosData, newForWorking: e.target.value })} // Update state
          />
          <label htmlFor="no" className="mb-0">No</label>
        </div>
      </div>
      {editHosData.newForWorking === "no" && (
        <>
          <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
            {jobDataAll?.map((job) => (
              <div className="m-2" key={job.jobDescription}>
                <Button
                  variant="contained"
                  color="success"
                  style={{ padding: "0.5rem 1rem" }}
                >
                  {job.jobDescription} --
                  {job.companyName}
                  <Icon
                    onClick={() => {
                      removeElementByCondition(job.jobDescription);
                    }}
                    style={{ padding: "0.5rem" }}
                    icon={cross}
                  />
                </Button>
              </div>
            ))}
          </div>
          <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
            <TextField
              style={{
                width: "100%",
                maxWidth: "100%",
                margin: "0.5rem",
                border: "1px solid black",
              }}
              variant="filled"
              label="Job Description"
              name="jobDescription"
              value={jobData.jobDescription}
              fullWidth
            />
            <TextField
              style={{
                width: "100%",
                maxWidth: "100%",
                margin: "0.5rem",
                border: "1px solid black",
              }}
              variant="filled"
              label="Company Name"
              name="companyName"
              value={jobData.companyName}
              fullWidth
            />
          </div>
          <span className="mt-2 d-flex justify-content-center">
            <Button
              onClick={() => addJobData(jobData)}
              variant="outlined"
              color="success"
            >
              Add
            </Button>
          </span>
        </>
      )}
    </div>
  </div>
</div>

                <hr />
                <div class="container mt-4">
                            <div class="row">
                            <span class="col-md-3 col-sm-12 col-sm-12">Contact Details</span>
                            <div className="col-12 col-md-9 d-flex flex-column px-3">
                            <div className="d-flex flex-column flex-md-row align-items-center">
                            <TextField
                        style={{
                          width: "100%",
                          maxWidth: "100%",
                          margin: "0.5rem 0", // Adjusted margin for spacing
                          border: "1px solid black",
                        }}
                        variant="filled"
                        label="Contact Email"
                        name="email"
                        value={editHosData.email}
                        fullWidth
                      />
                       </div>
                       <div className="d-flex flex-column flex-md-row">
                       <TextField
                        style={{
                          width: "100%",
                          maxWidth: "100%",
                          margin: "0.5rem 0", // Adjusted margin for spacing
                          border: "1px solid black",
                        }}
                        variant="filled"
                        label="Contact Phone Number"
                                    value={editHosData.phoneNumber}
                                    name="phoneNumber"
                        fullWidth
                      />
                                  
                                </div>
                                <div className="d-flex flex-column flex-md-row">
                                <TextField
                        style={{
                          width: "100%",
                          maxWidth: "100%",
                          margin: "0.5rem 0", // Adjusted margin for spacing
                          border: "1px solid black",
                        }}
                        variant="filled"
                        label="Whatsapp"
                        value={editHosData.whatsappNumber}
                        name="whatsappNumber"
                        fullWidth
                      />
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                <hr />
                <div class="container">
                            <div class="row">
                              <span class="col-3 col-md-3 col-sm-12 col-sm-12">
                                Address
                              </span>
                              <div className="col-12 col-md-9 d-flex flex-column px-3">
                                <div className="row">
                                  <div className="col-12 col-md-6 mb-3">
                                    <TextField
                                      style={{
                                        width: "100%",
                                        border: "1px solid black",
                                      }}
                                      variant="filled"
                                      label="Door/Flat No"
                                      name="doorFlatNumber"
                                      value={editHosData.doorFlatNumber}
                                      fullWidth
                                    />
                                 
                                  </div>
                                <div className="col-12 col-md-6 mb-3">
                                  <TextField
                                    style={{
                                      width: "100%",
                                      border: "1px solid black",
                                    }}
                                    variant="filled"
                                    label="Street Name"
                                    name="streetName"
                                    value={editHosData.streetName}
                                    fullWidth
                                  />
                                  
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 mb-3">
                                    <TextField
                                      style={{
                                        width: "100%",
                                        border: "1px solid black",
                                      }}
                                      variant="filled"
                                      label="Land mark"
                                      name="landMark"
                                      value={editHosData.landMark}
                                      fullWidth
                                    />
                                </div>
                                </div>
                                <span
                                  style={{ width: "100%" }}
                                  class="d-flex mt-4 justify-content-between"
                                >
                                  <input
                                    style={{
                                      width: "45%",
                                      border: "1px solid black",
                                    }}
                                    class=" p-3 bg-black  rounded-3"
                                    type="text"
                                    placeholder="State"
                                    name="state"
                                    value={editHosData.state}
                                  />
                                  <input
                                    style={{
                                      width: "45%",
                                      border: "1px solid black",
                                    }}
                                    class=" p-3 bg-black rounded-3"
                                    placeholder="Country"
                                    name="country"
                                    value={editHosData.country}
                                  />
                                </span>
                                <span
                                  style={{ width: "100%" }}
                                  class="d-flex mt-4 justify-content-between"
                                >
                                  <input
                                    style={{
                                      width: "45%",
                                      border: "1px solid black",
                                    }}
                                    class=" p-3 bg-black  rounded-3"
                                    type="text"
                                    placeholder="District"
                                    name="district"
                                    value={editHosData.district}
                                  />
                                  <input
                                    style={{
                                      width: "45%",
                                      border: "1px solid black",
                                    }}
                                    class=" p-3 bg-black rounded-3"
                                    placeholder="Pincode"
                                    name="pinCode"
                                    value={editHosData.pinCode}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                <hr />
                <div class="container mt-4">
                            <div class="row">
                            <span class="col-md-3 col-sm-12 col-sm-12">Account Details</span>
                              <div class="col-9  d-flex flex-column px-3">
                              <div className="row">
                              <div className="col-12 col-md-6 mb-3">
                              <TextField
                            style={{
                              width: "100%",
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="Bank Account Name"
                            name="bankAccountName"
                            value={editHosData.bankAccountName}
                            fullWidth
                          />
                                   </div>
                                   <div className="col-12 col-md-6 mb-3">

                                    <TextField
                                          style={{
                                            width: "100%",
                                            border: "1px solid black",
                                          }}
                                          variant="filled"
                                          label="Branch Name"
                                          name="bankBranchName"
                                          value={editHosData.bankBranchName}
                                          fullWidth
                                      />
                                    </div>
                                </div>
                                <div className="row">
                        <div className="col-12 col-md-6 mb-3">
                        <TextField
                            style={{
                              width: "100%",
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="IFSC Code"
                            name="ifscCode"
                            value={editHosData.ifscCode}
                            fullWidth
                          />
                            </div>
                            <div className="col-12 col-md-6 mb-3"> 
                            <TextField
                            style={{
                              width: "100%",
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="Bank Account Number"
                            name="bankAccountNumber"
                            value={editHosData.bankAccountNumber}
                            fullWidth
                          />     
                                </div>
                                </div>
                                <div className="row">
                        <div className="col-12">
                          <TextField
                            style={{
                              width: "100%",
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="PAN Card Number"
                            name="panCardNumber"
                            value={editHosData.panCardNumber}
                            fullWidth
                          />
                        </div>
                      </div>
                                
                              </div>
                            </div>
                          </div>
                <hr />
                <div class="container">
                            <div class="row">
                              <span class="col-3">Concepts</span>
                              <div class="col-9 d-flex flex-column px-3">
                                <div>
                                  {editHosData.workingOption?.map((element) => {
                                    return (
                                      <span class=" badge text-bg-success p-2 m-2">
                                        <span
                                          style={{
                                            fontSize: "1rem",
                                            paddingRight: "0.5rem",
                                          }}
                                        >
                                          {element}
                                        </span>
                                        <Icon
                                          onClick={() =>
                                            removeWorkingOption({ element })
                                          }
                                          icon={cross}
                                        />
                                      </span>
                                    );
                                  })}
                                </div>
                                <span
                                  style={{ width: "100%" }}
                                  class="d-flex justify-content-between"
                                >
                                  <div className="checkbox-container">
                                    {concept?.map((c, i) => {
                                      return (
                                        <div
                                          className="btn btn-outline-secondary pink-btn"
                                          htmlFor="checkbox1"
                                          name=""
                                          onClick={() => {
                                            setModel(true);
                                            setConceptSelected(c.conceptName);
                                          }}
                                        >
                                          {c.conceptName}
                                        </div>
                                      );
                                    })}
                                    {/* Add similar checkbox elements for other checkboxes */}
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr/>

             
                
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-12 col-md-3 d-flex align-items-center">
                      <span>Media Link</span>
                    </div>
                    <div className="col-12 col-md-9 d-flex flex-column px-3">
                      <TextField
                        variant="filled"
                        label="Instagram"
                        name="instagram"
                        value={editHosData.instagram}
                        fullWidth
                        style={{
                          margin: "3%",
                          border: "1px solid black",
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-3"></div>
                    <div className="col-12 col-md-9 d-flex flex-column px-3">
                      <TextField
                        variant="filled"
                        label="Facebook"
                        name="facebook"
                        value={editHosData.facebook}
                        fullWidth
                        style={{
                          margin: "3%",
                          border: "1px solid black",
                        }}
                      />
                    </div>
                  </div>
                </div>
                                <hr />
                                <div class="container status-section">
                                  <div class="row align-items-center">
                                    <span class="col-3 inputHeadings">
                                      Status
                                    </span>
                                    <div class="col-9 d-flex flex-column px-3">
                                      <FormControl>
                                        <FormLabel id="demo-controlled-radio-buttons-group">
                                          Status
                                        </FormLabel>
                                        <RadioGroup
                                          className="d-sm-flex flex-sm-column flex-md-row"
                                          aria-labelledby="demo-controlled-radio-buttons-group"
                                          name="controlled-radio-buttons-group"
                                          value={selectedValue}
                                        >
                                          <FormControlLabel
                                            name="status"
                                            value="active"
                                            control={
                                              <Radio
                                                name="status"
                                                {...controlProps("active")}
                                                color="success"
                                              />
                                            }
                                            label="Active"
                                          />
                                          <FormControlLabel
                                            name="status"
                                            value="inactive"
                                            control={
                                              <Radio
                                                name="status"
                                                {...controlProps("inactive")}
                                              />
                                            }
                                            label="In Active"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>
                <hr />
                <div class="container">
                            <div class="row">
                              <div class="col-12 d-flex flex-column px-3">
                              <div class="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                  <button
                                    onClick={(e) => editHos(e)}
                                    class="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-course-btn"
                                  >
                                    Edit HOS
                                  </button>
                                  <button
                                    onClick={() => setEditHosContainer(false)}
                                    class="btn btn-outline-danger btn-sm cancel-btn"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
              </form>
              <div id="">
                          <div
                            style={{ display: modal ? "block" : "none" }}
                            class="modal "
                            tabindex="-1"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 style={{ color: "black" }}>
                                    Set Concept Level
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => setModel(false)}
                                  ></button>
                                </div>
                                <div class="modal-body">
                                  <div
                                    className="btn btn-outline-secondary pink-btn"
                                    htmlFor="checkbox1"
                                    name=""
                                    onClick={() => {
                                      setWorkingOption(`${conceptSelected}-0`);
                                      setModel(false);
                                    }}
                                  >
                                    Level 0
                                  </div>
                                  <div
                                    className="btn btn-outline-secondary pink-btn"
                                    htmlFor="checkbox1"
                                    name=""
                                    onClick={() => {
                                      setWorkingOption(`${conceptSelected}-1`);
                                      setModel(false);
                                    }}
                                  >
                                    Level 1
                                  </div>
                                  <div
                                    className="btn btn-outline-secondary pink-btn"
                                    htmlFor="checkbox1"
                                    name=""
                                    onClick={() => {
                                      setWorkingOption(`${conceptSelected}-2`);
                                      setModel(false);
                                    }}
                                  >
                                    Level 2
                                  </div>
                                  <div
                                    className="btn btn-outline-secondary pink-btn"
                                    htmlFor="checkbox1"
                                    name=""
                                    onClick={() => {
                                      setWorkingOption(`${conceptSelected}-3`);
                                      setModel(false);
                                    }}
                                  >
                                    Level 3
                                  </div>
                                  <div
                                    className="btn btn-outline-secondary pink-btn"
                                    htmlFor="checkbox1"
                                    name=""
                                    onClick={() => {
                                      setWorkingOption(`${conceptSelected}-4`);
                                      setModel(false);
                                    }}
                                  >
                                    Level 4
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    onClick={() => setModel(false)}
                                  >
                                    Close
                                  </button>
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
            </div>
          </div>

          <div class="col col-12 col-md-3 col-lg-3">
            <div id="profile-container" class="m-1">
              <div id="profile-img">
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*"
                  name="profileImage"
                />
                {previewUrl !== "" ? (
                  <img id="image" src={previewUrl} alt="" />
                ) : (
                  <img src={imageURL(editHosData.imageACCESSKEY)} alt="" />
                )}
              </div>
              <div class="upld">
                <b>Upload</b>
              </div>
              <h5>Rakesh</h5>
              <h5 style={{ textAlign: "center" }}>
                <i class="fa-solid fa-id-badge"></i> Set as <b>Active</b>
              </h5>
            </div>
          </div>
        </div>

        
      </form>
      </ThemeProvider>
    </AddVipContainer>
  );
}
const AddVipContainer = styled.div`
  background-color: #25272d;
  padding-top: 2rem;
  height: 100vh;
  #image {
    object-fit: cover !important;
  }
  #otp-container {
    position: absolute;
    top: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #313131;
    height: 20rem;
    width: 20rem;
    top: 10%;
    left: 20%;
  }
  .otp-input {
    color: black;
    border-radius: 8px;
  }
  #otp ::placeholder {
    color: black;
  }
  .verify-btn {
    background-color: lightgrey;
    color: #1d1e22;
    border-radius: 1rem;
    padding: 1rem;
  }
  input::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  #add-student-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: gray !important;
  }

  h2,
  p,
  div,
  h6 {
    color: white;
  }
  .add-course-btn:hover {
    background-color: green;
  }
  .cancel-btn:hover {
    background-color: red;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }

  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }

  h5 {
    text-align: center;
    color: white;
  }

  input {
    color: white;
  }

  /* Status Section Styles */
  .status-section {
    margin-top: 1rem;
  }

  .status-label {
    color: white;
  }

  .status-options {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .active-dot {
    background-color: green;
  }

  .inactive-dot {
    background-color: red;
  }

  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #add-student-container {
      padding: 1rem;
    }

    #profile-container {
      margin-top: 1rem;
    }
  }
  /* Style for active radio button */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }
  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }
  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }
  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  .add-course-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }
  .mini-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
  }
  .checkbox-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #1d1e22;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to individual boxes within containers */
  }
  .checkbox-container {
    display: flex; /* Display checkboxes horizontally */
    justify-content: flex-start; /* Align checkboxes to the start */
    flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
    margin-bottom: 20px; /* Add margin at the bottom */
  }
  .checkbox-container div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px; /* Add margin at the bottom of each checkbox */
  }

  .checkbox-container div:last-child {
    margin-right: 0; /* Remove margin for the last checkbox */
  }
  .pink-btn {
    border-color: rgb(201, 66, 149); /* Change the outline color to pink */
    color: rgb(201, 66, 149); /* Change text color to ensure visibility */
  }
  .generate-btn:hover {
    background-color: green;
  }
  .pink-btn:hover {
    background-color: #313131;
    color: rgb(255, 255, 255);
  }
  .btn-verify {
    background-color: green;
    color: white;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    cursor: pointer;
  }
    @media (max-width: 575px) {
    .add-course-btn,
    .cancel-btn {
      align-items: center;
      width: 100%; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
    }
`;

export default EditFranchise;