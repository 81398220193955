import React from 'react'
import NavBar from "./miniComponents/subcomponents/NavBar";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import { styled } from "styled-components";
import { VipRevenueSection } from './VipRevenueSection';
export default function VipRevenue() {
  return (
    <VipRevenueContainer>
      <MiniNavBar selected={"viprevenue"} />
      <NavBar selected={"viprevenue"} />
      <VipRevenueSection />
    </VipRevenueContainer>
  );
}
const VipRevenueContainer = styled.div`
  display: flex;
  background-color: var(--background);
`;
