import React from 'react'
import NavBar from "./miniComponents/subcomponents/NavBar";
import { styled } from "styled-components";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import MarkAttendanceButton from './miniComponents/subcomponents/Markattandance';

function Attandance() {
  return (
    <Container>
      <MiniNavBar selected={"hosattendance"} />
      <NavBar selected={"hosattendance"} />
      <MarkAttendanceButton />
    </Container>
  );
}
const Container = styled.div`
  background-color: var(--background);
  display: flex;
`;
export default Attandance