import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { Carousel } from 'react-bootstrap';
import { URL as url } from "../assets/mocData/config.js";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faVideo, faImage } from '@fortawesome/free-solid-svg-icons';
import AWS from 'aws-sdk';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { REGION, ACCESS_KEY, SECRET_KEY, BUCKET_NAME } from "../assets/mocData/config.js";
import { enqueueSnackbar } from "notistack";
const MediaUploads = () => {
  const [message, setMessage] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [posters, setPosters] = useState([]);
  const [dates, setDates] = useState("");
  const [data, setData] = useState({});
  const [imgk, setImgk] = useState();
  const [selectedMediaType, setSelectedMediaType] = useState(null);
  const [sharing, setSharing] = useState(false);
  const [position, setPosition] = useState([]);
  const [open, setOpen] = useState(false);
  const [process, setPross] = useState(false);

  AWS.config.update({
    region: REGION,
    credentials: new AWS.Credentials(ACCESS_KEY, SECRET_KEY),
  });

  const s3 = new AWS.S3();

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleUpload = (event, mediaType) => {
    const files = Array.from(event.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (mediaType === 'photo') {
          setPhotos((prevPhotos) => [...prevPhotos, { file, src: reader.result }]);
        } else if (mediaType === 'video') {
          setVideos((prevVideos) => [...prevVideos, { file, src: reader.result }]);
        } else if (mediaType === 'poster') {
          setPosters((prevPosters) => [...prevPosters, { file, src: reader.result }]);
        }
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    });

    setSelectedMediaType(mediaType);
  };

  const push = (type) => setPosition((prev) => [...prev, type]);
  const pull = (type) => setPosition((prev) => prev.filter((pos) => pos !== type));

  const handleMultipartUpload = async (file) => {
    try {
      const params = {
        Bucket: BUCKET_NAME,
        Key: file.name,
        ContentType: file.type,
      };
  
      const multipartUpload = await s3.createMultipartUpload(params).promise();
      const chunkSize = 15 * 1024 * 1024; // Increase chunk size to 15MB
      const numChunks = Math.ceil(file.size / chunkSize);
      const uploadId = multipartUpload.UploadId;
      const parts = [];
  
      const uploadPromises = [];
  
      for (let i = 0; i < numChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const blob = file.slice(start, end);
  
        const partParams = {
          Bucket: BUCKET_NAME,
          Key: file.name,
          PartNumber: i + 1,
          UploadId: uploadId,
          Body: blob,
        };
  
        // Push upload part promises into an array
        uploadPromises.push(
          s3.uploadPart(partParams).promise().then((part) => ({
            ETag: part.ETag,
            PartNumber: i + 1,
          }))
        );
      }
  
      // Wait for all parts to be uploaded
      const uploadedParts = await Promise.all(uploadPromises);
  
      const completeParams = {
        Bucket: BUCKET_NAME,
        Key: file.name,
        UploadId: uploadId,
        MultipartUpload: { Parts: uploadedParts },
      };
  
      await s3.completeMultipartUpload(completeParams).promise();
    } catch (error) {
      enqueueSnackbar('Error during upload: ' + error.message, { variant: 'error' });
    }
  };

  const handleShare = async () => {
    if (position.length === 0) {
      enqueueSnackbar(" select at least one group.", { variant: 'warning' });
      return;
    }
    setOpen(true);
  };

  const handleConfirmShare = async () => {
    if (position.length === 0) {
      enqueueSnackbar("Select at least one group.", { variant: 'warning' });
      return;
    }

    setSharing(true);
    setOpen(false);

    const handleImageSubmit = async (file) => {
      try {
        if (file.size > 20 * 1024 * 1024) { // Use multipart upload for larger files
          await handleMultipartUpload(file);
        } else {
          const params = {
            Bucket: BUCKET_NAME,
            Key: file.name,
            Body: file,
            ContentType: file.type,
          };
          await s3.upload(params).promise();
        }

        const postData = {
          users: position,
          imagekey: file.name,
          types: selectedMediaType,
          date: dates || new Date(),
        };

        await axios.post(`${url}/media/mediapost/6718c9084a6c1d49e553fbb5`, postData);
      } catch (err) {
        enqueueSnackbar('Error sharing media: ' + err.message, { variant: 'error' });
        setSharing(false);
        return;
      }
    };

    try {
      const uploadPromises = [];

      if (selectedMediaType === 'photo') {
        uploadPromises.push(...photos.map(photo => handleImageSubmit(photo.file)));
      } else if (selectedMediaType === 'poster') {
        uploadPromises.push(...posters.map(poster => handleImageSubmit(poster.file)));
      } else if (selectedMediaType === 'video') {
        uploadPromises.push(...videos.map(video => handleImageSubmit(video.file)));
      }

      await Promise.all(uploadPromises);

      enqueueSnackbar('Media shared successfully', { variant: 'success' });

      setPross(true);
      setTimeout(() => {
        setSharing(false);
        setPross(false);
        setMessage([]);
        setPhotos([]);
        setVideos([]);
        setPosters([]);
        setPosition([]);
        setSelectedMediaType(null);
        resetCheckboxes();
      }, 1000);
    } catch (err) {
      enqueueSnackbar('Error sharing media: ' + err.message, { variant: 'error' });
      setSharing(false);
    }
  };

  useEffect(() => {
    const shareMessage = localStorage.getItem('shareMessage');
    if (shareMessage) {
      setMessage(shareMessage);
      localStorage.removeItem('shareMessage');
    }
  }, []);

  const handleCancel = () => {
    setPhotos([]);
    setVideos([]);
    setPosters([]);
    setPosition([]);
    setSelectedMediaType(null);
    resetCheckboxes();
    enqueueSnackbar('Media upload canceled', { variant: 'info' });
  };

  const hasImages = photos.length > 0 || videos.length > 0 || posters.length > 0;

  const isButtonDisabled = (mediaType) => {
    return sharing || (selectedMediaType && selectedMediaType !== mediaType);
  };

  const resetCheckboxes = () => {
    document.querySelectorAll('.btn-check').forEach(checkbox => {
      checkbox.checked = false;
    });
  };

  return (
   
      <MarketContainer>
      <div className="App">
        <div className="container">
          <div className="left-column">
            <div className="upload-container">
              <h2>Media</h2>
              <p>Share photos, videos, or posters.</p>
              {message && <p className="success-message">{message}</p>}
              <div className="button-container">
                <label className={selectedMediaType === 'photo' ? "upload-button pink" : "upload-button"}>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => handleUpload(e, 'photo')}
                    disabled={isButtonDisabled('photo')}
                  />
                  <FontAwesomeIcon icon={faCamera} /> Add Photo
                </label>
                <label className={selectedMediaType === 'video' ? "upload-button pink" : "upload-button"}>
                  <input
                    type="file"
                    accept="video/*"
                    multiple
                    onChange={(e) => handleUpload(e, 'video')}
                    disabled={isButtonDisabled('video')}
                  />
                  <FontAwesomeIcon icon={faVideo} /> Add Video
                </label>
                <label className={selectedMediaType === 'poster' ? "upload-button pink" : "upload-button"}>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => handleUpload(e, 'poster')}
                    disabled={isButtonDisabled('poster')}
                  />
                  <FontAwesomeIcon icon={faImage} /> Add Poster
                </label>
              </div>
            </div>

            <div className="display-container scrollbar" id="style-2">
              {photos.map((photo, index) => (
                <div key={`photo-${index}`} className="media-item">
                  <img src={photo.src} alt={`Uploaded Photo ${index}`} className="media-preview" />
                  <span className="file-name">{photo.file.name}</span>
                </div>
              ))}
              {videos.map((video, index) => (
                <div key={`video-${index}`} className="media-item">
                  <video controls width="200">
                    <source src={video.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <span className="file-name">{video.file.name}</span>
                </div>
              ))}
              {posters.map((poster, index) => (
                <div key={`poster-${index}`} className="media-item">
                  <img src={poster.src} alt={`Uploaded Poster ${index}`} className="media-preview" />
                  <span className="file-name">{poster.file.name}</span>
                </div>
              ))}
            </div>

            <div className="checkbox-container">
              <input onChange={(e) => { e.target.checked === true ? push("vip") : pull("vip") }} type="checkbox" className="btn-check" id="checkbox1" />
              <label className="btn btn-outline-secondary pink-btn" htmlFor="checkbox1">VIP</label>
              <input onChange={(e) => { e.target.checked == true ? push("vipFranchise") : pull("vipFranchise") }} type="checkbox" className="btn-check" id="checkbox2" />
              <label className="btn btn-outline-secondary pink-btn" htmlFor="checkbox2">VIP FRANCHISE</label>
              <input type="checkbox" onChange={(e) => { e.target.checked == true ? push("franchise") : pull("franchise") }} className="btn-check" id="checkbox3" />
              <label className="btn btn-outline-secondary pink-btn" htmlFor="checkbox3">FRANCHISE</label>
              <input type="checkbox" onChange={(e) => { e.target.checked == true ? push("branchStaff") : pull("branchStaff") }} className="btn-check" id="checkbox4" />
              <label className="btn btn-outline-secondary pink-btn" htmlFor="checkbox4">BRANCH STAFF</label>
              <input type="checkbox" onChange={(e) => { e.target.checked == true ? push("hostaff") : pull("hostaff") }} className="btn-check" id="checkbox5" />
            </div>
          </div>

          <div className="right-column">
            <h4 style={{ color: 'white' }}>Image Preview</h4>
            <div className="preview-container">
              <Carousel>
                {photos.map((photo, index) => (
                  <Carousel.Item key={`photo-${index}`}>
                    <img src={photo.src} alt={`Uploaded Photo ${index}`} className="preview-item" />
                  </Carousel.Item>
                ))}
                {videos.map((video, index) => (
                  <Carousel.Item key={`video-${index}`}>
                    <video controls className="preview-item">
                      <source src={video.src} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Carousel.Item>
                ))}
                {posters.map((poster, index) => (
                  <Carousel.Item key={`poster-${index}`}>
                    <img src={poster.src} alt={`Uploaded Poster ${index}`} className="preview-item" />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>

            <div className="additional-container">
              <div className="button-container">
                <button className="upload-buttonS" onClick={handleShare} disabled={!selectedMediaType}>
                  {process ? (
                    <CircularProgress variant="indeterminate" size={20} thickness={4} />
                  ) : (
                    "Share"
                  )}
                </button>
                <button
                  className="upload-buttonC"
                  onClick={handleCancel}
                  disabled={!hasImages || sharing}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Confirmation Dialog */}
        <Dialog
          open={open}
          onClose={handleCloseModal}
        >
          <DialogTitle>Confirm Share</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to share this media?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmShare} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </MarketContainer>
  );
}
  
const MarketContainer = styled.div`
 display: flex;
 justify-content: center;
  align-items: center;
  height:auto;


.outer-container {
  width: 100%;
  max-width: 1200px; /* Set maximum width */
}

.pink-btn {
  border-color: rgb(201, 66, 149); /* Change the outline color to pink */
  color: rgb(201, 66, 149); /* Change text color to ensure visibility */
}

.container {
  width: 100%;
  max-width: 1200px; /* Set maximum width */
  margin: 0 auto; /* Center the container horizontally */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-top: 40px;
 padding-left:100px;
}

.display-container {
  overflow-y: auto;
  height: 500px;
  padding: 30px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #1d1e22;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
}

.left-column,
.right-column {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.right-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  justify-content: center;
  margin-left: 20px;
}

.upload-container{
border: 2px solid #313131;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #1d1e22;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}
.display-container,
.dummy-container,
.additional-container,
.preview-container,
.checkbox-container {
  border: 2px solid #313131;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #1d1e22;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.upload-buttonC {
  flex-basis: calc(33.33% - 10px);
  padding: 10px 20px;
  background-color: #1d1e22;
  color: #fff;
  border: 1px solid red;
  border-radius: 20px;
  cursor: pointer;
}
  .upload-buttonC:hover {
  background-color:red;

}
 
.upload-buttonS {
  flex-basis: calc(33.33% - 10px);
  padding: 10px 20px;
  background-color: #1d1e22;
  color: #fff;
  border: 1px solid green;
  border-radius: 20px;
  cursor: pointer;
}
  .upload-buttonS:hover {
  background-color:green;

}
.upload-button {
  flex-basis: calc(33.33% - 10px);
  padding: 10px 20px;
  background-color: #1d1e22;
  color: #fff;
  border: 1px solid #c94295;
  border-radius: 20px;
  cursor: pointer;
}
.upload-button:hover {
  background-color:#333;

}
.upload-button.pink {
  background-color: rgb(200, 54, 110);
}

.upload-button input {
  display: none;
}

.display-container {
  overflow-y: auto;
  max-height: 300px;
}

.display-container::-webkit-scrollbar {
  width: 12px;
}

.display-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.display-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.display-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.media-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.media-preview {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.file-name {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.popup-content p {
  margin-bottom: 20px;
}

.popup-content button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  max-width: 500px;
  background-color: #f1f1f1;
  border: 2px solid #313131;
  border-radius: 10px;
  overflow: hidden;
  color: #313131;
}

.preview-item {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.additional-container {
  border: 2px solid #313131;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  justify-content:center;
}

.checkbox-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.checkbox-container label:last-child {
  margin-right: 0;
}

.checkbox-container label input {
  display: none;
}

/* Styles for the carousel container */
.carousel-container {
  width: 100%;
}

/* Styles for the carousel items */
.carousel-item {
  width: 100%;
  height: 100%;
}

/* Styles for the videos inside carousel items */
.carousel-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .container {
    padding: 0 20px;
  }
}

@media (max-width: 1000px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }

  .right-column {
    max-width: 100%;
    margin-left: 0;
  }

  .upload-container,
  .display-container,
  .dummy-container,
  .additional-container,
  .preview-container {
    padding: 20px;
  }

  .checkbox-container {
    flex-direction: column;
  }

  .button-container {
    flex-direction: column;
    align-items: center;
  }

  .upload-button {
    flex-basis: calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .container {
   width:100vw;
    grid-template-columns: 2fr;
    padding: 0 20px;
  }

  .left-column,
  .right-column {
    max-width: 100%;
  }

  .upload-container,
  .display-container,
  .dummy-container,
  .additional-container,
  .preview-container {
    padding: 20px;
  }

  .checkbox-container {
    flex-direction: column;
    align-content:center;
  }

  .checkbox-container label {
    margin-right: 0;
    justify-content:space-around;
    width:50%;
    margin-bottom: 10px;
  }

  .button-container {
    flex-direction: column;
    
    align-items: center;
  }

  .upload-button {
    flex-basis: 100%;
  }
}

@media (max-width: 575px) {
  .container {
    padding: 0 10px;
  }

  .left-column,
  .right-column,
  .upload-container,
  .display-container,
  .dummy-container,
  .additional-container,
  .preview-container {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
.upload-buttonC {
flex-basis: calc(33.33% - 10px);
  padding:0px 20px;
  background-color: #1d1e22;
  color: #fff;
  border: 1px solid red;
  border-radius: 20px;
  cursor: pointer;
}
.upload-buttonS {
 flex-basis: calc(33.33% - 10px);
  padding:0px 20px;
  background-color: #1d1e22;
  color: #fff;
  border: 1px solid green;
  border-radius: 20px;
  cursor: pointer;
}

  .upload-button {
    flex-basis: 100%;
  }

  .additional-container {
    justify-content: center;
  }

  .additional-container button {
    flex-basis:20%;
    margin-bottom: 10px;
    padding:15px;
  }

  .checkbox-container label {
    width: 100%;
    margin-right: 0;
  }
}
`;



export default MediaUploads;