import React from 'react'
import NavBar from "../subcomponents/NavBar";
import MiniNavBar from "../subcomponents/MiniNavBar";
import { styled } from "styled-components";
import { PincodeRevenue } from './PincodeTableContainer';
export default function PincodeRevenueSection() {
  return (
    <PincodeRevenueContainer>
      <MiniNavBar selected={"pincoderevenue"} />
      <NavBar selected={"pincoderevenue"} />
      <PincodeRevenue />
    </PincodeRevenueContainer>
  );
}
const PincodeRevenueContainer = styled.div`
  display: flex;
  background-color: var(--background);
`;
