import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import img1 from "../assets/images/img-1.jpg";
import axios from "axios";
import { URL as url } from '../assets/mocData/config';
import OTPForm from './otp';
import ChangePasswords from '../../src/changePasswords';
import { useGlobalContext } from '../assets/contex/GlobalContext';

const ForgotPassword = () => {
  const { profileData } = useGlobalContext();
  const [email] = useState(profileData?.profileData?.email || ''); // Prefill email
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [status, setStatus] = useState("");
  const [otp, setOtp] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);
  const [showOTPForm, setShowOTPForm] = useState(false);

  const [textIndex, setTextIndex] = useState(0);
  const [backgroundIndex, setBackgroundIndex] = useState(0);
  const texts = [
    {
      header: "ABOUT US",
      content:
        "Innovative Entrepreneurship: We foster innovative entrepreneurship by providing a platform for visionary individuals to explore their ideas.",
    },
    {
      header: "CONTACT US",
      content:
        "Get in Touch: Reach out to us to learn more about our initiatives, programs, and how you can get involved. Custom Solutions: Contact us for customized solutions tailored to your needs.",
    },
    {
      header: "JOIN US",
      content:
        "Be Part of Innovation: Join our vibrant community and become part of an ecosystem that nurtures innovation and creativity. Opportunities Await.",
    },
  ];
  const backgrounds = [img1, img1, img1, img1, img1, img1];

  useEffect(() => {
    const textIntervalId = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    const backgroundIntervalId = setInterval(() => {
      setBackgroundIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
    }, 2000);

    return () => {
      clearInterval(textIntervalId);
      clearInterval(backgroundIntervalId);
    };
  }, [texts.length, backgrounds.length]);

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmits = async (e) => {
    e.preventDefault();
    if (otp.length !== 6) {
      setMessage('OTP must be 6 digits.');
      return;
    }

    const data = { email: email, otp: otp };

    try {
      const response = await axios.post(`${url}/verify-otp`, data);
      setMessage(response.data.message);
      setOtpVerified(true);
    } catch (error) {
      setMessage('Error verifying OTP. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("Processing...");
    const data = { email: email };
    try {
      const response = await axios.post(`${url}/send-otp`, data);
      setMessage(response.data.message);
      setStatus(response.status);
      setError(false);
      setShowOTPForm(true); // Show OTP form when OTP is sent successfully
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      setError(true);
    }
  };

  return (
    <MainContainer>
      <VipLoginContainer>
        <div className="content">
          <div className="login-text-container">
            <p>TALENT INITIATORS & ACCELERATORS</p>
          </div>
          <div className="message">
            <h1>EMPOWERING</h1>
            <h1>ENTREPRENEURS TO</h1>
            <h1>BRING IDEAS ALIVE</h1>
            <p>Login to build your ideas </p>
          </div>
          <div className="about-us-container">
            <div className="row">
              <div className="col-md-4 d-flex flex-column justify-content-start">
                <h2>{texts[textIndex].header}</h2>
              </div>
              <div className="col-md-8">
                <p>{texts[textIndex].content}</p>
              </div>
            </div>
          </div>
        </div>

        <BackgroundImageContainer>
          <img src={backgrounds[backgroundIndex]} alt="Background" />

          <LoginFormContainer>
            {otpVerified ? (
              <ChangePasswords emailId={email} />
            ) : (
              <>
                {!showOTPForm && (
                  <>
                    <Title>Change Password</Title>
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Label>Email:</Label>
                        <Input
                          type="email"
                          value={email}
                          readOnly // Make email field non-editable
                        />
                      </FormGroup>
                      <Button type="submit">Submit</Button>
                    </Form>
                  </>
                )}

                {message && <Message error={error}>{message}</Message>}

                {showOTPForm && (
                  <OTPForm
                    otp={otp}
                    handleChange={handleChange}
                    handleSubmit={handleSubmits}
                    message={message}
                    error={error}
                  />
                )}
              </>
            )}
          </LoginFormContainer>
        </BackgroundImageContainer>
      </VipLoginContainer>
    </MainContainer>
  );
};


const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: white;
`;


const Form = styled.form`
    background: #15181e;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
`;

const Button = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #f00d88;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #999;
}
`;

const Message = styled.p`
    margin-top: 20px;
    color: ${props => (props.error ? 'red' : 'green')};
`;

const MainContainer = styled.div`
  background-color: #15181e;
  height: 100vh;
  overflow: auto;
`;

const VipLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  background-color: #15181e;
  color: white;
  padding-left: 60px;
  padding-top: 2rem;

  .content {
    width: 80%;
    max-width: 800px;
    text-align: center;
    padding: 20px;
  }

  .login-text-container {
    margin-bottom: 50px;
    font-size: 1rem;
    font-weight: 600;
    color: #c50274;
  }

  .message {
    margin-bottom: 20px;
  }

  .message h1 {
    font-size: 3.3rem;
    margin: 30px 0;
  }

  .message p {
    font-size: 1rem;
  }

  .about-us-container {
    border-radius: 20px;
    padding: 33px;
    background-image: url(${img1});
    background-size: cover;
    background-position: center;
    opacity: 0.7;
    margin-top: 90px;
    overflow: auto;
  }

  .col-md-4 h2 {
    font-size: 1rem;
    font-weight: bold;
  }

  .col-md-8 p {
    color: white;
    font-weight: bold;
    text-align: left;
    font-size: 0.9rem;
  }

  .about-us-container p {
    color: white;
    font-size: 1rem;
  }

  html,
  body {
    overflow: auto;
    font-family: Helvetica, sans-serif;
    background-color: #15181e;
  }

  @media (min-width: 1751px) and (max-width: 2560px) {
    padding-left: 50px;
  }

  @media (min-width: 1441px) and (max-width: 1750px) {
    padding-left: 20px;
    .about-us-container {
      width: 700px;
      height: 200px;
    }
    .message h1 {
      font-size: 3.7rem;
      margin: 30px 0;
    }
    .content {
      width: 700px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1440px) {
    padding-left: 45px;
    .about-us-container {
      width: 500px;
      height: 200px;
    }
    .message h1 {
      font-size: 3rem;
      margin: 30px 0;
    }
    .content {
      width: 500px;
    }
  }

  @media (min-width: 700px) and (max-width: 1023px) {
    padding-left: 3px;
    .about-us-container {
      max-width: 350px;
      height: 330px;
    }
    .message h1 {
      font-size: 2rem;
      margin: 30px 0;
    }
    .content {
      max-width: 380px;
      padding-left: 50px;
    }
    .message {
      max-width: 380px;
    }
  }

  @media (min-width: 320px) and (max-width: 699px) {
    flex-direction: column;
    padding-left: 10px;
    max-height: 200vh;
    overflow: auto;

    .message h1 {
      font-size: 1.3rem;
    }
    .content {
      display: none;
      min-width: 320px;
    }
    .about-us-container {
      min-width: 200px;
      height: 260px;
      margin-top: 40px;
    }
  }
`;

const BackgroundImageContainer = styled.div`
  position: relative;

  max-width: 900px;
  height: 900px;
  overflow: auto;
  margin-top: 70px;
  opacity: 0.7;

  img {
    width: 80%;
    height: 85%;
    object-fit: cover;
    border-radius: 15px;
  }

  @media (min-width: 1024px) and (max-width: 1440px) {
    min-width: 570px;
    height: 850px;
  }
  @media (min-width: 700px) and (max-width: 1023px) {
    min-width: 418px;
    height: 800px;
    .col-md-8 p {
      font-size: 0.5rem;
    }
    .about-us-container p {
      font-size: 0.5rem;
    }
  }
  @media (min-width: 320px) and (max-width: 699px) {
    min-width: 270px;
    min-height: 350px;
    max-height: 650px;
  }
`;

const LoginFormContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #212529;
  padding: 20px;
  border-radius: 10px;
  width: 65%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .input-container {
    margin-bottom: 70px;
    padding: 5px;
  }

  label {
    display: block;
    color: white;
    margin-bottom: 1px;
    margin-right: 23rem;
    font-size: 0.8rem;
  }

  label1 {
    display: block;
    color: white;
    margin-bottom: 5px;
    font-size: 0.7rem;
  }

  .input-container input[type="text"],
  .input-container input[type="password"] {
    width: calc(100% - 10px);
    padding: 10px;
    border: none;
    background-color: transparent;
    color: #ccc;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    outline: none;
    transition: border-bottom-color 0.3s;
  }

  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="text"]:not(:placeholder-shown),
  input[type="password"]:not(:placeholder-shown) {
    border-bottom-color: white;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-size: 0.8rem;
    margin-bottom: 40px;
  }

  .forget-password {
    color: #ccc;
    font-size: 0.8rem;
    cursor: pointer;
    text-decoration: none;
    margin-left: 10rem;
  }

  .login-button {
    width: 80%;
    padding: 18px;
    background-color: #c50274;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }

  .checkbox-label {
    position: relative;
    color: white;
    font-size: 0.7rem;
    display: flex;
    font-weight: bold;
    align-items: center;
  }

  .custom-checkbox {
    position: absolute;
    opacity: 0;
  }

  .checkmark {
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid pink;
    border-radius: 3px;
    margin-right: 5px;
  }

  .checkmark::after {
    content: "";
    position: absolute;
    display: none;
  }

  .custom-checkbox:checked + .checkmark::after {
    display: block;
    content: "";
    position: absolute;
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid pink;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .custom-checkbox {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .custom-checkbox:checked + .checkmark {
    background-color: pink;
  }
  label {
    margin-right: 19rem;
  }
  .input-container input[type="text"],
  .input-container input[type="password"] {
    width: calc(100% - 10px);
    padding: 13px;
  }
  .forget-password {
    margin-left: 7rem;
  }
  .checkbox-label {
    font-size: 0.7rem;
  }

  @media (min-width: 1441px) and (max-width: 1750px) {
    max-width: 80%;
    max-width: 550px;
    max-height: 85%;
    padding: 60px;
  }

  @media (min-width: 1024px) and (max-width: 1440px) {
    width: 75%;
    max-width: 550px;
    height: 65%;
    padding: 20px;
  }
  @media (min-width: 700px) and (max-width: 1023px) {
    width: 70%;
    max-width: 550px;
    height: 65%;
    padding: 12px;
    .forget-password {
      margin-left: 1.8rem;
    }
    label {
      display: block;
      color: white;
      margin-bottom: 1px;
      margin-right: 13rem;
      font-size: 0.6rem;
    }
  }
  @media (min-width: 320px) and (max-width: 699px) {
    width: 75%;
    max-width: 580px;
    height: 75%;
    padding: 20px;

    .forget-password {
      margin-left: 0.5rem;
      font-size: 0.6rem;
    }
    .input-container {
      margin-bottom: 0px;
      margin-top: 20px;
      padding: 0px;
    }
    label {
      margin-right: 9rem;
      font-size: 0.5rem;
    }
    .checkbox-label {
      font-size: 0.6rem;
    }

    .checkbox-container {
      padding-top: 12px;
    }

    .login-button {
      width: 60%;

      padding: 10px;
    }
  }
`;
export default ForgotPassword;
