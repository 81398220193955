import React, { useState } from 'react'
import { useGlobalContext } from '../assets/contex/GlobalContext'
import { useNavigate } from 'react-router-dom';
import RedirectPage from './miniComponents/templatePages/RedirectPage';
import UserDetails from './miniComponents/subcomponents/UserDetails';
import EditHosForm from './miniComponents/EditForms/EditHosForm';
import EditVipForm from './miniComponents/EditForms/EditVipForm';
import EditFranchise from './miniComponents/EditForms/EditFranchise';
function AllUserProfilePage() {
   const { profileViewData, setProfileViewData } = useGlobalContext();
   const [editDataContainer,setEditDataContainer]=useState(false)
   const navigate = useNavigate();
   console.log(profileViewData);
   console.log(profileViewData !== undefined);
    if(profileViewData.userName!==undefined){
        if(editDataContainer){
            if (
              profileViewData.userType == "hos" ||
              profileViewData.userType == "branchstaff"
            ) {
              return (
                <>
                  <EditHosForm
                    editHosData={profileViewData}
                    setEditHosData={setProfileViewData}
                    setEditHosContainer={setEditDataContainer}
                  />
                </>
              );
            } else if (profileViewData.userType == "vip") {
              return (
                <>
                  <EditVipForm
                    editHosData={profileViewData}
                    setEditHosData={setProfileViewData}
                    setEditHosContainer={setEditDataContainer}
                  />
                </>
              );
            } else if (profileViewData.userType == "franchise") {
              return (
                <>
                  <EditFranchise
                    editHosData={profileViewData}
                    setEditHosData={setProfileViewData}
                    setEditHosContainer={setEditDataContainer}
                  />
                </>
              );
            }
            return(<></>)
        }else{
            return (
              <UserDetails
                setEditDataContainer={setEditDataContainer}
                {...profileViewData}
                profileViewData={profileViewData}
              />
            );
        }
    }else{
        return (
          <RedirectPage/>
        );
    }
    

}

export default AllUserProfilePage