import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Box, Typography, Avatar, Paper } from "@mui/material";
import Icon from "react-icons-kit";
import ceoimage from "../../../assets/images/Tia - font.png"
import {
  ic_account_balance_wallet_outline,
  ic_message,
} from "react-icons-kit/md";
import { styled } from "@mui/material/styles"; // Correct import

import { fetchData, ceopostData } from "../../../Api";
import { URL as url } from "../../../assets/mocData/config";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";

function DashBoardHeader({  wallet }) {
  console.log(wallet);
  const [modalOpen, setModalOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const lastMessageRef = useRef(null);
  const { userToken, userRole, profileData } = useGlobalContext();
  const [walletData, setWallet] = useState({});
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  let [currentMonthnow, setMonth] = useState(currentMonth);
  function sumTransactionAmounts(transactions) {
    const sum = { collection: 0, revenue: 0, additionalrevenue: 0 };

    // Iterate through each transaction in the array
    transactions.forEach((transaction) => {
      sum.collection += transaction.collection || 0;
      sum.revenue += transaction.revenue || 0;
      sum.additionalrevenue += transaction.additionalrevenue || 0;
    });

    return sum;
  }
  const calculateTotalAmount = (payments) => {
    // Use reduce to sum up the amount in each payment object
    return payments.reduce((total, payment) => {
      return total + payment.revenue;
    }, 0); // Initial value of total is 0
  };
  const calculateTotalRevenue = (payments) => {
    // Use reduce to sum up the amount in each payment object
    return payments.reduce((total, payment) => {
      return total + payment.amount;
    }, 0); // Initial value of total is 0
  };
  const filterSalaryDetails = (user, currentMonthnow, selectedYear) => {
    console.log("user",user)
     let revenue = calculateTotalAmount(user.transactions);
     console.log(revenue)
    let amountrevenue = calculateTotalRevenue(user.transactionsRecieved);
    console.log(amountrevenue)
    return {
      amountrevenue: amountrevenue,
      totalTopay: revenue,
    };
  };
  const fetchDatas = async () => {
    const headers = {
      userRole: userRole,
      userToken: userToken,
    };

    const response = await fetchData(`${url}/userMessages/view`, headers);
    const filteredData = response.filter((item) =>
      item.shows.includes(userRole)
    );
    setMessages(filteredData);
  };

  const handleRead = async () => {
    const id = profileData.profileData._id;
    const data = { viewed: id, shows: userRole };

    try {
      await ceopostData(`${url}/userMessages/read/`, data, {
        userRole,
        userToken,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDatas();
    if (userRole == "vip" && wallet.accountDetail) {
      let data = filterSalaryDetails(
        wallet.accountDetail[0],
       
      );
      setWallet(data);
    }
      if (userRole == "vipFranchise" && wallet.accountDetail) {
        let data = filterSalaryDetails(wallet.accountDetail[0]);
        setWallet(data);
      }
  }, []);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, modalOpen]);

  return (
    <div id="dashboard-inner" className="scrol px-3">
      <div id="title" className="d-flex">
        <div className="d-flex align-items-center">
          <h2>DASHBOARD</h2>
        </div>
        {userRole == "franchise" && (
                <div
                  className="message-icon"
                  style={{ color: "#bf2f82c4", marginRight: "10px" }}
                >
                  <Icon size={36} icon={ic_message} onClick={handleOpenModal} />
                </div>
              )}
              {userRole == "branchstaff" && (
                <div
                  className="message-icon"
                  style={{ color: "#bf2f82c4", marginRight: "10px" }}
                >
                  <Icon size={36} icon={ic_message} onClick={handleOpenModal} />
                </div>
              )}
        {wallet ? (
          <>
            <WalletContainer className="d-flex align-items-center" id="in-ctn">
              <div
                className="message-icon"
                style={{ color: "#bf2f82c4", marginRight: "10px" }}
              ></div>
              {userRole !== "admin" &&(
                <div
                  className="message-icon"
                  style={{ color: "#bf2f82c4", marginRight: "10px" }}
                >
                  <Icon size={36} icon={ic_message} onClick={handleOpenModal} />
                </div>
              )}   
              <div className="ctn-inner d-flex justify-content-around align-items-center">
                <div style={{ color: "#bf2f82c4" }}>
                  <Icon size={36} icon={ic_account_balance_wallet_outline} />
                </div>
                <h5>Wallet</h5>
                {wallet.accountDetail ? (
                  <span
                    id="tg-11"
                    className="d-flex justify-content-center align-items-center"
                  >
                    {userRole == "vipFranchise" ? (
                      <>
                        {parseInt(walletData.totalTopay) -
                          parseInt(walletData.amountrevenue)}
                      </>
                    ) : (
                      <>
                        {parseInt(
                          wallet.accountDetail[0].transactionDetail.revenue
                        ) - parseInt(walletData.amountrevenue)}
                      </>
                    )}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="ctn-secondary" style={{ color: "#bf2f82c4" }}>
                <Icon size={36} icon={ic_account_balance_wallet_outline} />
              </div>
            </WalletContainer>
          </>
        ) : (
          <></>
        )}
        {/* Conditionally hide the WalletContainer for "admin" role */}
      </div>

      <StyledModal open={modalOpen} onClose={handleCloseModal}>
        <ModalContent>
          <Header>
            <Avatar src={ceoimage} />
            <Typography variant="h6" sx={{ marginLeft: 2, color: "white" }}>
              CEO
            </Typography>
          </Header>
          <MessagesContainer>
            {messages.map((msg, index) => (
              <Box
                key={index}
                ref={index === messages.length - 1 ? lastMessageRef : null}
              >
                <Typography
                  variant="caption"
                  sx={{ color: "gray", textAlign: "center", display: "block" }}
                >
                  {formatDateTime(msg.createdAt)}
                </Typography>
                <Message>
                  <Typography>{msg.message}</Typography>
                </Message>
              </Box>
            ))}
          </MessagesContainer>
          <Footer>
            <div onClick={handleRead}>
              <Button
                onClick={handleCloseModal}
                variant="contained"
                sx={{ backgroundColor: "#a64d79", color: "white" }}
              >
                Close
              </Button>
            </div>
          </Footer>
        </ModalContent>
      </StyledModal>
    </div>
  );
}

const WalletContainer = styled("div")`
  display: flex;
  align-items: center;

  .message-icon {
    margin-right: 10px;
    cursor: pointer; // Add cursor pointer for better UX
  }

  .ctn-secondary {
    display: none !important;
  }
`;

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: "#1c1c1c",
  width: "90%",
  maxWidth: "500px",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: theme.shadows[5] || "0px 4px 20px rgba(0, 0, 0, 0.1)",
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  backgroundColor: "#1c1c1c",
  borderBottom: `1px solid ${theme.palette.divider || "gray"}`,
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "#1c1c1c",
  maxHeight: "60vh",
  overflowY: "auto",
}));

const Message = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: "#a64d79",
  color: "white",
  borderRadius: "10px",
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "#1c1c1c",
  borderTop: `1px solid ${theme.palette.divider || "gray"}`,
  textAlign: "center",
}));

const formatDateTime = (dateString) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  return new Date(dateString).toLocaleString("en-US", options);
};

export default DashBoardHeader;
