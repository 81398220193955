import React, { useState ,useEffect} from 'react'
import { useGlobalContext } from '../../../assets/contex/GlobalContext';
import { fetchData,putData } from '../../../Api';
import { enqueueSnackbar } from 'notistack';
import {URL} from '../../../assets/mocData/config';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "styled-components";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputBase,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,

} from "@mui/material";

function BranchEditForm({
  setEditContainer,
  editData,
  setEditData,
  branchData,
  setData,
}) {
  let { userToken, userRole } = useGlobalContext();
  const [data, setEdit] = useState({
    ...editData,
  });
  const FormData = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "concept") {
      let totalConcept = [];

      if (data.concept.includes(value)) {
        let d = data.concept?.map((ele) => {
          if (ele !== value) {
            return ele;
          }
        });
        totalConcept = d.filter((item) => item !== undefined);
        setEdit({
          ...data,
          concept: totalConcept,
        });
      } else {
        totalConcept.push(...data.concept);
        totalConcept.push(value);

        setEdit({
          ...data,
          concept: totalConcept,
        });
      }
    } else {
      setEdit({
        ...data,
        [e.target.name]: value,
      });
    }
  };
  const [concept, setConcept] = useState([]);
  useEffect(() => {
    (async () => {
      let header = {
        userRole: userRole,
        userToken: userToken,
      };
      try {
        let response = await fetchData(`${URL}/${userRole}/concept`, header);
        setConcept(response);
      } catch (error) {
        setConcept([]);
      }
    })();
  }, []);
  const [selectedValue, setSelectedValue] = useState(data.status);

 const handleChange = (event) => {
   setSelectedValue(event.target.value);
 };
 const controlProps = (item) => ({
   checked: selectedValue === item,
   onChange: handleChange,
   value: item,
   name: "status",
   inputProps: { "aria-label": item },
 });
  const handleSubmit = async (e) => {
    e.preventDefault();
    let header = {
      userRole: userRole,
      userToken: userToken,
    };
    if(validateCourseForm(branchData))
    {
      try {
        let response = await putData(
          `${URL}/admin/branch/${data._id}`,
          data,
          header
        );
        console.log(response);
        enqueueSnackbar("Send Successfully", {
          autoHideDuration: 1000,
          variant: "success",
        });
  
        const indexToUpdate = branchData.findIndex((obj) => obj._id === data._id);
  
        if (indexToUpdate !== -1) {
          branchData[indexToUpdate] = data;
        }
        setData(branchData);
  
        setEditContainer(false);
      } catch (error) {
        // Handle E11000 error for duplicate keys
        if (error.response && error.response.data.error) {
          const errorMessage = error.response.data.error;
    
          if (errorMessage.includes("E11000")) {
            if (errorMessage.includes("branchCode")) {
              enqueueSnackbar("Branch Code already exists.", { variant: "warning" });
            } else if (errorMessage.includes("branchName")) {
              enqueueSnackbar("Branch name already exists.", { variant: "warning" });
            }
            else if (errorMessage.includes("branchOwnerEmail")) {
              enqueueSnackbar("Email already exists.", { variant: "warning" });
            } else {
              enqueueSnackbar("Duplicate data entry error.", { variant: "warning" });
            }
          } else {
            enqueueSnackbar(`${errorMessage}`, { variant: "warning" });
          }
        } else {
          enqueueSnackbar("Something went wrong. Please try again later.", {
            variant: "error",
          });
        }
    }
      // If validation passes, submit the form
      // Call API endpoint or dispatch action to submit data
      console.log("Form submitted:");
    }
    
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#a70e6e", // Pink color
      },
      text: {
        primary: "#ffffff", // White color for input text
      },
    },
    components: {
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: "black", // Dark background color for the input
            color: "#ffffff", // White color for input text
            borderRadius: "4px", // Optional: if you want rounded corners
            "&:hover": {
              backgroundColor: "black", // Slightly lighter dark color on hover
            },
            "&.Mui-focused": {
              backgroundColor: "black", // Ensure the background stays dark when focused
            },
          },
          input: {
            color: "#ffffff", // White color for input text
          },
          underline: {
            "&:before": {
              borderBottom: `1px solid #ffffff`, // Initial border color
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: `1px solid #a70e6e`, // Border color on hover
            },
            "&:after": {
              borderBottom: `2px solid #a70e6e`, // Border color when focused
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "gray", // White color for label
            "&.Mui-focused": {
              color: "#a70e6e", // Pink color for label when focused
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "#25272d", // Dark background color for dropdown options
            color: "#ffffff", // White text color for dropdown options
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "#ffffff", // White color for the dropdown icon
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "#a70e6e", // Pink color on hover
            },
          },
        },
      },
    },
  });
  const [selectedConcepts, setSelectedConcepts] = useState([]);
  const handleCheckboxChange = (event) => {
    const concept = event.target.value;
    if (event.target.checked) {
      setSelectedConcepts([...selectedConcepts, concept]); // Add to selected concepts
    } else {
      setSelectedConcepts(
        selectedConcepts.filter((c) => c !== concept) // Remove from selected concepts
      );
    }
  };

  
  const validateCourseForm = (BranchData) => {
    const {
      area,
      district,
      location,
      addressName,
      pincode,
      branchCode,
      branchName,
      concept,
      status,
      branchOwnerEmail
    } = BranchData;
    if (!data.branchCode) {
      enqueueSnackbar("Branch Code is required", { variant: "warning", autoHideDuration: 2000 });
      return;
    }
    if (!data.branchName) {
      enqueueSnackbar("Branch Name is required", { variant: "warning", autoHideDuration: 2000 });
      return;
    }
    if (!data.concept || data.concept.length === 0) {
      enqueueSnackbar("At least one concept must be selected", { variant: "warning", autoHideDuration: 2000 });
      return;
    }
    if (!data.addressName) {
      enqueueSnackbar("Address is required", { variant: "warning", autoHideDuration: 2000 });
      return;
    }
    if (!data.area) {
      enqueueSnackbar("Area is required", { variant: "warning", autoHideDuration: 2000 });
      return;
    }
    if (!data.district) {
      enqueueSnackbar("District is required", { variant: "warning", autoHideDuration: 2000 });
      return;
    }
    if (!data.pincode || !/^\d{6}$/.test(data.pincode)) {
      enqueueSnackbar("Pincode must be a 6-digit number", { variant: "warning", autoHideDuration: 2000 });
      return;
    }
    if (!data.location) {
      enqueueSnackbar("Location link is required", { variant: "warning", autoHideDuration: 2000 });
      return;
    }
    
    // If all validations pass
    return true;
  };
  return (
    <BranchEditContainer>
     <ThemeProvider theme={theme}>
      <div id="add-student-container">
        {/* <h2 style={{textAlign: "left"}}>Add Course</h2> */}
        <form onChange={FormData}>
          <div class="container">
            <div class="row">
              <div class="col col-12">
                <div id="form-container" class="m-1">
                  <form
                    class="scroll"
                    style={{
                      height: "75vh",
                      overflowY: "scroll",
                      paddingTop: "1rem",
                    }}
                    action=""
                  >
                    <div class="container">
                      <div class="row">
                        <hr />
                        <span class="col-3">Branch Code</span>
                        <div class="col-9 d-flex flex-column px-3">
                          <span
                            style={{ width: "100%" }}
                            class="d-flex justify-content-between"
                          >
                            <TextField
                             id="filled-basic"
                                variant="filled"
                             fullWidth
                             label="Branch Code"
                              name="branchCode"
                             value={data.branchCode}
                              placeholder="Enter Branch Code"
                             />
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="container">
                      <div class="row">
                        <span class="col-3">Branch Name</span>
                        <div class="col-9 d-flex flex-column px-3">
                          <span
                            style={{ width: "100%" }}
                            class="d-flex justify-content-between"
                          >
                           <TextField
   id="filled-basic"
   variant="filled"
    fullWidth
    label="Branch Name"
    name="branchName"
    value={data.branchName}
    placeholder="Enter Branch Name"
  />
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <br />
                   <div class="container">
  <div class="row">
    <span class="col-3 inputHeadings">Concepts</span>
    <div class="col-9 d-flex flex-column px-3">
      <span style={{ width: "100%" }} class="d-flex justify-content-between">
        <div className="checkbox-container">
          {concept?.map((c, i) => {
            return (
              <>
                <input
                  type="checkbox"
                  name="concept"
                  value={c.conceptName}
                  className="btn-check"
                  id={`checkbox${i}`}
                  onChange={handleCheckboxChange}
                  checked={data.concept.includes(c.conceptName)} // Check if the concept is pre-selected
                />
                <label
                  className="btn btn-outline-secondary pink-btn"
                  htmlFor={`checkbox${i}`}
                >
                  {c.conceptName.toUpperCase()}
                </label>
              </>
            );
          })}
        </div>
      </span>
    </div>
  </div>
</div>


                    <hr />
                    <div class="container">
                      <div class="row">
                        <span class="col-3">Address</span>
                        <div class="col-9 d-flex flex-column px-3">
                          <span
                            style={{ width: "100%" }}
                            class="d-flex justify-content-between"
                          >
                            <TextField
              variant="filled"
              id="filled-basic"
              fullWidth
              name="addressName"
               label="Address"
              value={data.addressName}
              placeholder="Enter Address"
              margin="normal"
            />
                          </span>
                          <span
                            style={{ width: "100%", marginTop: "1rem" }}
                            class="d-flex justify-content-between"
                          >
                            <TextField
              variant="filled"
              id="filled-basic"
              fullWidth
              name="area"
              label="Area"
              value={data.area}
              placeholder="Enter Area"
              margin="normal"
            />
                          </span>
                          <span
                            style={{ width: "100%", marginTop: "1rem" }}
                            class="d-flex justify-content-between"
                          >
                             <TextField
              variant="filled"
              id="filled-basic"
              fullWidth
              name="district"
              label="District"
              value={data.district}
             
              placeholder="Enter District"
              margin="normal"
            />
                          </span>
                          <span
                            style={{ width: "100%", marginTop: "1rem" }}
                            class="d-flex justify-content-between"
                          >
                             <TextField
              variant="filled"
              id="filled-basic"
              fullWidth
              name="pincode"
               label="Pincode"
              value={data.pincode}
            
              placeholder="Enter Pincode"
              type="number"
              margin="normal"
            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="container">
                      <div class="row">
                        <span class="col-3">Location</span>
                        <div class="col-9 d-flex flex-column px-3">
                          <span
                            style={{ width: "100%" }}
                            class="d-flex justify-content-between"
                          >
                            <TextField
              variant="filled"
              id="filled-basic"
              fullWidth
              name="location"
               label="Location"
              value={data.location}
              placeholder="Enter Location Link"
              margin="normal"
            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr />
                    {/* <!-- Status Section --> */}
                    <div class="container status-section">
                        <div class="row align-items-center">
                          <span class="col-3 inputHeadings">Status</span>
                          <div class="col-9 d-flex flex-column px-3">
                            <FormControl>
                              <FormLabel id="demo-controlled-radio-buttons-group">
                                Status
                              </FormLabel>
                              <RadioGroup
                                className="d-sm-flex flex-sm-column flex-md-row"
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={selectedValue}
                              >
                                <FormControlLabel
                                  name="status"
                                  value="active"
                                  control={
                                    <Radio
                                      name="status"
                                      {...controlProps("active")}
                                      color="success"
                                    />
                                  }
                                  label="Active"
                                />
                                <FormControlLabel
                                  name="status"
                                  value="inactive"
                                  control={
                                    <Radio
                                      name="status"
                                      {...controlProps("inactive")}
                                    />
                                  }
                                  label="In Active"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
<hr/>
                    <div class="container mt-3">
                        <div class="row justify-content-center">
                          <div class="col-12 d-flex justify-content-center">
                            <div class="d-flex flex-column flex-sm-row align-items-center">
                          <button
                            onClick={(e) => handleSubmit(e)}
                             class="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-course-btn"
                          >
                            Edit Branch
                          </button>
                          <button
                            type="button"
                            onClick={() =>{
                              setEditContainer(false)
                            }}
                              class="btn btn-outline-danger btn-sm cancel-btn"
                          >
                            Cancel
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      </ThemeProvider>
    </BranchEditContainer>
  );
}
const BranchEditContainer = styled.div`
  input::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  #add-student-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: gray !important;
  }

  h2,
  p,
  span,
  h6 {
    color: white;
  }
  .add-course-btn:hover {
    background-color: green;
  }
  .cancel-btn:hover {
    background-color: red;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }

  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }

  h5 {
    text-align: center;
    color: white;
  }

  input {
    color: white;
  }

  /* Status Section Styles */
  .status-section {
    margin-top: 1rem;
  }

  .status-label {
    color: white;
  }

  .status-options {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .active-dot {
    background-color: green;
  }

  .inactive-dot {
    background-color: red;
  }

  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #add-student-container {
      padding: 1rem;
    }

    #profile-container {
      margin-top: 1rem;
    }
  }

  /* Style for active radio button */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }
  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }
  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }
  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  
  #check-box {
    position: absolute;
  }

  #check-box .checkbox-bx {
    position: absolute;
    right: 5px;
    top: 2px;
  }
  #check-box .checkbox-bx label {
    position: relative;
    height: 24px;
    width: 24px;
  }
  #check-box .checkbox-bx input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }

  #check-box .checkbox-bx label span {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
    cursor: pointer;
    background-color: #25272d;
  }

  #check-box .checkbox-bx label span::after {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 8px;
    width: 10px;
    height: 20px;
    border: solid #00ff0a;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: width 0.5s, height 0.5sec, opacity 0.5sec;
  }

  #check-box .checkbox-bx input:checked ~ span::after {
    width: 10px;
    height: 20px;
    opacity: 1;
  }

  .pink-btn {
    border-color: rgb(201, 66, 149); /* Change the outline color to pink */
    color: rgb(201, 66, 149); /* Change text color to ensure visibility */
  }

  .checkbox-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #1d1e22;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to individual boxes within containers */
  }

  .checkbox-container {
    display: flex; /* Display checkboxes horizontally */
    justify-content: flex-start; /* Align checkboxes to the start */
    flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
    margin-bottom: 20px; /* Add margin at the bottom */
  }

  .checkbox-container label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px; /* Add margin at the bottom of each checkbox */
  }

  .checkbox-container label:last-child {
    margin-right: 0; /* Remove margin for the last checkbox */
  }

  .checkbox-container label input {
    display: none;
  }
    .add-course-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */ 
  }

 .add-course-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */ 
  }

  @media (max-width: 575px) {
  .add-course-btn,
  .cancel-btn {
     align-items: center;
      width: 100%; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
  }
}
`;
export default BranchEditForm