import React from 'react'
import MiniNavBar from './miniComponents/subcomponents/MiniNavBar';
import NavBar from './miniComponents/subcomponents/NavBar';
import { styled } from 'styled-components';
import StudentDetailContainer from './secondaryContainersForPages/StudentDetailContainer';

function StudentsDetailsPage() {
  return (
    <StudentContainer>
      <MiniNavBar selected={"studentsdetails"} />
      <NavBar selected={"studentsdetails"} />
      <StudentDetailContainer />
    </StudentContainer>
  );
}
const StudentContainer = styled.div`
  display: flex;
  background-color: var(--background);
`;
export default StudentsDetailsPage