import React from 'react';
import { styled } from 'styled-components';
import NavBar from '../pages/miniComponents/subcomponents/NavBar';
import MiniNavBar from './miniComponents/subcomponents/MiniNavBar';
import CrmTableContainer from './secondaryContainersForPages/CrmContainer';
import CrmUserView from './miniComponents/Crm/CrmUserVIew';
import { useGlobalContext } from '../assets/contex/GlobalContext';
import CrmUserVipView from './miniComponents/Crm/CrmUserVIew';
import CrmLeadProfile from './miniComponents/Crm/Crmleadprofile';

function CrmLead() {
  const { userRole } = useGlobalContext(); // Extract userRole from global context

  console.log("UserRole:", userRole); // Debugging statement

  
    return (
      <Crmcontainer>
        <NavBar selected={"crm"} />
        <MiniNavBar selected={"crm"} />
        <CrmLeadProfile />
      </Crmcontainer>
    );
  
}
const Crmcontainer = styled.div`
  display: flex;
  background-color: var(--background);

  #cont {
    height: 100vh;
    width: auto;
  }
  @media only screen and (max-width: 800px) {
    #miniNav {
      display: block !important;
    }
    h2 {
      margin-left: 3rem;
    }
  }
  #miniNav {
    display: none;
  }
`;

export default CrmLead;