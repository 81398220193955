import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { getDaysInMonth } from "date-fns";
function HoStaffAttedance() {
  const [isEditing, setIsEditing] = useState(false);
  const currentDate = new Date();
  const [currentMonth,setCurrentMonth]=useState(currentDate.getMonth());
  const currentYear = currentDate.getFullYear();
  const [numberOfDays,setNumberOfDays] = useState(
    getDaysInMonth(new Date(currentYear, currentMonth))
  );
  useEffect(() => {
    setNumberOfDays(getDaysInMonth(new Date(currentYear, currentMonth)));
  }, [currentMonth]);

  console.log(numberOfDays);
  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };
  const handleMonthChange = (event) => {
    setCurrentMonth(parseInt(event.target.value));
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const attendanceInput=(value)=>{
    const data=[]
    for(let i=1;i<=value;i++){
      data.push(
        <th className="t-head" scope="col">
          {`${months[currentMonth]}-${i}`}
        </th>
      );
    }
    return data
  }
  
  return (
    <HoStaffAttedanceContainer>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <select
          value={currentMonth}
          onChange={handleMonthChange}
          name=""
          id="monthFilter"
        >
          {months.map((month, index) => (
            <option key={index} value={index}>
              {month}
            </option>
          ))}
        </select>
      </div>
      <div id="table-container1">
        <div className="scroll">
          <table
            className="table table-hover"
            style={{ whiteSpace: "nowrap", position: "sticky" }}
          >
            <thead>
              <tr style={{ overflowX: "auto" }}>
                {}
                <th className="t-head rounded-start-3 sticky-row" scope="col">
                  {" "}
                </th>
                {attendanceInput(numberOfDays)}

                <th className="t-head rounded-end-3" scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="t-head rounded-start-3 sticky-row" scope="row">
                  Bubalan{" "}
                </th>
                <td>
                  <div id="check-box">
                    <div
                      class="checkbox-bx"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <label>
                        <div>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            disabled={!isEditing}
                          />
                        </div>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div
                      class="checkbox-bx"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div id="check-box">
                    <div class="checkbox-bx">
                      <label>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          disabled={!isEditing}
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm me-2"
                    onClick={handleEditClick}
                  >
                    {isEditing ? "Save" : "Edit"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </HoStaffAttedanceContainer>
  );
}
const HoStaffAttedanceContainer = styled.div`
  width: 100%;
  #monthFilter {
    background-color: var(--navbar-dark-primary);
    color: white;
    width: 10rem;
    height: 3rem;
    border-radius: 10px;
    border-color: 5px solid black;
  }
  #HoStaffAttedance-container {
    border-radius: 10px;
    background-color: #25272d;
    width: 100%;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 7.4rem;
    padding: 2rem;
    border-radius: 10px;
    height: 83vh;
    width: 100%;
  }
  #table-container1 {
    background-color: #25272d;
    margin-top: 1.4rem;
    padding: 2rem;
    border-radius: 10px;
    height: 83vh;
    width: 130vh;
  }

  .t-head {
    color: white !important;
  }
  .t-head {
    background-color: #18171b !important;
    color: white !important;
    padding: 1rem !important;
    text-align: center;
  }
  #table-container thead th {
    position: sticky;
    top: 0;
  }

  table.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  table.rounded-corners th,
  table.rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .scroll {
    overflow-x: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: var(--navbar-dark-primary);
  }
  .scroll::-webkit-scrollbar-button {
    height: 1px;
  }

  .sticky-row {
    position: sticky;
    left: 0;
    background-color: #fff; /* Match the background color of the table */
    z-index: 1; /* Ensure it's above other elements */
  }

  #check-box .checkbox-bx label {
    position: relative;
    height: 24px;
    width: 24px;
  }
  #check-box .checkbox-bx input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }

  #check-box .checkbox-bx label span {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
    cursor: pointer;
    background-color: black;
  }

  #check-box .checkbox-bx label span::after {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 8px;
    width: 10px;
    height: 20px;
    border: solid #00ff0a;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: width 0.5s, height 0.5sec, opacity 0.5sec;
  }

  #check-box .checkbox-bx input:checked ~ span::after {
    width: 10px;
    height: 20px;
    opacity: 1;
  }
  .edit-btn {
    margin-right: 0.7rem; /* Increase the space between buttons */
    font-size: 0.6rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
  }
`;
export default HoStaffAttedance;
