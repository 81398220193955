import React from 'react'
import NavBar from "./miniComponents/subcomponents/NavBar";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import { styled } from "styled-components";
import { enqueueSnackbar } from "notistack";
import MediaUpdatesSection from './MediaUpdateSection';
export default function Media() {
  return (
    <MediaContainer>
      <MiniNavBar selected={"mediaupdates"} />
      <NavBar selected={"mediaupdates"} />
      <MediaUpdatesSection />
    </MediaContainer>
  );
}
const MediaContainer = styled.div`
  display: flex;
  background-color: var(--background);
`;
