import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL as url } from "../../../assets/mocData/config";
import { fetchData } from "../../../Api";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import * as XLSX from 'xlsx';
import { Button } from "@mui/material";

function ViewVipTable({ filterData, setFilterData, vipData, setVipData }) {
  const [referedUserData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(50);
  const { userToken, userRole, profileData, setProfileViewData } =
    useGlobalContext();
  const navigate = useNavigate();
  const fetchVIP = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response = await fetchData(`${url}/vip/`, header);
      setUserData(response.data); // Setting fetched data
      setVipData(response.data); // Also updating parent state
      console.log(response.data);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`${error.response.data.message}`, {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
  };

  useEffect(() => {
    fetchVIP();
  }, []);

  // Pagination logic
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = vipData.slice(indexOfFirstRow, indexOfLastRow); // Use vipData for rows

  const getTotalPages = () => {
    return Math.ceil(vipData.length / rowsPerPage);
  };
  const handleDownloadExcel = () => {
    const formattedData = vipData.map((data, index) => ({
      "S.no": index + 1,
      "Vip ID": data.user_id,
      "Vip Name": data.userName,
      "Vip Collection": 30000, // Replace with actual collection value if available
      "Email": data.email,
      "Phone Number": data.phoneNumber,
      "Vip Status": "DLI /SLI /NLI", // Replace with actual status if available
      "User Status": data.status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "VIP Data");
    
    // Generate buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    
    // Create a link to download the file
    const url = window.URL.createObjectURL(dataBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'vip_data.xlsx'); // Specify file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <ViewvipContainer>
      <div id="viewvip-table-container">
        <Button
          variant="outlined"
          className="download-btn"
          onClick={handleDownloadExcel} // Call your download function here
          sx={{
            borderRadius: "10px",
            marginLeft: "1rem",
            // Customize color as needed
            color: "#f00d88",
            borderColor: "#f00d88",
          }}
          disabled={currentRows.length === 0}
        >
          Download Excel
        </Button>
        <div id="table-container">
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                    S.no
                  </th>
                  <th className="t-head" scope="col">
                    Vip ID
                  </th>
                  <th className="t-head " scope="col">
                    Vip Name
                  </th>
                  <th className="t-head " scope="col">
                    vip Collection
                  </th>
                  <th className="t-head " scope="col">
                    Email
                  </th>
                  <th className="t-head" scope="col">
                    Phone Number
                  </th>
                  <th className="t-head" scope="col">
                    First Business Date
                  </th>
                  <th className="t-head" scope="col">
                    Vip Status
                  </th>
                  <th className="t-head" scope="col">
                    User Status
                  </th>
                  <th className="t-head rounded-end-3" scope="col">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="table-dark">
                {currentRows.length === 0 ? (
                  <tr>
                    <td
                      colSpan="9"
                      style={{ textAlign: "center", padding: "2rem" }}
                    >
                      <SentimentVeryDissatisfiedIcon fontSize="large" />
                      <p>No Records found</p>
                    </td>
                  </tr>
                ) : (
                  currentRows.map((data, i) => (
                    <tr key={data.user_id}>
                      <td>{indexOfFirstRow + i + 1}</td>
                      <td>{data.user_id}</td>
                      <td>{data.userName}</td>
                      <td>{data.totalCollection}</td>
                      <td>{data.email}</td>
                      <td>{data.phoneNumber}</td>
                      <td> {new Date(data.otherDetails.firstBusinessDate).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short', // You can use 'long' for the full month name or 'numeric' for numbers
    year: 'numeric'
  })}</td>
                      <td>
                        <span
    style={{
      display: "inline-block", // To ensure the span behaves like a block-level element
      padding: "5px 10px", // Padding for the box
      borderRadius: "5px", // Rounded corners for the box
      color: "white", // Text color matches the border color
      border: `1px solid white`, // Conditional border color
      fontSize: "14px", // Responsive font size
      textAlign: "center", // Center the text inside the box
      width: "auto", // Adjust width based on content
      minWidth: "80px", // Minimum width for small screens
      maxWidth: "100%", // Full width on smaller screens
    }}
  >{data.otherDetails.vip_status}</span></td>
                      <td>
                      <span
    style={{
      display: "inline-block", // To ensure the span behaves like a block-level element
      padding: "5px 10px", // Padding for the box
      borderRadius: "5px", // Rounded corners for the box
      color: data.status === "active" ? "green" : "red", // Text color matches the border color
      border: `2px solid ${data.status === "active" ? "green" : "red"}`, // Conditional border color
      fontSize: "14px", // Responsive font size
      textAlign: "center", // Center the text inside the box
      width: "auto", // Adjust width based on content
      minWidth: "80px", // Minimum width for small screens
      maxWidth: "100%", // Full width on smaller screens
    }}
  >{data.status}</span></td>
                      <td>
                        <button
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "white",
                            border: "1px solid white",
                            padding: "0.6rem",
                            textAlign: "center",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setProfileViewData(data);
                            navigate("/profile");
                          }}
                        >
                          View Profile
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={getTotalPages()}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ViewvipContainer>
  );
}


const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`;
const ViewvipContainer = styled.div`
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }

  td {
    text-align: center;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 2rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;

    overflow-y: scroll;
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }

  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
`;

export default ViewVipTable;
