import React from "react";
import { styled } from "styled-components";
import { Icon } from "react-icons-kit";
import { ic_search } from "react-icons-kit/md/ic_search";
import { ic_filter_alt } from "react-icons-kit/md/ic_filter_alt";
import { ic_close } from "react-icons-kit/md/ic_close";

function TopContainer({ heading, options, filter, FilterOpen, setFilterData, showFields = { search: false, filter: false } }) {
  return (
    <TopCont id="top-cont" className="d-flex justify-content-between">
      <h2>{heading}</h2>
      <div className="d-flex">
        {showFields.search && (
          <div id="searchfield">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                console.log(e.target.value);
                setFilterData(e.target.value);
              }}
            />
            <span style={{ color: "var(--icon-color)" }}>
              <Icon size={20} icon={ic_search} />
            </span>
          </div>
        )}
       
      </div>
    </TopCont>
  );
}


const TopCont = styled.div`
  display: flex;
  justify-content: space-between;

  h2 {
   color:#fff;
  }

  #searchfield {
    background-color: #36283a;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    @media (max-width: 800px) {
        height: 2.5rem !important;

}
   

    
    input {
      background-color: #36283a;
      border: none;
      color: white;
      padding: 0.5rem;
      margin-right: 0.5rem;
      width: 12rem;
      
    }

    input:focus {
      outline: none;
    }

    ::placeholder {
      color: #bf2f82c4;
    }
  }

  .icon-button {
    height: 3.5rem;
    width: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }

  #opacity {}

  .fa-x {
    color: var(--icon-color);
    font-size: large;
    cursor: pointer;
  }

  .filter-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px auto;
    max-width: 18rem;
    position: absolute;
    z-index: 1;
    right: 5%;
  }

  .filter-box {
    background: var(--navbar-dark-primary);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-bottom: 15px;
  }

  .filter-box h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #fff;
  }

  .filter-box p {
    font-size: 16px;
    margin-bottom: 5px;
    color: #ddd;
  }

  .inner-box {
    background-color: var(--background);
    padding: 20px;
    border-radius: 10px;
    color: #fff;
  }

  .nested-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .filter-item {
    margin-bottom: 10px;
  }

  .filter-item button {
    display: block;
    width: 100%;
    padding: 8px;
    border: 1px solid #2e2e2e;
    background-color: var(--navbar-dark-primary);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
  }

  .filter-item button:hover {
    background-color: #d70491;
  }

 /* Responsive styles for screen sizes below 800px */
  @media (max-width:999px) {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}
    h2 {
      white-space: nowrap !important;
      margin-bottom: 1rem !important;
    }
}
@media (max-width:800px) {
    #searchfield {
      padding: 0.5rem !important;
      margin-bottom: 1rem !important;
      
      input {
        padding: 0.3rem !important;
        width: 8rem !important;
       
      }
    }

    .icon-button {
      height: 2.5rem !important;
      width: 2.5rem !important;
    }

    
  }
`;

export default TopContainer;