import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL as url } from "../../../assets/mocData/config";
import { fetchData } from "../../../Api";
import { Pagination, Stack,Button } from "@mui/material";
import * as XLSX from "xlsx"; // Import xlsx

export default function FranchiseReferralTable({ setSelectedView, setFranchise , filterData }) {
  let [data, setUserData] = useState([]);
  
  const navigate = useNavigate();
  const { userToken, userRole, profileData, setTransferData } =
    useGlobalContext();
  const fetchFranchise = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    userRole == "hos"
      ? (header.userRole = "admin")
      : (header.userRole = userRole);
    if (userRole == "admin" || userRole == "hos") {
      try {
        let response = await fetchData(
          `${url}/admin/franchise/referals`,
          header
        );
        setUserData(response);
      } catch (error) {
        enqueueSnackbar(`${error}`, { variant: "error" });
      }
    } else {
      try {
        let response = await fetchData(
          `${url}/vip/refer/franchise/${profileData.profileData.user_id}`,
          header
        );
        setUserData(response.data);
        
      } catch (error) {
        console.log(error);
        enqueueSnackbar(`${error.response.data.message}`, {
          variant: "error",
          autoHideDuration: 1000,
        });
      }
    }
  };
 
  useEffect(() => {
    fetchFranchise();
  }, []);

  const filteredData = data.filter((user) => {
    return (
      user.userName.toLowerCase().includes(filterData.toLowerCase()) ||
      user.email.toLowerCase().includes(filterData.toLowerCase()) ||
      user.phoneNumber.includes(filterData)
    );
  });


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Franchise Data");
    XLSX.writeFile(workbook, "franchise_data.xlsx");
  };
  return (
    <FranchiseRefferalsContainer>
      <div id="FranchiseRefferals-table-container">
      <Button
            variant="outlined"
             className="download-btn"
            onClick={downloadExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88",
            }}
            disabled={currentData?.length===0}
          >
            Download Excel
          </Button>
        <div id="table-container" style={{ width: "100%" }}>
          <div className="scroll">
            <table className="table  table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                    S.no
                  </th>
                  <th className="t-head" scope="col">
                    Refferal Name
                  </th>
                  <th className="t-head " scope="col">
                    Reffered By
                  </th>
                  <th className="t-head " scope="col">
                    Email
                  </th>
                  <th className="t-head" scope="col">
                    Phone Number
                  </th>
                  <th className="t-head" scope="col">
                    WhatsApp Number
                  </th>
                  <th className="t-head" scope="col">
                    Status
                  </th>
                  <th className="t-head rounded-end-3" scope="col">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="table-dark">
                {currentData.map((user, i) => {
                  return (
                    <tr>
                      <td>{++i}</td>
                      <td>{user.userName}</td>
                      <td>{user.referredBy.name}</td>
                      <td>{`${user.email}`}</td>
                      <td>{`${user.phoneNumber}`}</td>
                      <td>{`${user.whatsappNumber}`}</td>
                      <td>
                      <span
    style={{
      display: "inline-block", // To ensure the span behaves like a block-level element
      padding: "5px 10px", // Padding for the box
      borderRadius: "5px", // Rounded corners for the box
      color: user.status === "active" ? "green" : "red", // Text color matches the border color
      border: `2px solid ${user.status === "active" ? "green" : "red"}`, // Conditional border color
      fontSize: "14px", // Responsive font size
      textAlign: "center", // Center the text inside the box
      width: "auto", // Adjust width based on content
      minWidth: "80px", // Minimum width for small screens
      maxWidth: "100%", // Full width on smaller screens
    }}
  >
    {`${user.status}`}
    </span></td>
                      <td>
                        <button
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "white",
                            border: "1px solid white",
                            padding: "0.6rem",
                            textAlign: "center",
                            backgroundColor: "black",
                          }}
                          onClick={() => {
                            setFranchise(user);
                            setSelectedView("AddFranchiseMember");
                          }}
                        >
                          Add as Franchise
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
        <Paginations
  count={Math.ceil(data.length / itemsPerPage)}
  page={currentPage}
  onChange={handlePageChange}
  variant="outlined"
  color="secondary"
/>
  </Stack>
</div>
    </FranchiseRefferalsContainer>
  );
}
const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`;
const FranchiseRefferalsContainer = styled.div`
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  } 

  td {
    text-align: center;
  }

 #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
    overflow-y: scroll;
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }

  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
`;