import React, { useEffect } from "react";
import styled from "styled-components";
import { Box, Typography, Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";



const LoginNotSuccessful = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/welcome");
    }, 5000); // Redirect after 5 seconds

    return () => clearTimeout(timer); // Cleanup timer
  }, [navigate]);

  return (
    <Wrapper>
      <Content>
        <IconWrapper>
          <ErrorOutlineIcon style={{ fontSize: 60 }} />
        </IconWrapper>
        <Typography variant="h4" component="h1">
          Login Not Successful
        </Typography>
        <Message variant="body1">
          We were unable to log you in. You will be redirected to the welcome
          page shortly.
        </Message>
        <StyledButton
          variant="contained"
          size="large"
          onClick={() => navigate("/welcome")}
        >
          Go to Welcome Page
        </StyledButton>
      </Content>
    </Wrapper>
  );
};
// Styled Components
const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background);
`;

const Content = styled(Box)`
  text-align: center;
  background: white;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const IconWrapper = styled(Box)`
  font-size: 4em;
  color: #f44336;
`;

const Message = styled(Typography)`
  margin-top: 1em;
  margin-bottom: 2em;
  color: #555;
`;

const StyledButton = styled(Button)`
  margin-top: 1em;
  color: white;
  background-color: #f44336;
  &:hover {
    background-color: #d32f2f;
  }
`;

export default LoginNotSuccessful;
