import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { postData,fetchData } from '../../../Api';
import { URL as url } from '../../../assets/mocData/config';
import { useGlobalContext } from '../../../assets/contex/GlobalContext';
import axios from 'axios';
import { Delete, Edit } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { Pagination, Stack } from "@mui/material";
import { useSnackbar } from 'notistack';


const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`

const CrmAdminFranchiseViewContainer = styled.div`
  
        input {
            color: black;
        }
        .btn-verify {
            background-color: green;
            color: white;
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
            cursor: pointer;
            margin-left: 0.5rem;
        }

        #pay-btn {
            width: 10rem;
            background-color: #25272d;
            color: greenyellow;
            border-radius: 10px;
            padding: 0.5rem;
            border: 1px solid greenyellow;
        }

        color: white;
        height: auto;
        width: 100%;
        padding: 0rem;

        .scroll {
            overflow-y: auto;
            max-height: 87vh; /* Adjust as needed */
        }
        .scroll::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }
        .scroll::-webkit-scrollbar-thumb {
            border-radius: 99px;
            background-color: transparent;
        }
        .scroll::-webkit-scrollbar-button {
            height: 16px;
        }

        .accordion-item {
            background-color: var(--navbar-dark-primary);
        }
        .accordion-button {
            color: var(--icon-color) !important;
        }
        h6, h4, h5 {
            color: white;
            padding: 1rem;
            font-weight: 900;
        }
        p {
            color: white;
        }
        .fa-solid {
            font-size: 1rem;
            color: var(--icon-color)
        }
        .text {
            margin-left: 0.6rem;
            font-weight: 600;
            font-size: 0.9rem;
            color: white;
        }

        .mt-3 {
            margin-top: 1rem !important;
        }

        .t-head {
            text-align: center;
        }

        td {
            text-align: center;
        }

        #table-container {
            background-color: #25272d;
            margin-top: 1rem;
            padding: 1rem;
            border-radius: 10px;
            height: 70vh;
            overflow-y: scroll;
        }

        .t-head {
            color: white !important;
            background-color: #18171b !important;
            padding: 1rem !important;
            text-align: center;
        }

        .rounded-corners {
            border-spacing: 0;
            border-collapse: separate;
            border-radius: 10px;
        }

        .rounded-corners th, .rounded-corners td {
            border: 1px solid black;
        }

        thead tr {
            border-bottom: 1rem solid #25272d;
        }

        tr {
            border-color: #25272d;
        }

        .table > :not(caption) > * > * {
            background-color: #25272d;
        }

        td {
            padding: 2rem !important;
        }

        /* Add media queries for responsiveness */
        @media (max-width: 768px) {
            .scroll {
                max-height: 87vh;
            }
        }

        @media (max-width: 576px) {
            .scroll {
                max-height: 40vh;
            }
        }
            .button-33 {
  background-color: #f00d88; /* Background color based on #f00d88 */
  border-radius: 100px;
  box-shadow: rgba(240, 13, 136, 0.2) 0 -25px 18px -14px inset, rgba(240, 13, 136, 0.15) 0 1px 2px, rgba(240, 13, 136, 0.15) 0 2px 4px, rgba(240, 13, 136, 0.15) 0 4px 8px, rgba(240, 13, 136, 0.15) 0 8px 16px, rgba(240, 13, 136, 0.15) 0 16px 32px;
  color: #fff; /* Text color */
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-33:hover {
  box-shadow: rgba(240, 13, 136, 0.35) 0 -25px 18px -14px inset, rgba(240, 13, 136, 0.25) 0 1px 2px, rgba(240, 13, 136, 0.25) 0 2px 4px, rgba(240, 13, 136, 0.25) 0 4px 8px, rgba(240, 13, 136, 0.25) 0 8px 16px, rgba(240, 13, 136, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}
`;

function CrmAdminVipView({filterData}) {
    const [fname, setFname] = useState("");
    const [fId, setFId] = useState("");
    const [source, setSource] = useState("hos");
    const { enqueueSnackbar }=useSnackbar();
    const today = new Date().toISOString().split('T')[0];
    const [mapDatas,setMapDatas]=useState([])
    const { userToken, userRole } = useGlobalContext();

    const {newDate,setNewData}=useState([])
    let headers = {
        userRole: userRole,
        userToken: userToken,
      };


    const [formData, setFormData] = useState({
        personalInformation: {
            studentName: "",
            phoneNumber: "",
            age: "",
            bloodGroup: "",
            dataOfBirth: "",
            gender: "",
            fatherName: "",
            motherName: "",
            degree: "",
        },
        address: {
            streetNo: "",
            streetName: "",
            landmark: "",
            district: "",
            state: "",
            pincode: ""
        }
    });


    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleDeleteClick = (id) => {
        setSelectedId(id);
        setOpen(true); // Open the confirmation modal
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null);
    };

    const handleConfirmDelete = async () => {
        try {
            // Make the DELETE request to the backend API
            await axios.delete(`${url}/leads/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,  // Add Bearer token in the Authorization header
                    userRole: userRole
                }
            });
            
            // Remove the deleted item from the mapDatas state
            setMapDatas((prevData) => prevData.filter((item) => item._id !== selectedId));
            
            setOpen(false); // Close the modal
        } catch (error) {
            console.error("Failed to delete the lead:", error);
            alert("Failed to delete the lead. Please try again.");
        }
    };
    

  // Filter the table based on the filterData prop
  const filteredData = mapDatas.filter((mapData) => {
    const franchiseId = mapData?.generalInformation?.followersId?.toLowerCase?.() || '';
    const franchiseName = mapData?.generalInformation?.followersName?.toLowerCase?.() || '';
    const leadSource = mapData?.generalInformation?.source?.toLowerCase?.() || '';
    const studentName = mapData?.personalInformation?.studentName?.toLowerCase?.() || '';
    const phoneNumber = mapData?.personalInformation?.phoneNumber?.toLowerCase?.() || '';
  
    if (!filterData) {
      console.warn('filterData is undefined or invalid:', filterData);
      return true;  // Return all data if filterData is undefined
    }
  
    const normalizedFilter = filterData.toLowerCase();
  
    return (
      franchiseId.includes(normalizedFilter) ||
      franchiseName.includes(normalizedFilter) ||
      leadSource.includes(normalizedFilter) ||
      studentName.includes(normalizedFilter) ||
      phoneNumber.includes(normalizedFilter)
    );
  });

    const getDeatils = async () => {
        // Check if fId (Franchise ID) is empty
        if (!fId.trim()) {
            enqueueSnackbar("Please enter a valid Franchise ID", { variant: "warning" });
            return; // Prevent further execution
        }
    
        try {
            const newData = { userId: fId };
            const response = await axios.post(`${url}/details`, newData);
            const datasss = response.data;
    
            // Assuming datasss is an array and you're looking for userType in the first item
            if (datasss.length > 0 && datasss[0].userType === "vip") {
                setFname(datasss[0].userName);
                console.log(datasss[0].userType); // Log the userType for debugging
            } else {
                enqueueSnackbar("Enter valid VIP ID (only VIP IDs are allowed)", { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar("Failed to fetch details", { variant: "error" });
        }
    };
    


    const handleFile = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');
        
        setFormData((prevState) => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [field]: value
            }
        }));
       
       
    };


    
    const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = mapDatas.slice(indexOfFirstItem, indexOfLastItem);
  

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


async function getDataApi(){
    
    const response=await fetchData(`${url}/leads`,headers)
    setMapDatas(response)
    console.log(response)

}



useEffect(()=>{
    getDataApi()
   
},[fId])
const validateForm = (formData) => {
       
    
    // Validate Franchise ID
    if (!fId.trim()) {
        enqueueSnackbar("Franchise ID is required", { variant: 'warning' });
        return false;
    }

    // Validate Franchise Name
    if (!fname.trim()) {
        enqueueSnackbar("Franchise Name is required", { variant: 'warning' });
        return false;
    }

    // Validate Student Name
    if (!formData.personalInformation.studentName.trim()) {
        enqueueSnackbar("Student Name is required", { variant: 'warning' });
        return false;
    }

    // Validate Phone Number (assuming 10 digits for phone number)
    const phonePattern = /^\d{10}$/;
    if (!formData.personalInformation.phoneNumber.trim()) {
      enqueueSnackbar("Phone Number is required", { variant: 'warning' });
      return false;
    } else if (!phonePattern.test(formData.personalInformation.phoneNumber)) {
      enqueueSnackbar("Phone Number must be 10 digits", { variant: 'warning' });
      return false;
    }

    // Validate Age (should be a number and greater than 0)
    const age = parseInt(formData.personalInformation.age, 10);
    if (!formData.personalInformation.age.trim()) {
       enqueueSnackbar("Age is required", { variant: 'warning' });
       return false;
    } else if (isNaN(age) || age <= 0) {
        enqueueSnackbar("Age must be a valid number greater than 0", { variant: 'warning' });
        return false;
    }

    // Validate Gender
    if (!formData.personalInformation.gender.trim()) {
        enqueueSnackbar("Gender is required", { variant: 'warning' });
        return false;
    }

    // Validate Father's Name
    if (!formData.personalInformation.fatherName.trim()) {
        enqueueSnackbar("Father's Name is required", { variant: 'warning' });
        return false;
    }

    return true;
};
const handleSubmit =async(e) => {
        e.preventDefault();
        
        const isValid=validateForm(formData);
       if(!isValid){
        return;
       }

        const newFormData={generalInformation:{
            followersId:fId,
            followersName:fname,
            source:source},...formData}


        try{

            const data= await postData(`${url}/leads`,newFormData,headers)


            setFname("")
            setSource("")
            setFormData({
                personalInformation: {
                    studentName: "",
                    phoneNumber: "",
                    age: "",
                    bloodGroup: "",
                    dataOfBirth: "",
                    gender: "",
                    fatherName: "",
                    motherName: "",
                    degree: "",
                },
                address: {
                    streetNo: "",
                    streetName: "",
                    landmark: "",
                    district: "",
                    state: "",
                    pincode: ""
                }
            })
            setFId("")
        }
            catch(err){
                alert("not uploading")
            }
            /*
        await postData(`${url}`,formData,headers)*/
       
    };

    return (
        <CrmAdminFranchiseViewContainer>
            <div id="table-container"style={{height:"auto"}}>
                <h4>ADD LEAD</h4>
                <div className="d-flex justify-content-end mb-3">
                    <button
                        className="button-4 btn btn-secondary"
                        style={{
                            backgroundColor: '#f00d88',
                            borderColor: 'rgba(27, 31, 35, 0.15)',
                            borderRadius: '6px',
                            boxShadow: 'rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset',
                            boxSizing: 'border-box',
                            color: '#24292E',
                            cursor: 'pointer',
                            display: 'inline-block',
                            fontFamily: '-apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '20px',
                            listStyle: 'none',
                            padding: '6px 16px',
                            position: 'relative',
                            transition: 'background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1)',
                            userSelect: 'none',
                            WebkitUserSelect: 'none',
                            touchAction: 'manipulation',
                            verticalAlign: 'middle',
                            whiteSpace: 'nowrap',
                            wordWrap: 'break-word',
                            height: 'auto',
                            borderRadius: '0.25rem'
                          }}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#form-container"
                        aria-expanded="false"
                        aria-controls="form-container"
                    >
                        ADD
                    </button>
                </div>
                <form className="container" onSubmit={handleSubmit}>
                    <div id="form-container" className="collapse">
                        <div className="scroll">
                            <div className="container">
                                <div className="row mb-3">
                                    <span className="col-12 col-md-3">General Information</span>
                                    <div className="col-12 col-md-9 d-flex flex-wrap px-3">
                                        <input
                                            style={{ width: "75%" }}
                                            className="form-control mb-2 me-md-2 inputField"
                                            type="text"
                                            placeholder="Vip ID"
                                            value={fId}
                                            onChange={(e) => setFId(e.target.value)}
                                        />
                                        <button className="btn btn-outline-secondary mb-2 button-33" type="button"
                                           onClick={getDeatils}>Get Details
                                        </button>
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2 inputField"
                                            type="text"
                                            placeholder="Vip Name"
                                            value={fname}
                                            onChange={(e) => setFname(e.target.value)}
                                        />
                                    </div>
                                </div>
                               
                                <div className="row mb-3" style={{display:"none"}}>
                                    <span className="col-12 col-md-3">Lead Source</span>
                                    <div className="col-12 col-md-9 d-flex flex-wrap px-3">
                                        <input
                                            style={{ width: "75%" }}
                                            className="form-control mb-2 me-md-2 inputField"
                                            type="text"
                                            placeholder="Source"
                                            value={source}
                                            onChange={(e) => setSource(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className="row mb-3">
                                    <span className="col-12 col-md-3">Personal Information</span>
                                    <div className="col-12 col-md-9 d-flex flex-wrap px-3">
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2 inputField"
                                            type="text"
                                            value={formData.personalInformation.studentName}
                                            name='personalInformation.studentName'
                                            placeholder="Student Name"
                                            onChange={handleFile}
                                        />
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2"
                                            type="text"
                                            name="personalInformation.phoneNumber"
                                            placeholder="Phone Number"
                                            value={formData.personalInformation.phoneNumber}
                                            onChange={handleFile}
                                        />
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2"
                                            type="text"
                                            name="personalInformation.age"
                                            placeholder="Age"
                                            value={formData.personalInformation.age}
                                            onChange={handleFile}
                                        />
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2"
                                            type="text"
                                            name='personalInformation.bloodGroup'
                                            placeholder="Blood Group"
                                            value={formData.personalInformation.bloodGroup}
                                            onChange={handleFile}
                                        />
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2"
                                            type="date"
                                            name='personalInformation.dataOfBirth'
                                            placeholder="DOB"
                                            value={formData.personalInformation.dataOfBirth}
                                            onChange={handleFile}
                                        />
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2"
                                            type="text"
                                            name="personalInformation.gender"
                                            placeholder="Gender"
                                            value={formData.personalInformation.gender}
                                            onChange={handleFile}
                                        />
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2"
                                            type="text"
                                            name="personalInformation.fatherName"
                                            placeholder="Father's Name"
                                            value={formData.personalInformation.fatherName}
                                            onChange={handleFile}
                                        />
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2"
                                            type="text"
                                            name="personalInformation.motherName"
                                            placeholder="Mother's Name"
                                            value={formData.personalInformation.motherName}
                                            onChange={handleFile}
                                        />
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2"
                                            type="text"
                                            name="personalInformation.degree"
                                            placeholder="Class/School"
                                            value={formData.personalInformation.degree}
                                            onChange={handleFile}
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className="row mb-3">
                                    <span className="col-12 col-md-3">Address</span>
                                    <div className="col-12 col-md-9 d-flex flex-wrap px-3">
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2"
                                            type="text"
                                            name="address.streetNo"
                                            placeholder="Street No/Door No"
                                            value={formData.address.streetNo}
                                            onChange={handleFile}
                                        />
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2"
                                            type="text"
                                            name="address.streetName"
                                            placeholder="Street Name"
                                            value={formData.address.streetName}
                                            onChange={handleFile}
                                        />
                                        <input
                                            style={{ width: "100%" }}
                                            className="form-control mb-2 me-md-2"
                                            type="text"
                                            name="address.landmark"
                                            placeholder="Landmark"
                                            value={formData.address.landmark}
                                            onChange={handleFile}
                                        />
                                        <input
                                                    className="form-select mb-2 me-md-2"
                                                    style={{ width: "100%" }}
                                                    name='address.district'
                                                    value={formData.address.district}
                                                    onChange={handleFile}
                                                    type="text"
                                                    placeholder="District"
                                                />
                                                <input
                                                    className="form-select mb-2 me-md-2"
                                                    style={{ width: "100%" }}
                                                    name='address.state'
                                                    value={formData.address.state}
                                                    onChange={handleFile}
                                                    type="text"
                                                    placeholder="State"
                                                />
                                        <input
                            style={{width:"100%"}}
                                className="form-control mb-2 me-md-2"
                                type="number"
                                placeholder="Pincode"
                                name="address.pincode"
                              
                                onChange={handleFile}
                            />
                        </div>
                        <div className="d-flex justify-content-center flex-wrap">
                        
                        <div class="vip ms-2 mb-3 p-2"><button id='pay-btn' type="button" class="btn btn-sm d-flex justify-content-center"  name="addvip">
                          <div style={{color:'greenyellow',width:'100%'}}>CANCEL</div></button>
                        </div>
                        <div class="vip pl-4 mb-3 p-2">
                            <button id='pay-btn' type="submit" class="btn btn-sm d-flex justify-content-center"  name="addvip"><div style={{color:'greenyellow',width:'100%'}} >ADD Lead</div></button>
                        </div>

        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>  
<div id="viewvip-table-container">
                <div id="table-container">
                    <h5>VIP LEADS</h5>
                    <div className="scroll">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="t-head rounded-start-3" scope="col">S.no</th>
                                    <th className="t-head" scope="col">FRANCHISE ID</th>
                                    <th className="t-head" scope="col">FRANCHISE Name</th>
                                    <th className="t-head" scope="col">Lead Source</th>
                                  
                                    <th className="t-head" scope="col">Student Name</th>
                                    <th className="t-head" scope="col">Phone Number</th>
                                    <th className="t-head" scope="col">Lead Status</th>
                                    <th className="t-head" scope="col">Profile View</th>
                                    <th className="t-head" scope="col">Meeting Status</th>
                                    <th className="t-head rounded-end-3" scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="table-dark">
    {filteredData.reduce((rows, mapData, index) => {
        if (mapData.generalInformation && mapData.generalInformation.followersId.includes('vip')) {
            // Determine the color based on lead status
            const statusColor = {
                'Notintrested': 'red',
                
                'Intrested': 'green'
            }[mapData.personalInformation.leadStatus] || '#F6C324'; // Default to gray if status not found
            const todayMeetings = mapData.meeting.filter(meeting => meeting.scheduledDate === today);

            // If there are no meetings today, prepare the message
            let scheduleMessage = todayMeetings.length > 0
                ? todayMeetings.map(meeting => `Meeting at ${meeting.scheduledTime}`).join(', ')
                : 'No meetings today';
    
            rows.push(
                <tr key={index}>
                    <td>{rows.length + 1}</td> {/* Display serial number */}
                    <td>{mapData.generalInformation.followersId}</td>  {/* Display Franchise ID */}
                    <td>{mapData.generalInformation.followersName}</td>
                    <td>{mapData.generalInformation.source}</td>
                    <td>{mapData.personalInformation.studentName}</td>
                    <td>{mapData.personalInformation.phoneNumber}</td>
                    <td>
                        <span className="badge" style={{
                            height: "80%", width: "100%", color: 'white',
                            backgroundColor: statusColor, // Apply color based on status
                            border: `1px solid ${statusColor}`, // Border color matches the background
                            padding: "0.6rem", textAlign: 'center'
                        }}>
                            {mapData.personalInformation.leadStatus}
                        </span>
                    </td>
                    <td>
                        <a href={`/crmleadprofile/${mapData._id}`}>
                            <button className="badge" style={{
                                height: "80%", width: "100%", color: 'white',
                                border: '1px solid white', padding: "0.6rem", textAlign: 'center',
                                background: 'transparent', cursor: 'pointer'
                            }}>View Profile</button>
                        </a>
                    </td>
                    <td>{scheduleMessage}</td>
                    <td>
                    <span style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
       
        <Delete style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(mapData._id)} />
    </span>
                    </td>
                </tr>
            );
        }
        return rows;
    }, [])}
</tbody>


                        </table>
                    </div>

                    <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this franchise lead?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">Cancel</Button>
                        <Button onClick={handleConfirmDelete} color="secondary" autoFocus>Delete</Button>
                    </DialogActions>
                </Dialog>

                </div>
                
                <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
        <Paginations
  count={Math.ceil(mapDatas.length / itemsPerPage)}
  page={currentPage}
  onChange={handlePageChange}
  variant="outlined"
  color="secondary"
/>
  </Stack>
</div>
            </div>

              
        </CrmAdminFranchiseViewContainer>
    );
}

export default CrmAdminVipView;

                                    
                                           
