import React from 'react'
import NavBar from "../subcomponents/NavBar";
import MiniNavBar from "../subcomponents/MiniNavBar";
import { styled } from "styled-components";
import { PincodeRefferals } from './PincodeTableContainer';
export default function PincodeRefferal() {
  return (
    <PincodeRefferalContainer>
      <MiniNavBar selected={"pincoderefferals"} />
      <NavBar selected={"pincoderefferals"} />
      <PincodeRefferals />
    </PincodeRefferalContainer>
  );
}
const PincodeRefferalContainer = styled.div`
  display: flex;
  background-color: var(--background);
`;
