import React, { useEffect, useState } from "react";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import NavBar from "./miniComponents/subcomponents/NavBar";
import { Modal, Button, TextField, Box } from "@mui/material";
import { styled } from "styled-components";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TopContainer from "./miniComponents/subcomponents/TopContainer";
import CloseIcon from "@mui/icons-material/Close"; // Import for the close icon
import { fetchData, postData } from "../Api";
import { URL } from "../assets/mocData/config";
import { useGlobalContext } from "../assets/contex/GlobalContext";

// Custom theme for MUI buttons and text fields
const theme = createTheme({
  palette: {
    primary: {
      main: "#a70e6e", // Pink color for primary actions
    },
    secondary: {
      main: "#a70e6e", // Pink color for secondary actions (cancel)
    },
  },
});

function Settings() {
  const [open, setOpen] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const [values, setValues] = useState({
    vipReferralCommission: "",
    vipFranchiseReferralCollection: "",
    vipFranchiseReferralRevenue: "",
    franchiseBusinessCommission: "",
    franchiseReferralCollection: "",
    franchiseReferralRevenue: "",
    GSTPercentage: "",
    vipSupportNumber: "",
    franchiseSupportNumber: "",
  });
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState("");
  const { userRole, userToken } = useGlobalContext();
  const FilterOpen = () => setFilter(!filter);

  const handleOpen = (field) => {
    setActiveField(field);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    setOpen(false);
    if (activeField) {
      const newValues = { ...values };
      newValues[activeField] = document.getElementById("modal-input").value;
      const headers = {
        userRole: userRole,
        userToken: userToken,
      };
      try {
        await postData(
          `${URL}/admin/settings`,
          {
            [activeField]: parseInt(newValues[activeField]),
          },
          headers
        );
      } catch (error) {
        console.log(error);
      }
      console.log({ [activeField]: parseInt(newValues[activeField]) });

      setValues(newValues);
    }
  };
  const fetchSettings = async () => {
    const headers = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let data = await fetchData(`${URL}/admin/settings`, headers);
      console.log(data);
      setValues(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);
  return (
    <SettingsContainer>
      <MiniNavBar />
      <NavBar />
      <SettingsPage>
        {/* TopContainer with heading, search, and filter functionality */}
        <TopContainer
          heading={<h2 style={{ padding: "16px" }}>SETTINGS</h2>}
          options={[]}
          filter={filter}
          FilterOpen={FilterOpen}
          setFilterData={setFilterData}
          showFields={{ search: false, filter: false }}
        />

        <ContentContainer>
          <ThemeProvider theme={theme}>
            {/* Box for VIP Referral Commission */}
            <BoxContainer>
              <Heading>VIP Referral Commission</Heading>
              <BoxWrapper>
                <ValueDisplay>
                  {values.vipReferralCommission || "No value entered yet"}
                </ValueDisplay>
                <StyledButton
                  variant="contained"
                  onClick={() => handleOpen("vipReferralCommission")}
                >
                  Update
                </StyledButton>
              </BoxWrapper>
            </BoxContainer>

            {/* Box for VIP Franchise Referral Collection */}
            <BoxContainer>
              <Heading>VIP Franchise Referral Collection</Heading>
              <BoxWrapper>
                <ValueDisplay>
                  {values.vipFranchiseReferralCollection ||
                    "No value entered yet"}
                </ValueDisplay>
                <StyledButton
                  variant="contained"
                  onClick={() => handleOpen("vipFranchiseReferralCollection")}
                >
                  Update
                </StyledButton>
              </BoxWrapper>
            </BoxContainer>

            {/* Box for VIP Franchise Referral Revenue */}
            <BoxContainer>
              <Heading>VIP Franchise Referral Revenue</Heading>
              <BoxWrapper>
                <ValueDisplay>
                  {values.vipFranchiseReferralRevenue || "No value entered yet"}
                </ValueDisplay>
                <StyledButton
                  variant="contained"
                  onClick={() => handleOpen("vipFranchiseReferralRevenue")}
                >
                  Update
                </StyledButton>
              </BoxWrapper>
            </BoxContainer>

            {/* Box for Franchise Business Commission */}
            <BoxContainer>
              <Heading>Vip Franchise Business Commission </Heading>
              <BoxWrapper>
                <ValueDisplay>
                  {values.franchiseBusinessCommission || "No value entered yet"}
                </ValueDisplay>
                <StyledButton
                  variant="contained"
                  onClick={() => handleOpen("franchiseBusinessCommission")}
                >
                  Update
                </StyledButton>
              </BoxWrapper>
            </BoxContainer>

            {/* Box for Franchise Referral Collection */}
            <BoxContainer>
              <Heading>Franchise Referral Collection</Heading>
              <BoxWrapper>
                <ValueDisplay>
                  {values.franchiseReferralCollection || "No value entered yet"}
                </ValueDisplay>
                <StyledButton
                  variant="contained"
                  onClick={() => handleOpen("franchiseReferralCollection")}
                >
                  Update
                </StyledButton>
              </BoxWrapper>
            </BoxContainer>

            {/* Box for Franchise Referral Revenue */}
            <BoxContainer>
              <Heading>Franchise Referral Revenue</Heading>
              <BoxWrapper>
                <ValueDisplay>
                  {values.franchiseReferralRevenue || "No value entered yet"}
                </ValueDisplay>
                <StyledButton
                  variant="contained"
                  onClick={() => handleOpen("franchiseReferralRevenue")}
                >
                  Update
                </StyledButton>
              </BoxWrapper>
            </BoxContainer>

            {/* Box for GST Percentage */}
            <BoxContainer>
              <Heading>GST Percentage</Heading>
              <BoxWrapper>
                <ValueDisplay>
                  {values.GSTPercentage || "No value entered yet"}
                </ValueDisplay>
                <StyledButton
                  variant="contained"
                  onClick={() => handleOpen("GSTPercentage")}
                >
                  Update
                </StyledButton>
              </BoxWrapper>
            </BoxContainer>

            {/* Box for VIP Support Number */}
            <BoxContainer>
              <Heading>VIP Support Number</Heading>
              <BoxWrapper>
                <ValueDisplay>
                  {values.vipSupportNumber || "No value entered yet"}
                </ValueDisplay>
                <StyledButton
                  variant="contained"
                  onClick={() => handleOpen("vipSupportNumber")}
                >
                  Update
                </StyledButton>
              </BoxWrapper>
            </BoxContainer>

            {/* Box for Franchise Support Number */}
            <BoxContainer>
              <Heading>Franchise Support Number</Heading>
              <BoxWrapper>
                <ValueDisplay>
                  {values.franchiseSupportNumber || "No value entered yet"}
                </ValueDisplay>
                <StyledButton
                  variant="contained"
                  onClick={() => handleOpen("franchiseSupportNumber")}
                >
                  Update
                </StyledButton>
              </BoxWrapper>
            </BoxContainer>

            {/* Modal for entering new values */}
            <Modal open={open} onClose={handleClose}>
              <ModalContent>
                <h4>Enter Value</h4>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="modal-input"
                  label="Value"
                  defaultValue={activeField ? values[activeField] : ""}
                />
                <ButtonContainer>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </StyledButton>
                  <StyledButton
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </StyledButton>
                </ButtonContainer>
              </ModalContent>
            </Modal>
          </ThemeProvider>
        </ContentContainer>
      </SettingsPage>
    </SettingsContainer>
  );
}

// Styled components with responsiveness

const SettingsPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  h2 {
    color: white;
  }

  @media only screen and (max-width: 800px) {
    #miniNav {
      display: block !important;
    }
    h2 {
      margin-left: 3rem;
    }
  }
  #miniNav {
    display: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 1px;
  background-color: var(--background);
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  padding: 16px;
  width: 100%;
  background-color: #25272d;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media (min-width: 768px) {
    width: 45%; // Two boxes per row on medium screens
  }

  @media (min-width: 1200px) {
    width: 30%; // Three boxes per row on large screens
  }

  @media (min-width: 1500px) {
    width: 28%; // Slightly wider for extra-large screens to reduce middle gap
  }
`;

const Heading = styled.h2`
  color: #a70e6e;
  margin-bottom: 16px;
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.3rem;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ValueDisplay = styled.div`
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  color: #ffffff;
  background-color: #f00d88;
  min-width: 150px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 10px;
    font-size: 0.9rem;
  }
`;

const StyledButton = styled(Button)`
  && {
    background-color:#f00d88;
    color: #ffffff;
    &:hover {
      background-color: #89105a;
    }
  }

  @media (min-width: 768px) {
    padding: 12px 24px;
    font-size: 1rem;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  width: 80%;
  margin: 100px auto;

  @media (min-width: 768px) {
    width: 50%;
    padding: 32px;
  }

  @media (min-width: 1200px) {
    width: 30%;
    padding: 40px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const SettingsContainer = styled.div`
  display: flex;
  background-color: var(--background);
`;

export default Settings;
