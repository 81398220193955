import React, { useEffect, useState } from "react";
import { Pagination, Stack,Button } from "@mui/material";
import { styled } from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL as url } from "../../../assets/mocData/config";
import { fetchData } from "../../../Api";
import * as XLSX from 'xlsx';

function VipFranchiseReferrals({ filterData, setVFData, setSelectedView }) {
  const [data, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const { userToken, userRole, profileData, setTransferData } = useGlobalContext();

  const fetchVIPFranchise = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let response;
      if (userRole === "admin" || userRole === "hos") {
        response = await fetchData(`${url}/vip/refer/vipfranchise`, header);
      } else {
        response = await fetchData(`${url}/vip/refer/vipfranchise/${profileData.profileData.user_id}`, header);
      }
      setUserData(response.data);
      setFilteredData(response.data); // Initialize filtered data
    } catch (error) {
      enqueueSnackbar(error.response?.data?.message || "An error occurred", { variant: "error", autoHideDuration: 1000 });
    }
  };

  useEffect(() => {
    fetchVIPFranchise();
  }, []);

  useEffect(() => {
    if (filterData) {
      const lowercasedFilter = filterData.toLowerCase();
      const newFilteredData = data.filter(item =>
        item.userName.toLowerCase().includes(lowercasedFilter) ||
        (item.referredBy || "").toLowerCase().includes(lowercasedFilter) ||
        (item.pinCode || "").toLowerCase().includes(lowercasedFilter) ||
        (item.email || "").toLowerCase().includes(lowercasedFilter) ||
        (item.phoneNumber || "").toLowerCase().includes(lowercasedFilter)
      );
      setFilteredData(newFilteredData);
      setCurrentPage(1); // Reset to first page when search changes
    } else {
      setFilteredData(data);
    }
  }, [filterData, data]);
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "VIP Franchise Data");
    XLSX.writeFile(workbook, "vip_franchise_data.xlsx");
  };
  return (
    <VipFranchiseRefferalsContainer>
      <div id="VipRefferals-table-container">
      <Button
            variant="outlined"
             className="download-btn"
            onClick={downloadExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88",
            }}
            disabled={currentData?.length === 0}
          >
            Download Excel
          </Button>
        <div id="table-container">
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                    S.no
                  </th>
                  <th className="t-head" scope="col">
                    Refferal Name
                  </th>
                  <th className="t-head" scope="col">
                    Referred By
                  </th>
                  <th className="t-head" scope="col">
                    District
                  </th>
                  <th className="t-head" scope="col">
                    State
                  </th>
                  <th className="t-head" scope="col">
                    Country
                  </th>
                  <th className="t-head" scope="col">
                    Pincode
                  </th>
                  <th className="t-head" scope="col">
                    Email
                  </th>
                  <th className="t-head" scope="col">
                    Phone Number
                  </th>
                  <th className="t-head" scope="col">
                    VIP
                  </th>
                  <th className="t-head" scope="col">
                    Status
                  </th>
                  {(userRole === "admin" || userRole === "hos") && (
                    <>
                      <th className="t-head rounded-end-3" scope="col">
                        VIP Actions
                      </th>
                      <th className="t-head rounded-end-3" scope="col">
                        VF Actions
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody className="table-dark">
                {currentData?.map((item, i) => (
                  <tr key={item.id || i}>
                    <td>{indexOfFirstItem + i + 1}</td>
                    <td>{item.userName}</td>
                    <td>{item.referredBy.name }</td>
                    <td>{item.district}</td>
                    <td>{item.state}</td>
                    <td>{item.country}</td>
                    <td>{item.pinCode}</td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.otherDetails.isVip === "yes" ? "🟢" : "🔴"}</td>
                    <td>
                    <span
    style={{
      display: "inline-block", // To ensure the span behaves like a block-level element
      padding: "5px 10px", // Padding for the box
      borderRadius: "5px", // Rounded corners for the box
      color: item.status === "active" ? "green" : "red", // Text color matches the border color
      border: `2px solid ${item.status === "active" ? "green" : "red"}`, // Conditional border color
      fontSize: "14px", // Responsive font size
      textAlign: "center", // Center the text inside the box
      width: "auto", // Adjust width based on content
      minWidth: "80px", // Minimum width for small screens
      maxWidth: "100%", // Full width on smaller screens
    }}
  >
    {item.status}</span></td>
                    {(userRole === "admin" || userRole === "hos") && (
                      <td>
                        <button
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "white",
                            border: "1px solid white",
                            padding: "0.6rem",
                            textAlign: "center",
                            backgroundColor: "black",
                          }}
                          onClick={() => {
                            if (item.otherDetails.isVip === "yes") {
                              enqueueSnackbar("No action needed", {
                                variant: "warning",
                              });
                            } else {
                              setTransferData(item);
                              navigate("/vipdetails");
                            }
                          }}
                        >
                          Add as Vip
                        </button>
                      </td>
                    )}
                    {(userRole === "admin" || userRole === "hos") && (
                      <td>
                        <button
                          className="badge"
                          style={{
                            height: "80%",
                            width: "100%",
                            color: "white",
                            border: "1px solid white",
                            padding: "0.6rem",
                            textAlign: "center",
                            backgroundColor: "black",
                          }}
                          onClick={() => {
                            if (item.otherDetails.isVip === "yes") {
                              setVFData(item);
                              setSelectedView("AddVipFranchise");
                            } else {
                              enqueueSnackbar(
                                "Only VIPs can become VIP Franchise",
                                { variant: "warning" }
                              );
                            }
                          }}
                        >
                          Add as Vip Franchise
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={Math.ceil(filteredData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </VipFranchiseRefferalsContainer>
  );
}

const Paginations = styled(Pagination)`
  color: white;
  & .MuiPaginationItem-root {
    color: white;
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333;
    color: white;
  }
`;

const VipFranchiseRefferalsContainer = styled.div`
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }

  td {
    text-align: center;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
    overflow-y: scroll;
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }

  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
`;

export default VipFranchiseReferrals;
