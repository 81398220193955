import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

import axios from "axios";
import { enqueueSnackbar } from "notistack";

import AddBranch from "../miniComponents/AddForms/AddBranch";
import BranchTable from "../miniComponents/Tables/BranchTable"
import TopContainer from "../miniComponents/subcomponents/TopContainer";
import { useGlobalContext } from "../../assets/contex/GlobalContext";
function BranchContainer({ user, BranchTitles }) {
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState("");

  const [selectedView, setSelectedView] = useState("AddBranch");
  const { userRole } = useGlobalContext();
  const FilterOpen = () => {
    filter ? setFilter(false) : setFilter(true);
  };

  if (userRole == "admin") {
    return (
      <BranchTableContainer>
        <div id="course-list">
          {/* <MiniNavBar selected={"vipBranch"}/> */}
          <TopContainer
            heading={"FRANCHISE DETAILS"}
            options={["Tia mock", "Tia Brain", "Live Tutor", "Test hub"]}
            filter={filter}
            FilterOpen={FilterOpen}
            setFilterData={setFilterData}
          />
        
        </div>
      </BranchTableContainer>
    );
  } else {
    return (
      <BranchTableContainer>
        <div id="course-list"style={{ height: "100vh", overflowY: "scroll" }}>
          {/* <MiniNavBar selected={"vipBranch"}/> */}
          <TopContainer
            heading={"FRANCHISE DETAILS"}
            options={["Tia mock", "Tia Brain", "Live Tutor", "Test hub"]}
            filter={filter}
            FilterOpen={FilterOpen}
            setFilterData={setFilterData}
            showFields={{ search: true, filter: true }}
          />
          {filter ? (
            <div style={{ filter: "blur(15px)" }}>
              <BranchTable
                user={user}
                filterData={filterData}
                setFilterData={setFilterData}
              />
            </div>
          ) : (
            <BranchTable
              user={user}
              filterData={filterData}
              setFilterData={setFilterData}
            />
          )}
        </div>
      </BranchTableContainer>
    );
  }
}
const BranchTableContainer = styled.div`
  #course-list {
    width: 100%;
    padding: 2rem;
  }

  ::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  .btn {
    background-color: var(--navbar-dark-primary);
    padding: 1rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  #line {
    background-color: var(--icon-color);
    height: 3px;
    width: 100%;
  }
  h2 {
    color: white;
  }
  @media only screen and (max-width: 800px) {
    #miniNav {
      display: block !important;
    }
    #course-list {
      width: 100vw;
    }
    #table-container {
      width: 90vw !important;
    }
    h2 {
      margin-left: 3rem;
    }
  }
  #miniNav {
    display: none;
  }
  #searchfield {
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
  }

  #searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height: 20px;
    width: 10rem;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
  ::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #bf2f82c4;
  }

  .font {
    border: 0px solid;
    color: #bf2f82c4;
  }

  .icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
  }

  .icon {
    color: #bf2f82c4;
    border-radius: 5rem;
  }

  #table-container {
    width: 73vw;
    height: 78vh;
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
  }

  #table-container thead th {
    position: sticky;
    top: 0;
  }

  .t-head {
    background-color: #18171b !important;
    color: white !important;
    padding: 1rem !important;
    text-align: center;
  }
  h4 {
    text-align: center;
  }

  table.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  table.rounded-corners th,
  table.rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
`;

export default BranchContainer;
