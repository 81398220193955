import React from 'react'
import { styled } from 'styled-components';
import NavBar from "./miniComponents/subcomponents/NavBar";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import DashboardContainer from "./secondaryContainersForPages/DashboardContainer" 
function DashBoardPage() {
  return (
    <DashBoardContain>
      <MiniNavBar selected={"dashboard"} />
      <NavBar selected={"dashboard"} />
      <DashboardContainer/>
    </DashBoardContain>
  );
}
const DashBoardContain = styled.div`
  display: flex;
  background-color: var(--background);
`;
export default DashBoardPage