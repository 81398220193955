import React from 'react'
import NavBar from "./miniComponents/subcomponents/NavBar";
import { styled } from "styled-components";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import BranchStaffContainer from './secondaryContainersForPages/BranchStaffContainer';
function BranchStaffDetails() {
  return (
    <Container>
      <MiniNavBar selected={"branchstaff"} />
      <NavBar selected={"branchstaff"} />
      <BranchStaffContainer/>
    </Container>
  ); 
}
const Container = styled.div`
  background-color: var(--background);
  display: flex;
`;
export default BranchStaffDetails