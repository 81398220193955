import React from "react";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import NavBar from "./miniComponents/subcomponents/NavBar";
import { styled } from "styled-components";
import FranchiseContainer from "./secondaryContainersForPages/FranchiseContainer";

function FranchisePage() {
  return (
    <FranchiseContainers>
      <MiniNavBar selected={"franchisedetails"} />
      <NavBar  selected={"franchisedetails"} />
     <FranchiseContainer/>
    </FranchiseContainers>
  );
}
const FranchiseContainers = styled.div`
  display: flex;
  background-color: var(--background);
`;

export default FranchisePage;
