import React from 'react'
import NavBar from "./miniComponents/subcomponents/NavBar";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import { styled } from "styled-components";
import MediaUploadSection from './MediaUploadsSection';
export default function ReferUsers() {
  return (
    <MediaContainer>
      <MiniNavBar selected={"mediaupload"} />
      <NavBar selected={"mediaupload"} />
      <MediaUploadSection />
    </MediaContainer>
  );
}
const MediaContainer = styled.div`
  display: flex;
  background-color: var(--background);

   

`;
