import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { styled } from "styled-components";
import { URL } from "../../../assets/mocData/config";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputBase,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress"; // Loading spinner component
import {
  createTheme,
  ThemeProvider,
  styled as muiStyled,
} from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f00d88", // Pink color
    },
    text: {
      primary: "#ffffff", // White color for input text
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: "#ffffff", // White color for input text
          backgroundColor: "black", // Background color for filled input
          "&:before": {
            borderColor: "white", // Initial border color white
          },
          "&:hover:before": {
            borderColor: "#f00d88", // Border color on hover
          },
          "&.Mui-focused:before": {
            borderColor: "#f00d88", // Border color when focused
          },
          "&.Mui-focused": {
            backgroundColor: "black", // Background color when focused
          },
        },
        input: {
          color: "#ffffff", // White color for input text
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#f00d88", // White color for label
          "&.Mui-focused": {
            color: "#f00d88", // Pink color for label when focused
          },
        },
      },
    },
  },
});
function EditConcept({
  userRole,
  userToken,
  editData,
  setEditData,
  setEditContainer,
  concepts,
  setConcept
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
const [conceptData, setConceptData] = useState({
  ...editData,
});
 const [selectedValue, setSelectedValue] = useState(editData.status);
const handleChange = (event) => {
  setSelectedValue(event.target.value);
};
const controlProps = (item) => ({
  checked: selectedValue === item,
  onChange: handleChange,
  value: item,
  name: "status",
  inputProps: { "aria-label": item },
});
  const FormData = (e) => {
    console.log(e.target.value);
    let name = e.target.name;
    let value = e.target.value;

    setConceptData({
      ...conceptData,
      [e.target.name]: value,
    });
  };
  const [isLoading, setIsLoading] = useState(false);
  const editConceptData = async (e) => {
    e.preventDefault();
    
    // Validate the form before submission
    if (!validateForm()) return;
    setIsLoading(true);

    try {
      let response = await axios.put(
        `${URL}/admin/concept/${editData._id}`,
        { ...conceptData, status: selectedValue },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            role: userRole,
          },
        }
      );
      console.log(response);
      const indexToUpdate = concepts.findIndex(
        (obj) => obj._id === editData._id
      );

      if (indexToUpdate !== -1) {
        concepts[indexToUpdate] = conceptData;
      }
      setConcept(concepts);
      enqueueSnackbar("Concept edited successfully", { variant: "success" });
      setEditContainer(false);
    } catch (error) {
      // Handle E11000 error for duplicate keys
      if (error.response && error.response.data.error) {
        const errorMessage = error.response.data.error;
    
        if (errorMessage.includes("E11000")) {
          if (errorMessage.includes("conceptCode")) {
            enqueueSnackbar("Concept Code already exists.", { variant: "warning" });
          } else if (errorMessage.includes("conceptName")) {
            enqueueSnackbar("Concept Name already exists.", { variant: "warning" });
          } else {
            enqueueSnackbar("Duplicate data entry error.", { variant: "warning" });
          }
        } else {
          enqueueSnackbar(`${errorMessage}`, { variant: "warning" });
        }
      } else {
        enqueueSnackbar("Something went wrong. Please try again later.", {
          variant: "error",
        });
      }
    }
  };

  const validateForm = () => {
    if (!conceptData.conceptCode) {
      enqueueSnackbar("Concept Code is required", { variant: "error" });
      return false;
    }
    if (!conceptData.conceptName) {
      enqueueSnackbar("Concept Name is required", { variant: "error" });
      return false;
    }
    if (!selectedValue) {
      enqueueSnackbar("Status must be selected", { variant: "error" });
      return false;
    }
    return true;
  };
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10,
          backgroundColor: "#18171b",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <EditConceptContainer>
      <ThemeProvider theme={theme}>
        <div class="d-flex">
          <div id="add-Concept-container">
            {/* <h2 style={{textAlign: "left"}}>Add Course</h2> */}
            <div class="container">
              <form onChange={FormData}>
                <div class="row">
                  <div class="col col-12">
                    <div id="form-container" class="m-1">
                      <form
                        style={{ height: "auto", paddingTop: "1rem" }}
                        action=""
                      >
                        <div class="container">
                          <div class="row">
                            <hr />
                            <span class="col-3 inputHeadings">
                              Concept Code
                            </span>
                            <div class="col-md-9 col-sm-12 d-flex flex-column px-3">
                              <span
                                style={{ width: "100%" }}
                                class="d-flex justify-content-between"
                              >
                                <TextField
                                  value={conceptData.conceptCode}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled" 
                                  id="filled-basic" 
                                  label="Enter Concept Code"
                                  name="conceptCode"
                                  
                                  type="text"
                                  
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="container">
                          <div class="row">
                            <span class="col-3 inputHeadings">
                              Concept Name
                            </span>
                            <div class="col-9 d-flex flex-column px-3">
                              <span
                                style={{ width: "100%" }}
                                class="d-flex justify-content-between"
                              >
                                <TextField
                                  value={conceptData.conceptName}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled" 
                                  id="filled-basic" 
                                  label="Enter Concept Name"
                                  name="conceptName"
                                  
                                  type="text"
                                
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                       
                        {/* <!-- Status Section --> */}
                        <div class="container status-section">
                          <div class="row align-items-center">
                            <span class="col-3 inputHeadings">Status</span>
                            <div class="col-9 d-flex flex-column px-3">
                              <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">
                                 Status
                                </FormLabel>
                                <RadioGroup
                                  className="d-sm-flex flex-sm-column flex-md-row"
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  value={selectedValue}
                                >
                                  <FormControlLabel
                                    name="status"
                                    value="active"
                                    control={
                                      <Radio
                                        name="status"
                                        {...controlProps("active")}
                                        color="success"
                                      />
                                    }
                                    label="Active"
                                  />
                                  <FormControlLabel
                                    name="status"
                                    value="inactive"
                                    control={
                                      <Radio
                                        name="status"
                                        {...controlProps("inactive")}
                                      />
                                    }
                                    label="In Active"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                        </div>

                        {/* <div class="container status-section">
                          <div class="row align-items-center">
                            <span class="col-3 inputHeadings">Status</span>
                            <div class="col-9 d-flex flex-column px-3">
                              <div
                                class="form-check"
                                style={{ position: "relative", top: "1.8rem" }}
                              >
                                <Radio {...controlProps("c")} color="success" />
                                <Radio
                                  {...controlProps("e")}
                                  sx={{
                                    color:"pink",
                                    "&.Mui-checked": {
                                      color: "pink",
                                    },
                                  }}
                                />
                                <input
                                  required={true}
                                  class="form-check-input active-radio"
                                  type="radio"
                                  name="status"
                                  value="active"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  <p>Active</p>
                                </label>
                                <div
                                  class="form-check"
                                  style={{
                                    position: "relative",
                                    bottom: "40px",
                                    left: "7rem",
                                  }}
                                >
                                  <input
                                    class="form-check-input inactive-radio"
                                    type="radio"
                                    name="status"
                                    value="inactive"
                                    id="flexRadioDefault2"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault2"
                                  >
                                    <p>Inactive</p>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>  */}
                        <div class="container mt-3">
                        <div class="row justify-content-center">
                            <div class="col-12 d-flex justify-content-center">
                              <div class="d-flex flex-column flex-sm-row align-items-center">
                                <button
                                onClick={(e) => editConceptData(e)}
                                type="submit"
                                class="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-concept-btn"
                                >
                                Edit Concept
                              </button>
                              <button
                                onClick={() =>{
                                  setEditContainer(false)
                                  setConceptData({
                                    conceptName: "",
                                    conceptCode: "",
                                    companyCommissionPercentage: "",
                                    status: "",
                                  })
                                }
                                }
                                type="button"
                                class="btn btn-outline-danger btn-sm cancel-btn"
                                >
                                Cancel
                              </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </EditConceptContainer>
  );
}
const EditConceptContainer = styled.div`
  input::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  #add-Concept-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: gray !important;
  }

  h2,
  p,
  span,
  h6 {
    color: white;
  }
  .add-concept-btn:hover {
    background-color: green;
  }
  .cancel-btn:hover {
    background-color: red;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }

  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }

  h5 {
    text-align: center;
    color: white;
  }

  input {
    color: white;
  }

  /* Status Section Styles */
  .status-section {
    margin-top: 1rem;
  }

  .status-label {
    color: white;
  }

  .status-options {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .active-dot {
    background-color: green;
  }

  .inactive-dot {
    background-color: red;
  }

  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #add-Concept-container {
      padding: 1rem;
    }

    #profile-container {
      margin-top: 1rem;
    }
  }
  /* Style for active radio button */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }
  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }
  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }
  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  .add-concept-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }
     @media (max-width: 575px) {
    .add-concept-btn,
    .cancel-btn {
      align-items: center;
      width: 100%; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
    }
`;
export default EditConcept;
