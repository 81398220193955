import React, { useState } from "react";
import TopContainer from "../miniComponents/subcomponents/TopContainer";
import styled from "styled-components";
import { Box, Tabs, Tab ,CircularProgress} from "@mui/material";
import {
  AccountsFranchise,
  AccountsHOstaff,
  AccountsStudents,
  Accountsvip,
  AccountsVipFranchise,
  VipSystem,
} from "../miniComponents/Tables/AccountsTables";

function AccountsContainer() {
  const [filterData, setFilterData] = useState("");

  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);
    const handleChange = (event, newValue) => {
      
        setSelectedView(newValue);
       
    };

  const [selectedView, setSelectedView] = useState("Vip_Accounts");
  console.log(selectedView);
  const FilterOpen = () => {
    filter ? setFilter(false) : setFilter(true);
  };
  return (
    <AccountDetailContainer>
      <div id="course-list" style={{ width: "auto" ,height:"100vh",overflowY:"scroll"}}>
        
      {loading && (
          <LoadingOverlay>
            <CircularProgress sx={{ color: "#f00d88" }} />
          </LoadingOverlay>
        )}{/* <MiniNavBar selected={"vipcourselist"}/> */}
        <TopContainer
          heading={"ACCOUNTS"}
          options={["Tia mock", "Tia Brain", "Live Tutor", "Test hub"]}
          filter={filter}
          FilterOpen={FilterOpen}
          setFilterData={setFilterData}
          showFields={{ search: true, filter: true }}
        />
         <Box
      sx={{
        marginTop: "1rem",
        width: "100%",
 // Set a max-width to prevent stretching too much on large screens
        backgroundColor: "var(--navbar-dark-primary)", // Background color
        borderRadius: "8px", // Rounded corners
        boxShadow: 3, // Shadow effect
        padding: "1rem", // Padding around the tabs
        display: "flex", // Use Flexbox
        justifyContent: "center", // Center horizontally
      }}
    >
      <Tabs
        value={selectedView}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Course Navigation Tabs"
        textColor="inherit"
        indicatorColor="secondary"
        sx={{
          "& .MuiTab-root": {
            minWidth: "150px", // Minimum width for each tab
            fontWeight: "bold", // Bold text
            fontSize: "0.875rem", // Font size
            color: "white", // Default text color
            "&.Mui-selected": {
              color: "white", // Active tab text color
            },
            // Additional styling for spacing
            margin: "0 0.5rem",
          },
          "& .MuiTabs-indicator": {
            height: "4px", // Indicator height
            backgroundColor: "#f00d88", // Indicator color
          },
        }}
      >
        <Tab
          label="Vip"
          value="Vip_Accounts"
        />
        <Tab
          label="Vip Franchise"
          value="VipFranchise_Accounts"
        />
        <Tab
          label="Franchise Royalty"
          value="Franchise_Accounts"
        />
        
        <Tab
          label="Company Revenue"
          value="Students_Accounts"
        />
        <Tab
          label="Vip System"
          value="Vip_System"
        />
      </Tabs>
    </Box>
        
        {selectedView == "Vip_Accounts" ? (
          <>
            <Accountsvip
            filterData={filterData} // Passing search query
            setFilterData={setFilterData} />
          </>
        ) : selectedView === "VipFranchise_Accounts" ? (
          <>
            <AccountsVipFranchise
            filterData={filterData} // Passing search query
            setFilterData={setFilterData} />
          </>
        ) : selectedView === "Franchise_Accounts" ? (
          <>
            <AccountsFranchise
            filterData={filterData} // Passing search query
            setFilterData={setFilterData} />
          </>
        ) : selectedView === "Students_Accounts" ? (
          <>
            <AccountsStudents
            filterData={filterData} // Passing search query
            setFilterData={setFilterData} />
          </>
        ) : selectedView === "Vip_System" ? (
          <>
            <VipSystem
            filterData={filterData} // Passing search query
            setFilterData={setFilterData} /> 
          </>
        ) : null}
      </div>
    </AccountDetailContainer>
  );
}
const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;
const AccountDetailContainer = styled.div`
  width: 78%;
    @media only screen and (max-width: 1000px){
      width:99vw;
    }
  #course-list {
    width: 100%;
    padding: 2rem;
    
  }

  ::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  .btn {
    background-color: var(--navbar-dark-primary);
    padding: 1rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  #line {
    background-color: var(--icon-color);
    height: 3px;
    width: 100%;
  }
  h2 {
    color: white;
  }
  @media only screen and (max-width: 800px) {
    #miniNav {
      display: block !important;
    }
    h2 {
      margin-left: 3rem;
    }
  }
  #miniNav {
    display: none;
  }
  #searchfield {
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
  }

  #searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height: 20px;
    width: 10rem;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
  ::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #bf2f82c4;
  }

  .font {
    border: 0px solid;
    color: #bf2f82c4;
  }

  .icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
  }

  .icon {
    color: #bf2f82c4;
    border-radius: 5rem;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 83vh;
  }

  #table-container thead th {
    position: sticky;
    top: 0;
  }

  .t-head {
    background-color: #18171b !important;
    color: white !important;
    padding: 1rem !important;
    text-align: center;
  }
  h4 {
    text-align: center;
  }

  table.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  table.rounded-corners th,
  table.rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
`;
export default AccountsContainer;
