import React from "react";
import { styled } from "styled-components";

import VipContainer from "./secondaryContainersForPages/VipContainer";
import { useGlobalContext } from "../assets/contex/GlobalContext";
import NavBar from "./miniComponents/subcomponents/NavBar";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
function VipDetailPage() {
  let { userRole } = useGlobalContext();
  return (
    <VipDetailsContainer>
      <MiniNavBar user={userRole} selected={"vipdetails"} />
      <NavBar user={userRole} selected={"vipdetails"} />
      <VipContainer user={userRole} />
    </VipDetailsContainer>
  );
}
const VipDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: var(--background);
`;
export default VipDetailPage;
