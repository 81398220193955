import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #121212; /* Dark background */
    padding: 20px;

    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const Title = styled.h2`
    font-size: 24px;
    margin-bottom: 20px;
    color: #ffffff; /* Light text color */

    @media (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 15px;
    }
`;

const Form = styled.form`
    background: #1f1f1f; /* Darker form background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 400px;
    width: 100%;

    @media (max-width: 768px) {
        padding: 15px;
        max-width: 100%;
    }
`;

const FormGroup = styled.div`
    margin-bottom: 15px;

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #cccccc; /* Lighter label color */

    @media (max-width: 768px) {
        margin-bottom: 3px;
        font-size: 14px;
    }
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #333; /* Dark input background */
    color: #ffffff; /* Light text color */
    font-size: 16px;
    text-align: center;
    letter-spacing: 5px;

    &::placeholder {
        color: #bbbbbb; /* Placeholder color */
    }

    @media (max-width: 768px) {
        padding: 8px;
        font-size: 14px;
    }
`;

const Button = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #218838;
    }

    @media (max-width: 768px) {
        padding: 8px;
        font-size: 14px;
    }
`;

const Message = styled.p`
    margin-top: 20px;
    color: ${props => (props.error ? 'red' : 'green')};

    @media (max-width: 768px) {
        margin-top: 15px;
        font-size: 14px;
    }
`;

const OTPForm = ({ otp, handleChange, handleSubmit, message, error }) => {
    return (
        <Container>
            <Title>Enter OTP</Title>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label>OTP:</Label>
                    <Input
                        type="text"
                        value={otp}
                        onChange={handleChange}
                        maxLength="6"
                        pattern="\d*"
                        required
                    />
                </FormGroup>
                <Button type="submit">Submit</Button>
            </Form>
            {message && <Message error={error}>{message}</Message>}
        </Container>
    );
};

export default OTPForm;
