import React, { useState } from 'react';
import $ from "jquery";
import { styled } from 'styled-components';
import { putData } from '../Api';
import { useGlobalContext } from '../assets/contex/GlobalContext';
import { URL as url } from '../assets/mocData/config';
import { enqueueSnackbar } from 'notistack';

function VipVideoPortal() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { profileData, userRole, userToken } = useGlobalContext();

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const completeWatching = async () => {
    let headers = {
      userRole: "admin",
      userToken: userToken
    };
    try {
      let response = await putData(
        `${url}/admin/users/${profileData.profileData._id}`,
        { verified: true,status:"active" },
        headers
      );

      let item = JSON.parse(localStorage.getItem('data'))
      item.profileData = response.user;

      localStorage.setItem(
        "data",
        JSON.stringify({
          ...item,
        })
      );
      window.location.reload();
      console.log(response);
    } catch (error) {
      enqueueSnackbar(error.response.data.message, {
        variant: "error",
      });

      console.log(error);
    }
  };

  const videosByLanguage = {
    tamil: [
      { id: 1,  title: "Do you want to be part of this project?", duration: "9 mins", youtubeId: "wBS4vTQRotA" },
      { id: 2,  title: "VIP beginner 1", duration: "9 mins", youtubeId: "oVywqy5kcjY" },
      { id: 3,  title: "VIP beginner 2", duration: "9 mins", youtubeId: "x4zZ7lmF44M" },
      { id: 4,  title: "VIP System counselling 1", duration: "9 mins", youtubeId: "wqiDb-XvvJY" },
      { id: 5,  title: "Preliminary 1", duration: "9 mins", youtubeId: "YTOnVvvTx8Q" },
      { id: 6,  title: "Preliminary 2", duration: "9 mins", youtubeId: "_Dt7PewClN8" },
    ],
    english: [
      { id: 1, title: "", duration: "9 mins", youtubeId: "5wAmpUTR2NU" },
      { id: 2, title: "", duration: "9 mins", youtubeId: "J7dEsGD3JT0" },
      { id: 3,  title: "", duration: "9 mins", youtubeId: "fDTvrwPr8VA" },
    ],
    
  };

  const languages = ['tamil', 'english'];
  const selectedLanguage = languages[activeIndex];
  const videos = videosByLanguage[selectedLanguage] || [];

  return (
    <VideoPortalContainer>
      <main>
        <div className="section-container py-5 text-center container ">
          <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h1 className="title fw-light text-white">
                TALENT INITIATORS & ACCELERATORS
              </h1>
              <p className="lead-text lead text-white">
                EMPOWERING ENTREPRENEURS TO BRING IDEAS ALIVE
              </p>
            </div>
          </div>
        </div>
      </main>
      <div className="album-section album py-5">
        <ol className="breadcrumb  lighten-6 first-1 shadow-lg mb-5 ">
          {languages.map((lang, index) => (
            <li key={lang} className="breadcrumb-item">
              <a
                href="#"
                className={`black-text text-uppercase ${activeIndex === index ? "active-1" : ""}`}
                onClick={() => handleItemClick(index)}
              >
                <span>{lang.charAt(0).toUpperCase() + lang.slice(1)}</span>
              </a>
              <img
                className="ml-md-3"
                src="https://img.icons8.com/offices/100/000000/double-right.png"
                width="20"
                height="20"
                alt="Right Arrow"
              />
            </li>
          ))}
        </ol>

        <div className="container card-container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            {/* Video cards */}
            {videos.map((video) => (
              <div key={video.id} className="col">
                <div className="card shadow-sm border-0 rounded-bottom">
                  {/* Embedded YouTube Video */}
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      src={`https://www.youtube.com/embed/${video.youtubeId}`}
                      title={`YouTube Video ${video.id}`}
                      allowFullScreen
                    ></iframe>
                  </div>
                  {/* End of Embedded YouTube Video */}
                  <div className="card-body">
                    <p className="card-text text-white">{video.title}</p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          className="btn-check"
                          id={`btn-check-${video.id}-outlined`}
                          defaultChecked={true}
                        />
                        
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <footer className="text-body-secondary py-5">
  <div className="container">
    <div className="d-flex justify-content-center mb-3">
      <button
        className="btn btn-success btn-responsive"
        onClick={() => completeWatching()}
      >
        Complete Watching
      </button>
    </div>
    <p className="float-end mb-1">
      <a href="#">Back to top</a>
    </p>
    <p className="mb-0 text-white">
      Tia <a href="https://tiaedu.in/">Visit the homepage.</a>
    </p>
  </div>
</footer>


    </VideoPortalContainer>
  );
}


const VideoPortalContainer = styled.div`
background-color:#18171B;
.bg-body-tertiary {
background-color: #18171B !important;
}
  /* Entire CSS styles here */
  .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (max-width: 575px) {
  .btn-responsive {
    padding: 5px 10px;
    font-size: 13px;
  }
}

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }
.album-section{
    background-color:#25272d !important;
   
}
  .b-example-divider {
    width: 100%;
    height: 3rem;
    background-color: rgba(255, 255, 255, .1);
    border: solid rgba(255, 255, 255, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(255, 255, 255, .1), inset 0 .125em .5em rgba(255, 255, 255, .15);
  }

  .b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
  }

  .bi {
    vertical-align: -.125em;
    fill: currentColor;
  }

  .nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
  }

  .nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .btn-bd-primary {
    --bd-violet-bg: #712cf9;
    --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-white);
    --bs-btn-bg: var(--bd-violet-bg);
    --bs-btn-border-color: var(--bd-violet-bg);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #6528e0;
    --bs-btn-hover-border-color: #6528e0;
    --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #5a23c8;
    --bs-btn-active-border-color: #5a23c8;
  }

  .bd-mode-toggle {
    z-index: 1500;
  }

  .bd-mode-toggle .dropdown-menu .active .bi {
    display: block !important;
  }

 

  .breadcrumb>li+li:before {
    content: "" !important;
  }

  .breadcrumb {
    padding: 19px;
    font-size: 14px;
    color: #aaa !important;
    letter-spacing: 2px;
    border-radius: 5px !important;
    background-color: #1d1d1d; /* Dark background color */
  }

  .first ol {
    border-top-right-radius: 200px !important;
    border-bottom-right-radius: 200px !important;
    background-color: #333 !important; /* Dark background color */
  }

  .first-1 {
    background-color: #fff; /* Light background color */
  }

  a {
    text-decoration: none !important;
    color: #aaa !important;
  }

  a:focus,
  a:active {
    outline: none !important;
    box-shadow: none !important;
  }

  img {
    vertical-align: bottom;
  }

  .first span {
    color: white;
  }

  .active-1 {
    font-size: 13px !important;
    padding-bottom: 12px !important;
    padding-top: 12px !important;
    padding-right: 25px !important;
    padding-left: 25px !important;
    border-radius: 200px !important;
    background-color: #fff; /* Light background color */
    color: #333; /* Dark text color */
  }

  @media (max-width: 767px) {
    .breadcrumb {
      font-size: 10px;
    }

    .breadcrumb-item+.breadcrumb-item {
      padding-left: 0;
    }

    img {
      width: 11px;
      height: 11px;
      vertical-align: middle;
    }

    .active-1 {
      font-size: 10px !important;
      padding-right: 8px !important;
      padding-left: 8px !important;
      width: 100% !important;
    }

    .breadcrumb {
      letter-spacing: 1px !important;
    }

    .breadcrumb>* div {
      max-width: 60px;
    }
  }
  .card-body{
    background-color:#18171B !important;
    
  }


  @media (min-width: 768px) {
    /* display: flex;
    flex-direction:row; */
  }

  
  .breadcrumb {
    background-color: #18171B; /* rgb(21, 20, 22) */
    border-radius: 5px !important;
    list-style-type: none;
    padding: 19px;
    font-size: 14px;
    color: #aaa !important;
    letter-spacing: 2px;
    /* Add max-width to reduce width */
    max-width: 300px; /* Adjust as needed */
    .breadcrumb-item:last-child {
      border-radius: 0 5px 5px 0;
    }

    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  .breadcrumb-item {
    font-weight: bold;
    & + &::before {
      content: ""; /* Remove bullet between items */
    }
  }

  .breadcrumb-item a {
    text-decoration: none !important;
    color: #aaa !important;
  }

  .breadcrumb-item a.active-1 {
    color: white;
    font-size: 13px !important;
    padding: 12px !important;
    border-radius: 200px !important;
    background-color: white !important;
  }

  .breadcrumb-item a.active-1 span {
    color: rgb(51, 0, 80);
  }

  img {
    vertical-align: middle;
  }

  /* Add this style for the rounded right end */
  .breadcrumb-item:last-child {
    border-radius: 0 5px 5px 0;
  }

  @media (max-width: 767px) {
    .breadcrumb {
      font-size: 10px;
    }
    .breadcrumb-item + .breadcrumb-item {
      padding-left: 0;
    }
    img {
      width: 11px;
      height: 11px;
      vertical-align: middle;
    }
    .breadcrumb-item a.active-1 {
      font-size: 10px !important;
      padding: 8px !important;
      width: 100% !important;
    }
    .breadcrumb {
      letter-spacing: 1px !important;
    }
    .breadcrumb > * div {
      max-width: 40px;
    }
  }
  .embed-responsive-item {
  width: 100%;
  height: 110%;
}
`;
export default VipVideoPortal