import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img from '../assets/images/img-1.jpg';
import vid from '../assets/images/img-1.jpg';
import { imageURL } from "../Api";
import { URL } from "../assets/mocData/config";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useGlobalContext } from '../assets/contex/GlobalContext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { enqueueSnackbar } from "notistack";
function AdminMedia() {
  const { userRole } = useGlobalContext();
  const [selectedImages, setSelectedImages] = useState({});
  const [photoData, setPhotoData] = useState([]);
  const [posterData, setPosterData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [indexes, setIndexes] = useState([]);

  // State for snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // State for dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [dialogMediaType, setDialogMediaType] = useState('');

  const getUrl = async (data) => {
    const updatedData = await Promise.all(
      data.map(async (item) => {
        if (item.imagekey) {
          const url = await imageURL(item.imagekey);
          return { ...item, imagekey: url };
        }
        return item;
      })
    );
    return updatedData;
  };

  useEffect(() => {
    const getImageData = async () => {
      try {
        const response = await axios.get(`${URL}/media/mediadatas`);
        const imageData = response.data[0];

        const filteredPhotos = imageData.photo.filter((ph) => ph.users);
        const filteredPosters = imageData.poster.filter((po) => po.users);
        const filteredVideos = imageData.video.filter((vi) => vi.users);

        const updatedPhotos = await getUrl(filteredPhotos);
        const updatedPosters = await getUrl(filteredPosters);
        const updatedVideos = await getUrl(filteredVideos);

        setPhotoData(updatedPhotos);
        setPosterData(updatedPosters);
        setVideoData(updatedVideos);
      } catch (err) {
        console.log(err);
      }
    };

    getImageData();
  }, [userRole]);

  const toggleSelectImage = (id, src) => {
    setIndexes((prevIndexes) =>
      prevIndexes.includes(id) ? prevIndexes.filter((idx) => idx !== id) : [...prevIndexes, id]
    );

    setSelectedImages((prevSelectedImages) => {
      const newSelectedImages = { ...prevSelectedImages };
      if (newSelectedImages[id]) {
        delete newSelectedImages[id];
      } else {
        newSelectedImages[id] = src;
      }
      return newSelectedImages;
    });
  };

  const handleOpenSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOpenDialog = (type, mediaType) => {
    setDialogType(type);
    setDialogMediaType(mediaType);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const deleteAll = async (mediaType) => {
    try {
      const newData = { types: mediaType };
      await axios.delete(`${URL}/media/deleteallpost/6718c9084a6c1d49e553fbb5`, {
        data: newData,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteSelected = async (mediaType) => {
    const datas = indexes
      .filter((idx) => idx.startsWith(mediaType))
      .map((idx) => parseInt(idx.split("-")[1]));

    try {
      const newData = { types: mediaType, indexes: datas };
      await axios.delete(`${URL}/media/deletepost/6718c9084a6c1d49e553fbb5`, {
        data: newData,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteAll = (mediaType) => {
    if (mediaType === 'photos' && !photoData.length || 
        mediaType === 'posters' && !posterData.length || 
        mediaType === 'videos' && !videoData.length) {
      handleOpenSnackbar('No media available to delete.');
    } else {
      handleOpenDialog('deleteAll', mediaType);
    }
  };

  const handleDeleteSelected = (mediaType) => {
    const selectedInMediaType = indexes.some((idx) => idx.startsWith(mediaType));

    if (!selectedInMediaType) {
      handleOpenSnackbar(`Select a ${mediaType.slice(0, -1)} to delete.`);
    } else {
      handleOpenDialog('deleteSelected', mediaType);
    }
  };

  const handleConfirmDelete = () => {
    if (dialogType === 'deleteAll') {
      deleteAll(dialogMediaType);
    } else if (dialogType === 'deleteSelected') {
      deleteSelected(dialogMediaType);
    }
    handleCloseDialog();
  };

  const renderCarousel = (data, id) => {
    const slidesToShow = data.length < 5 ? data.length : 4;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToShow,
      centerMode: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: Math.min(data.length, 3),
            slidesToScroll: Math.min(data.length, 3),
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: Math.min(data.length, 2),
            slidesToScroll: Math.min(data.length, 2),
            infinite: false,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: Math.min(data.length, 1),
            slidesToScroll: Math.min(data.length, 1),
            infinite: false,
          },
        },
      ],
    };
    const ids = data.map((_, index) => `${id}-${index}`);

    return (
      <div key={id}>
        <Header>
          <h5>{id.charAt(0).toUpperCase() + id.slice(1)}</h5>
          <SelectAll>
            <StyledButton onClick={() => handleDeleteAll(id)}>Delete All</StyledButton>
            <TrashIconButton onClick={() => handleDeleteSelected(id)}>
              <FontAwesomeIcon icon={faTrash} />
            </TrashIconButton>
          </SelectAll>
        </Header>

        {data.length ? (
          <StyledSlider {...settings} itemCount={data.length}>
            {data.map((src, index) => (
              <MediaContainer
                key={index}
                selected={selectedImages[`${id}-${index}`]}
                onClick={() => toggleSelectImage(`${id}-${index}`, src.imagekey)}
                itemCount={data.length}
                isSelected={selectedImages[`${id}-${index}`]}
              >
                {src.imagekey ? (
                  id === "videos" ? (
                    <VideoContainer>
                      <video src={src.imagekey} controls />
                    </VideoContainer>
                  ) : (
                    <img src={src.imagekey} alt={`Media ${index}`} />
                  )
                ) : (
                  <FallbackImage src={id === "videos" ? vid : img} alt="Fallback Media" />
                )}
                {selectedImages[`${id}-${index}`] && <TickIcon className="fa-solid fa-check"></TickIcon>}
              </MediaContainer>
            ))}
          </StyledSlider>
        ) : (
          <NoContentMessage style={{whiteSpace:"nowrap"}}>NO {id} AVAILABLE </NoContentMessage>
        )}
      </div>
    );
  };

  return (
    <MediaUpdatesContainer>
      {renderCarousel(photoData, "photos")}
      {renderCarousel(posterData, "posters")}
      {renderCarousel(videoData, "videos")}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm {dialogType === 'deleteAll' ? 'Delete All' : 'Delete Selected'}</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to {dialogType === 'deleteAll' ? 'delete all' : 'delete selected'} {dialogMediaType}?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </MediaUpdatesContainer>
  );
}

export default AdminMedia;

// The styled components remain unchanged.


const NoContentMessage = styled.div`
  color: #ccc;
  text-align: center;
  padding: 20px;
  font-size: 18px;
   text-transform: uppercase;
`;
const MediaUpdatesContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > div {
    margin-bottom: 2rem;
  }

  h4, h5, p, span {
    color: white;
  }

  input[type=checkbox] {
    accent-color: green;
  }

  @media only screen and (max-width: 800px) {
    h4 {
      margin-left: 2rem;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #c94295;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c94295;
  }
     @media only screen and (max-width: 800px) {
    font-size: 12px;
  }
`;

const TrashIconButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  margin-left: 10px;

  &:hover {
    color: red;
  }
      @media only screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

const MediaContainer = styled.div`
  border: ${(props) => (props.isSelected ? '4px solid #c94295' : 'none')};
  cursor: pointer;
  position: relative;
  width: 100%;  /* Set width to 100% for responsiveness */
  max-width:250px;  /* Ensure media doesn't exceed 300px width */
  height:250px; /* Auto height to maintain aspect ratio */
  margin-right: 15px;
  margin-bottom: 15px;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);  /* Add hover shadow for better UI */
    transition: box-shadow 0.3s ease;
  }

  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px; /* Rounded corners for a modern look */
  }
`;

const StyledSlider = styled(Slider)`
  max-width: 1000px;
  margin: 0 auto;

  .slick-slide {
    padding: 10px;
    display: flex;
    justify-content: center;
  }

  .slick-track {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
  }

  @media (max-width: 768px) {
    .slick-slide {
      padding: 5px;
    }
  }
`;

const SelectAll = styled.div`
  display: flex;
  align-items: center;
`;

const TickIcon = styled.i`
  position: absolute;
  top: 5px;
  left: 5px;
  color: green;
  font-size: 1.5rem;
  z-index: 10;
`;

const FallbackImage = styled.img`
  max-width: 100%;
  max-height: 250px;
  opacity: 0.5;
  border-radius: 8px; /* Rounded corners */
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  background-color: #f0f0f0;
  border-radius: 8px; /* Rounded corners */
`;

