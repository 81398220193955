import React from "react";
import { styled } from "styled-components";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import NavBar from "./miniComponents/subcomponents/NavBar";
import VipFranchiseContainer from "./secondaryContainersForPages/VipFranchiseContainer";

function VipFranchisePage() {
  return (
    <VipFranchiseDetailsContainer>
      <MiniNavBar selected={"vipfranchisedetails"} />
      <NavBar  selected={"vipfranchisedetails"} />
      <VipFranchiseContainer />
    </VipFranchiseDetailsContainer>
  );
}
const VipFranchiseDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: var(--background);
  @media (max-width:800px) {

  justify-content:center;

`;
export default VipFranchisePage;
