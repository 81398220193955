import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';
import { useGlobalContext } from '../assets/contex/GlobalContext';
import { URL as url } from '../assets/mocData/config';
import { postData, fetchData, deleteCeoMessage } from '../Api';
import { useSnackbar } from 'notistack';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const CEOmessage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [mediums, setMediums] = useState([]);
  const [open, setOpen] = useState(false); // State to manage the modal
  const [deleteId, setDeleteId] = useState(null); // ID of the message to delete
  const { userToken, userRole } = useGlobalContext();
  const foc = useRef();

  const headers = {
    userRole: userRole,
    userToken: userToken,
  };

  const dataFetching = async () => {
    try {
      const response = await fetchData(`${url}/admin/ceomessage/view`, headers);
      setMessages(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSend = async () => {
    if (newMessage.trim() === '') {
      enqueueSnackbar('Message cannot be empty', { variant: 'warning' });
      return;
    }
    if (mediums.length === 0) {
      enqueueSnackbar('Select at least one receiver', { variant: 'warning' });
      return;
    }
    try {
      const data = { message: newMessage, shows: mediums };
      await postData(`${url}/admin/ceomessage/send`, data, headers);
      setRefresh(!refresh);
      setNewMessage('');
      foc.current.focus();
      enqueueSnackbar('Message sent successfully', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Failed to send message', { variant: 'error' });
      console.log(err);
    }
  };

  useEffect(() => {
    dataFetching();
  }, [refresh]);

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const pushMedium = (medium) => {
    setMediums((prevMediums) => [...prevMediums, medium]);
  };

  const pullMedium = (medium) => {
    setMediums((prevMediums) => prevMediums.filter((m) => m !== medium));
  };

  const handleOpenModal = (id) => {
    setDeleteId(id); // Set the ID of the message to delete
    setOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpen(false); // Close the modal
  };

 const handleDelete = async () => {
  try {
    await deleteCeoMessage(`${url}/admin/ceomessage/${deleteId}`, headers);
    setRefresh(!refresh);
    handleCloseModal(); // Close the modal after deleting
    enqueueSnackbar('Message deleted successfully', { variant: 'success' });
  } catch (err) {
    enqueueSnackbar('Failed to delete message', { variant: 'error' });
  }
};

  const formatDateTime = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric', 
      hour12: true 
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  return (
    <MarketContainer style={{overflowY:"scroll"}}>
      <div className="App">
        <div className="container" style={{
                  height: "auto",
                  
                  
                }}>
          <div className="left-column" >
            <div className="upload-container">
              <h3>CEO BROADCAST MESSAGE</h3>
            </div>
            <div className="display-container scrollbar" id="style-2"> 
              <UpdateVipContainer>
                {messages.map((message) => (
                  <div key={message.id} className="message-wrapper">
                    <div className="date-box">
                      <span className="date">{formatDateTime(message.createdAt)}</span>
                    </div>
                    <div className="message-bubble">
                      <div className="message-content">
                        <p className="message-text">
                          {message.message}
                        </p>
                        <FaTrash className="delete-icon" onClick={() => handleOpenModal(message._id)} />
                      </div>
                      <div className="meta-info">
                        {message.shows.map((receiver, index) => (
                          <span key={index} className="receiver-box">{receiver}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </UpdateVipContainer>
            </div>
            <div className="checkbox-container">
              <input
                type="checkbox"
                onChange={(e) => e.target.checked ? pushMedium("vip") : pullMedium("vip")}
                className="btn-check"
                id="checkbox1"
              />
              <label className="btn btn-outline-secondary pink-btn" htmlFor="checkbox1">VIP</label>
              <input
                type="checkbox"
                onChange={(e) => e.target.checked ? pushMedium("vipFranchise") : pullMedium("vipFranchise")}
                className="btn-check"
                id="checkbox2"
              />
              <label className="btn btn-outline-secondary pink-btn" htmlFor="checkbox2">VIP FRANCHISE</label>
              <input
                type="checkbox"
                onChange={(e) => e.target.checked ? pushMedium("franchise") : pullMedium("franchise")}
                className="btn-check"
                id="checkbox3"
              />
              <label className="btn btn-outline-secondary pink-btn" htmlFor="checkbox3">FRANCHISE</label>
              <input
                type="checkbox"
                onChange={(e) => e.target.checked ? pushMedium("branchstaff") : pullMedium("branchstaff")}
                className="btn-check"
                id="checkbox4"
              />
              <label className="btn btn-outline-secondary pink-btn" htmlFor="checkbox4">BRANCH STAFF</label>
              <input
                type="checkbox"
                onChange={(e) => e.target.checked ? pushMedium("hostaff") : pullMedium("hostaff")}
                className="btn-check"
                id="checkbox5"
              />
              <label className="btn btn-outline-secondary pink-btn" htmlFor="checkbox5">HO STAFF</label>
            </div>
            <div className="upload-container">
              <div className="message-input-container">
                <textarea
                  ref={foc}
                  className="message-input"
                  placeholder="Type your message..."
                  value={newMessage}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault(); // Prevents a newline from being added
                      handleSend();
                    }
                  }}
                ></textarea>
                <button className="send-button" onClick={handleSend}>Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Delete Confirmation */}
      <Dialog
        open={open}
        onClose={handleCloseModal}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this message?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </MarketContainer>
  );
};

const MarketContainer = styled.div`
  width: 100%; /* Ensure the container takes the full width of the page */
  margin: 0 auto; /* Center the container horizontally */

height:100vh;
  .container {
    width: 100%; /* Full width of the container */
    max-width: 100%; /* Remove any maximum width to ensure full width */
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr; /* Single column to take full width */
    gap: 20px;
    margin-top: 50px;
  }

  .display-container {
    overflow-y: auto;
    height: 500px;
    padding: 20px;
    margin-bottom: 20px;
    width: 100%; /* Ensure this container takes full width */
    background-color: #1d1e22;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  }

  .left-column {
    display: flex;
    flex-direction: column;
    width: 100%; /* Ensure the column takes full width */
  }

  .upload-container,
  .checkbox-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    width: 100%; /* Ensure these sections take full width */
    background-color: #1d1e22;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .checkbox-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .checkbox-container label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .checkbox-container label:last-child {
    margin-right: 0;
  }

  .message-input-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .message-input {
    flex-grow: 1;
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #333;
    background-color: #2d2d2d;
    color: white;
  }

  .send-button {
    padding: 10px 20px;
    background-color: #c94295;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .send-button:hover {
    background-color: #a83275;
  }

  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #1e1e24;
    border-radius: 10px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const UpdateVipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .message-wrapper {
    display: flex;
    flex-direction: column;
    position: relative; /* Ensure child elements can be positioned relative to this container */
  }

  .date-box {
    padding: 5px 10px;
    border: 2px solid #ccc;
    background-color: #e0e0e0;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10px;
    width: auto;
    align-self: center;
  }

  .date {
    margin: 0;
    color: #333;
    font-size: 14px;
  }

  .message-bubble {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    background-color: #c94295;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
    border: 2px solid #a83275;
    color: white;
    position: relative; /* Allows positioning of the delete icon */
  }

  .message-content {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
  }

  .message-text {
    flex-grow: 1;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .meta-info {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 5px;
    font-size: 12px;
  }

  .receiver-box {
    background-color: #c94295;
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px 10px;
    color: white;
  }

  .delete-icon {
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s ease;
    position: absolute; /* Allows the icon to be placed in the bottom right corner */
    bottom: 10px; /* Spacing from the bottom */
    right: 10px; /* Spacing from the right */
  }

  .delete-icon:hover {
    color: #a83275;
  }
`;

export default CEOmessage;
