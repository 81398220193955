import "./App.css";
import { Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import ReferedUsers from "./pages/ReferedUser"
import LoginPage from "./pages/LoginPage";
import { useGlobalContext } from "./assets/contex/GlobalContext";
import DashBoardPage from "./pages/DashBoardPage";
import ConceptPage from "./pages/ConceptPage";
import CoursePage from "./pages/CoursePage";
import BranchesPage from "./pages/BranchesPage";
import HosDetailContainer from "./pages/secondaryContainersForPages/HosDetailContainer";
import AllUserProfilePage from "./pages/AllUserProfilePage";
import StudentsDetailsPage from "./pages/StudentsDetailsPage";
import VipDetailPage from "./pages/VipDetailPage";
import VipFranchisePage from "./pages/VipFranchisePage";
import FranchisePage from "./pages/FranchisePage";
import ReferUsers from "./pages/ReferUsers";
import BranchStaffDetails from "./pages/BranchStaffDetails"
import Accounts from "./pages/Accounts";
import AutoVipLoginPortal from "./pages/AutoVipLoginPage";
import VipDetailsUpdatePortal from "./pages/VipDetailsUpdatePortal";
import TemplatePagesWaitForApproval from "./pages/miniComponents/templatePages/TemplatePages";
import VipVideoPortal from "./pages/VipVideoPortal";
import Ceo from "./pages/ceoContainer";
import CeoUserContainer from "./pages/ceo.user.container";
import ForgotPassword from "./pages/forgotPassword";
import Crm from "./pages/Crm";
import Crmlead from "./pages/CrmLead";
import Attandance from "./pages/Attantance";
import MediaUploads from "./pages/MediaUploads"
import MediaUpdates from "./pages/MediaUpdates"
import MarketingUpdates from "./pages/MarkettingUpdateSec"
import ChangePasswords from "./pages/changepasswordforforgot";
import VipRevenue from "./pages/VipRevenue";
import PincodeRefferal from "./pages/miniComponents/VipFranchiseTables/PincodeRefferals";
import PincodeRevenueSection from "./pages/miniComponents/VipFranchiseTables/PincodeRevenue";
import UserProfile from "./pages/miniComponents/userProfileView/UserProfile";
import Settings from "./pages/Settings";
import MediaForAdmin from "./pages/MediaUpadesForAdmin";
import Media from "./pages/MediaUpdates";
import CompanyBusiness from "./pages/CompanyBusiness";
import StudentProfille from "./pages/secondaryContainersForPages/StudentuserProfile.jsx"
import CrmAddlead from "./pages/CrmAddLead"

function App() {
  const { userRole, profileData } = useGlobalContext();
  const [newVipData, setNewVipData] = useState([]);
  
  if (userRole == null) {
    return (
      <div className="App">
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgot" element={<ForgotPassword/>}></Route>
          <Route
            path="/welcome"
            element={<AutoVipLoginPortal setNewVipData={setNewVipData} />}
          />
          <Route
            path="/vipupdatedetail"
            element={<VipDetailsUpdatePortal newVipData={newVipData} setNewVipData={setNewVipData} />}
          />
          <Route path="/waitpage" element={<TemplatePagesWaitForApproval />} />
          /* These routes are defining different paths in the application and rendering specific
          components when those paths are accessed. */
         
        </Routes>
      </div>
    );
  } else {
    if (userRole == "admin") {
      return (
        <div className="App">
          <Routes>
          
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<DashBoardPage />} />
            <Route path="/dashboard" element={<DashBoardPage />} />
            <Route path="*" element={<DashBoardPage />} />
            <Route path="/conceptdetails" element={<ConceptPage />} />
            <Route
              path="/coursedetails"
              element={<CoursePage selected={"coursedetails"} />}
            />
            <Route
              path="/branchdetails"
              element={<BranchesPage selected={"branchdetails"} />}
            />
            <Route path="/hosstaff" element={<HosDetailContainer />} />
            <Route path="/profile" element={<AllUserProfilePage />} />
            <Route path="/vipdetails" element={<VipDetailPage />} />
            <Route path="/vipfranchisedetails" element={<VipFranchisePage />} />
            <Route path="/franchisedetails" element={<FranchisePage />} />
            <Route path="/studentsdetails" element={<StudentsDetailsPage />} />
            <Route path="/accounts" element={<Accounts />} />
            
            <Route path="/crm" element={<Crm />} />
            <Route path="/crmleadprofile/:id" element={<Crmlead />} />
            <Route path="/message" element={<Ceo />} />
            <Route path="/changePassword" element={<ChangePasswords />} />
            <Route path="/hosattendance" element={<Attandance />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/mediaupdates" element={<MediaForAdmin/>} />
            <Route path="/mediaupload" element={<MediaUploads />} />
            <Route path="/studentprofile" element={<StudentProfille />} />

                        {/* 
             <Route path="/vipdetails" element={<VipDetailsC />} />
            <Route
              path="/vipfranchisedetails"
              element={<VipFranchiseDetails />}
            />

            <Route path="/franchisedetails" element={<FranchiseDetails />} />
            <Route path="/studentsdetails" element={<StudentsDetails />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/media" element={<MediaUploads />} />
            <Route path="/viewvipprofile" element={<VipFullProfile />} /> */}
          </Routes>
        </div>
      );
    } else if (userRole == "hos") {
      return (
        <div className="App">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<DashBoardPage />} />
            <Route path="/dashboard" element={<DashBoardPage />} />
            <Route path="*" element={<DashBoardPage />} />
            <Route
              path="/courselist"
              element={<CoursePage selected={"courselist"} />}
            />
            <Route
              path="/branches"
              element={<BranchesPage selected={"branches"} />}
            />
             <Route path="/message" element={<Ceo />} />
            <Route path="/profile" element={<AllUserProfilePage />} />
            <Route path="/vipdetails" element={<VipDetailPage />} />
            <Route path="/vipfranchisedetails" element={<VipFranchisePage />} />
            <Route path="/franchisedetails" element={<FranchisePage />} />
            <Route path="/studentsdetails" element={<StudentsDetailsPage />} />
            <Route path="/changePassword" element={<ChangePasswords />} />
            <Route path="/crm" element={<Crm />} />
          
            <Route path="/crmleadprofile" element={<Crmlead />} />
            <Route path="/userprofileview" element={<UserProfile />} />
            <Route path="/mediaupdates" element={<MediaForAdmin/>} />
            <Route path="/mediaupload" element={<MediaUploads />} />
            <Route path="/studentprofile" element={<StudentProfille />} />
            <Route path="/accounts" element={<Accounts />} />
          </Routes>
        </div>
      );
    } else if (userRole == "vip") {
      if (profileData.profileData.verified) {
        return (
          <div className="App">
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="refereduser" element={<ReferedUsers/>}/>
              <Route path="/" element={<DashBoardPage />} />
              <Route path="/dashboard" element={<DashBoardPage />} />
              <Route path="/changePassword" element={<ChangePasswords />} />
              <Route path="/companybusiness" element={<CompanyBusiness />} />
              <Route path="*" element={<DashBoardPage />} />
              <Route
                path="/courselist"
                element={<CoursePage selected={"courselist"} />}
              />
              <Route
                path="/branches"
                element={<BranchesPage selected={"branches"} />}
              />
              <Route
                path="/studentsdetails"
                element={<StudentsDetailsPage />}
              />
              <Route path="/vipdetails" element={<VipDetailPage />} />
              <Route path="/referuser" element={<ReferUsers />} />
              <Route path="/marketingupdates" element={<MarketingUpdates />} />
              <Route path="/mediaupdates" element={<Media />} />
              <Route path="/crmleadprofile/:id" element={<Crmlead />} />
              <Route path="/message" element={<CeoUserContainer />} />
              <Route path="/crm" element={<Crm />} />
              <Route path="/viprevenue" element={<VipRevenue />} />
              <Route path="/userprofileview" element={<UserProfile />} />
             
              {/* <Route path="/marketingupdates" element={<MarketingUpdates/>}/> */}

              {/* 
              <Route path="/studentsdetails" element={<StudentsDetails />} />
              <Route path="/vipdetails" element={<VipDetails />} />
              <Route path="/franchisedetails" element={<FranchiseDetails />} />
              <Route
                path="/vipfranchisedetails"
                element={<VipFranchiseDetails />}
              />
              <Route path="/referuser" element={<RefereUser />} />
              <Route path="/mediaupdates" element={<MediaUpdates />} />
              <Route path="/marketingupdates" element={<MarketingUpdates />} />
              <Route path="/myrevenue" element={<MyRevenue />} /> */}
            </Routes>
          </div>
        );
      } else {
        return (
          <div className="App">
            <Routes>

              <Route path="/dashboard" element={<VipVideoPortal />} />
            </Routes>
          </div>
        );
      }
    } else if (userRole == "vipFranchise") {
      return (
        <div className="App">
          <Routes>
            <Route path="/crmleadprofile" element={<Crmlead />} />
            <Route path="/changePassword" element={<ChangePasswords />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<DashBoardPage />} />
            <Route path="/dashboard" element={<DashBoardPage />} />
            <Route path="*" element={<DashBoardPage />} />
            <Route
              path="/courselist"
              element={<CoursePage selected={"courselist"} />}
            />
            <Route path="/mediaupdates" element={<Media />} />
            <Route path="/marketingupdates" element={<MarketingUpdates />} />
            <Route path="/pincoderefferals" element={<PincodeRefferal />} />
            <Route path="/pincoderevenue" element={<PincodeRevenueSection />} />
            <Route
              path="/branches"
              element={<BranchesPage selected={"branches"} />}
            />
            <Route path="/userprofileview" element={<UserProfile />} />
            {/* 
            <Route path="/pincodereferrals" element={<PinCodeReference />} />
            <Route path="/pincoderevenue" element={<PincodeRevenue />} />
            <Route path="/mediaupdates" element={<MediaUpdates />} />
            <Route path="/marketingupdates" element={<MarketingUpdates />} />
            <Route path="/message" element={<Message />} />
            <Route path="/crm" element={<Message />} /> */}
          </Routes>
        </div>
      );
    } else if (userRole == "franchise") {
      return (
        <div className="App">
          <Routes>
            
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<DashBoardPage />} />
            <Route path="/dashboard" element={<DashBoardPage />} />
            <Route path="/changePassword" element={<ChangePasswords />} />
            <Route path="*" element={<DashBoardPage />} />
            <Route
              path="/courselist"
              element={<CoursePage selected={"courselist"} />}
            />
            <Route
              path="/branches"
              element={<BranchesPage selected={"branches"} />}
            />
              <Route path="/studentprofile" element={<StudentProfille />} />
            <Route path="/mediaupdates" element={<Media/>} />
            <Route path="/branchstaff" element={<BranchStaffDetails />} />
            <Route path="/profile" element={<AllUserProfilePage />} />
            <Route path="/studentsdetails" element={<StudentsDetailsPage />} />
            <Route path="/marketingupdates" element={<MarketingUpdates />} />
            <Route path="/crmleadprofile/:id" element={<Crmlead />} />
            <Route path="/crm" element={<Crm />} />
            <Route path="/crmlead" element={<CrmAddlead />} />
            <Route path="/userprofileview" element={<UserProfile />} />

            {/* <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/courselist"
              element={<CourseList selected={"courselist"} />}
            />
            <Route
              path="/branches"
              element={<Branches selected={"branches"} />}
            />
            <Route path="/branchstaff" element={<BranchStaff />} />
            <Route path="/addstudent" element={<AddStudent />} />
            <Route path="/studentreference" element={<ReferedStudent />} />
            <Route path="/studentadmission" element={<AdmissionStudents />} />
            <Route path="/studentcompleted" element={<StudentsCompleted />} />
            <Route path="/practiceupdate" element={<Practice />} />
            <Route path="/certification" element={<CertificationPhase />} />
            <Route path="/mediaupdates" element={<MediaUpdates />} />
            <Route path="/marketingupdates" element={<MarketingUpdates />} />
            <Route path="/message" element={<StudentsCompleted />} />
            <Route path="*" element={<Dashboard />} /> */}
          </Routes>
        </div>
      );
    } else if (userRole == "branchstaff") {
      return (
        <div className="App">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<DashBoardPage />} />
            <Route path="/dashboard" element={<DashBoardPage />} />
            <Route path="/changePassword" element={<ChangePasswords />} />
            <Route path="*" element={<DashBoardPage />} />
            <Route path="/crmleadprofile/:id" element={<Crmlead />} />
            <Route
              path="/courselist"
              element={<CoursePage selected={"courselist"} />}
            />
             <Route path="/studentprofile" element={<StudentProfille />} />
            <Route path="/crm" element={<Crm />} />
            <Route path="/mediaupdates" element={<Media/>} />
            <Route path="/marketingupdates" element={<MarketingUpdates />} />
            <Route
              path="/branches"
              element={<BranchesPage selected={"branches"} />}
            />
            <Route path="/userprofileview" element={<UserProfile />} />
              <Route path="/studentsdetails" element={<StudentsDetailsPage/>}/>
              <Route path="/crm" element={<Crm />} />
              <Route path="/crmleadprofile" element={<Crmlead />} />
           
            {/* <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/courselist"
              element={<CourseList selected={"courselist"} />}
            />
            <Route path="*" element={<Dashboard />} /> */}
          </Routes>
        </div>
      );
    }
  }
}

export default App;
