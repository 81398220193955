import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { fetchData, postData } from "../../../Api";
import {URL} from "../../../assets/mocData/config";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem, 
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f00d88", // Pink color
    },
    text: {
      primary: "#ffffff", // White color for input text
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: "#ffffff", // White color for input text
          backgroundColor: "black", // Background color for filled input
          "&:before": {
            borderColor: "white", // Initial border color white
          },
          "&:hover:before": {
            borderColor: "#f00d88", // Border color on hover
          },
          "&.Mui-focused:before": {
            borderColor: "#f00d88", // Border color when focused
          },
          "&.Mui-focused": {
            backgroundColor: "black", // Background color when focused
          },
        },
        input: {
          color: "#ffffff", // White color for input text
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "gray", // Gray color for label
          "&.Mui-focused": {
            color: "#f00d88", // Pink color for label when focused
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "white", // White color for the date picker icon
        },
      },
    },
  },
});


function AddBranch() {
  const { enqueueSnackbar } = useSnackbar();
  let { userToken, userRole } = useGlobalContext();
  
  const [data, setData] = useState({
    addressName: "",
    district: "",
    location: "",
    area: "",
    pincode: "",
    branchCode: "",
    branchName: "",
    concept: [],
    status: "",
    branchOwnerEmail: ""
  });
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedConcepts, setSelectedConcepts] = useState([]);
  const [error, setError] = useState("");

  const handleCheckboxChange = (event) => {
    const concept = event.target.value;
    if (event.target.checked) {
      setSelectedConcepts([...selectedConcepts, concept]); // Add to selected concepts
    } else {
      setSelectedConcepts(
        selectedConcepts.filter((c) => c !== concept) // Remove from selected concepts
      );
    }
  };

  
  const [selectedValue, setSelectedValue] = useState("a");
  const [concept, setConcept] = useState([]);

  // Fetch available concepts
  useEffect(() => {
    (async () => {
      let header = {
        userRole: "admin",
        userToken: userToken,
      };
      try {
        let response = await fetchData(`${URL}/admin/concept`, header);
        console.log("Fetched concepts:", response); // Log fetched concepts
        setConcept(response);
      } catch (error) {
        console.error("Error fetching concepts:", error);
        setConcept([]);
      }
    })();
  }, []);
  // Handle input change
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Control radio button checked state
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "status",
    inputProps: { "aria-label": item },
  });

  // Handle form field change
  const FormData = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    
    if (name === "concept") {
      let totalConcept = [...data.concept];
      if (data.concept.includes(value)) {
        totalConcept = totalConcept.filter(concept => concept !== value);
      } else {
        totalConcept.push(value);
      }
      setData({
        ...data,
        concept: totalConcept,
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateCourseForm(data)) {
      let headers = {
        userRole: userRole,
        userToken: userToken,
      };

      try {
        let response = await postData(`${URL}/admin/branch`, data, headers);
        enqueueSnackbar("Branch added successfully!", {
          autoHideDuration: 1000,
          variant: "success",
        });
        // Reset form after successful submission
        handleCancel();
        setRefreshKey(prevKey => prevKey + 1);
      } catch (error) {
        // Handle E11000 error for duplicate keys
        if (error.response && error.response.data.error) {
          const errorMessage = error.response.data.error;
    
          if (errorMessage.includes("E11000")) {
            if (errorMessage.includes("branchCode")) {
              enqueueSnackbar("Branch Code already exists.", { variant: "warning" });
            } else if (errorMessage.includes("branchName")) {
              enqueueSnackbar("Branch name already exists.", { variant: "warning" });
            }
            else if (errorMessage.includes("branchOwnerEmail")) {
              enqueueSnackbar("Email already exists.", { variant: "warning" });
            } else {
              enqueueSnackbar("Duplicate data entry error.", { variant: "warning" });
            }
          } else {
            enqueueSnackbar(`${errorMessage}`, { variant: "warning" });
          }
        } else {
          enqueueSnackbar("Something went wrong. Please try again later.", {
            variant: "error",
          });
        }
    }
  }
  };

  // Validate form fields
  const validateCourseForm = (BranchData) => {
    const {
      area,
      district,
      location,
      addressName,
      pincode,
      branchCode,
      branchName,
      concept,
      status,
      branchOwnerEmail
    } = BranchData;
    if (selectedConcepts.length === 0) {
      enqueueSnackbar("Please select a concept.", { autoHideDuration: 1000, variant: "warning" });
      return false;
    }
    // Check for required fields
    if (branchCode.trim() === "") {
      enqueueSnackbar("Please enter a Branch Code.", { autoHideDuration: 1000, variant: "warning" });
      return false;
    }
    if (branchName.trim() === "") {
      enqueueSnackbar("Please enter a Branch Name.", { autoHideDuration: 1000, variant: "warning" });
      return false;
    }
    if (branchOwnerEmail.trim() === "") {
      enqueueSnackbar("Please enter a Branch Owner Email.", { autoHideDuration: 1000, variant: "warning" });
      return false;
    }
    if (addressName.trim() === "") {
      enqueueSnackbar("Please enter an Address Name.", { autoHideDuration: 1000, variant: "warning" });
      return false;
    }
    if (status.trim() === "") {
      enqueueSnackbar("Please select a Status.", { autoHideDuration: 1000, variant: "warning" });
      return false;
    }
    
    // If all validations pass
    return true;
  };

  // Reset form fields
  const handleCancel = () => {
    setData({
      area: "",
      district: "",
      location: "",
      addressName: "",
      pincode: "",
      branchCode: "",
      branchName: "",
      concept: [],
      status: "",
      branchOwnerEmail: ""
    });
    setSelectedValue("");
    setRefreshKey(prevKey => prevKey + 1); // Reset the status radio button
  };


  return (
    <ThemeProvider theme={theme}>
    <AddBranchContainer>
      <div class="d-flexx">
        <div id="add-student-container">
          {/* <h2 style={{textAlign: "left"}}>Add Course</h2> */}
          <form onChange={FormData}>
            <div class="container">
              <div class="row">
                <div class="col col-12">
                  <div id="form-container" style={{width:"55vw"}} class="m-1">
                    <form
                      class="scroll"
                      style={{
                        height: "75vh",
                        overflowY: "scroll",
                        paddingTop: "1rem",
                        paddingBottom: "3rem",
                      }}
                      action=""
                    >
                      <div class="container">
                        <div class="row">
                          <hr />
                          <span class="col-3 inputHeadings">Branch Code</span>
                          <div class="col-9 d-flex flex-column px-3">
                            <span
                              style={{ width: "100%" }}
                              class="d-flex justify-content-between"
                            >
                            <TextField
                                  value={data.branchCode}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                name="branchCode"
                               
                                type="text"
                                id="filled-basic"
                                variant="filled"
                                label="Enter Branch Code"
                                />
                              
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="container">
                        <div class="row">
                          
                          <span class="col-3 inputHeadings">Branch Owner Email</span>
                          <div class="col-9 d-flex flex-column px-3">
                            <span
                              style={{ width: "100%" }}
                              class="d-flex justify-content-between"
                            >
                                <TextField
                                  value={data.branchOwnerEmail}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                name="branchOwnerEmail"
                               
                                type="text"
                                 id="filled-basic"
                          variant="filled"
                                label="Enter Branch Owner Email"
                                />
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="container">
                        <div class="row">
                        <span class="col-3 inputHeadings">Branch Name</span>
                          <div class="col-9 d-flex flex-column px-3">
                            <span
                              style={{ width: "100%" }}
                              class="d-flex justify-content-between"
                            >
                              
                              <TextField
                                style={{
                                  width: "100%",
                                  border: "1px solid black",
                                }}
                                
                                name="branchName"
                                value={data.branchName}
                                type="text"
                               
                                 id="filled-basic"
                          variant="filled"
                                label="Enter Branch Name"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <br />
                      <div class="container" key={refreshKey}>
                        <div class="row">
                        <span class="col-3 inputHeadings">Concepts</span>
                          <div class="col-9 d-flex flex-column px-3">
                            
                            <span
                              style={{ width: "100%" }}
                              class="d-flex justify-content-between"
                            >
                              <div className="checkbox-container">
                              {concept?.length > 0 ? (
  concept.map((c, i) => (
    <div key={i}>
      <input
        type="checkbox"
        name="concept"
        value={c.conceptName}
        className="btn-check"
        id={`checkbox${i}`}
        onChange={handleCheckboxChange}
      />
      <label
        className="btn btn-outline-secondary pink-btn"
        htmlFor={`checkbox${i}`}
      >
        {c.conceptName.toUpperCase()}
      </label>
    </div>
  ))
) : (
  <p>No concepts available.</p> // Fallback message if no concepts are found
)}

                                {/* Add similar checkbox elements for other checkboxes */}
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div class="container">
                        <div class="row">
                        <span class="col-3 inputHeadings">Address</span>
                          <div class="col-9 d-flex flex-column px-3">
                            <span
                              style={{ width: "100%" }}
                              class="d-flex justify-content-between"
                            >
                              <TextField

                                value={data.addressName}
                                style={{
                                  width: "100%",
                                  border: "1px solid black",
                                }}
                                name="addressName"
                            
                                type="text"
                                 id="filled-basic"
                          variant="filled"
                                label="Enter Address"
                              />
                            </span>
                            <span
                              style={{ width: "100%", marginTop: "1rem" }}
                              class="d-flex justify-content-between"
                            >
                              <TextField
                                style={{
                                  width: "100%",
                                  border: "1px solid black",
                                }}
                                name="area"
                                value={data.area}
                           
                                type="text"
                                id="filled-basic"
                          variant="filled"
                                label="Enter Area"
                              />
                            </span>
                            <span
                              style={{ width: "100%", marginTop: "1rem" }}
                              class="d-flex justify-content-between"
                            >
                              <TextField
                                style={{
                                  width: "100%",
                                  border: "1px solid black",
                                }}
                                name="district"
                                value={data.district}
                                type="text"
                                id="filled-basic"
                          variant="filled"
                                label="Enter District"
                              />
                            </span>
                            <span
                              style={{ width: "100%", marginTop: "1rem" }}
                              class="d-flex justify-content-between"
                            >
                              <TextField
                                style={{
                                  width: "100%",
                                  border: "1px solid black",
                                }}
                                name="pincode"
                                value={data.pincode}
                                type="number"
                                 id="filled-basic"
                                 variant="filled"
                                label="Enter Pincode"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="container">
                        <div class="row">
                        <span class="col-3 inputHeadings">Location</span>
                          <div class="col-9 d-flex flex-column px-3">
                            <span
                              style={{ width: "100%" }}
                              class="d-flex justify-content-between"
                            >
                              <TextField
                                style={{
                                  width: "100%",
                                  border: "1px solid black",
                                }}
                                name="location"
                                value={data.location}
                                type="text"
                                id="filled-basic"
                          variant="filled"
                                label="Enter Location Link"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <hr />
                      {/* <!-- Status Section --> */}
                      <div class="container status-section">
                          <div class="row align-items-center">
                            <span class="col-3 inputHeadings">Status</span>
                            <div class="col-9 d-flex flex-column px-3">
                              <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">
                                  Status
                                </FormLabel>
                                <RadioGroup
                                  className="d-sm-flex flex-sm-column flex-md-row"
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  value={selectedValue}
                                >
                                  <FormControlLabel
                                    name="status"
                                    value="active"
                                    control={
                                      <Radio
                                        name="status"
                                        {...controlProps("active")}
                                        color="success"
                                      />
                                    }
                                    label="Active"
                                  />
                                  <FormControlLabel
                                    name="status"
                                    value="inactive"
                                    control={
                                      <Radio
                                        name="status"
                                        {...controlProps("inactive")}
                                      />
                                    }
                                    label="In Active"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div class="container mt-3">
                        <div class="row justify-content-center">
                          <div class="col-12 d-flex justify-content-center">
                            <div class="d-flex flex-column flex-sm-row align-items-center">
                            <button
                              type="submit"
                              onClick={(e) => handleSubmit(e)}
                              class="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-course-btn"
                            >
                              Add Branch
                            </button>
                            <button
                              type="button"
                              onClick={handleCancel}
                               class="btn btn-outline-danger btn-sm cancel-btn"
                            >
                              Cancel
                            </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </AddBranchContainer>
    </ThemeProvider>
  );
}
const AddBranchContainer = styled.div`
  input::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }

margin-top:2rem;

  h2,
  p,
  span,
  h6 {
    color: white;
  }
  .add-course-btn:hover {
    background-color: green;
  }
  .cancel-btn:hover {
    background-color: red;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }

  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }

  h5 {
    text-align: center;
    color: white;
  }

  input {
    color: white;
  }

  /* Status Section Styles */
  .status-section {
    margin-top: 1rem;
  }

  .status-label {
    color: white;
  }

  .status-options {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .active-dot {
    background-color: green;
  }

  .inactive-dot {
    background-color: red;
  }

  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #add-student-container {
      padding: 1rem;
    }

    #profile-container {
      margin-top: 1rem;
    }
       
  }
  
  /* Style for active radio button */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }
  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }
  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }
  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  .add-course-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }
 @media (max-width: 575px) {
    .add-course-btn,
    .cancel-btn {
      align-items: center;
      width: 100%; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
    }
}



  #check-box {
    position: absolute;
  }

  #check-box .checkbox-bx {
    position: absolute;
    right: 5px;
    top: 2px;
  }
  #check-box .checkbox-bx label {
    position: relative;
    height: 20px;
    width: 20px;
  }
  #check-box .checkbox-bx input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }

  #check-box .checkbox-bx label span {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
    cursor: pointer;
    background-color: #25272d;
  }

  #check-box .checkbox-bx label span::after {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 8px;
    width: 9px;
    height: 18px;
    border: solid #00ff0a;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: width 0.5s, height 0.5sec, opacity 0.5sec;
  }

  #check-box .checkbox-bx input:checked ~ span::after {
    width: 10px;
    height: 20px;
    opacity: 1;
  }

  .pink-btn {
    border-color: rgb(201, 66, 149); /* Change the outline color to pink */
    color: rgb(201, 66, 149); /* Change text color to ensure visibility */
  }

  .checkbox-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #1d1e22;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to individual boxes within containers */
  }

  .checkbox-container {
    display: flex; /* Display checkboxes horizontally */
    justify-content: flex-start; /* Align checkboxes to the start */
    flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
    margin-bottom: 20px; /* Add margin at the bottom */
  }

  .checkbox-container label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px; /* Add margin at the bottom of each checkbox */
  }

  .checkbox-container label:last-child {
    margin-right: 0; /* Remove margin for the last checkbox */
  }

  .checkbox-container label input {
    display: none;
  }
`;
export default AddBranch;
