import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { Box, Typography, Button } from "@mui/material";

function RedirectPage() {
  const navigate = useNavigate();
  
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(-1);
    }, 5000); // Redirect after 5 seconds

    return () => clearTimeout(timer); // Cleanup timer
  }, [navigate]);

  return (
    <Wrapper>
      <TopSection>
        <ErrorCodeDiv>Welcome To TIA</ErrorCodeDiv>
      </TopSection>
      <BottomSection>
        <Message variant="h3">"Good Things Take A Moment. Redirecting To The Right Page!"</Message>
        <SubMessage variant="h6">
        You will be redirected to the welcome
        page shortly.
        </SubMessage>
        <StyledButton
  onClick={() => navigate(-1)}
  sx={{
    backgroundColor: '#f00d88',
    '&:hover': {
      backgroundColor: '#ff69b4',
    },
    color: 'white',
    borderRadius: '8px',
    padding: '0.75em 1.5em',
    textTransform: 'none',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  }}
>
  Go Back Home
</StyledButton>
      </BottomSection>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  background-color: var(--background);
`;

const TopSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; /* Align to the bottom */
  flex-basis: 40%;
  background-color: var(--background);
  text-align: center;
  width: 100%;
  padding-bottom: 2rem; /* Add space between the error code and the bottom of the section */
`;

const ErrorCodeDiv = styled.div`
  font-size: 5vw; /* Very large size that scales with viewport */
  color: #f00d88;
  line-height: 1; /* Tighter line height to avoid extra spacing */
  margin-bottom: 18px; /* Avoid margin pushing it away from the bottom */
  text-align: center;
  width: 100%;
`;

const BottomSection = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 60%;
  background-color:  var(--navbar-dark-primary);
  width: 100%;
  gap: 1rem;
  padding: 3rem;
  margin-top: -3em; /* Pulling the bottom section upwards */
`;

const Message = styled(Typography)`
  font-size: 4rem;
  font-weight: bold;
  color: white;
  text-align: center;
  margin-bottom: 0; 
  width: 90%;
  max-width: 1000px;

    @media (max-width: 768px) {
    font-size: 2.5rem; /* Smaller font size */
  }
`;

const SubMessage = styled(Typography)`
  font-size: 2.5rem;
  color: white;
  text-align: center;
  width: 90%;
  max-width: 600px;
  margin-bottom: 50px; 
   /* Media query for smaller devices */
  @media (max-width: 768px) {
    font-size: 1.5rem; /* Smaller font size */
  }
`;

const StyledButton = styled(Button)`
  margin-top: 1.5em;
  color: white;
  background-color: #f00d88; /* Pink color */
  &:hover {
    background-color: #ff1493; /* Darker pink on hover */
  }
  border-radius: 8px; /* Rounded corners */
  padding: 0.75em 1.5em; /* Vertical and horizontal padding */
  text-transform: none; /* Disable uppercase transformation */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
`;


export default RedirectPage;
