import React,{useEffect,useState} from "react";
import {styled} from 'styled-components'
import axios from'axios';
import {enqueueSnackbar} from 'notistack';
import { Box, Tabs, Tab } from "@mui/material";
import TopContainer from '../miniComponents/subcomponents/TopContainer';
import CrmUserView from '../miniComponents/Crm/CrmUserVIew';
function CrmUserContainer({ user, courseListTitles }) {
    const[filter,setFilter]=useState(false);
    const [filterData, setFilterData] = useState("");
    const handleChange = (event, newValue) => {
      setSelectedView(newValue);
    };
    const [selectedView,setSelectedView]=useState('FranchiseView');
    console.log(selectedView);
  const FilterOpen=()=>{  
    filter?setFilter(false):setFilter(true)
  }
  console.log(user);
  return(
    <CrmTableContainer>
        <div id="course-list" style={{height:"100vh",overflowY:"scroll"}}>
            <TopContainer
            heading={"CRM"}
            filter={filter}
            showFields={{ search: true, filter: true }}
            setFilterData={setFilterData}
            FilterOpen={FilterOpen}/>
            
            <div>
              <CrmUserView  filterData={filterData} setFilterData={setFilterData}/>
            </div>
          </div>
    
    </CrmTableContainer>
  );
}
const CrmTableContainer=styled.div`
    width: auto;
   
    
#course-list{
    width:78vw;
    padding: 2rem;

}

::placeholder {
        color: gray;
        opacity: 1; /* Firefox */
      }
      ::-ms-input-placeholder { /* Edge 12-18 */
  color: gray;
}
.btn{
        background-color: var(--navbar-dark-primary);
        padding: 1rem;
        color: white;
        font-weight: 600;
        display: flex;
        flex-direction: column;
    }
    #line{
        background-color: var(--icon-color);
        height: 3px;
        width: 100%;
    }
h2{
    color:white;
}
@media only screen and (max-width: 1000px) {
#course-list{
    width:100vw;
    padding: 2rem;

}
width:auto;
  #miniNav{
        display: block !important;
    }
h2{
    margin-left: 3rem;
}
}
#miniNav{
    display: none ;
}
#searchfield{
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
}

#searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height:20px;
    width: 10rem;
}

textarea:focus, input:focus{
    outline: none;
}
::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }
  
  ::-ms-input-placeholder { /* Edge 12 -18 */
    color: #bf2f82c4;
  }

.font{
    
    border: 0px solid;
    color: #bf2f82c4;
}

.icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
}

.icon{
    color: #bf2f82c4;
    border-radius: 5rem;
}



#table-container{
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 83vh;
}
 
#table-container thead th { 
    position: sticky; 
    top: 0; 
    
}

.t-head{
    background-color: #18171b !important;
    color:white !important;
    padding: 1rem !important;
    text-align: center;
}
h4{
    text-align: center;
}

table.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }
  
  table.rounded-corners th, table.rounded-corners td {
    border: 1px solid black;
  }



thead tr {
    border-bottom: 1rem solid #25272d;
  }
tr{
    border-color: #25272d ;
}

.table > :not(caption) > * > *{
    background-color:  #25272d;
}
`


export default CrmUserContainer
  