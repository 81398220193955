import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { URL as url } from "../../../assets/mocData/config";
import { enqueueSnackbar } from "notistack";
import { format, parseISO } from "date-fns";
import { deleteData, fetchData, postData } from "../../../Api";
import { Icon } from "react-icons-kit";
import { ic_search } from "react-icons-kit/md/ic_search";
import { isAfter, isBefore } from "date-fns"; 
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";


import {
  Pagination,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a70e6e", // Pink color
    },
    text: {
      primary: "#ffffff", // White color for input text
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "black", // Dark background color for the input
          color: "#ffffff", // White color for input text
          borderRadius: "4px", // Optional: if you want rounded corners
          "&:hover": {
            backgroundColor: "black", // Slightly lighter dark color on hover
          },
          "&.Mui-focused": {
            backgroundColor: "black", // Ensure the background stays dark when focused
          },
        },
        input: {
          color: "#ffffff", // White color for input text
        },
        underline: {
          "&:before": {
            borderBottom:"1px solid #ffffff", // Initial border color
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "1px solid #a70e6e", // Border color on hover
          },
          "&:after": {
            borderBottom: "2px solid #a70e6e", // Border color when focused
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "gray", // White color for label
          "&.Mui-focused": {
            color: "#a70e6e", // Pink color for label when focused
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#25272d", // Dark background color for dropdown options
          color: "#ffffff", // White text color for dropdown options
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#ffffff", // White color for the dropdown icon
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#a70e6e", // Pink color on hover
          },
        },
      },
    },
  },
});

function CourseDiscountGenerator({ filterData, setFilterData }) {
  const [discountData, setDiscountData] = useState({
    percentage: "",
    code: "",
    description: "",
    branches: "all",
    branchName: "all",
    startDate: "",
    endDate: "",
  });
  
  const [dataFilt, setFiltData] = useState("");
  const [branches, setBranches] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const { userToken, userRole } = useGlobalContext();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  const [open, setOpen] = useState(false); // For controlling modal visibility
  const [selectedDiscountId, setSelectedDiscountId] = useState(null); 

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "branches") {
      let selectedBranch = branches.find((branch) => branch._id === value);
      if (selectedBranch) {
        setDiscountData({
          ...discountData,
          branchName: selectedBranch.branchName,
          [name]: selectedBranch.branchOwnerEmail,
        });
        return;
      }
    }
    setDiscountData({ ...discountData, [name]: value });
  };

  // Validation function to ensure all required fields are filled
  const validateDiscountData = () => {
    const { percentage, code, description, startDate, endDate } = discountData;

    if (!percentage || !code || !description || !startDate || !endDate) {
      enqueueSnackbar("All fields are required", {
        autoHideDuration: 3000,
        variant: "warning",
      });
      return false;
    }

    // Get today's date without time
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Convert startDate and endDate to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Validation: Ensure the startDate is today or later
    if (isBefore(start, today)) {
      enqueueSnackbar("Start date cannot be in the past", {
        autoHideDuration: 3000,
        variant: "warning",
      });
      return false;
    }

    // Validation: Ensure the endDate is after the startDate
    if (isBefore(end, start)) {
      enqueueSnackbar("End date must be after the start date", {
        autoHideDuration: 3000,
        variant: "warning",
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Perform validation before submitting
    if (!validateDiscountData()) {
      return;
    }

    let header = {
      userRole: userRole,
      userToken: userToken,
    };

    try {
      let data = await postData(
        `${url}/admin/courselist/discount`,
        discountData,
        header
      );

      // Add the new discount data to the list of discounts
      setDiscounts([...discounts, data.data]);

      // Show success message
      enqueueSnackbar("Discount coupon created successfully!", {
        autoHideDuration: 3000,
        variant: "success",
      });
      
      // Clear form fields after submission
      setDiscountData({
        percentage: "",
        code: "",
        description: "",
        branches: "all",
        branchName: "all",
        startDate: "",
        endDate: "",
      });

      setRefreshKey(prevKey => prevKey + 1);
    } catch (error) {
      enqueueSnackbar("Failed to add discount", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }
  };

  const handleCancel = () => {
    // Clear form and reset data
    setDiscountData({
      percentage: "",
      code: "",
      description: "",
      branches: "all",
      branchName: "all",
      startDate: "",
      endDate: "",
    });
    setRefreshKey(prevKey => prevKey + 1);
  };

  const fetchBranch = async () => {
    let headers = {
      userToken: userToken,
      userRole: userRole,
    };
    try {
      let response = await fetchData(`${url}/${userRole}/branch`, headers);
      let discountData = await fetchData(
        `${url}/admin/courselist/discount/view/admin`,
        headers
      );
      setDiscounts(discountData);
      setBranches(response);
    } catch (error) {
      enqueueSnackbar("Failed Fetching data", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchBranch();
  }, []);

  const handleClickOpen = (id) => {
    setSelectedDiscountId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteDiscount(selectedDiscountId);
    setOpen(false);
  };

  const deleteDiscount = async (id) => {
    try {
      let header = {
        userRole: userRole,
        userToken: userToken,
      };
      await deleteData(`${url}/admin/courselist/discount/${id}`, header);
      setDiscounts(discounts.filter((discount) => discount._id !== id));
      enqueueSnackbar("Discount deleted successfully!", {
        autoHideDuration: 3000,
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Failed to delete discount", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }
  };



  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Use filterData from props for filtering
  const filteredDiscounts = discounts.filter((discount) => {
    if (!filterData || filterData.trim() === "") {
      return true; // If no filter data, include all discounts
    }
    const filter = filterData.toLowerCase();
    return (
      discount.percentage.toString().includes(filter) ||
      discount.description.toLowerCase().includes(filter) ||
      discount.code.toLowerCase().includes(filter) ||
      discount.branchName.toLowerCase().includes(filter)
    );
  });

  const currentDiscounts = filteredDiscounts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const [refreshKey, setRefreshKey] = useState(0);

  return (
    <ThemeProvider theme={theme}>
      <CourseDiscountGeneratorContainer>
        <div key={refreshKey} className="d-flex flex-column flex-md-row">
          <div id="Coursediscount-container" className="w-100">
            <div className="container">
              <div className="row">
                <div id="form-container" className="m-1">
                  <form onSubmit={handleSubmit}>
                    <div className="container">
                      <div className="row">
                        <hr />
                        <div className="col-12 d-flex flex-wrap">
                          {/* Form fields for adding discount */}
                          {/* ... (No changes needed here) */}
                          {/* Your existing form fields */}
                          <TextField
                            value={discountData.percentage}
                            className="form-field"
                            name="percentage"
                            type="number"
                            label="Discount Percentage"
                            variant="filled"
                            id="filled-basic"
                            onChange={handleChange}
                          />
                          <TextField
                            value={discountData.description}
                            className="form-field"
                            name="description"
                            type="text"
                            label="Discount Description"
                            variant="filled"
                            id="filled-basic"
                            onChange={handleChange}
                          />
                          <TextField
                            value={discountData.code}
                            className="form-field"
                            name="code"
                            type="text"
                            label="Discount Code"
                            variant="filled"
                            id="filled-basic"
                            onChange={handleChange}
                          />
                          <FormControl className="form-field">
                            <InputLabel id="branch-label">
                              Select Branch
                            </InputLabel>
                            <Select
                              value={discountData.branchname}
                              labelId="branch-label"
                              name="branches"
                              label="Select Branch"
                              variant="filled"
                              id="filled-basic"
                              onChange={handleChange}
                            >
                             
                              {branches.map((branch) => (
                                <MenuItem key={branch._id} value={branch._id}>
                                  {branch.branchName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            label="Start Date"
                            variant="filled"
                            id="filled-basic"
                            type="date"
                            name="startDate"
                            value={discountData.startDate}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            className="form-field"
                          />
                          <TextField
                            label="End Date"
                            type="date"
                            name="endDate"
                            value={discountData.endDate}
                            onChange={handleChange}
                            variant="filled"
                            id="filled-basic"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            className="form-field"
                          />
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div className="container mt-3">
                      <div className="row justify-content-center">
                        <div className="col-12 d-flex justify-content-center">
                          <div className="d-flex flex-column flex-sm-row align-items-center">
                            <button
                              type="submit"
                              className="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-concept-btn"
                            >
                              Generate code
                            </button>
                            <button
                              type="button"
                              onClick={handleCancel}
                              className="btn btn-outline-danger btn-sm cancel-btn"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="Discount-table-container">
          <div id="table-container">
            <div className="scroll">
              <table className="table  table-hover">
                <thead>
                  <tr>
                    <th className="t-head rounded-start-3" scope="col">
                      S.no
                    </th>
                    <th className="t-head" scope="col">
                      Discount Percentage
                    </th>
                    <th className="t-head" scope="col">
                      Discount Description
                    </th>
                    <th className="t-head" scope="col">
                      Discount Code
                    </th>
                    <th className="t-head" scope="col">
                      Branch
                    </th>
                    <th className="t-head" scope="col">
                      Start Date
                    </th>
                    <th className="t-head" scope="col">
                      End Date
                    </th>
                    <th className="t-head rounded-end-3" scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="table-dark">
                  {currentDiscounts.map((FA, i) => (
                    <tr key={FA._id}>
                      <td>{indexOfFirstItem + i + 1}</td>
                      <td>{FA.percentage}</td>
                      <td>{FA.description}</td>
                      <td>{FA.code}</td>
                      <td>{FA.branchName}</td>
                      <td>{format(parseISO(FA.startDate), "dd-MM-yyyy")}</td>
                      <td>{format(parseISO(FA.endDate), "dd-MM-yyyy")}</td>
                      <td>
                      <span
  className="badge"
  style={{
    height: "80%",
    width: "100%",
    color: "Red",
    border: "1px solid Red",
    padding: "0.6rem",
    textAlign: "center",
    cursor: "pointer",
  }}
  onClick={() => handleClickOpen(FA._id)} // Open modal with discount ID
>
  Delete
</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-3">
          <Stack
            alignItems={"center"}
            marginTop={"0.5rem"}
            marginBottom={"1rem"}
          >
            <Paginations
              count={Math.ceil(filteredDiscounts.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              color="secondary"
            />
          </Stack>
        </div>

        <Dialog
  open={open}
  onClose={handleClose}
>
  <DialogTitle style={{color:"black"}}>Confirm Deletion</DialogTitle>
  <DialogContent>
    <DialogContentText >
      Are you sure you want to delete this discount?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose} color="primary">
      Cancel
    </Button>
    <Button onClick={handleDelete} color="secondary" autoFocus>
      Confirm
    </Button>
  </DialogActions>
</Dialog>

      
      </CourseDiscountGeneratorContainer>
    </ThemeProvider>
  );
}

const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`;
const CourseDiscountGeneratorContainer = styled.div`
  input::placeholder {
    color: gray;
    opacity: 1;
  }
  ::-ms-input-placeholder {
    color: gray;
  }
  #Coursediscount-container
    input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1); /* Invert the color to white */
  }

  #Coursediscount-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: gray !important;
    
  }

  h2,
  p,
  span,
  h6 {
    color: white;
  }

  .Coursediscount-btn:hover {
    background-color: green;
  }
  .cancel-btn:hover {
    background-color: red;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }

  .container {
    margin-top: 1rem;
  }

  input {
    color: white;
  }

  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .btn-outline-success,
  .btn-outline-danger {
    border-radius: 20px;
    font-size: 1rem;
    padding: 0.6rem 2.5rem;
    margin-right: 2rem;
  }

  .btn-outline-success:hover {
    background-color: green;
  }

  .btn-outline-danger:hover {
    background-color: red;
  }

  .t-head {
    text-align: center;
  }

  td {
    text-align: center;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 55vh;
    overflow-y: scroll;
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }

  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }


  .form-field .MuiInputBase-input::placeholder {
  color: red; /* Gray color for the placeholder text */
}
  /* Media queries for responsive design */
.form-field {
  flex: 1 1 22%;
  margin-right: 3%;
  margin-bottom: 3%;
}

@media (max-width: 768px) {
  .form-field {
    flex: 1 1 100%;
    margin-right: 0;
    margin-bottom: 20px; /* Adjust the spacing between fields */
  }
}
    
  /* Style for active radio button */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }
  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }
  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }
  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  .add-concept-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }
      @media (max-width: 575px) {
    .add-concept-btn,
    .cancel-btn {
      align-items: center;
      width: 100%; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
    }
`;

export default CourseDiscountGenerator;