import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img from '../assets/images/img-1.jpg';
import vid from '../assets/images/img-1.jpg';
import { imageURL } from "../Api";
import { URL } from "../assets/mocData/config";
import axios from 'axios';
import { useGlobalContext } from '../assets/contex/GlobalContext';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import DownloadIcon from '@mui/icons-material/Download';
import { useSnackbar } from 'notistack';  // Import useSnackbar
import { enqueueSnackbar } from "notistack";
function MediaUpdatesSection() {
  const { userRole,profileData } = useGlobalContext();
  const [selectedImages, setSelectedImages] = useState({});
  const [photoData, setPhotoData] = useState([]);
  const [posterData, setPosterData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(''); 
  const { enqueueSnackbar } = useSnackbar(); 
  const posterRef = useRef();
  const posterImageRef = useRef();
  const photoRef=useRef();
  const photoImageRef=useRef();

  const getUrl = async (data) => {
    const updatedData = await Promise.all(
      data.map(async (item) => {
        if (item.imagekey) {
          const url = await imageURL(item.imagekey);
          return { ...item, imagekey: url };
        }
        return item;
      })
    );
    return updatedData;
  };

  useEffect(() => {
    const getImageData = async () => {
      try {
        const response = await axios.get(`${URL}/media/mediadatas`);
        const imageData = response.data[0];

        const filteredPhotos = imageData.photo.filter(ph => ph.users && ph.users.includes(userRole));
        const filteredPosters = imageData.poster.filter(po => po.users && po.users.includes(userRole));
        const filteredVideos = imageData.video.filter(vi => vi.users && vi.users.includes(userRole));

        const updatedPhotos = await getUrl(filteredPhotos);
        const updatedPosters = await getUrl(filteredPosters);
        const updatedVideos = await getUrl(filteredVideos);

        setPhotoData(updatedPhotos);
        setPosterData(updatedPosters);
        setVideoData(updatedVideos);
      } catch (err) {
        console.log(err);
      }
    };

    getImageData();
  }, [userRole]);

  const toggleSelectImage = (id, src) => {
    setSelectedImages((prevSelectedImages) => {
      const newSelectedImages = { ...prevSelectedImages };
      if (newSelectedImages[id]) {
        delete newSelectedImages[id];
      } else {
        newSelectedImages[id] = src;
      }
      console.log('Selected Media:', Object.values(newSelectedImages));
      return newSelectedImages;
    });
  };

  const selectAllImages = (ids, selectAll, data) => {
    const newSelectedImages = { ...selectedImages };
    ids.forEach((id, index) => {
      if (selectAll) {
        newSelectedImages[id] = data[index].imagekey;
      } else {
        delete newSelectedImages[id];
      }
    });
    console.log('Selected Media:', Object.values(newSelectedImages));
    setSelectedImages(newSelectedImages);
  };

  const handleOpenDialog = (type) => {
    const selectedIds = Object.keys(selectedImages).filter((id) => id.startsWith(type));
    
    if (selectedIds.length === 0) {
      // Show a snackbar message if no images are selected
      enqueueSnackbar('Please select at least one media item to download.', { variant: 'warning' });
      return;
    }
    
    setDialogType(type);
    setDialogOpen(true);
  };
  
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmDownload = () => {
    downloadSelectedImages(dialogType);
    handleCloseDialog();
  };

  const handleOpenSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const downloadSelectedImages = async (idPrefix) => {
    const selectedIds = Object.keys(selectedImages).filter(id => id.startsWith(idPrefix));
    if (selectedIds.length === 0) {
      enqueueSnackbar('Please select at least one media item to download.', { variant: 'warning' });
      return;
    }
  
    for (const id of selectedIds) {
      const presignedUrl = selectedImages[id];
      const imageKey = getImageKeyFromUrl(presignedUrl);
  
      function getImageKeyFromUrl(url) {
        try {
          if (typeof url !== 'string' || !url) {
            console.error('Invalid URL input:', url);
            return null;
          }
          const urlPattern = /https?:\/\/[^\/]+\/(.+?)(\?|$)/;
          const match = url.match(urlPattern);
          if (!match || !match[1]) {
            console.error('No match found or match[1] is undefined:', match);
            return null;
          }
          return decodeURIComponent(match[1]);
        } catch (error) {
          console.error('Error extracting image key:', error);
          return null;
        }
      }
  
      const url = await imageURL(imageKey);
  
      try {
        // Fetch the media from the provided URL
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
  
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
  
        // Handle photo downloads
        if (idPrefix === "photos") {
          const imageBlob = await response.blob();
          const imageUrl = window.URL.createObjectURL(imageBlob);
          photoImageRef.current.src = imageUrl;
  
          photoImageRef.current.onload = async () => {
            const element = photoRef.current;
            if (element) {
              try {
                html2canvas(element, { 
                  useCORS: true, 
                  scale: 2, // Adjust for higher resolution
                  backgroundColor: null // Remove background to avoid white space
                }).then((canvas) => {
                  // Trim the canvas to remove any extra space
                  const trimmedCanvas = trimCanvas(canvas);
                  
                  const link = document.createElement('a');
                  link.href = trimmedCanvas.toDataURL('image/png');
                  link.download = 'photo.png';
                  link.click();
                  enqueueSnackbar('Photo download successful.', { variant: 'success' });
                });
              } catch (error) {
                console.error("Error generating PNG:", error);
                enqueueSnackbar('Failed to download photo.', { variant: 'error' });
              }
            } else {
              console.warn("Photo element reference is null.");
            }
          };
        }
        // Handle poster downloads
        else if (idPrefix === "posters") {
          const posterBlob = await response.blob();
          const posterUrl = window.URL.createObjectURL(posterBlob);
          posterImageRef.current.src = posterUrl;
  
          posterImageRef.current.onload = async () => {
            const element = posterRef.current;
            if (element) {
              try {
                html2canvas(element).then((canvas) => {
                  const link = document.createElement('a');
                  link.href = canvas.toDataURL('image/png');
                  link.download = 'poster.png';
                  link.click();
                  enqueueSnackbar('Poster download successful.', { variant: 'success' });
                });
              } catch (error) {
                console.error("Error generating PNG:", error);
                enqueueSnackbar('Failed to download poster.', { variant: 'error' });
              }
            } else {
              console.warn("Poster element reference is null.");
            }
          };
        } 
        // Handle video downloads
        else if (idPrefix === "videos") {
          const videoBlob = await response.blob();
          const videoUrl = window.URL.createObjectURL(videoBlob);
  
          const link = document.createElement('a');
          link.href = videoUrl;
          link.download = 'video.mp4';  // Customize filename here
          link.click();
          enqueueSnackbar('Video download successful.', { variant: 'success' });
        }
  
      } catch (error) {
        console.error('Error fetching and processing media:', error);
        enqueueSnackbar('Failed to download media.', { variant: 'error' });
      }
    }
  };

  function trimCanvas(canvas) {
    const ctx = canvas.getContext('2d');
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  
    let top = 0;
    let bottom = canvas.height;
    let left = 0;
    let right = canvas.width;
  
    // Loop through and find the edges of non-transparent pixels
    outerLoop: for (let y = 0; y < canvas.height; y++) {
      for (let x = 0; x < canvas.width; x++) {
        const index = (y * canvas.width + x) * 4;
        if (imgData.data[index + 3] > 0) { // Check if pixel is not transparent
          top = y;
          break outerLoop;
        }
      }
    }
  
    outerLoop: for (let y = canvas.height - 1; y >= 0; y--) {
      for (let x = 0; x < canvas.width; x++) {
        const index = (y * canvas.width + x) * 4;
        if (imgData.data[index + 3] > 0) {
          bottom = y;
          break outerLoop;
        }
      }
    }
  
    outerLoop: for (let x = 0; x < canvas.width; x++) {
      for (let y = 0; y < canvas.height; y++) {
        const index = (y * canvas.width + x) * 4;
        if (imgData.data[index + 3] > 0) {
          left = x;
          break outerLoop;
        }
      }
    }
  
    outerLoop: for (let x = canvas.width - 1; x >= 0; x--) {
      for (let y = 0; y < canvas.height; y++) {
        const index = (y * canvas.width + x) * 4;
        if (imgData.data[index + 3] > 0) {
          right = x;
          break outerLoop;
        }
      }
    }
  
    const trimmedWidth = right - left;
    const trimmedHeight = bottom - top;
  
    const trimmedCanvas = document.createElement('canvas');
    trimmedCanvas.width = trimmedWidth;
    trimmedCanvas.height = trimmedHeight;
  
    const trimmedCtx = trimmedCanvas.getContext('2d');
    trimmedCtx.putImageData(ctx.getImageData(left, top, trimmedWidth, trimmedHeight), 0, 0);
  
    return trimmedCanvas;
  }
  
  const renderCarousel = (data, id) => {
 
    const slidesToShow = data.length < 5 ? data.length : 4;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToShow,
      centerMode: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: Math.min(data.length, 3),
            slidesToScroll: Math.min(data.length, 3),
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: Math.min(data.length, 2),
            slidesToScroll: Math.min(data.length, 2),
            infinite: false,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: Math.min(data.length, 1),
            slidesToScroll: Math.min(data.length, 1),
            infinite: false,
          },
        },
      ],
    };
    const ids = data.map((_, index) => `${id}-${index}`);

    return (
     
        <div key={id}>
          <Header>
            <h5>{id.charAt(0).toUpperCase() + id.slice(1)}</h5>
            {data.length > 0 && (
              <SelectAll>
                <span className="select-all">Select All</span>
<div className="checkbox-bx">
  <label>
    <input type="checkbox" onChange={(e) => selectAllImages(ids, e.target.checked, data)} />
    <span></span>
  </label>
</div>
                <DownloadButton onClick={() => handleOpenDialog(id)}>
                  <DownloadIcon sx={{ color: '#f00d88' }} />
                </DownloadButton>
              </SelectAll>
            )}
          </Header>
          {data.length > 0 ? (
          <StyledSlider {...settings}>
            {data.map((src, index) => (
              <MediaContainer
                key={index}
                selected={selectedImages[`${id}-${index}`]}
                onClick={() => toggleSelectImage(`${id}-${index}`, src.imagekey)}
              >
                {src.imagekey ? (
                  id === "videos" ? (
                    <VideoContainer>
                      <video src={src.imagekey} controls />
                    </VideoContainer>
                  ) : id === "photos" ? (
                    <img src={src.imagekey} alt={`Media ${index}`} />
                  ) : (
                    <>
                      <img src={src.imagekey} alt={`Media ${index}`} />
                     
                    </>
                  )
                ) : ( 
                  <FallbackImage src={id === "videos" ? vid : img} alt="Fallback Media" />
                )}
                {selectedImages[`${id}-${index}`] && <TickIcon className="fa-solid fa-check"></TickIcon>}
              </MediaContainer>
            ))}
          </StyledSlider>
          ) : (
            <NoContentMessage>NO {id} AVAILABLE </NoContentMessage>
          )}
        </div>
      );
    };

  return (
    <MediaUpdatesContainer>
      <div>
        <h2 style={{ color: "white" }}>MEDIA UPDATES</h2>
      </div>
      {renderCarousel(photoData, "photos")}
      {renderCarousel(posterData, "posters")}
      {renderCarousel(videoData, "videos")}

      <div style={{ position: "absolute", top: "-1000px", left: "-10000px" }}>
  <div ref={posterRef} style={{ position: "relative" }}>
    <img ref={posterImageRef} src="" alt="" style={{ display: "block", width: "100%", height: "auto" }} />
    
    <h6 style={{ position: "absolute", bottom: "85px", right: "180px", color: "#fff", margin: 0 ,fontSize:"85px"}}>
      {profileData.profileData.phoneNumber}
    </h6>
  </div>

  <div ref={photoRef}>
    <img ref={photoImageRef} src="" alt="" />
  </div>
</div>


      {/* Confirmation Modal */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to download the selected media?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDownload} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Messages */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </MediaUpdatesContainer>
  );
}

const MediaUpdatesContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > div {
    margin-bottom: 2rem;
  }

  h4, h5, p, span {
    color: white;
  }

 

  @media only screen and (max-width: 800px) {
    h4 {
      margin-left: 2rem;
    }
  }

  

  /* Container for Select All and Checkbox */
.select-all {
  font-size: 16px; /* Adjust font size for better responsiveness */
  margin-right: -5px; /* Reduced gap between "Select All" and checkbox */
}

.checkbox-bx {
  display: inline-block;
  vertical-align: middle;
}

/* Responsive Checkbox */
label {
  position: relative;
  height: 24px;
  width: 24px;
}

input {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}

input[type=checkbox] {
  accent-color: green;
}

.checkbox-bx label span {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 6px;
  cursor: pointer;
  background-color: #25272d;
}

@media (max-width: 768px) {
  
    
}

@media (max-width: 480px) {
  
    .select-all {
 /* Adjust font size for better responsiveness */
  margin-right: -10px; /* Reduced gap between "Select All" and checkbox */
}
}

.checkbox-bx label span::after {
  content: "";
  position: absolute;
  left: 12px;
  bottom: 8px;
  width: 10px;
  height: 20px;
  border: solid #00ff0a;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: width 0.5s, height 0.5s, opacity 0.5s;
}

@media (max-width: 768px) {
  .checkbox-bx label span::after {
    left: 8px;
    bottom: 6px;
    width: 8px;
    height: 16px;
  }
}

@media (max-width: 480px) {
  .checkbox-bx label span::after {
    left: 6px;
    bottom: 4px;
    width: 6px;
    height: 12px;
  }
}

.checkbox-bx input:checked ~ span::after {
  width: 10px;
  height: 20px;
  opacity: 1;
}

/* Toggle Button */
.checkbox-wrapper-10 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 6em;
  height: 2.5em;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding: 0;
}

.checkbox-wrapper-10 .tgl + .tgl-btn:after,
.checkbox-wrapper-10 .tgl + .tgl-btn:before {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 2.2em;
  font-size: 1rem;
  top: -7px;
  left: 0;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .checkbox-wrapper-10 .tgl + .tgl-btn:after,
  .checkbox-wrapper-10 .tgl + .tgl-btn:before {
    font-size: 0.7rem;
    line-height: 1.8em;
    top: -5px;
    width: 85%;
    height: 85%;
  }
}

@media only screen and (max-width: 768px) {
  .checkbox-wrapper-10 .tgl + .tgl-btn:after,
  .checkbox-wrapper-10 .tgl + .tgl-btn:before {
    font-size: 0.9rem;
    line-height:2.5em;
    top: -6px;
  }
}

@media only screen and (min-width: 1024px) {
  .checkbox-wrapper-10 .tgl + .tgl-btn:after,
  .checkbox-wrapper-10 .tgl + .tgl-btn:before {
    font-size: 1rem;
    line-height: 2.2em;
    top: -7px;
  }
}

.checkbox-wrapper-10 .tgl + .tgl-btn:before {
  background: transparent;
  content: attr(data-tg-off);
  border: 1px solid red;
  border-radius: 2em;
  transform: rotateY(-180deg);
  backface-visibility: hidden;
  transition: all 0.4s ease;
}

.checkbox-wrapper-10 .tgl + .tgl-btn:after {
  background: transparent;
  content: attr(data-tg-on);
  border-radius: 2em;
  border: 1px solid green;
  transform: rotateY(0deg);
  backface-visibility: hidden;
  transition: all 0.4s ease;
}

.checkbox-wrapper-10 .tgl:checked + .tgl-btn:before {
  transform: rotateY(0deg);
}

.checkbox-wrapper-10 .tgl:checked + .tgl-btn:after {
  transform: rotateY(180deg);
}


`;

const NoContentMessage = styled.div`
  color: #ccc;
  text-align: center;
  padding: 20px;
  font-size: 18px;
   text-transform: uppercase;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #c94295;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c94295;
  }
`;

const TrashIconButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  margin-left: 10px;

  &:hover {
    color: red;
  }
`;

const MediaContainer = styled.div`
   border: ${({ selected }) => (selected ? '4px solid #f00d88' : 'none')};
  cursor: pointer;
  position: relative;
  width: 100%;  /* Set width to 100% for responsiveness */
  max-width:250px;  /* Ensure media doesn't exceed 300px width */
  height:250px; /* Auto height to maintain aspect ratio */
  margin-right: 15px;
  margin-bottom: 15px;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);  /* Add hover shadow for better UI */
    transition: box-shadow 0.3s ease;
  }

  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px; /* Rounded corners for a modern look */
  }
`;

const StyledSlider = styled(Slider)`
  max-width: 1000px;
  margin: 0 auto;

  .slick-slide {
    padding: 10px;
    display: flex;
    justify-content: center;
  }

  .slick-track {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
  }

  @media (max-width: 768px) {
    .slick-slide {
      padding: 5px;
    }
  }
`;


const TickIcon = styled.i`
  position: absolute;
  top: 5px;
  left: 5px;
  color: green;
  font-size: 1.5rem;
  z-index: 10;
`;

const FallbackImage = styled.img`
  max-width: 100%;
  max-height: 250px;
  opacity: 0.5;
  border-radius: 8px; /* Rounded corners */
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  background-color: #f0f0f0;
  border-radius: 8px; /* Rounded corners */
`;



const DownloadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: #36283a;
  cursor: pointer;
`;

const SelectAll = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;




export default MediaUpdatesSection;