import React from 'react';
import { styled } from 'styled-components';
import NavBar from '../pages/miniComponents/subcomponents/NavBar';
import MiniNavBar from './miniComponents/subcomponents/MiniNavBar';
import CrmTableContainer from './secondaryContainersForPages/CrmContainer';
import CrmUserView from './miniComponents/Crm/CrmUserVIew';
import { useGlobalContext } from '../assets/contex/GlobalContext';
import CrmAddleadContainer from './secondaryContainersForPages/CrmAddleadContainer';

function CrmAddlead() {
  const { userRole } = useGlobalContext(); // Extract userRole from global context

  console.log("UserRole:", userRole); // Debugging statement

  if (userRole === "franchise") {
    return (
      <Crmcontainer>
        <NavBar selected={"crm"} />
        <MiniNavBar selected={"crm"} />
        <CrmAddleadContainer />
      </Crmcontainer>
    );
  }
}

const Crmcontainer = styled.div`
width:auto;
  display: flex;
  background-color: var(--background);

  #cont {
    height: 100vh;
    width: 100%;
  }
  @media only screen and (max-width: 800px) {
    #miniNav {
      display: block !important;
    }
    h2 {
      margin-left: 3rem;
    }
  }
  #miniNav {
    display: none;
  }
`;

export default CrmAddlead;
