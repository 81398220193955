import React from "react";
import styled from 'styled-components';
import NavBar from "../../miniComponents/subcomponents/NavBar";
import UserProfileComponent from "./UserProfileComponent";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
function UserProfile() {
   const { userRole } = useGlobalContext();
  return (
    <UserProfileContainer>
      <NavBar selected={"marketingupdates"} user={userRole} />
      <UserProfileComponent  />
    </UserProfileContainer>
  );
}
const UserProfileContainer = styled.div`
  display: flex;
  
  background-color: var(--background);
`;
export default UserProfile;