import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { URL as url } from "../../../assets/mocData/config";
import { enqueueSnackbar } from "notistack";
import { handleImageSubmit, imageURL, postData, putData } from "../../../Api";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "styled-components";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputBase,
  Radio,
  RadioGroup,
  TextField,
  Box,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Icon from "react-icons-kit";
import { cross } from "react-icons-kit/icomoon/cross";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a70e6e",
    },
    text: {
      primary: "#ffffff",
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "black",
          color: "#ffffff",
          borderRadius: "4px",
          "&:hover": {
            backgroundColor: "black",
          },
          "&.Mui-focused": {
            backgroundColor: "black",
          },
        },
        input: {
          color: "#ffffff",
        },
        underline: {
          "&:before": {
            borderBottom: `1px solid #ffffff`,
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: `1px solid #a70e6e`,
          },
          "&:after": {
            borderBottom: `2px solid #a70e6e`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "gray",
          "&.Mui-focused": {
            color: "#a70e6e",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#25272d",
          color: "#ffffff",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#ffffff",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#a70e6e",
          },
        },
      },
    },
  },
});

export function AddVipFranchise({ vipFranchiseData, setSelectedView }) {
  const { userToken, userRole } = useGlobalContext();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false); // Close the modal
  };
  const handleConfirmAdd=()=>{
    handleAddVipFranchiseUser();
  }
  const handleOpenDeleteModal = () => {
    // Set the course to be deleted
    setOpenDeleteModal(true); // Open the modal
  };
  const [data, setData] = useState({
    userName: "",
    phoneNumber: "",
    email: "",
    otherDetails: { pinCodes: [] },
    workingOption: ["vipFranchise"],
    status: "active",
    referredBy: {
      referID: "none",
    },
  });
  const [pin, setPin] = useState("");
  console.log(data);
  const validateVipFranchiseData = (data) => {
    // Validate userName (no numbers allowed)
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!data.userName.trim()) {
      enqueueSnackbar("User Name is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    } else if (!nameRegex.test(data.userName)) {
      enqueueSnackbar("User Name should not contain numbers or special characters.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    // Validate phoneNumber (only numbers allowed)
    const phoneRegex = /^[0-9]+$/;
    if (!data.phoneNumber.trim()) {
      enqueueSnackbar("Phone Number is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    } else if (!phoneRegex.test(data.phoneNumber)) {
      enqueueSnackbar("Phone Number should contain only numbers.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    // Validate email
    if (!data.email.trim()) {
      enqueueSnackbar("Email is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    // Validate pincodes
    if (data.otherDetails.pinCodes.length === 0) {
      enqueueSnackbar("At least one pincode is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    const pincodeRegex = /^[0-9]{6}$/; // Assuming a 6-digit pincode format
    const invalidPincode = data.otherDetails.pinCodes.some((pincode) => !pincodeRegex.test(pincode));
  
    if (invalidPincode) {
      enqueueSnackbar("Each Pincode should be a valid 6-digit number.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    return true;
  };
  
  const handleAddVipFranchiseUser = async () => {
    if (validateVipFranchiseData(data)) {
      const originalUserId = data._id?.toString();
      const newUserId = originalUserId?.slice(0, -2) + "vf";
      const headers = { userToken, userRole: "admin" };
      const dataSend = {
        userName: data.userName,
        _id: newUserId,
        phoneNumber: data.phoneNumber,
        userType: "vipFranchise",
        email: data.email,
        workingOption: ["vipFranchise"],
        referredBy: data.referredBy,
        otherDetails: {
          ...vipFranchiseData.otherDetails,
          pinCodes: data.otherDetails.pinCodes,
        },
      };
  
      try {
        await putData(`${url}/admin/vipfranchise`, dataSend, headers);
        enqueueSnackbar("VIP Franchise added", { variant: "success" });
        window.location.reload();
      } catch (error) {
        // Handle specific error for non-VIP members
        if (
          error.response &&
          error.response.data === "User not found or unable to become VIP Franchise"
        ) {
          enqueueSnackbar("This member is not a VIP member.", { variant: "warning" });
        } else {
          enqueueSnackbar("Something went wrong. Please try again later.", {
            variant: "error",
          });
        }
      }
    }
  };
  

  const FormData = (e) => {
    e.preventDefault();
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handlePinCodeChange = (pincode) =>
    !data.otherDetails.pinCodes.includes(pincode);

  const handleRemovePincode = (pinCode) => {
    const updatedPinCodes = data.otherDetails.pinCodes.filter(
      (e) => e !== pinCode
    );
    setData({ ...data, otherDetails: { pinCodes: updatedPinCodes } });
  };

  const handleAddPinCode = (pincode) => {
    const pincodeRegex = /^[0-9]{6}$/; // Pincode must be 6 digits
  
    // Check if pincode is empty
    if (!pincode.trim()) {
      enqueueSnackbar("Pincode cannot be empty.", { variant: "warning" });
      return;
    }
  
    // Check if pincode is a valid 6-digit number
    if (!pincodeRegex.test(pincode)) {
      enqueueSnackbar("Pincode must be a valid 6-digit number.", { variant: "warning" });
      return;
    }
  
    // Check if pincode already exists in the list
    if (handlePinCodeChange(pincode)) {
      setData({
        ...data,
        otherDetails: { pinCodes: [...data.otherDetails.pinCodes, pincode] },
      });
      setPin(""); // Reset the pincode input field
    } else {
      enqueueSnackbar("This pincode is already added.", { variant: "warning" });
    }
  };
  
  useEffect(() => {
    if (vipFranchiseData) {
      vipFranchiseData.otherDetails = {
        ...vipFranchiseData.otherDetails,
        pinCodes: [],
      };
      setData({
        ...data,
        ...vipFranchiseData,
      });
    }
  }, [vipFranchiseData]);

  return (
    <AddVipFranchisevipContainer>
      <ThemeProvider theme={theme}>
        <div className="container">
          <div className="row">
            <div className="col col-12" style={{marginTop:"2rem"}}>
              <div
                id="form-container"
                className="scroll m-1"
                style={{
                  height: "78vh",
                  overflowY: "scroll",
                  paddingTop: "3rem",
                  width: "100%",
                }}
              >
                <form onChange={FormData}>
                  <div className="col-12 d-flex flex-column px-3">
                    <div style={{ width: "100%" }}>
                      <span
                        style={{ width: "100%" }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <TextField
                          variant="filled"
                          label="Referral ID"
                          name="refferalID"
                          value={data?.referredBy?.name}
                          sx={{
                            width: "100%",
                            border: "1px solid black",
                            "@media (min-width: 768px)": {
                              width: "45%",
                            },
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="container">
                    <div className="row">
                      <span className="col-3">Personal Information</span>
                      <div className="col-9 d-flex flex-column px-3">
                        <TextField
                          variant="filled"
                          label="Name"
                          name="userName"
                          value={data.userName}
                          sx={{
                            width: "100%",
                            border: "1px solid black",
                            "@media (min-width: 768px)": {
                              width: "65%",
                            },
                          }}
                        />
                        <br></br>
                        <TextField
                          variant="filled"
                          label="Phone Number"
                          name="phoneNumber"
                          value={data.phoneNumber}
                          sx={{
                            width: "100%",
                            border: "1px solid black",
                            "@media (min-width: 768px)": {
                              width: "65%",
                            },
                          }}
                        />
                        <br />
                        <span
                          style={{ width: "100%" }}
                          className="d-flex justify-content-between"
                        >
                          <TextField
                            variant="filled"
                            label="Email"
                            name="email"
                            value={data.email}
                            sx={{
                              width: "100%",
                              border: "1px solid black",
                              "@media (min-width: 768px)": {
                                width: "65%",
                              },
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr />
                </form>
                <div className="container">
                  <div className="row">
                    <span className="col-3">Pincodes</span>
                    <div className="col-9 d-flex flex-column px-3">
                      <div style={{ width: "100%" }}>
                        {data.otherDetails.pinCodes?.map((p) => {
                          return (
                            <span className="badge text-bg-success m-1">
                              {p}
                              <Icon
                                onClick={() => handleRemovePincode(p)}
                                style={{ padding: "0.5rem" }}
                                icon={cross}
                              />
                            </span>
                          );
                        })}
                        <span
  style={{ width: "100%", paddingTop: "1rem" }}
  className="d-flex justify-content-between"
>
  <TextField
    variant="filled"
    label="Pincode"
    name="Pincode"
    value={pin}
    onChange={(e) => setPin(e.target.value)}
    sx={{
      width: "65%",
      border: "1px solid black",
    }}
  />
  
  <button
    onClick={() => handleAddPinCode(pin)}
    style={{ color: "white", whiteSpace: "nowrap", marginLeft: "1rem" }}
  >
    Add Pin
  </button>
</span>

                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ width: "100%" }}
                  className="mt-3 d-flex justify-content-center"
                >
                 <div class="container mt-3">
  <div class="row justify-content-center">
    <div class="col-12 d-flex flex-column flex-sm-row justify-content-center">
      <button
        id="pay-btn"
        type="button"
        class="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-concept-btn"
        name="addvip"
        onClick={() => handleOpenDeleteModal()}
        style={{
          whiteSpace: "nowrap",
          border: "2px solid green",
        }}
      >
        ADD VIP Franchise
      </button>
      <button
        id="cancel-btn"
        type="button"
        class="btn btn-outline-danger btn-sm mb-2 mb-sm-0 ms-0 ms-sm-2 cancel-btn"
        name="cancel"
        onClick={() => setSelectedView("vipFranchiseReferrals")}
        style={{
          whiteSpace: "nowrap",
          border: "2px solid red",
        }}
      >
        CANCEL
      </button>
    </div>
  </div>
</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
      >
        <DialogTitle>Confirm Add</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to add this person as a vip franchise?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAdd} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </AddVipFranchisevipContainer>
  );
}

const AddVipFranchisevipContainer = styled.div`

/* Container for the form */
#form-container {
  background-color: var(--navbar-dark-primary); /* Dark background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  color: #fff;
  max-width: 100%; /* Allow full width up to its container */
 
  margin: 0 auto; /* Center the container */
}

/* General form styles */
form {
  display: flex;
  
  flex-direction: column;
}

hr {
  border-color: #555;
}

/* Responsive grid system */
.container {
  max-width: 100%;
  padding: 0 15px; /* Add padding for better alignment */
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.col-3,
.col-9,
.col-12 {
  padding:inherit;
}

/* Columns on larger screens */
@media (min-width: 768px) {


  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Full width for small screens */
@media (max-width: 767px) {


  .form-row {
    flex-direction:row;
  }

  .form-row .input-field {
    width: 100%;
  }

  .col-3,
  .col-9,
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Aligning the text labels */
.col-3 {
  display: flex;
  align-items: center;
  font-weight: bold;
}

/* Input styles */
.MuiFilledInput-root {
  background-color: #000;
  color: #fff;
  border-radius: 4px !important;
  border: 1px solid black !important;
}

.MuiFilledInput-root:hover,
.MuiFilledInput-root.Mui-focused {
  background-color: #000 !important;
}

.MuiInputLabel-root {
  color: gray !important;
}

.MuiInputLabel-root.Mui-focused {
  color: #a70e6e !important;
}

.MuiFilledInput-underline:before {
  border-bottom: 1px solid #fff !important;
}

.MuiFilledInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #a70e6e !important;
}

.MuiFilledInput-underline:after {
  border-bottom: 2px solid #a70e6e !important;
}

/* Button styles */
button {
  background-color: transparent;
  border: 2px solid #a70e6e;
  transition: background-color 0.3s ease;
   padding: 0.6rem 1rem; 
   border-radius: 20px; 
   @media (max-width: 575px){
    padding: 0.2rem 0.4rem; 
   }
}



/* Center alignment and responsiveness for small screens */
@media (max-width: 575px) {
  #form-container {
    padding: 10px;
    margin: 0 auto;
    max-width: 90%; /* Keep the form container centered with a smaller width */
  }

  .form-container {
    gap: 15px;
  }

  .input-field {
    font-size: 16px !important;
    padding: 10px !important;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }

  .button-container button {
    width: 100%;
    padding: 12px;
    font-size: 16px !important;
  }

  .vip {
    width: 100%;
    margin-bottom: 10px;
  }

  .vip:last-child {
    margin-bottom: 0;
  }

  #pay-btn {
    width: 100%;
  }
}

/* Alignment of the "Pincode" input and "Add Pin" button */
.pincode-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.pincode-container .MuiFilledInput-root {
  width: 45%;
}

.pincode-container button {
  margin-left: 10px;
}

/* Badge styles for Pincodes */
.badge {
  background-color: #a70e6e;
  color: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
}

.badge .react-icons-kit {
  cursor: pointer;
  margin-left: 8px;
}

/* Style for active radio button */
  
.add-concept-btn,
.cancel-btn {
  margin-right: 2rem; /* Space between buttons */
  font-size: 1rem; /* Button font size */
  padding: 0.6rem 2.5rem; /* Button padding */
  border-radius: 20px; /* Rounded buttons */
}


  @media (max-width: 575px) {
    .add-concept-btn,
    .cancel-btn {
      align-items: center;
      width: auto; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
    }


  /* Specific adjustment for the cancel button */
  .cancel-btn {
    padding: 0.4rem 0.6rem;!important/* Match the padding of the ADD VIP Franchise button */
  }
}



`;

