import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import iconTotalUser from "../../../assets/images/total users.png";
import { BarChart } from "@mui/x-charts/BarChart";
import { calendar } from "react-icons-kit/icomoon/calendar";
import { Icon } from "react-icons-kit";
import {
  parse,
  parseISO,
  compareAsc,
  format,
  isSameMonth,
  isValid,
} from "date-fns";

import { LineChart } from "@mui/x-charts/LineChart";
import { Box } from "@mui/material";
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import { ChartsReferenceLine } from "@mui/x-charts/ChartsReferenceLine";
import { LinePlot, MarkPlot } from "@mui/x-charts/LineChart";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];

function DashBoardGraph({ user, accountDetail, student }) {
  const now = new Date();
  const currentMonth = now.getMonth() + 1; // Adjust for zero-based index
  const currentYear = now.getFullYear();
  const [nullContainer, setNullContainer] = useState(true);

  const [adminLable, setAdminLable] = useState([0]);
  const [dataVip, setDataVip] = useState([0]);
  const [dataVipFranchise, setDataVipFranchise] = useState([0]);
  const [dataFranchise, setDataFranchise] = useState([0]);
  const [dataStudent, setDataStudent] = useState([0]);
  const [dataBranchStaff, setDataBranchStaff] = useState([0]);
  const [dataHos, setDataHos] = useState([0]);
  const [adminStudentDate, setStudentAdminDate] = useState([0]);
  const [adminStudentNo, setStudentAdminNo] = useState([0]);
  const [vipFranchiseData, setVipFranchiseData] = useState({
    collections: [0],
    labels: [0],
    revenues: [0],
  });
  const { userRole } = useGlobalContext();
  let [currentMonthnow, setMonth] = useState(currentMonth - 1);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  console.log(selectedYear);
  let [preprocessData, setpreprocessData] = useState({
    months: [0],
    students: [0],
    wallet: [0],
    collection: [0],
    color: [0],
    totalStudents: 0,
    totalCollection: 0,
    lastMonthCollection: 0,
    lastMonthStudent: 0,
    currentMonthStudent: 0,
    currentMonthCollection: 0,
  });

  function preprocessTransactionData(data, month,year) {
    let months = [];
    let collection = [];
    let student = [];
    let wallet = [];
    let color = [];
    let totalStudents = 0;
    let totalCollection = 0;
    let totalWallet = 0;
    let lastMonthStudent = 0;
    let currentMonthStudent = 0;
    let currentMonthWallet = 0;
    let lastMonthWallet = 0;
    let lastMonthCollection = 0;
    let currentMonthCollection = 0;
    const transactions = data?.sort((a, b) => {
      const dateA = parseISO(a.month); // Parse the date string
      const dateB = parseISO(b.month); // Parse the date string
      return compareAsc(dateA, dateB); // Compare the parsed dates
    });
    try {
       const result = transactions?.map((transaction, i) => {
         totalStudents += parseInt(transaction.student);
         totalCollection += parseInt(transaction.collection);
         totalWallet += parseInt(transaction.wallet);
         let date = new Date(transaction.date);
         if (
           parseInt(transaction.month.split("-")[1]) - 1 == month - 1 &&
           date.getFullYear() == year
         ) {
           lastMonthStudent += parseInt(transaction.student);
           lastMonthCollection += parseInt(transaction.collection);
           lastMonthWallet += parseInt(transaction.wallet);
         }
         if (
           parseInt(transaction.month.split("-")[1]) - 1 == month &&
           date.getFullYear() == year
         ) {
           months.push(transaction.month);
           collection.push(transaction.collection);
           student.push(transaction.student);
           wallet.push(transaction.wallet);
           currentMonthStudent += parseInt(transaction.student);
           currentMonthCollection += parseInt(transaction.collection);
           currentMonthWallet += parseInt(transaction.wallet);
         }
         if (i % 2 == 0) {
           color.push("#ec008bde");
         } else {
           color.push("#bf2f82c4");
         }
       });

    } catch (error) {
      
    }
    // Create an array of objects containing the month, collection, and student count for each transaction
   
    if (months.length == 0) {
      return false;
    } else {
      return {
        months: months,
        students: student,
        wallet: wallet,
        collection: collection,
        color: color,
        totalStudents: totalStudents,
        totalCollection: totalCollection,
        lastMonthCollection: lastMonthCollection,
        lastMonthStudent: lastMonthStudent,
        currentMonthStudent: currentMonthStudent,
        currentMonthCollection: currentMonthCollection,
        totalWallet: totalWallet,
        lastMonthWallet: lastMonthWallet,
        currentMonthWallet: currentMonthWallet,
      };
    }
  }
  function formatDateToLocal(date) {
    // Get the year, month, and day in the local time zone
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");

    // Return the formatted date string
    return `${year}-${month}-${day}`;
  }
  function preprocessDataAdmin(users, month, year) {
    // Use a Map to keep track of user counts for each date
    const dateClusters = new Map();

    // Filter users based on the specified month and year
    const filteredUsers = users.filter((user) => {
      const createdDate = new Date(user.createdAt);
        return (
          createdDate.getMonth() == parseInt(month) &&
          createdDate.getFullYear() == parseInt(year)
        );
    });
    // Iterate over each user in the filtered list
    filteredUsers.forEach((user) => {
      // Extract the date without the time
      const createdDate = new Date(user.createdAt);

      createdDate.setHours(0, 0, 0, 0); // Normalize time to midnight for date comparison
      // const dateKey = createdDate.toISOString().split('T')[0]; // Format date as 'YYYY-MM-DD'
      const dateKey = formatDateToLocal(createdDate);

      // Initialize the date entry if it doesn't exist
      if (!dateClusters.has(dateKey)) {
        dateClusters.set(dateKey, {
          date: dateKey,
          noVip: 0,
          noFranchise: 0,
          noVipFranchise: 0,
          noStudent: 0,
          noBranchStaff: 0,
          noHos: 0, // Assuming 'hos' is another category
          noAdmin: 0, // Including 'admin' as another category
        });
      }

      // Increment the appropriate counter based on the user's type
      const cluster = dateClusters.get(dateKey);
      switch (user.userType) {
        case "vip":
          if (user.workingOption && user.workingOption[0] === "vipFranchise") {
            cluster.noVipFranchise++;
          }
          cluster.noVip++;
          break;
        case "franchise":
          cluster.noFranchise++;
          break;
        case "branchstaff":
          cluster.noBranchStaff++;
          break;
        case "hos":
          cluster.noHos++;
          break;
        case "admin":
          cluster.noAdmin++;
          break;
        // Add more cases if needed for additional user types

        default:
          // Handle other cases or do nothing
          break;
      }
    });

    // Convert the Map to an array for easy use with charts
    return Array.from(dateClusters.values());
  }
  const preprocessTransactionsForBarChart = (
    vipFranchiseData,
    currentMonthnow
    ,currentYear
  ) => {
    // Check if transactions exist and are an array
    if (!vipFranchiseData || !Array.isArray(vipFranchiseData.transactions)) {
      console.error("Invalid vipFranchiseData or transactions are missing");
      return { labels: [], revenues: [], collections: [] }; // Return empty data to avoid errors
    }

    // Filter transactions that match the current month
    const filteredTransactions = vipFranchiseData?.transactions.filter(
      (transaction) => {
        const createdDate = new Date(transaction.date);
        return (
          createdDate.getMonth() == parseInt(currentMonthnow) &&
          createdDate.getFullYear() == parseInt(currentYear)
        );
      }
    );
   
    if (filteredTransactions.length === 0) {
      console.log("No transactions found for the specified month.");
      return { labels: [0], revenues: [0], collections: [0] }; // Return empty data if no transactions
    }

    // Aggregate the filtered transactions by day
    const aggregatedData = filteredTransactions.reduce((acc, transaction) => {
      // Extract day from the transaction date
      const transactionDate = format(new Date(transaction.date), "yyyy-MM-dd");

      // Initialize the date if not already in the accumulator
      if (!acc[transactionDate]) {
        acc[transactionDate] = {
          date: transactionDate,
          totalRevenue: 0,
          totalCollection: 0,
        };
      }

      // Accumulate the revenue and collection for the day
      acc[transactionDate].totalRevenue += transaction.revenue || 0;
      acc[transactionDate].totalCollection += transaction.collection || 0;

      return acc;
    }, {});

    // Convert aggregated data to an array for plotting
    const chartData = Object.values(aggregatedData);

    // Prepare labels (dates) and datasets (revenue and collection)
    const labels = chartData.map((data) => data.date);
    const revenues = chartData.map((data) => data.totalRevenue);
    const collections = chartData.map((data) => data.totalCollection);

    // Return the processed data for the bar chart
    return { labels, revenues, collections };
  };
  const clusterStudentsByJoinDate = (students, currentMonth,currentYear) => {
    const filteredStudents = students?.filter((student) => {
      const joinDate = student.transactions[0].date;

      const createdDate = new Date(student.createdAt);
      return (
        createdDate.getMonth() == parseInt(currentMonth) &&
        createdDate.getFullYear() == parseInt(currentYear)
      );
    });

    // Cluster students by join date
    const clusteredData = filteredStudents?.reduce((acc, student) => {
      const joinDate = format(
        parseISO(student.transactions?.[0]?.date),
        "yyyy-MM-dd"
      );

      if (!acc[joinDate]) {
        acc[joinDate] = [];
      }
      acc[joinDate].push(student);

      return acc;
    }, {});

    // // Return two separate arrays: one for the keys (labels) and one for the values (counts)
    const keys = Object.keys(clusteredData); // Date labels (yyyy-MM-dd)
    const values = Object.values(clusteredData).map(
      (students) => students.length
    ); // Number of students per date

    return { keys, values };
  };
  const sumStudent = (student) => {
    let count = 0;
    student.forEach((s) => {
      count += s;
    });
    return count;
  };
  useEffect(() => {
    if (accountDetail) {
      try {
        if (user == "admin" || user == "hos") {
          let clusteredData = preprocessDataAdmin(
            accountDetail,
            currentMonthnow,
            selectedYear
          );
          if (student) {
            let studentData = clusterStudentsByJoinDate(
              student,
              currentMonthnow,
              selectedYear
            );
            if (studentData.keys.length > 0) {
              setStudentAdminDate([...studentData.keys]);
              setStudentAdminNo([...studentData.values]);
            } else {
              setStudentAdminDate([0]);
              setStudentAdminNo([0]);
            }
          }

          const labels = clusteredData.map((entry) =>
            format(entry.date, "dd-MM-yyyy")
          );
          const dataVip = clusteredData.map((entry) => entry.noVip);
          const dataVipFranchise = clusteredData.map(
            (entry) => entry.noVipFranchise
          );
          const dataFranchise = clusteredData.map((entry) => entry.noFranchice);
          const dataStudent = clusteredData.map((entry) => entry.noStudent);
          const dataBranchStaff = clusteredData.map(
            (entry) => entry.noBranchStaff
          );
          const dataHos = clusteredData.map((entry) => entry.noHos);
          if (labels.length > 0) {
            setAdminLable(labels);
            setDataBranchStaff(dataBranchStaff);
            setDataHos(dataHos);
            setDataVipFranchise(dataVipFranchise);
            setDataFranchise(dataFranchise);
            setDataVip(dataVip);
          } else {
            setAdminLable([0]);
            setDataBranchStaff([0]);
            setDataHos([0]);
            setDataVipFranchise([0]);
            setDataFranchise([0]);
            setDataVip([0]);
          }

          // setDataStudent(dataStudent);
        } else if (
          userRole == "vipFranchise" &&
          accountDetail[0].transactions
        ) {
          const chartData = preprocessTransactionsForBarChart(
            accountDetail[0],
            currentMonthnow,
            selectedYear
          );
          setVipFranchiseData(chartData);
        } else {
          let data = preprocessTransactionData(
            accountDetail[0]?.transactions,
            currentMonthnow,
            selectedYear
          );

          if (data) {
            setpreprocessData(data);
          } else {
            setpreprocessData({
              months: [0],
              students: [0],
              wallet: [0],
              collection: [0],
              color: [0],
              totalStudents: 0,
              totalCollection: 0,
              lastMonthCollection: 0,
              lastMonthStudent: 0,
              currentMonthStudent: 0,
              currentMonthCollection: 0,
            });
            setNullContainer(false);
          }
        }
      } catch (error) {
        window.location.reload();
      }
      
    }
  }, [currentMonthnow, selectedYear]);

  const uData = [
    5000, 10000, 15000, 20000, 25000, 1300, 30000, 10000, 20000, 23000, 10000,
    0,
  ];
  const pData = [5, 10, 15, 20, 25, 25, 20, 15, 10, 5, 15, 0];
  const color1 = ["#ec008bde", "#ec008bde", "#ec008bde", "#ec008bde"];
  const color2 = ["#bf2f82c4", "#bf2f82c4", "#bf2f82c4", "#bf2f82c4"];
  const xLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const BarPlotstyle = {
    width: "100%",

    //change left yAxis label styles
    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
      strokeWidth: "0.4",
      fill: "white",
    },
    // change all labels fontFamily shown on both xAxis and yAxis
    "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
      fontFamily: "Roboto",
    },
    // change bottom label styles
    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
      strokeWidth: "0.5",
      fill: "white",
    },
    // bottomAxis Line Styles
    "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
      stroke: "#bb00ff",
      strokeWidth: 0.4,
    },
    // leftAxis Line Styles
    "& .MuiChartsAxis-left .MuiChartsAxis-line": {
      stroke: "#00000FF",
      strokeWidth: 0.4,
    },
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  if (userRole == "vipFranchise") {
    return (
      <DashGraphContainer className="container mt-4">
        <Select
          onChange={(e) => {
            setMonth(e.target.value);
          }}
        >
          {xLabels.map((month, i) => {
            if (currentMonth - 1 == i) {
              return (
                <option value={i} selected>
                  {month}
                </option>
              );
            }
            return <option value={i}>{month}</option>;
          })}
        </Select>
        <Select value={selectedYear} onChange={handleYearChange}>
          {Array.from({ length: 10 }, (_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </Select>
        <div className="row ">
          <div className="col col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>Collection</h4>
                </div>
                <BarChart
                  sx={BarPlotstyle}
                  colors={["#ec008bde"]}
                  height={300}
                  series={[
                    {
                      data: vipFranchiseData.collections,
                      label: "Collection",
                      id: "pvId",
                    },
                  ]}
                  xAxis={[{ data: vipFranchiseData.labels, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>

          <div className="col col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>Revenue</h4>
                </div>
                <BarChart
                  sx={BarPlotstyle}
                  colors={["#ec008bde"]}
                  height={300}
                  series={[
                    {
                      data: vipFranchiseData.revenues,
                      label: "collection",
                      id: "pvId",
                    },
                  ]}
                  xAxis={[{ data: vipFranchiseData.labels, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>
        </div>
      </DashGraphContainer>
    );
  }
  if (user == "franchise" || user == "branchstaff") {
    return (
      <DashGraphContainer className="container mt-4">
        <Select onChange={(e) => setMonth(e.target.value)}>
          {xLabels.map((month, i) => {
            if (currentMonth - 1 == i) {
              return (
                <option value={i} selected>
                  {month}
                </option>
              );
            }
            return <option value={i}>{month}</option>;
          })}
        </Select>
        <Select value={selectedYear} onChange={handleYearChange}>
          {Array.from({ length: 10 }, (_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </Select>
        <div className="row ">
          <div className="col col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>Student</h4>
                </div>

                <div className="container d-flex flex-wrap">
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center pt-2">
      <span>Total</span>
      <div>
        <img src={iconTotalUser} alt="" />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.totalStudents}</p>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center p-2">
      <span>Last month</span>
      <div style={{ color: "var(--icon-color)" }}>
        <Icon size={16} icon={calendar} />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.lastMonthStudent}</p>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center pt-2">
      <span>Current month</span>
      <div style={{ color: "var(--icon-color)" }}>
        <Icon size={16} icon={calendar} />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.currentMonthStudent}</p>
    </div>
  </div>
</div>

                <BarChart
                  sx={BarPlotstyle}
                  colors={preprocessData.color}
                  height={300}
                  series={[
                    {
                      data: preprocessData.students,
                      label: "students",
                      id: "pvId",
                    },
                  ]}
                  xAxis={[{ data: preprocessData.months, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>

          <div className="col col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>Collection</h4>
                </div>

                <div className="container d-flex flex-wrap">
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center pt-2">
      <span>Total</span>
      <div>
        <img src={iconTotalUser} alt="" />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.totalCollection}</p>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center p-2">
      <span>Last month</span>
      <div style={{ color: "var(--icon-color)" }}>
        <Icon size={16} icon={calendar} />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.lastMonthCollection}</p>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center pt-2">
      <span>Current month</span>
      <div style={{ color: "var(--icon-color)" }}>
        <Icon size={16} icon={calendar} />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.currentMonthCollection}</p>
    </div>
  </div>
</div>

                <BarChart
                  sx={BarPlotstyle}
                  colors={preprocessData.color}
                  height={300}
                  series={[
                    {
                      data: preprocessData.collection,
                      label: "collection",
                      id: "pvId",
                    },
                  ]}
                  xAxis={[{ data: preprocessData.months, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>
        </div>
      </DashGraphContainer>
    );
  } else if (user == "vip") {
    return (
      <DashGraphContainer className="container mt-4">
        <Select
          onChange={(e) => {
            setMonth(e.target.value);
          }}
        >
          {xLabels.map((month, i) => {
            if (currentMonth - 1 == i) {
              return (
                <option value={i} selected>
                  {month}
                </option>
              );
            }
            return <option value={i}>{month}</option>;
          })}
        </Select>
        <Select value={selectedYear} onChange={handleYearChange}>
          {Array.from({ length: 10 }, (_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </Select>
        <div className="row ">
          <div className="col col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>Student</h4>
                </div>

                <div className="container d-flex flex-wrap">
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center pt-2">
      <span>Total</span>
      <div>
        <img src={iconTotalUser} alt="" />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.totalStudents}</p>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center p-2">
      <span>Last month</span>
      <div style={{ color: "var(--icon-color)" }}>
        <Icon size={16} icon={calendar} />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.lastMonthStudent}</p>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center pt-2">
      <span>Current month</span>
      <div style={{ color: "var(--icon-color)" }}>
        <Icon size={16} icon={calendar} />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.currentMonthStudent}</p>
    </div>
  </div>
</div>

                <BarChart
                  sx={BarPlotstyle}
                  colors={preprocessData.color}
                  height={300}
                  series={[
                    {
                      data: preprocessData.students,
                      label: "students",
                      id: "pvId",
                    },
                  ]}
                  xAxis={[{ data: preprocessData.months, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>

          <div className="col col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>Collection</h4>
                </div>

                <div className="container d-flex flex-wrap">
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center pt-2">
      <span>Total</span>
      <div>
        <img src={iconTotalUser} alt="" />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.totalCollection}</p>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center p-2">
      <span>Last month</span>
      <div style={{ color: "var(--icon-color)" }}>
        <Icon size={16} icon={calendar} />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.lastMonthCollection}</p>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-md-4 mini-cont d-flex flex-column mb-3">
    <div className="mini-elements d-flex justify-content-between px-2 align-items-center pt-2">
      <span>Current month</span>
      <div style={{ color: "var(--icon-color)" }}>
        <Icon size={16} icon={calendar} />
      </div>
    </div>
    <div className="mt-2 d-flex flex-row-reverse" style={{ width: "100%" }}>
      <p className="count px-3">{preprocessData.currentMonthCollection}</p>
    </div>
  </div>
</div>

                <BarChart
                  sx={BarPlotstyle}
                  colors={preprocessData.color}
                  height={300}
                  series={[
                    {
                      data: preprocessData.collection,
                      label: "collection",
                      id: "pvId",
                    },
                  ]}
                  xAxis={[{ data: preprocessData.months, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>
        </div>
      </DashGraphContainer>
    );
  } else if (user == "admin" || user == "hos") {
    return (
      <DashGraphContainer className="container mt-4">
        <Select
          onChange={(e) => {
            setMonth(e.target.value);
          }}
        >
          {xLabels.map((month, i) => {
            if (currentMonth - 1 == i) {
              return (
                <option value={i} selected>
                  {month}
                </option>
              );
            }
            return <option value={i}>{month}</option>;
          })}
        </Select>
        <Select value={selectedYear} onChange={handleYearChange}>
          {Array.from({ length: 10 }, (_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </Select>
        <div className="row ">
          <div className="col-6 col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>VIP</h4>
                </div>
                <BarChart
                  sx={BarPlotstyle}
                  colors={["#ec008bde"]}
                  height={300}
                  series={[{ data: dataVip, label: "VIP", id: "VIP" }]}
                  xAxis={[{ data: adminLable, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>

          <div className="col-6 col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>VIP FRANCHISE</h4>
                </div>
                <BarChart
                  sx={BarPlotstyle}
                  colors={["#ec008bde"]}
                  height={300}
                  series={[
                    {
                      data: dataVipFranchise,
                      label: "VIP FRANCHISE",
                      id: "VIP FRANCHISE",
                    },
                  ]}
                  xAxis={[{ data: adminLable, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>
          <div className="col-6 col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>BRANCH STAFF</h4>
                </div>
                <BarChart
                  sx={BarPlotstyle}
                  colors={["#ec008bde"]}
                  height={300}
                  series={[
                    {
                      data: dataBranchStaff,
                      label: "BRANCH STAFF",
                      id: "BRANCH STAFF",
                    },
                  ]}
                  xAxis={[{ data: adminLable, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>

          <div className="col-6 col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>FRANCHISE</h4>
                </div>
                <BarChart
                  sx={BarPlotstyle}
                  colors={["#ec008bde"]}
                  height={300}
                  series={[
                    {
                      data: dataFranchise,
                      label: "FRANCHISE",
                      id: "FRANCHISE",
                    },
                  ]}
                  xAxis={[{ data: adminLable, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>
          <div className="col col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>Student</h4>
                </div>

                <div className="container d-flex">
                  <div className="col  mini-cont d-flex flex-column">
                    <div className="mini-elements d-flex justify-content-between px-2 align-items-center pt-2">
                      <span>Total</span>
                      <div>
                        <img src={iconTotalUser} alt="" />
                      </div>
                    </div>
                    <div
                      style={{ width: "100%" }}
                      className="mt-2 d-flex flex-row-reverse"
                    >
                      <p className="count px-3">{student?.length}</p>
                    </div>
                  </div>
                  <div className="col  mini-cont d-flex flex-column">
                    <div className="mini-elements d-flex justify-content-between px-2 align-items-center  pt-2">
                      <span>Current month</span>
                      <div style={{ color: "var(--icon-color)" }}>
                        <Icon size={16} icon={calendar} />
                      </div>
                    </div>
                    <div
                      style={{ width: "100%" }}
                      className="mt-2 d-flex flex-row-reverse"
                    >
                      <p className="count px-3">{sumStudent(adminStudentNo)}</p>
                    </div>
                  </div>
                </div>
                <BarChart
                  sx={BarPlotstyle}
                  colors={["#ec008bde"]}
                  height={300}
                  series={[
                    {
                      data: adminStudentNo,
                      label: "students",
                      id: "pvId",
                    },
                  ]}
                  xAxis={[{ data: adminStudentDate, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>
        </div>
      </DashGraphContainer>
    );
  }
  if (
    user == "vip" ||
    user == "admin" ||
    user == "hos" ||
    user == "franchise"
  ) {
    return (
      <DashGraphContainer className="container mt-4">
        <div className="row ">
          <div className="col col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>Student</h4>
                </div>

                <div className="container d-flex">
                  <div className="col  mini-cont d-flex flex-column">
                    <div className="mini-elements d-flex justify-content-between px-2 align-items-center pt-2">
                      <span>Total</span>
                      <div>
                        <img src={iconTotalUser} alt="" />
                      </div>
                    </div>
                    <div
                      style={{ width: "100%" }}
                      className="mt-2 d-flex flex-row-reverse"
                    >
                      <p className="count px-3">3</p>
                    </div>
                  </div>
                  <div className="col   mini-cont d-flex flex-column">
                    <div className="mini-elements d-flex justify-content-between px-2 align-items-center p-2">
                      <span>Last month</span>
                      <div style={{ color: "var(--icon-color)" }}>
                        <Icon size={16} icon={calendar} />
                      </div>
                    </div>
                    <div
                      style={{ width: "100%" }}
                      className="mt-2 d-flex flex-row-reverse"
                    >
                      <p className="count px-3">2</p>
                    </div>
                  </div>
                  <div className="col  mini-cont d-flex flex-column">
                    <div className="mini-elements d-flex justify-content-between px-2 align-items-center  pt-2">
                      <span>Current month</span>
                      <div style={{ color: "var(--icon-color)" }}>
                        <Icon size={16} icon={calendar} />
                      </div>
                    </div>
                    <div
                      style={{ width: "100%" }}
                      className="mt-2 d-flex flex-row-reverse"
                    >
                      <p className="count px-3">1</p>
                    </div>
                  </div>
                </div>
                <BarChart
                  sx={BarPlotstyle}
                  colors={color1}
                  height={300}
                  series={[{ data: pData, label: "pv", id: "pvId" }]}
                  xAxis={[{ data: xLabels, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>
          <div className="col col-xs-12 pt-1">
            <div className="graph-container">
              <div className=" flex-column align-items-start">
                <div>
                  <h4>Student</h4>
                </div>

                <div className="container d-flex">
                  <div className="col  mini-cont d-flex flex-column">
                    <div className="mini-elements d-flex justify-content-between px-2 align-items-center pt-2">
                      <span>Total</span>
                      <div>
                        <img src={iconTotalUser} alt="" />
                      </div>
                    </div>
                    <div
                      style={{ width: "100%" }}
                      className="mt-2 d-flex flex-row-reverse"
                    >
                      <p className="count px-3">3</p>
                    </div>
                  </div>
                  <div className="col   mini-cont d-flex flex-column">
                    <div className="mini-elements d-flex justify-content-between px-2 align-items-center p-2">
                      <span>Last month</span>
                      <div style={{ color: "var(--icon-color)" }}>
                        <Icon size={16} icon={calendar} />
                      </div>
                    </div>
                    <div
                      style={{ width: "100%" }}
                      className="mt-2 d-flex flex-row-reverse"
                    >
                      <p className="count px-3">2</p>
                    </div>
                  </div>
                  <div className="col  mini-cont d-flex flex-column">
                    <div className="mini-elements d-flex justify-content-between px-2 align-items-center  pt-2">
                      <span>Current month</span>
                      <div style={{ color: "var(--icon-color)" }}>
                        <Icon size={16} icon={calendar} />
                      </div>
                    </div>
                    <div
                      style={{ width: "100%" }}
                      className="mt-2 d-flex flex-row-reverse"
                    >
                      <p className="count px-3">1</p>
                    </div>
                  </div>
                </div>
                <BarChart
                  sx={BarPlotstyle}
                  colors={color1}
                  height={300}
                  series={[{ data: pData, label: "pv", id: "pvId" }]}
                  xAxis={[{ data: xLabels, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>
        </div>
      </DashGraphContainer>
    );
  }

  return (
    <>
      <div>
        <p>no records found</p>
      </div>
    </>
  );
}
const Select = styled.select`
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #36283a;
  color: white;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
    margin: 0 3px;
  }
`;
const DashGraphContainer = styled.div`
  .mini-elements {
    width: 100%;
  }
  .mini-elements span {
    margin-top: none;
    font-size: 0.8rem;
  }
  .mini-elements img {
    height: 1rem;
  }
  .count {
    color: var(--icon-color);
    font-size: large;
    text-align: end;
  }
  .fa-solid {
    font-size: 1rem;
  }
  .css-1u0lry5-MuiChartsLegend-root {
    display: none;
  }
  g.MuiChartsLegend-root.MuiChartsLegend-row.css-0 {
    display: none;
  }
    @media (max-width: 1000px) {
  .col-6 {
    width: 100% !important;
  }
    
}

`;
export default DashBoardGraph;
