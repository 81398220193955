import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "styled-components";
import { enqueueSnackbar } from "notistack";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputBase,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  Box,
  InputLabel,
  MenuItem,
  Select, 

} from "@mui/material";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { fetchData } from "../../../Api";
import axios from "axios";
import { URL } from "../../../assets/mocData/config";
import CircularProgress from "@mui/material/CircularProgress";

const theme = createTheme({
  palette: {
    primary: { 
      main: "#a70e6e", // Pink color
    },
    text: {
      primary: "#ffffff", // White color for input text
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "black", // Dark background color for the input
          color: "#ffffff", // White color for input text
          borderRadius: "4px", // Optional: if you want rounded corners
          "&:hover": {
            backgroundColor: "black", // Slightly lighter dark color on hover
          },
          "&.Mui-focused": {
            backgroundColor: "black", // Ensure the background stays dark when focused
          },
        },
        input: {
          color: "#ffffff", // White color for input text
        },
        underline: {
          "&:before": {
            borderBottom: `1px solid #ffffff`, // Initial border color
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: `1px solid #a70e6e`, // Border color on hover
          },
          "&:after": {
            borderBottom: `2px solid #a70e6e`, // Border color when focused
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "gray", // White color for label
          "&.Mui-focused": {
            color: "#a70e6e", // Pink color for label when focused
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#25272d", // Dark background color for dropdown options
          color: "#ffffff", // White text color for dropdown options
        },
      },
    }, 
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#ffffff", // White color for the dropdown icon
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#a70e6e", // Pink color on hover
          },
        },
      },
    },
  },
});
function AddCourse() {
  let { userRole, userToken, setConceptData } = useGlobalContext();
  const [data, setData] = useState({
    courseCode: "",
    courseName: "",
    courseFee: "",
    status: "",
    conceptName: "",
    vipPercentage: "",
    companyCommissionPercentage:"",
    concept: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [concept, setConcept] = useState([]);
  const [selectedValue, setSelectedValue] = useState("a");

  // Fetch concepts on mount
  useEffect(() => {
    (async () => {
      let header = {
        userRole: userRole,
        userToken: userToken,
      };
      try {
        let response = await fetchData(`${URL}/${userRole}/concept`, header);
        setConcept(response);
        setConceptData(response);
      } catch (error) {
        setConcept([]);
      } 
    })();
  }, []);

  // Handle form input changes
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "status",
    inputProps: { "aria-label": item },
  });

  // Handle form field updates
  const FormData = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "conceptName") {
      let d = concept?.filter((ele) => ele.conceptName == value);
      setData({
        ...data,
        [e.target.name]: value,
        concept: d[0]._id,
      });
    } else {
      setData({
        ...data,
        [e.target.name]: value,
      });
    }
  };

  // Validate form data
  const validateData = () => {
    if (!data.courseCode.trim()) {
      enqueueSnackbar("Course Code is required.", { variant: "warning" });
      return false;
    }
    if (!data.courseName.trim()) {
      enqueueSnackbar("Course Name is required.", { variant: "warning" });
      return false;
    }
    if (!data.courseFee.trim()) {
      enqueueSnackbar("Course Fee is required.", { variant: "warning" });
      return false;
    }
    if (!data.status.trim()) {
      enqueueSnackbar("Status is required.", { variant: "warning" });
      return false;
    }
    if (!data.conceptName.trim()) {
      enqueueSnackbar("Concept Name is required.", { variant: "warning" });
      return false;
    }
    if (!data.vipPercentage.trim()) {
      enqueueSnackbar("VIP Percentage is required.", { variant: "warning" });
      return false;
    }
    if (!data.companyCommissionPercentage.trim()) {
      enqueueSnackbar("Company Commission Percentage is required.", { variant: "warning" });
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate before submitting
    if (!validateData()) {
      return; // Stop if validation fails
    }

    

    try {
      let response = await axios.post(`${URL}/admin/courselist`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          role: userRole,
        },
      });

      enqueueSnackbar("Course added successfully!", {
        autoHideDuration: 1000,
        variant: "success",
      });

      // Reset the form fields after successful addition
      setData({
        courseCode: "",
        courseName: "",
        courseFee: "",
        status: "",
        conceptName: "",
        vipPercentage: "",
        companyCommissionPercentage: "",
      });
      setSelectedValue("");
    } catch (error) {
      if (error.response && error.response.data.error) {
        const errorMessage = error.response.data.error;

        // Handle duplicate key errors
        if (errorMessage.includes("E11000")) {
          if (errorMessage.includes("courseCode")) {
            enqueueSnackbar("Course Code already exists.", { variant: "warning" });
          } else if (errorMessage.includes("courseName")) {
            enqueueSnackbar("Course Name already exists.", { variant: "warning" });
          }
        } else {
          enqueueSnackbar(errorMessage, { variant: "warning" });
        }
      } else {
        enqueueSnackbar("Something went wrong. Please try again later.", {
          variant: "error",
        });
      }
    } 
  };

  // Handle form reset (cancel)
  const handleCancel = () => {
    setData({
      courseCode: "",
      courseName: "",
      courseFee: "",
      status: "",
      conceptName: "",
      vipPercentage: "",
      companyCommissionPercentage: "",
    });
    setSelectedValue(""); // Reset the status RadioGroup value
  };

  return (
    <AddCourseContainer className="scroll" style={{ height: "100%" }}>
      {isLoading && (
        <div
          style={{
            width: "80%",
            height: "80%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
            backgroundColor: "rgb(0,0,0)",
            backgroundColor: "rgba(0,0,0, 0.4)",
          }}
        >
          <CircularProgress color="success" />
        </div>
      )}

      <ThemeProvider theme={theme}>
        <div class="d-flex">
          <div id="add-Concept-container">
            {/* <h2 style={{textAlign: "left"}}>Add Course</h2> */}
            <div class="container">
              <form onChange={FormData}>
                <div class="row">
                  <div class="col col-12">
                    <div id="form-container" class="m-1">
                      <form
                        style={{ height: "auto", paddingTop: "1rem" }}
                        action=""
                      >
                        <div class="container">
                          <div class="row">
                            <hr />
                            <span class="col-3 inputHeadings">Course Code</span>
                            <div class="col-md-9 col-sm-12 d-flex flex-column px-3">
                              <span
                                style={{ width: "100%" }}
                                class="d-flex justify-content-between"
                              >
                                <TextField
                                  value={data.courseCode}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  id="filled-basic"
                                  label="Enter Course Code"
                                  name="courseCode"
                                  type="text"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="container">
                          <div class="row">
                            <span class="col-3 inputHeadings">Course Name</span>
                            <div class="col-9 d-flex flex-column px-3">
                              <span
                                style={{ width: "100%" }}
                                class="d-flex justify-content-between"
                              >
                                <TextField
                                  value={data.courseName}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  id="filled-basic"
                                  label="Enter Course Name"
                                  name="courseName"
                                  type="text"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="container">
                          <div className="row">
                            <span className="col-3 inputHeadings">
                              Concept Name
                            </span>
                            <div className="col-9 d-flex flex-column px-3">
                              <span
                                style={{ width: "100%" }}
                                className="d-flex  justify-content-between"
                              >
                                <Box sx={{ width: "100%" }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="concept-select-label">
                                      Select Concept
                                    </InputLabel>
                                    <Select
                                      value={data.conceptName}
                                      labelId="concept-select-label"
                                      id="concept-select"
                                      variant="filled"
                                      name="conceptName"
                                      label="Select Concept"
                                      required
                                      onChange={FormData}
                                    >
                                     
                                      {concept?.map((element) => (
                                        <MenuItem
                                          key={element._id}
                                          value={element.conceptName}
                                        >
                                          {element.conceptName}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="container">
                          <div class="row">
                            <span class="col-3 inputHeadings">Course Fee</span>
                            <div class="col-9 d-flex flex-column px-3">
                              <span
                                style={{ width: "100%" }}
                                class="d-flex justify-content-between"
                              >
                                <TextField
                                  value={data.courseFee}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  id="filled-basic"
                                  label="Enter Course fee"
                                  name="courseFee"
                                  type="number"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="container">
                          <div class="row">
                            <span class="col-3 inputHeadings">
                              Vip Revenue Percentage
                            </span>
                            <div class="col-9 d-flex flex-column px-3">
                              <span
                                style={{ width: "100%" }}
                                class="d-flex justify-content-between"
                              >
                                <TextField
                                  value={data.vipPercentage}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  id="filled-basic"
                                  label="Enter Vip Revenue Percentage"
                                  name="vipPercentage"
                                  type="number"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="container">
                          <div class="row">
                            <span class="col-3 inputHeadings">
                              Company Commission Percentage%
                            </span>
                            <div class="col-9 d-flex flex-column px-3">
                              <span
                                style={{ width: "100%" }}
                                class="d-flex justify-content-between"
                              >
                                <TextField
                                  value={data.companyCommissionPercentage}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  id="filled-basic"
                                  label="Enter Percentage"
                                  name="companyCommissionPercentage"
                                  type="number"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {/* <!-- Status Section --> */}
                        <div class="container status-section">
                          <div class="row align-items-center">
                            <span class="col-3 inputHeadings">Status</span>
                            <div class="col-9 d-flex flex-column px-3">
                              <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">
                                  Status
                                </FormLabel>
                                <RadioGroup
                                  className="d-sm-flex flex-sm-column flex-md-row"
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  value={selectedValue}
                                >
                                  <FormControlLabel
                                    name="status"
                                    value="active"
                                    control={
                                      <Radio
                                        name="status"
                                        {...controlProps("active")}
                                        color="success"
                                      />
                                    }
                                    label="Active"
                                  />
                                  <FormControlLabel
                                    name="status"
                                    value="inactive"
                                    control={
                                      <Radio
                                        name="status"
                                        {...controlProps("inactive")}
                                      />
                                    }
                                    label="In Active"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                        </div>

                        {/* <div class="container status-section">
                        <div class="row align-items-center">
                          <span class="col-3 inputHeadings">Status</span>
                          <div class="col-9 d-flex flex-column px-3">
                            <div
                              class="form-check"
                              style={{ position: "relative", top: "1.8rem" }}
                            >
                              <Radio {...controlProps("c")} color="success" />
                              <Radio
                                {...controlProps("e")}
                                sx={{
                                  color:"pink",
                                  "&.Mui-checked": {
                                    color: "pink",
                                  },
                                }}
                              />
                              <input
                                required={true}
                                class="form-check-input active-radio"
                                type="radio"
                                name="status"
                                value="active"
                                id="flexRadioDefault1"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault1"
                              >
                                <p>Active</p>
                              </label>
                              <div
                                class="form-check"
                                style={{
                                  position: "relative",
                                  bottom: "40px",
                                  left: "7rem",
                                }}
                              >
                                <input
                                  class="form-check-input inactive-radio"
                                  type="radio"
                                  name="status"
                                  value="inactive"
                                  id="flexRadioDefault2"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault2"
                                >
                                  <p>Inactive</p>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>  */}
                        <div class="container mt-3">
                          <div class="row justify-content-center">
                            <div class="col-12 d-flex justify-content-center">
                              <div class="d-flex flex-column flex-sm-row align-items-center">
                                <button
                                  onClick={(e) => handleSubmit(e)}
                                  type="submit"
                                  class="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-concept-btn"
                                >
                                  Add Course
                                </button>
                                <button
                                  onClick={handleCancel}
                                  type="button"
                                  class="btn btn-outline-danger btn-sm cancel-btn"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </AddCourseContainer>
  );
}
const AddCourseContainer = styled.div`
 height: 80vh;
  input::placeholder {
    color: gray !important;
  }
  #add-Concept-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: gray !important;
  }

  h2,
  p,
  span,
  h6 {
    color: white;
  }
  .add-concept-btn:hover {
    background-color: green;
  }
  .cancel-btn:hover {
    background-color: red;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }

  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }

  h5 {
    text-align: center;
    color: white;
  }

  /* Status Section Styles */
  .status-section {
    margin-top: 1rem;
  }

  .status-label {
    color: white;
  }

  .status-options {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .active-dot {
    background-color: green;
  }

  .inactive-dot {
    background-color: red;
  }

  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #add-Concept-container {
      padding: 1rem;
    }

    #profile-container {
      margin-top: 1rem;
    }
  }
  /* Style for active radio button */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }
  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }
  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }
  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  .add-concept-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }
      @media (max-width: 575px) {
    .add-concept-btn,
    .cancel-btn {
      align-items: center;
      width: 100%; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
    }


`;
export default AddCourse;
