import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import styled from 'styled-components';
import { useGlobalContext } from '../../../assets/contex/GlobalContext';
import { imageURL } from '../../../Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery, Snackbar } from '@mui/material'; // Importing Snackbar and useMediaQuery
import MuiAlert from '@mui/material/Alert';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import TopContainer from "../subcomponents/TopContainer";



const ProfileComponent = () => {
  const [filterData, setFilterData] = useState("");
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const isSmallScreen = useMediaQuery('(max-width:1000px)'); // Check if screen is small
  const { profileImage, profileData, setProfileImage, setProfileData } = useGlobalContext();

  const FilterOpen = () => {
    setFilter(!filter);
  };

  const downloadColumn = async (columnId, filename) => {
    setLoading(true);
    try {
      const element = document.getElementById(columnId);
      if (element) {
        const canvas = await html2canvas(element, { useCORS: true });
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = filename;
        link.click();
      }
    } catch (error) {
      console.error('Error downloading the column:', error);
    }
    setLoading(false);
  };

  const fetchImageBlob = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error fetching image:', error);
      return null;
    }
  };

  const handleDownload = async () => {
    if (isSmallScreen) {
      // If screen is small, show Snackbar message and return early
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      // Convert profile image URL to Blob URL
      const profileImageBlobURL = await fetchImageBlob(imageURL(profileData.profileData.imageACCESSKEY));
      
      // Temporarily update the image src to the Blob URL
      if (profileImageBlobURL) {
        document.getElementById('profilePhoto').src = profileImageBlobURL;
      }

      // Download the columns
      await downloadColumn('leftColumn', 'left_column.png');
      setTimeout(async () => {
        await downloadColumn('rightColumn', 'right_column.png');
      }, 2000);
    } catch (error) {
      console.error('Error downloading columns:', error);
    }
    setLoading(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return ( 
    <Container>
      <div id="course-list" style={{ height: "100vh", overflowY: "scroll", position: "relative" }}>
    <Heading>ID CARD</Heading>
      <ProfileContainer id="profileContainer"  >
        <LeftColumn id="leftColumn">
          <BadgeImage>
            <img src="/tia.png" alt="Badge" />
          </BadgeImage>
          <ProfilePhoto>
            <img id="profilePhoto" src={imageURL(profileData.profileData.imageACCESSKEY)} alt="Profile" />
          </ProfilePhoto>
          <UserInfo>
          <h1 style={{ color: "#f00d88" }}>
            {profileData.profileData.userName.toUpperCase()}
         </h1>

            <Label>Staff ID: {profileData.profileData.user_id}</Label>
            <Label>Phone: {profileData.profileData.phoneNumber}</Label>
            <Label>Email: {profileData.profileData.email}</Label>
          </UserInfo>
        </LeftColumn>
        <RightColumn id="rightColumn">
          <AddressContainer>
            <LocationIcon icon={faLocationDot} />
            <AddressLabel>Address :</AddressLabel>
            <AddressText>{profileData.profileData.doorFlatNumber} {profileData.profileData.streetName}, {profileData.profileData.district}, {profileData.profileData.state}, {profileData.profileData.country} - {profileData.profileData.pinCode}</AddressText>
            <CompanyDetails>
              <BadgeImage1>
                <img src="/tia.png" alt="Badge" />
              </BadgeImage1>
              <CompanyName>talent initiators and accelerators india pvt ltd</CompanyName>
              <AddressText>51/1, P&T Colony, Chettipalayam Road, Podanur, Coimbatore: 641023</AddressText>
              <AddressText>Tamil Nadu</AddressText>
              <Website>
          <FontAwesomeIcon icon={faGlobe} style={{ marginRight: '8px' }} />
           www.tiaedu.in
         </Website>

            </CompanyDetails>
          </AddressContainer>
        </RightColumn>
      </ProfileContainer>
     
      <DownloadButton onClick={handleDownload} disabled={loading}>
        {loading ? 'Downloading...' : 'Download ID card'}
      </DownloadButton>


      {/* Snackbar for small screen warning */}
      <Snackbar open={snackbarOpen} autoHideDuration={4000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
          Download is only available on large screens.
        </MuiAlert>
      </Snackbar>
      </div>
    </Container>
  );
};

const Heading = styled.h1`
  display: flex;
  color:White;
  padding:1rem;
  justify-content:center; /* Align left by default */

  @media (max-width: 768px) {
    justify-content: center; /* Center align below 768px */
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding:3rem;
  margin: 0 auto;
  @media (max-width:768px) {
    padding:1rem;
  }

    #course-list {
    width: 100%;
    height: 100vh;
     position: relative;
  }
     #course-list::-webkit-scrollbar {
  display: none;
}

/* Firefox */
#course-list {
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}
`;





const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 700px;
  
  @media (max-width: 768px) {
    min-height: 600px;
  }

  @media (max-width: 480px) {
    min-height: 500px;
  }
`;
const LeftColumn = styled(Column)`
  background: url('/vip.png') center center no-repeat;
  background-size: cover;
  width: 100%;
  height:420px; // Ensures the column has a minimum height


 
  @media (max-width: 1000px) {
    background-size: 100% 100%; // Ensures the background fits the height of content
    height:700px; // Allow content to define height
    min-height: 400px; // Adjust this for proper height
  }

  @media (max-width: 768px) {
    background-size: 100% 100%;
     width:380px;
    height:700px;
    min-height: 350px; // Smaller screens still need proper height
  }

  @media (max-width: 480px) {
    background-size: 100% 100%;
    width:280px;
    height:590px;
    min-height: 300px; // Keep content readable at smaller sizes
  }
`;

const RightColumn = styled(Column)`
  background: url('/vip.png') center center no-repeat;
  background-size: cover;
  width: 100%;
  height:420px;// Ensures minimum height for smaller screens
  

 
  @media (max-width: 1000px) {
    background-size: 100% 100%; 
    width:380px;
    height:700px;
    min-height: 400px; // Adjust to avoid content squishing
  }

  @media (max-width: 768px) {
    background-size: 100% 100%;
     width:380px;
    height:700px;
    min-height: 350px;
  }

  @media (max-width: 480px) {
    background-size: 100% 100%;
    width:280px;
    height:590px;
    min-height: 300px;
  }
`;

const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:18px; // Avoid gaps between columns
  border: 2px solid #000;
  border-radius: 20px;
  width: 100%;
  max-width: 750px;
  background-color:var(--background);

  @media (max-width: 1000px) {
    grid-template-columns: 1fr; // Stack columns on small screens
    height: auto;
    min-height: 400px; // Ensure the whole container maintains proper height
  }
`;

const DownloadButton = styled.button`
  background-color:#f00d88;
  color: #fff;
  margin-top: 20px;
  margin-left:40%;
  border-radius:5px;
  padding: 10px 20px;
  border: none;
  margin-top: 20px;
    @media (max-width: 1000px){
    display:none;
    }
`;

const ProfilePhoto = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 10%;
  overflow: hidden;
  border: 4px solid #f00d88;
  margin: 20px 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1000px) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 480px) {
    width: 80px;
    height: 80px;
  }
`;

const BadgeImage = styled.div`
  text-align: center;
  margin-bottom: 10px;

  img {
    max-width: 100px;
    height: auto;
  }
`;

const BadgeImage1 = styled.div`
  text-align: center;
  margin-bottom: 10px;

  img {
    max-width: 100px;
    height: auto;
  }
`;
const UserInfo = styled.div`
  text-align: center;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;

    @media (max-width: 1000px) {
      font-size: 1.2rem;
    }
  }

  p {
    font-size: 1rem;
    margin-bottom: 5px;

    @media (max-width: 1000px) {
      font-size: 0.9rem;
    }
  }
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 30px;
`;

const LocationIcon = styled(FontAwesomeIcon)`
  color: #f00d88;
  font-size:2rem;
  width: 40px;
  margin-bottom: 10px;
`;

const AddressLabel = styled.h2`
  font-size: 1.3rem;
  color: #000;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const AddressText = styled.p`
  font-size: 1rem;
  margin: 5px 0;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const CompanyDetails = styled.div`
  margin-top: 50px;
`;

const CompanyName = styled.h3`
  font-size: 1.2rem;
  color: #d14991;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const Website = styled.p`
  font-size: 1rem;
  color: #000;
  margin-top: 10px;
  left:50
  display: flex; 
  align-items: center;
  text-decoration: underline;

  svg {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;

    svg {
      font-size: 1rem; // Adjust the icon size for smaller screens
    }
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;

    svg {
      font-size: 0.8rem; // Adjust the icon size for smaller screens
    }
  }
`;

const Label = styled.div`
  color: #111;
  border-radius: 10px;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  width: fit-content;
  margin-bottom: 15px;
  font-weight: 600;

  @media (max-width: 1005px) {
    font-size: 16px;
    padding: 4px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;



export default ProfileComponent;