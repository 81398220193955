import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { URL as url } from '../../../assets/mocData/config';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
 
const BranchTableContainer = styled.div`
  padding-top: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Button = styled.button`
  margin: 10px;
  background-color: var(--navbar-dark-primary);
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f00d88;
  }
`;

const CenterGroup = styled.div`
  display: flex;
  align-items: center;
`;

const Select = styled.select`
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #36283a;
  color: white;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  position: relative;
  background-color: #25272d;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  color: white;

  @media (min-width: 1024px) {
    width: 50%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  color: #bf2f82c4;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #36283a;
  color: white;

  &::placeholder {
    color: gray;
  }
`;

const SuggestionsList = styled.ul`
  margin-top: 0;
  padding: 0;
  list-style-type: none;
  background-color: #25272d;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;

  li {
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: #3a3d46;
    }
  }
`;

const SelectAllButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #004494;
  }
`;

const AttendanceTableContainer = styled.div`
  margin-top: 10px;
  overflow-x: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  max-height: 400px;
  overflow-y: auto;

  @media (min-width: 768px) {
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    max-width: 72vw;
  }
`;

const AttendanceTable = styled.div`
  display: flex;
  overflow: hidden;
  height: 50vh;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    color: white;
    white-space: nowrap;
  }

  th {
    background-color: var(--navbar-dark-primary);
    color: #fff;
    font-weight: bold;
  }

  td {
    background-color: #36283a;
  }

  tr:nth-child(even) td {
    background-color: #2c2f36;
  }

  tr:hover td {
    background-color: #3a3d46;
  }
`;

const StaffColumn = styled.div`
  position: sticky;
  left: 0;
  background-color: #25272d;
  z-index: 1;

  table {
    width: 100%;
  }
`;

const DatesColumn = styled.div`
  overflow-x: auto;
`;

const AttendanceForm = ({ closeModal }) => {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStaff, setSelectedStaff] = useState(null); // Assuming you will use this later
  const [status, setStatus] = useState('full-day');
  const [suggestions, setSuggestions] = useState([]);
  const selectallarray=[]

  const currentDate = new Date().toISOString().split('T')[0];

  // Handlers
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    // Update suggestions based on searchTerm here
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSelectAll = () => {
    alert('Select All Clicked!');

    console.log("Sanjai")
    console.log()

  };

  const handleSubmit=async(e)=>{
    e.preventDefault()
    console.log(searchTerm)
    
    const san = currentDate.split("-");
// Parse the month from the current date (san[1] is the month part of YYYY-MM-DD)
const monthIndex = parseInt(san[1], 10) - 1; // Convert to 0-based index
const monthes = monthNames[monthIndex];

const data = {
  id: searchTerm,
  month:monthes,
  year:san[0],
  arrayValue: {
    data: parseInt(san[2],10),// Day part of YYYY-MM-DD
    attent: status
  }
};
console.log(data);

try {
  await axios.post(`${url}/attantance/updateif`, data);

  window.location.reload();
} catch (err) {
  console.log('Error updating attendance data:', err);
}

    
  }

  return (
    <ModalBackground onClick={closeModal}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <SelectAllButton onClick={handleSelectAll}>Select All</SelectAllButton>
        <Form  onSubmit={handleSubmit}>
          <Label>Employee ID Search</Label>
          <Input
            type="text"
            placeholder="Search by Employee ID or Name"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {suggestions.length > 0 && (
            <SuggestionsList>
              {suggestions.map((suggestion, index) => (
                <li key={index}>
                  {suggestion.id} - {suggestion.name}
                </li>
              ))}
            </SuggestionsList>
          )}

          <Label>Date</Label>
          <Input type="text" value={currentDate} readOnly />

          <Label>Status</Label>
          <Select value={status} onChange={handleStatusChange}>
            <option value="full-day">Full Day</option>
            <option value="half-day">Half Day</option>
            <option value="absent">Absent</option>
          </Select>

          <Button type="submit">Submit</Button>
        </Form>
      </ModalContainer>
    </ModalBackground>
  );
}

const MarkAttendanceButton = () => {

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]
  const [isModalOpen, setIsModalOpen] = useState(false);
  const month=new Date().getMonth()
  const [selectedMonth,setSelectedMonth] = useState(monthNames[month]);
  const [selectedYear,setSelectedYear] =useState(new Date().getFullYear())
  const daysInMonth = 31;
  const [attendanceData,setAttendanceData] =useState([]);
  const [filteredDatas,setFilteredDatas]=useState([])

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  


  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    console.log(event.target.value)
  };
  const handleYearChange=(event)=>{
    setSelectedYear(event.target.value)
    console.log(event.target.value)
  }


  const getData=async()=>{
    const data={year:`${selectedYear}`}
    try {
      // Construct the URL with query parameters
      const response = await axios.post(`${url}/attantance/attantanceView`, data);
  
      console.log(response.data);
      setAttendanceData(response.data);
      const filteredDataByMonths = filterMonthData(response.data, selectedMonth)
      setFilteredDatas(filteredDataByMonths)
      console.log(filteredDataByMonths)
    

  }
  catch(err){
    console.log(err)
  }
  }


  const filterMonthData = (data, month) => {
    return data.map(entry => {
      // Check if the month key exists in the entry object
      const monthData = entry[month] !== undefined ? entry[month] : []; // Default to an empty array if the month key is not found
  
      return {
        staffId: entry.staffId,
        staffName: entry.staffName,
        joinigdate:entry.joiningdate,

        [month]: monthData // Extract data for the specified month
      };
    });
  };
  
  // Specify the month you're interested in (e.g., 'january', 'february', etc.)
  // Change this to the desired month
  
  

  useEffect(()=>{
    getData()
   
   
  },[selectedMonth,selectedYear])

  return (
    <BranchTableContainer>
      <ButtonGroup>
        <Button onClick={openModal}>
          Mark Attendance for today
        </Button>
        <CenterGroup>
          <Select value={selectedMonth} onChange={handleMonthChange}>
            {Array.from({ length: 12 }, (_, i) => {
              const month = new Date(0, i).toLocaleString('default', { month: 'long' });
              return <option key={i} value={month}>{month}</option>;
            })}
          </Select>
          <Select value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: 10 }, (_, i) => {
              const year = new Date().getFullYear() - i;
              return <option key={year} value={year}>{year}</option>;
            })}
          </Select>
        </CenterGroup>
        <Button onClick={() => alert('Edit Attendance Clicked!')}>
          Edit Attendance
        </Button>
      </ButtonGroup>
      
      {isModalOpen && <AttendanceForm closeModal={closeModal} />}

      <AttendanceTableContainer>
        <AttendanceTable>
          <StaffColumn>
            <table>
              <thead>
                <tr>
                  <th>Staff ID</th>
                  <th>Staff Name</th>
                </tr>
              </thead>
              <tbody>
                {attendanceData.map((staff, index) => (
                  <tr key={index}>
                    <td>{staff.staffId}</td>
                    <td>{staff.staffName}</td>

                  </tr>
                ))}
              </tbody>
            </table>
          </StaffColumn>
          <DatesColumn>
            <table>
              <thead>
                <tr>
                  {[...Array(daysInMonth).keys()].map((day) => (
                    <th key={day + 1}>{day + 1}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredDatas.map((staff, index) => (
                 <tr key={index}>
                 {staff[selectedMonth] && staff[selectedMonth].length > 0 ? (
                   staff[selectedMonth].map((data, idx) => (
                     <td key={idx}>{data.attent===""?<CheckBoxOutlineBlankIcon/>:<CheckBoxIcon/>}</td>
                   ))
                 ) : (
                   <td>No data available</td> // Adjust colSpan as needed
                 )}
               </tr>
                ))}
              </tbody>
            </table>
          </DatesColumn>
        </AttendanceTable>
      </AttendanceTableContainer>
    </BranchTableContainer>
  );
};

export default MarkAttendanceButton;