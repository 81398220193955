import React from 'react'
import { useGlobalContext } from "../assets/contex/GlobalContext";
import NavBar from "./miniComponents/subcomponents/NavBar";
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import { styled } from "styled-components";
import { ReferencedForm } from './secondaryContainersForPages/AllReferedUsers';
export default function ReferedUsers() {
  return (
    <RefereUserContainer>
      <MiniNavBar selected={"refereduser"} />
      <NavBar selected={"refereduser"} />
      <ReferencedForm />
    </RefereUserContainer>
  );
}
const RefereUserContainer = styled.div`
  display: flex;
  background-color: var(--background);
`;
