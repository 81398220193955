import React from "react";
import MiniNavBar from "../miniComponents/subcomponents/MiniNavBar";
import NavBar from "../miniComponents/subcomponents/NavBar";
import { styled } from "styled-components";
import HosListTable from "./HosListTable";

function HosDetailContainer() {
  return (
    <HosDetailsContainer>
      <MiniNavBar selected={"hosstaff"} />
      <NavBar selected={"hosstaff"} />
      <HosListTable />
    </HosDetailsContainer>
  );
}

const HosDetailsContainer = styled.div`
  display: flex;
  background-color: var(--background);
  overflow: hidden;
`;
export default HosDetailContainer;
