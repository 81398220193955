import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL as url } from "../../../assets/mocData/config";
import { fetchData } from "../../../Api";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import * as XLSX from 'xlsx';
import { Button } from "@mui/material";
export function ViewVipReferalTable({ filterData, setAddVipData, setSelectedView }) {
  const [referedUserData, setUserData] = useState([]);
  const [filteredReferedUserData, setFilteredUserData] = useState([]); // State to hold filtered data
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(50);
  const { userToken, userRole, profileData } = useGlobalContext();
  const navigate = useNavigate();

  // Fetch VIP referral data
  const fetchVIP = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };
    const endpoint = userRole === "admin" || userRole === "hos"
      ? `${url}/vip/refer/vip`
      : `${url}/vip/refer/vip/${profileData.profileData.user_id}`;

    try {
      let response = await fetchData(endpoint, header);
      setUserData(response.data);
      setFilteredUserData(response.data); // Initialize filtered data with full data
    } catch (error) {
      enqueueSnackbar(`${error.response.data.message}`, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchVIP();
  }, [userRole, profileData.profileData.user_id]);

  // Filtering logic based on search query
  useEffect(() => {
    const filteredData = referedUserData.filter((data) =>
      data.userName.toLowerCase().includes(filterData.toLowerCase()) ||
      (data.referredBy?.name || profileData.profileData.user_id)
        .toLowerCase()
        .includes(filterData.toLowerCase()) ||
      data.email.toLowerCase().includes(filterData.toLowerCase()) ||
      data.phoneNumber.toLowerCase().includes(filterData.toLowerCase())
    );
    setFilteredUserData(filteredData); // Set the filtered data
  }, [filterData, referedUserData]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredReferedUserData.slice(indexOfFirstRow, indexOfLastRow); // Use filtered data

  const getTotalPages = () => {
    return Math.ceil(filteredReferedUserData.length / rowsPerPage);
  };
  const exportToExcel = () => {
    const dataToExport = filteredReferedUserData.map((data, index) => ({
      'S.no': index + 1,
      'Refferal Name': data.userName,
      'Reffered By': data.referredBy?.name || profileData.profileData.user_id,
      'District': data.district,
      'State': data.state,
      'Country': data.country,
      'Pincode': data.pinCode,
      'Email': data.email,
      'Phone Number': data.phoneNumber,
      'Detail Completion': data.otherDetails.profileDataCompletion ? 'COMPLETED' : 'PENDING',
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "VIP Referral Data");
    
    XLSX.writeFile(wb, "VIP_Referral_Data.xlsx");
  };
  return (
    <ViewVipReferalTableContainer>
      <div id="VipRefferals-table-container">
      <Button
            variant="outlined"
             className="download-btn"
            onClick={exportToExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88",
            }}
            disabled={currentRows.length === 0}
          >
            Download Excel
          </Button>
        <div id="table-container">
          <div className="scroll">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="t-head rounded-start-3" scope="col">
                    S.no
                  </th>
                  <th className="t-head" scope="col">
                    Refferal Name
                  </th>
                  <th className="t-head " scope="col">
                    Reffered By
                  </th>
                  <th className="t-head " scope="col">
                    District
                  </th>
                  <th className="t-head " scope="col">
                    State
                  </th>
                  <th className="t-head " scope="col">
                    Country
                  </th>
                  <th className="t-head " scope="col">
                    Pincode
                  </th>
                  <th className="t-head " scope="col">
                    Email
                  </th>
                  <th className="t-head" scope="col">
                    Phone Number
                  </th>
                  <th className="t-head" scope="col">
                    Detail Completion
                  </th>
                  {(userRole === "admin" || userRole === "hos") && (
                    <th className="t-head rounded-end-3" scope="col">
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="table-dark">
                {currentRows.length === 0 ? (
                  <tr>
                    <td colSpan="10" style={{ textAlign: "center", padding: "2rem" }}>
                      <SentimentVeryDissatisfiedIcon fontSize="large" />
                      <p>No Records found</p>
                    </td>
                  </tr>
                ) : (
                  currentRows.map((data, i) => (
                    <tr key={data.user_id}>
                      <td>{indexOfFirstRow + i + 1}</td>
                      <td>{data.userName}</td>
                      <td>{data.referredBy?.name || profileData.profileData.user_id}</td>
                      <td>{data.district}</td>
                      <td>{data.state}</td>
                      <td>{data.country}</td>
                      <td>{data.pinCode}</td>
                      <td>{data.email}</td>
                      <td>{data.phoneNumber}</td>
                      <td>
  {data?.otherDetails?.profileDataCompletion ? (
    <span
      className="badge"
      style={{
        height: "80%",
        width: "100%",
        color: 'white',
        backgroundColor: '#90ee90', // Apply color based on status
        border: `1px solid #90ee90`, // Border color matches the background
        padding: "0.6rem",
        textAlign: 'center'
      }}
    >
      COMPLETED
    </span>
  ) : (
    <span
      className="badge"
      style={{
        height: "80%",
        width: "100%",
        color: 'white',
        backgroundColor: '#F6C324', // Apply color based on status
        border: `1px solid #F6C324`, // Border color matches the background
        padding: "0.6rem",
        textAlign: 'center'
      }}
    >
      PENDING
    </span>
  )}
</td>
                      {(userRole === "admin" || userRole === "hos") && (
                        <td>
                          <button
                            className="badge"
                            style={{
                              height: "80%",
                              width: "100%",
                              color: "white",
                              border: "1px solid white",
                              padding: "0.6rem",
                              textAlign: "center",
                              backgroundColor: "gray",
                            }}
                            onClick={() => {
                              setAddVipData(data);
                              setSelectedView("AddVip");
                            }}
                          >
                            Add as Vip
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Stack
          alignItems={"center"}
          marginTop={"0.5rem"}
          marginBottom={"1rem"}
        >
          <Paginations
            count={getTotalPages()}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ViewVipReferalTableContainer>
  );
}


const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`;
const ViewVipReferalTableContainer = styled.div`
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  width: 100%;
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;

    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }

  td {
    text-align: center;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
    overflow-y: scroll;
  }

  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }

  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }

  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }

  td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
`;
 