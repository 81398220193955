import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { fetchData, postData } from "../../../Api";
import TopContainer from "../subcomponents/TopContainer";
import { Pagination, Stack } from "@mui/material";
import { URL } from "../../../assets/mocData/config";
import { getDate } from "date-fns";
import axios from "axios";

const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`;

const ReferedVipStyled = styled.div`
  width: 100%;
  padding: 2rem;
  #searchfield {
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
  }

  #searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height: 20px;
    width: 10rem;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
  ::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }
  h2 {
    color: white;
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #bf2f82c4;
  }

  .font {
    border: 0px solid;
    color: #bf2f82c4;
  }

  .icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
  }

  .icon {
    color: #bf2f82c4;
    border-radius: 5rem;
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }
  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }
  .t-head {
    text-align: center;
  }
  td {
    text-align: center;
  }
  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 80vh;
    overflow-y: scroll;
  }
  .t-head {
    color: white !important;
    background-color: #18171b !important;
    padding: 1rem !important;
    text-align: center;
  }
  .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }
  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }
  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }
  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
  td {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .btn-group label.btn {
    font-size: 0.7rem !important; /* Decrease font size inside buttons */
    padding: 0.5rem 0.5rem !important; /* Adjust button padding */
  }
  .btn-check:checked + .btn {
    background-color: var(--navbar-dark-primary);
    color: white;
    border-color: var(--navbar-dark-primary);
  }
  .btn-check:checked + .btn.red {
    background-color: red; /* Red color */
  }
  .btn-check:checked + .btn.warning {
    background-color: orange; /* Warning color (orange) */
  }
  .btn-check:checked + .btn.green {
    background-color: green; /* Green color */
  }
`;

function PincodeRevenue() {
  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken, profileData } = useGlobalContext();
  const [vipData, setVipData] = useState([]);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const FilterOpen = () => {
    filter ? setFilter(false) : setFilter(true);
  };
  const [values, setValues] = useState({
    franchiseBusinessCommission: 0
  });
  const handleClickOpen = (user) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const payAmount = async (amount) => {
    console.log(amount);
  };

  const fetchVip = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };

    try {
      let response = await axios.post(
        `${URL}/vip/vipfranchise/revenue`,
        { pinCodes: profileData.profileData.otherDetails.pinCodes },
        {
          headers: {
            Authorization: `Bearer ${header.userToken}`,
            role: header.userRole,
          },
        }
      );
      console.log(response);
      setVipData(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchVip();
  }, []);
  const filteredData = vipData.filter((item) => {
    const pincode = item.studentPincode.pinCode.toLowerCase().includes(filterData.toLowerCase());
    const studentName = item.studentName.studentName.toLowerCase().includes(filterData.toLowerCase());
    const courseName = item.studentCourseStatus.courseName.courseName.toLowerCase().includes(filterData.toLowerCase());
    return pincode || studentName || courseName;
  });
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const fetchSettings = async () => {
    const headers = {
      userRole: "admin",
      userToken: userToken,
    };
    try {
      let data = await fetchData(`${URL}/admin/settings`, headers);
      console.log(data);
      setValues({
        franchiseBusinessCommission:parseInt(data.data.franchiseBusinessCommission)/100
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);
  // Calculate the data to be displayed on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = vipData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <ReferedVipStyled style={{ height: "100vh", overflowY: "scroll" }}>
      <TopContainer
        heading={"PINCODE REVENUE"}
        options={["Tia mock", "Tia Brain", "Live Tutor", "Test hub"]}
        filter={filter}
        FilterOpen={FilterOpen}
        setFilterData={setFilterData}
        showFields={{ search: true }}
      />
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                  S.no
                </th>
                <th className="t-head" scope="col">
                  Pincode
                </th>
                <th className="t-head" scope="col">
                  Student Name
                </th>
                <th className="t-head" scope="col">
                  Course Name
                </th>
                <th className="t-head" scope="col">
                  Course Fee
                </th>
                <th className="t-head rounded-end-3" scope="col">
                  My Revenue
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {currentData.map((item, index) => (
                <tr key={index}>
                  <td>{indexOfFirstItem + index + 1}</td>
                  <td>{item.studentPincode.pinCode}</td>
                  <td>{item.studentName.studentName}</td>
                  <td>{item.studentCourseStatus.courseName.courseName}</td>
                  <td>{item.studentCourseStatus.courseName.courseFee}</td>
                  <td>
                    {item.studentCourseStatus.courseName.courseFee * values.franchiseBusinessCommission}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination */}
      <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={Math.ceil(vipData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ReferedVipStyled>
  );
}

function PincodeRefferals() {
  const [viewStats, setViewStatsContainer] = useState(false);
  const { userRole, userToken, profileData } = useGlobalContext();
  const [vipData, setVipData] = useState([]);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  const handleClickOpen = (user) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const payAmount = async (amount) => {
    console.log(amount);
  };

  const fetchVip = async () => {
    const header = {
      userRole: userRole,
      userToken: userToken,
    };

    try {
      let response = await axios.post(
        `${URL}/vip/vipfranchise/pending`,
        { pinCodes: profileData.profileData.otherDetails.pinCodes },
        {
          headers: {
            Authorization: `Bearer ${header.userToken}`,
            role: header.userRole,
          },
        }
      );
      console.log(response);
      setVipData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchVip();
  }, []);
  const filteredData = vipData.filter((item) => {
    const pincode = item.studentPincode.pinCode.toLowerCase().includes(filterData.toLowerCase());
    const studentName = item.studentName.studentName.toLowerCase().includes(filterData.toLowerCase());
    const courseName = item.studentCourseStatus.courseName.courseName.toLowerCase().includes(filterData.toLowerCase());
    return pincode || studentName || courseName;
  });
  const FilterOpen = () => {
    filter ? setFilter(false) : setFilter(true);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Calculate the data to be displayed on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = vipData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <ReferedVipStyled style={{ height: "100vh", overflowY: "scroll" }}>
      <TopContainer
        heading={"PINCODE REFFERALS"}
        options={["Tia mock", "Tia Brain", "Live Tutor", "Test hub"]}
        filter={filter}
        FilterOpen={FilterOpen}
        setFilterData={setFilterData}
        showFields={{ search: true, filter: true }}
      />
      <div id="table-container">
        <div className="scroll">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="t-head rounded-start-3" scope="col">
                  S.no
                </th>
                <th className="t-head" scope="col">
                  Pincode
                </th>
                <th className="t-head" scope="col">
                  Student Name
                </th>
                <th className="t-head" scope="col">
                  Course Name
                </th>
                <th className="t-head" scope="col">
                  Course Fee
                </th>
                <th className="t-head rounded-end-3" scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="table-dark">
              {currentData.map((item, index) => (
                <tr key={index}>
                  <td>{indexOfFirstItem + index + 1}</td>
                  <td>{item.studentPincode.pinCode}</td>
                  <td>{item.studentName.studentName}</td>
                  <td>{item.studentCourseStatus.courseName.courseName}</td>
                  <td>{item.studentCourseStatus.courseName.courseFee}</td>
                  <td><span
    style={{
      display: "inline-block", // To ensure the span behaves like a block-level element
      padding: "5px 10px", // Padding for the box
      borderRadius: "5px", // Rounded corners for the box
      color: "white", // Text color matches the border color
      border: `2px solid white`, // Conditional border color
      fontSize: "14px", // Responsive font size
      textAlign: "center", // Center the text inside the box
      width: "auto", // Adjust width based on content
      minWidth: "80px", // Minimum width for small screens
      maxWidth: "100%", // Full width on smaller screens
    }}
  >{item.status}</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination */}
      <div className="d-flex justify-content-center mt-3">
        <Stack alignItems={"center"} marginTop={"0.5rem"} marginBottom={"1rem"}>
          <Paginations
            count={Math.ceil(vipData.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      </div>
    </ReferedVipStyled>
  );
}
export { PincodeRefferals, PincodeRevenue };
