import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Icon } from "react-icons-kit"; 
import { bin2 } from "react-icons-kit/icomoon/bin2";
import { pencil } from "react-icons-kit/icomoon/pencil";
import { deleteData, fetchData } from "../../../Api";
import { URL } from "../../../assets/mocData/config";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { Pagination, Stack, TablePagination, colors } from "@mui/material";
import SelectAllIcon from '@mui/icons-material/SelectAll';
import { ic_search } from "react-icons-kit/md/ic_search";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import * as XLSX from 'xlsx';
 

import EditCourseForm from "../EditForms/EditCourseForm"
function CourseTable({ courseListTitles, filterData, setFilterData }) {
  const [editableContainer, setEditContainer] = useState(false);
  const [deleteContainer, setDeleteContainer] = useState(false);
  const [Coursedata, setData] = useState([]);
  const [multipleDeletion, setMulipleDeletion] = useState(false);
  const [multipleDeletionID, setMultipleDeletionId] = useState([]);
  const { userRole, userToken, setCourseData, courseData } = useGlobalContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null); // Store the course to delete
  const itemsPerPage =50;
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      Coursedata.map((course, index) => ({
        "S.No": index + 1,
        "Course Code": course.courseCode,
        "Concept Name": course.conceptName,
        "Course Name": course.courseName,
        "Course Fee": course.courseFee,
        "Status": course.status,
      }))
    );
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Courses");
  
    // Generate buffer
    XLSX.writeFile(workbook, "CourseList.xlsx");
  };
  let header = {
    userRole: userRole,
    userToken: userToken,
  };

  const selectionForDeletion = (id) => {
    if (multipleDeletionID.includes(id)) {
      let data = multipleDeletionID?.filter((del) => del !== id);
      setMultipleDeletionId(data);
      return;
    }

    setMultipleDeletionId([...multipleDeletionID, id]);
  };
  const getTotalPages = (Coursedata) => {
    return Math.ceil(Coursedata?.length / itemsPerPage);
  };
  const handlePageChange = (event, value) => {
    console.log(value); 
    setCurrentPage(value);
  };
  const getPagedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    if (Coursedata) {
      return Coursedata?.slice(startIndex, startIndex + itemsPerPage);
    }
    return [];
  };
  const fetchCourseData = async () => {
    try {
      
      let response = await fetchData(`${URL}/vip/courselist`, header);
      setData(response);
      setCourseData(response);
    } catch (error) {
      enqueueSnackbar("Failed Fetching data", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, []);

  useEffect(() => {
    let data = [];
    data = Coursedata?.map((element) => {
      if (
        element.conceptName.toLowerCase().includes(filterData.toLowerCase()) ||
        element.courseCode.toLowerCase().includes(filterData.toLowerCase()) ||
        element.courseName.toLowerCase().includes(filterData.toLowerCase())
      ) {
        return element;
      }
    });
    data = data?.filter((item) => item !== undefined);
    
    if (data?.length != 0 && filterData.length > 0) {
      setData(data);
    } else {
      setData(courseData);
    }
  }, [filterData]);


  const handleOpenDeleteModal = (course) => {
    setCourseToDelete(course); // Set the course to be deleted
    setOpenDeleteModal(true); // Open the modal
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false); // Close the modal
  };

  const handleConfirmDelete = () => {
    if (courseToDelete) {
      DeleteCourseData(courseToDelete); // Call the delete function
    }
    handleCloseDeleteModal(); // Close the modal after confirmation
  };


  useEffect(() => {
    fetchCourseData();
  }, []);

  const DeleteCourseData = async (data) => {
    try {
      let response = await deleteData(`${URL}/admin/courselist/${data._id}`, {
        userRole,
        userToken,
      });
      if (response) {
        setData(Coursedata.filter((element) => element._id !== data._id));
        enqueueSnackbar("Deleted Successfully", { variant: "success" });
      }
      setEditContainer(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };


  const DeleteMultipleCourse = async () => {
    let header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      let res = await axios.post(
        `${URL}/admin/courselist/delete/many`,
        { courseCodes: multipleDeletionID },
        {
          headers: {
            Authorization: `Bearer ${header.userToken}`,
            role: header.userRole,
          },
        }
      );
      let data = Coursedata?.filter((c) => {
        if (!multipleDeletionID.includes(c._id)) {
          return c;
        }
      });
      console.log(data);
      setData(data);
      enqueueSnackbar(`Deleted Successfully`, "success");
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const [EditData, EditSetData] = useState({});
  const [dataFilt, setFiltData] = useState("");
  console.log(dataFilt);
  const editCourseData = (data) => {
    editableContainer ? setEditContainer(false) : setEditContainer(true);
    EditSetData(data);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  console.log(Coursedata?.length);
  console.log("jkl", setFilterData);
  return (
    <CourseTableContainer>
      {!editableContainer ? (
        <>
         <div className="d-flex row-reverse pb-4" style={{ marginTop: "1rem" }}>
  {userRole === "admin" && (
    <>
      {!multipleDeletion ? (
        <>
          <Button
            variant="contained"
            color="success"
            startIcon={<SelectAllIcon />}
            onClick={() => setMulipleDeletion(true)}
            sx={{
              borderRadius: '10px',
              backgroundColor: 'var(--navbar-dark-primary)',
              '&:hover': {
                backgroundColor: '#f00d88', // Optional: Customize hover color if needed
              },
            }}
          >
            Select Delete
          </Button>
          <Button
            variant="outlined"
             className="download-btn"
            onClick={exportToExcel}
             disabled={Coursedata?.length === 0} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
             // Customize color as needed
             color:"#f00d88",
             borderColor:"#f00d88",
            }}
          >
            Download Excel
          </Button>
        </>
      ) : (
        <>
          <button
            style={{
              backgroundColor: "red",
              color: "white",
              borderRadius: "10px",
            }}
            onClick={() => {
              DeleteMultipleCourse();
            }}
          >
            Delete
          </button>
          <button
            style={{ backgroundColor: "white", borderRadius: "10px" }}
            onClick={() => {
              setMulipleDeletion(false);
              setMultipleDeletionId([]);
            }}
          >
            Cancel
          </button>
        </>
      )}
    </>
  )} 
</div>

          
            <div id="table-container">
              <div className="scroll">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      {courseListTitles.map((e, i) => (
                        <th
                          className={`t-head ${
                            i === 0 ? "rounded-start-3" : ""
                          } ${
                            i === courseListTitles.length - 1
                              ? "rounded-end-3"
                              : ""
                          }`}
                          scope="col"
                          key={i}
                        >
                          {e}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="table-dark">
                    {getPagedData().map((e, i) => (
                      <tr key={e._id}>
                        <td>
                          {multipleDeletion ? (
                            <input
                              type="checkbox"
                              onClick={() => selectionForDeletion(e._id)}
                            />
                          ) : (
                            <>
                              <i className="fa-solid fa-chalkboard"></i>
                              <h6 className="p-1" style={{ width: "6rem" }}>
                                {i + 1 + (currentPage - 1) * itemsPerPage}
                              </h6>
                            </>
                          )}
                        </td>
                        <td>
                          <h6>{e.courseCode}</h6>
                        </td>
                        <td>
                          <h6>{e.conceptName}</h6>
                        </td>
                        <td>
                          <h6>{e.courseName}</h6>
                        </td>
                        <td>
                          <h6>{e.courseFee}</h6>
                        </td>
                        {userRole === "admin" && (
                          <>
                            <td>
                              {e.status === "active" && (
                                <span className="dot"></span>
                              )}
                              {e.status === "inactive" && (
                                <span className="dot-red"></span>
                              )}
                            </td>
                            <td>
                              <span
                              onClick={() => handleOpenDeleteModal(e)}
                              style={{
                                paddingRight: "1rem",
                                cursor: "pointer",
                              }}
                              >
                              <Icon
                                
                                icon={bin2}
                              /></span>
                              <span
                              onClick={() => {
                                editCourseData(e);
                              }}
                              >
                              <Icon
                                
                                icon={pencil}
                              /></span>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          
          <Stack
            alignItems={"center"}
            marginTop={"0.5rem"}
            marginBottom={"1rem"}
          >
            <Paginations
              count={getTotalPages(Coursedata)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              color="secondary"
            />
          </Stack>
        </>
      ) : (
        <div style={{height:"80vh"}} className="scroll">
          <EditCourseForm
            EditData={EditData}
            setEditContainer={setEditContainer}
            EditSetData={EditSetData}
            editCourseData={editCourseData}
            setData={setData}
            Coursedata={Coursedata}
          />
        </div>
      )}

<Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this course?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </CourseTableContainer>
  );
}
const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`;

const CourseTableContainer = styled.div`
  height: auto;
  .empty {
    height: 80%;
    background-color: #25272d;
    border-radius: 20px;
  }
  h1 {
    color: white;
  }
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  .dot {
    height: 15px;
    width: 15px;
    background-color: #219621;
    border-radius: 50%;
    display: inline-block;
  }
  .dot-red {
    height: 15px;
    width: 15px;
    background-color: #c82323;
    border-radius: 50%;
    display: inline-block;
  }
  td {
    text-align: center;
  }
  .fa-solid {
    font-size: 1.3rem;
    color: var(--icon-color);
  }
  h4 {
    margin-left: 1em;
  }
    
  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #profile-container {
      margin-top: 1rem;
    }
  }
  width: 100%;
  #course-list {
    width: 100%;
    padding: 2rem;
  }

  ::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  .btn {
    background-color: var(--navbar-dark-primary);
    padding: 1rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  #line {
    background-color: var(--icon-color);
    height: 3px;
    width: 100%;
  }
  h2 {
    color: white;
  }

  #searchfield {
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
  }

  #searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height: 20px;
    width: 10rem;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
  ::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #bf2f82c4;
  }

  .font {
    border: 0px solid;
    color: #bf2f82c4;
  }

  .icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
  }

  .icon {
    color: #bf2f82c4;
    border-radius: 5rem;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 1rem;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
    width: 100%;
  }

  #table-container thead th {
    position: sticky;
    top: 0;
  }

  .t-head {
    background-color: #18171b !important;
    color: white !important;
    padding: 1rem !important;
    text-align: center;
  }
  h4 {
    text-align: center;
  }

  table.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  table.rounded-corners th,
  table.rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }
  .table {
    background-color: var(--navbar-dark-primary);
  }
  .table > :not(caption) > * > * {
    background-color: #25272d;
  }
     td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  /* Style for active radio button */
`;
export default CourseTable;
