import { enqueueSnackbar } from "notistack"; // For displaying notifications
import React, { useState } from "react"; // React and hooks for state management
import { styled } from "styled-components"; // For styling components with styled-components
import { postData } from "../../../Api"; // Custom function to handle POST API calls
import { useGlobalContext } from "../../../assets/contex/GlobalContext"; // Context API for global state
import { URL } from "../../../assets/mocData/config"; // URL configuration for API endpoint
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material"; // Material-UI components for form controls
import { createTheme, ThemeProvider } from "@mui/material/styles"; // For applying custom themes
import CircularProgress from "@mui/material/CircularProgress"; // Loading spinner component
const theme = createTheme({
  palette: {
    primary: {
      main: "#f00d88", // Pink color
    },
    text: {
      primary: "#ffffff", // White color for input text
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: "#ffffff", // White color for input text
          backgroundColor: "black", // Background color for filled input
          "&:before": {
            borderColor: "white", // Initial border color white
          },
          "&:hover:before": {
            borderColor: "#f00d88", // Border color on hover
          },
          "&.Mui-focused:before": {
            borderColor: "#f00d88", // Border color when focused
          },
          "&.Mui-focused": {
            backgroundColor: "black", // Background color when focused
          },
        },
        input: {
          color: "#ffffff", // White color for input text
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "gray", // White color for label
          "&.Mui-focused": {
            color: "#f00d88", // Pink color for label when focused
          },
        },
      },
    },
  },
});
// 2. Component: AddConcept

/**
 * @function AddConcept
 * @description A React component for adding a new concept. It allows the user to input concept details and send them to the server via an API call.
 * @param {Object} props - The component's props, including `user` information.
 */
function AddConcept({ user }) {
  // 2.1 useState hooks

  // State to manage form data for concept details
  const [conceptData, setConceptData] = useState({
    conceptName: "",
    conceptCode: "",
    status: "",
  });

  // State to manage loading indicator
  const [isLoading, setIsLoading] = useState(false);

  // State to handle the selected value for the Radio buttons
  const [selectedValue, setSelectedValue] = useState("a");

  // Global context data such as user role and token
  const { userRole, userToken } = useGlobalContext();

  // 2.2 Functions

  /**
   * @function handleChange
   * @description Handles changes in the RadioGroup for selecting the concept's status.
   * @param {Object} event - The change event object from the input.
   */
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  /**
   * @function controlProps
   * @description Returns the necessary props for Radio buttons based on the selected value.
   * @param {string} item - The value to be checked in the RadioGroup.
   * @returns {Object} - Props for the Radio buttons.
   */
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "status",
    inputProps: { "aria-label": item },
  });

  /**
   * @function FormData
   * @description Updates the form data state when the user types into input fields.
   * @param {Object} e - The event triggered by input change.
   */
  const FormData = (e) => {
    let value = e.target.value;
    setConceptData({
      ...conceptData,
      [e.target.name]: value,
    });
  };

  /**
   * @function validateConceptData
   * @description Validates the input data to ensure required fields are filled.
   * @param {Object} data - The form data to validate.
   * @returns {boolean} - Returns `true` if validation passes, otherwise `false`.
   */
  const validateConceptData = (data) => {
    if (!conceptData.conceptName.trim()) {
      enqueueSnackbar("Concept Name is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }

    if (!conceptData.conceptCode.trim()) {
      enqueueSnackbar("Concept Code is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }

    if (!conceptData.status.trim()) {
      enqueueSnackbar("Status is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }

    return true;
  };

  /**
   * @function handleCancel
   * @description Resets the form data and radio button value when the user clicks "Cancel".
   */
  const handleCancel = () => {
    setConceptData({
      conceptName: "",
      conceptCode: "",
      status: "",
    });
    setSelectedValue("");
  };

  /**
   * @function addConcept
   * @description Handles form submission. Validates the form data and sends a POST request to the server if valid.
   * @param {Object} e - The event triggered by form submission.
   */
  const addConcept = async (e) => {
    e.preventDefault();

    if (validateConceptData(conceptData)) {
      setIsLoading(true);
      let headers = {
        userRole: userRole,
        userToken: userToken,
      };

      try {
        
        // API call to add the concept
        let response = await postData(
          `${URL}/${userRole}/concept`,
          conceptData,
          headers
        );

        // Reset form on successful submission
        setConceptData({
          conceptName: "",
          conceptCode: "",
          status: "",
        });
        setSelectedValue("");
      } catch (error) {
        
        // Handle E11000 error for duplicate keys
        if (error.response && error.response.data.error) {
          const errorMessage = error.response.data.error;
      
          if (errorMessage.includes("E11000")) {
            if (errorMessage.includes("conceptCode")) {
              enqueueSnackbar("Concept Code already exists.", { variant: "warning" });
            } else if (errorMessage.includes("conceptName")) {
              enqueueSnackbar("Concept Name already exists.", { variant: "warning" });
            } else {
              enqueueSnackbar("Duplicate data entry error.", { variant: "warning" });
            }
          } else {
            enqueueSnackbar(`${errorMessage}`, { variant: "warning" });
          }
        } else {
          enqueueSnackbar("Something went wrong. Please try again later.", {
            variant: "error",
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  // 2.3 Return JSX
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10,
          backgroundColor: "#18171b",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <AddConceptContainer>
      <ThemeProvider theme={theme}>
        <div class="d-flex">
          <div id="add-Concept-container">
            {/* <h2 style={{textAlign: "left"}}>Add Course</h2> */}
            <div class="container">
              <form onChange={FormData}>
                <div class="row">
                  <div class="col col-12">
                    <div id="form-container" class="m-1">
                      <form
                        style={{ height: "auto", paddingTop: "1rem" }}
                        action=""
                      >
                        <div class="container">
                          <div class="row">
                            <hr />
                            <span class="col-3 inputHeadings">
                              Concept Code
                            </span>
                            <div class="col-md-9 col-sm-12 d-flex flex-column px-3">
                              <span
                                style={{ width: "100%" }}
                                class="d-flex justify-content-between"
                              >
                                <TextField
                                  value={conceptData.conceptCode}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  id="filled-basic"
                                  label="Enter Concept Code"
                                  name="conceptCode"
                                  type="text"
                                  fullWidth
                                />
                                {/* <input
                                  value={conceptData.conceptCode}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  class="p-3 bg-black rounded-3"
                                  name="conceptCode"
                                  type="text"
                                  required={true}
                                  placeholder="Enter Concept Code"
                                /> */}
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="container">
                          <div class="row">
                            <span class="col-3 inputHeadings">
                              Concept Name
                            </span>
                            <div class="col-9 d-flex flex-column px-3">
                              <span
                                style={{ width: "100%" }}
                                class="d-flex justify-content-between"
                              >
                                <TextField
                                  value={conceptData.conceptName}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  variant="filled"
                                  id="filled-basic"
                                  label="Enter Concept Name"
                                  name="conceptName"
                                  type="text"
                                />
                                {/* <input
                                  value={conceptData.conceptName}
                                  style={{
                                    width: "100%",
                                    border: "1px solid black",
                                  }}
                                  class="p-3 bg-black rounded-3"
                                  name="conceptName"
                                  type="text"
                                  required={true}
                                  placeholder="Enter Concept Name"
                                /> */}
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />

                        {/* <!-- Status Section --> */}
                        <div class="container status-section">
                          <div class="row align-items-center">
                            <span class="col-3 inputHeadings">Status</span>
                            <div class="col-9 d-flex flex-column px-3">
                              <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">
                                  Status
                                </FormLabel>
                                <RadioGroup
                                  className="d-sm-flex flex-sm-column flex-md-row"
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  value={selectedValue}
                                >
                                  <FormControlLabel
                                    name="status"
                                    value="active"
                                    control={
                                      <Radio
                                        name="status"
                                        {...controlProps("active")}
                                        color="success"
                                      />
                                    }
                                    label="Active"
                                  />
                                  <FormControlLabel
                                    name="status"
                                    value="inactive"
                                    control={
                                      <Radio
                                        name="status"
                                        {...controlProps("inactive")}
                                      />
                                    }
                                    label="In Active"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div class="container mt-3">
                          <div class="row justify-content-center">
                            <div class="col-12 d-flex justify-content-center">
                              <div class="d-flex flex-column flex-sm-row align-items-center">
                                <button
                                  onClick={(e) => addConcept(e)}
                                  type="submit"
                                  class="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-concept-btn"
                                >
                                  Add Concept
                                </button>
                                <button
                                  onClick={handleCancel}
                                  type="button"
                                  class="btn btn-outline-danger btn-sm cancel-btn"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </AddConceptContainer>
  );
}

// 3. Styled Component for Container

const AddConceptContainer = styled.div`
  height: 80vh;
  input::placeholder {
    color: gray !important;
  }
  #add-Concept-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: gray !important;
  }

  h2,
  p,
  span,
  h6 {
    color: white;
  }
  .add-concept-btn:hover {
    background-color: green;
  }
  .cancel-btn:hover {
    background-color: red;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }

  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }

  h5 {
    text-align: center;
    color: white;
  }

  /* Status Section Styles */
  .status-section {
    margin-top: 1rem;
  }

  .status-label {
    color: white;
  }

  .status-options {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .active-dot {
    background-color: green;
  }

  .inactive-dot {
    background-color: red;
  }

  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #add-Concept-container {
      padding: 1rem;
    }

    #profile-container {
      margin-top: 1rem;
    }
  }
  /* Style for active radio button */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }
  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }
  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }
  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  .add-concept-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }
    @media (max-width: 575px) {
    .add-concept-btn,
    .cancel-btn {
      align-items: center;
      width: 100%; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
    }
`;

export default AddConcept;
