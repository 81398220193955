import React, { useState } from "react";
import { styled } from "styled-components";
import { enqueueSnackbar } from "notistack";
import { useGlobalContext } from "../../assets/contex/GlobalContext";
import { postData } from "../../Api";
import { URL as url } from "../../assets/mocData/config";
import { Button } from "@mui/material";
import Icon from "react-icons-kit";
import { cross } from "react-icons-kit/icomoon/cross";
import TopContainer from "../miniComponents/subcomponents/TopContainer";
import { Tabs, Tab, Box, InputLabel, Select, MenuItem, FormControl, FormControlLabel, FormLabel, InputBase, Radio, RadioGroup, Paper, Autocomplete, TextField, createTheme, ThemeProvider } from "@mui/material";
import { Country, State, City } from "country-state-city";
import ReferedVip from "./AllReferedUsers";

function ReferVip() {
  const { userRole, userToken, profileData } = useGlobalContext();
  const [data, setData] = useState({
    newForWorking: "",
    userType: "vip",
    workingOption: [],
    verified: false,
    password: "",
    otherDetails: {
      profileDataCompletion: false,
    },
    referredBy: {
      name: profileData.profileData.userName,
      referID: profileData.profileData._id,
    },
  });

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [jobDataAll, setJobDataAll] = useState([]);
  const [jobData, setJobData] = useState({
    jobDescription: "",
    companyName: "",
  });

  const [allEducation, setAllEducation] = useState([]);

  const [education, setEducation] = useState({
    degree: "",
    collegeName: "",
  });

  // Function to handle adding education data
  const addEducationData = () => {
    if (education.degree === "" || education.collegeName === "") {
      alert("Please fill in both Degree and College Name fields.");
      return;
    }
    setAllEducation([...allEducation, education]);
    setEducation({
      degree: "",
      collegeName: "",
    });
  };

  // Function to remove education entry by degree
  const removeElementByConditionEducation = (degree) => {
    const filteredArray = allEducation.filter(
      (element) => element.degree !== degree
    );
    setAllEducation(filteredArray);
  };

  // Function to remove job data by jobDescription
  const removeElementByCondition = (jobDescription) => {
    const filteredArray = jobDataAll.filter(
      (element) => element.jobDescription !== jobDescription
    );
    console.log(filteredArray);
    setJobDataAll(filteredArray);
  };

  const validateReferData = (data) => {
    if (!data.userName) {
      enqueueSnackbar("User Name is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    if (!data.phoneNumber) {
      enqueueSnackbar("Phone Number is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    if (!data.email) {
      enqueueSnackbar("Email is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    return true;
  };

  const addJobData = (dataJ) => {
    if (dataJ.jobDescription === "" || dataJ.companyName === "") {
      enqueueSnackbar("Fill the job description and company name", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return null;
    }
    setJobDataAll([...jobDataAll, dataJ]);
    setData({
      ...data,
      jobDescription: [...jobDataAll, dataJ],
    });
    setJobData({
      jobDescription: "",
      companyName: "",
    });
  };

  const referUser = async (e) => {
    e.preventDefault();
    if (validateReferData(data)) {
      try {
        let headers = {
          userRole: userRole,
          userToken: userToken,
        };
        if (selectedCity) {
          data.country = selectedCountry.name;
          data.state = selectedState.name;
          data.district = selectedCity.name;
        }
        let response = await postData(`${url}/vip/refer`, data, headers);
        console.log(response);
        setData({
          verified: false,
          userType: "vip",
          workingOption: [],
        });
        window.location.reload();
      } catch (error) {
        const errorData = error.response?.data || {};
        const errorMessage = errorData.errors
          ? Object.values(errorData.errors)
              .map((err) => err.message)
              .join(", ")
          : errorData.message || "An error occurred";
        enqueueSnackbar(errorMessage, { variant: "warning" });
        console.log(error);
      }
    }
  };

  const handleFormDataChange = (e) => {
    e.preventDefault();
    if (e.target.name === "profileImage") {
      // Handle file input if needed
    } else if (
      e.target.name === "jobDescription" ||
      e.target.name === "companyName"
    ) {
      setJobData({ ...jobData, [e.target.name]: e.target.value });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };


  const handleClickGender = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };


 const theme = createTheme({
  palette: {
    primary: {
      main: "#f00d88", // Pink color
    },
    text: {
      primary: "#ffffff", // White color for input text
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: "#ffffff", // White color for input text
          backgroundColor: "black", // Background color for filled input
          "&:before": {
            borderColor: "white", // Initial border color white
          },
          "&:hover:before": {
            borderColor: "#f00d88", // Border color on hover
          },
          "&.Mui-focused:before": {
            borderColor: "#f00d88", // Border color when focused
          },
          "&.Mui-focused": {
            backgroundColor: "black", // Background color when focused
          },
        },
        input: {
          color: "#ffffff", // White color for input text
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "gray", // Gray color for label
          "&.Mui-focused": {
            color: "#f00d88", // Pink color for label when focused
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "white", // White color for the dropdown icon
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#25272d", // Dark gray background for dropdown
          color: "#ffffff", // White text color for dropdown items
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#ffffff",// Pink color for the dropdown icon
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "#25272d", // Gray background
          color: "#ffffff", // White text color
          "&:hover": {
            backgroundColor: "#f00d88", // Pink background on hover
          },
        },
      },
    },
  },
});

  return (
    <ThemeProvider theme={theme}>
      <div>
        <form
          onChange={handleFormDataChange}
          className="container"
          style={{ position: "relative" }}
        >
          <div class="row d-md-flex flex-column-reverse flex-md-row">
            <div class="col col-12 col-md-9">
              <div id="form-container" class="m-1">
                <form
                  class="scroll"
                  style={{
                    height: "85vh",
                    overflowY: "scroll",
                    paddingTop: "1rem",
                    paddingBottom: "5rem",
                  }}
                  action=""
                >
                  <div class="container"></div>

                  <div className="container my-4">
                    <hr />
                    <span className="h4 d-block mb-4 pb-2">
                      Personal Information
                    </span>
                    <div className="row">
                      <div className="col-12">
                        <div className="row g-3">
                          <div className="col-12">
                            <TextField
                              fullWidth
                              name="userName"
                              label="Name"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="age"
                              label="Age"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="bloodGroup"
                              label="Blood Group"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              label="Date of Birth (dd-mm-yyyy)"
                              variant="filled"
                              id="filled-basic"
                              type="date"
                              name="dateOfBirth"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                style: {
                                  colorScheme: "dark", // For dark mode styling
                                  width: "100%",
                                },
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                          <FormControl
  fullWidth
  variant="filled"
>
  <InputLabel>Gender</InputLabel>
  <Select
    name="gender"
    value={data.gender}
    onChange={handleClickGender} // Ensure this updates the `data` state correctly
  >
    <MenuItem value="Male">Male</MenuItem>
    <MenuItem value="Female">Female</MenuItem>
    <MenuItem value="Other">Other</MenuItem>
  </Select>
</FormControl>
</div>


                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="fatherName"
                              label="Father Name"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="motherName"
                              label="Mother Name"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>

                          <div>
                            <div className="mb-3">
                              {allEducation.map((education, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mb-2"
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#f00d88", // Adjust this to your preferred color
                                      color: "#fff",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      textAlign: "left",
                                      whiteSpace: "normal", // Allow text to wrap
                                      wordBreak: "break-word", // Break long words
                                      padding: "0.5rem 1rem", // Adjust padding as needed
                                    }}
                                  >
                                    <span style={{ flex: 1 }}>
                                      {education.degree} --{" "}
                                      {education.collegeName}
                                    </span>
                                    <Icon
                                      onClick={() =>
                                        removeElementByConditionEducation(
                                          education.degree
                                        )
                                      }
                                      style={{
                                        paddingLeft: "0.5rem",
                                        color: "white",
                                        cursor: "pointer",
                                      }} // White cross icon
                                      icon={cross}
                                    />
                                  </Button>
                                </div>
                              ))}
                            </div>

                            {/* Education input fields and Add button */}
                            <div className="row g-2">
                              <div className="col-12 col-md-5">
                                <TextField
                                  fullWidth
                                  name="degree"
                                  label="Degree"
                                  variant="filled"
                                  onChange={(e) =>
                                    setEducation({
                                      ...education,
                                      degree: e.target.value,
                                    })
                                  }
                                  style={{
                                    backgroundColor: "#1C1C1C",
                                    color: "#fff",
                                  }}
                                />
                              </div>
                              <div className="col-12 col-md-5">
                                <TextField
                                  fullWidth
                                  name="collegeName"
                                  label="College Name"
                                  variant="filled"
                                  onChange={(e) =>
                                    setEducation({
                                      ...education,
                                      collegeName: e.target.value,
                                    })
                                  }
                                  style={{
                                    backgroundColor: "#1C1C1C",
                                    color: "#fff",
                                  }}
                                />
                              </div>
                              <div className="col-12 col-md-2 d-flex align-items-center justify-content-center">
                                <Button
                                  variant="outlined"
                                  color="success"
                                  onClick={addEducationData}
                                  className="w-100"
                                  style={{
                                    height: "40px", // Adjust this to your preferred height
                                    padding: "0.5rem", // Smaller padding for smaller height
                                    fontSize: "0.875rem", // Smaller font size for the button text
                                  }}
                                >
                                  Add
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="container mt-4">
                    <div class="row">
                      <span class="col-md-3 col-12">Career Details</span>
                      <div className="col-md-9 col-12 d-flex flex-column px-3">
                        <div className="d-flex flex-column flex-md-row align-items-center mb-3">
                          <p className="mb-0 me-3">Currently Unemployed</p>
                          <div className="d-flex align-items-center me-3">
                            <input
                              style={{
                                margin: "1rem",
                                width: "1rem",
                                height: "1rem",
                                border: "1px solid black",
                              }}
                              className="me-2"
                              type="radio"
                              id="yes"
                              value="yes"
                              name="newForWorking"
                            />
                            <label htmlFor="yes" className="mb-0">
                              Yes
                            </label>
                          </div>
                          <div className="d-flex align-items-center me-3">
                            <input
                              style={{
                                margin: "1rem",
                                width: "1rem",
                                height: "1rem",
                                border: "1px solid black",
                              }}
                              className="me-2"
                              type="radio"
                              id="no"
                              value="no"
                              name="newForWorking"
                            />
                            <label htmlFor="no" className="mb-0">
                              No
                            </label>
                          </div>
                        </div>
                        {data.newForWorking == "no" && (
                          <>
                            <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
                              {jobDataAll?.map((job) => (
                                <div className="m-2" key={job.jobDescription}>
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#f00d88", // Adjust this to your preferred color
                                      color: "#fff",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      textAlign: "left",
                                      whiteSpace: "normal", // Allow text to wrap
                                      wordBreak: "break-word", // Break long words
                                      padding: "0.5rem 1rem", // Adjust padding as needed
                                    }}
                                  >
                                    <span className="flex-grow-1">
                                      {job.jobDescription} -- {job.companyName}
                                    </span>
                                    <Icon
                                      onClick={() =>
                                        removeElementByCondition(
                                          job.jobDescription
                                        )
                                      }
                                      style={{
                                        paddingLeft: "0.5rem",
                                        color: "white",
                                        cursor: "pointer",
                                      }} // White cross icon
                                      icon={cross}
                                    />
                                  </Button>
                                </div>
                              ))}
                            </div>
                            <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
                              <TextField
                                style={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  margin: "0.5rem",
                                  border: "1px solid black",
                                }}
                                variant="filled"
                                label="Job Description"
                                name="jobDescription"
                                fullWidth
                              />
                              <TextField
                                style={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  margin: "0.5rem",
                                  border: "1px solid black",
                                }}
                                variant="filled"
                                label="Company Name"
                                name="companyName"
                                fullWidth
                              />
                            </div>
                            <div className="d-flex justify-content-center mb-3">
                              <Button
                                onClick={() => addJobData(jobData)}
                                variant="outlined"
                                color="success"
                                size="small"
                                sx={{
                                  borderColor: "green",
                                  color: "green",
                                  padding: "2px 8px",
                                  minHeight: "24px",
                                  fontSize: "0.75rem",
                                  lineHeight: "1",
                                  "&:hover": {
                                    borderColor: "darkgreen",
                                    backgroundColor: "rgba(0, 128, 0, 0.1)",
                                  },
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="container mt-4">
                    <div class="row">
                      <span class="col-3">Contact Details</span>
                      <div className="col-12 col-md-9 d-flex flex-column px-3">
                        <div className="d-flex flex-column flex-md-row align-items-center">
                          <TextField
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              margin: "0.5rem 0", // Adjusted margin for spacing
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="Contact Email"
                            name="email"
                            fullWidth
                          />
                        </div>

                        <div className="d-flex flex-column flex-md-row">
                          <TextField
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              margin: "0.5rem 0", // Adjusted margin for spacing
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="Contact Phone Number"
                            name="phoneNumber"
                            fullWidth
                          />
                        </div>
                        <div className="d-flex flex-column flex-md-row">
                          <TextField
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              margin: "0.5rem 0", // Adjusted margin for spacing
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="Whatsapp"
                            name="whatsappNumber"
                            fullWidth
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="container">
                    <div class="row">
                      <span class="col-3">Address</span>
                      <div className="col-12 col-md-9 d-flex flex-column px-3">
                        {/* First Row */}
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Door/Flat No"
                              name="doorFlatNumber"
                              fullWidth
                            />
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Street Name"
                              name="streetName"
                              fullWidth
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Land mark"
                              name="landMark"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <Autocomplete
                              options={Country.getAllCountries()}
                              getOptionLabel={(option) => option.name}
                              value={selectedCountry}
                              onChange={(event, newValue) =>
                                setSelectedCountry(newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="Country"
                                  placeholder="Country"
                                  InputLabelProps={{
                                    style: { color: "gray" },
                                  }}
                                  sx={{
                                    "& .MuiFilledInput-root": {
                                      backgroundColor: "black",
                                      color: "white",
                                    },
                                    "& .MuiFilledInput-root:hover:before": {
                                      borderColor: "#f00d88",
                                    },
                                    "& .MuiFilledInput-root.Mui-focused:before":
                                      {
                                        borderColor: "#f00d88",
                                      },
                                  }}
                                />
                              )}
                              PaperComponent={(props) => (
                                <Paper
                                  {...props}
                                  sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              )}
                              sx={{
                                "& .MuiAutocomplete-option": {
                                  "&:hover": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                  "&[aria-selected='true']": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                },
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <Autocomplete
                              options={
                                State?.getStatesOfCountry(
                                  selectedCountry?.isoCode
                                ) || []
                              }
                              getOptionLabel={(option) => option.name}
                              value={selectedState}
                              onChange={(event, newValue) =>
                                setSelectedState(newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="State"
                                  placeholder="State"
                                  InputLabelProps={{
                                    style: { color: "gray" },
                                  }}
                                  sx={{
                                    "& .MuiFilledInput-root": {
                                      backgroundColor: "black",
                                      color: "white",
                                    },
                                    "& .MuiFilledInput-root:hover:before": {
                                      borderColor: "#f00d88",
                                    },
                                    "& .MuiFilledInput-root.Mui-focused:before":
                                      {
                                        borderColor: "#f00d88",
                                      },
                                  }}
                                />
                              )}
                              PaperComponent={(props) => (
                                <Paper
                                  {...props}
                                  sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              )}
                              sx={{
                                "& .MuiAutocomplete-option": {
                                  "&:hover": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                  "&[aria-selected='true']": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <Autocomplete
                              options={
                                selectedState
                                  ? City.getCitiesOfState(
                                      selectedState?.countryCode,
                                      selectedState?.isoCode
                                    )
                                  : []
                              }
                              getOptionLabel={(option) => option.name}
                              value={selectedCity}
                              onChange={(event, newValue) =>
                                setSelectedCity(newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="District"
                                  placeholder="District"
                                  InputLabelProps={{
                                    style: { color: "gray" },
                                  }}
                                  sx={{
                                    "& .MuiFilledInput-root": {
                                      backgroundColor: "black",
                                      color: "white",
                                    },
                                    "& .MuiFilledInput-root:hover:before": {
                                      borderColor: "#f00d88",
                                    },
                                    "& .MuiFilledInput-root.Mui-focused:before":
                                      {
                                        borderColor: "#f00d88",
                                      },
                                  }}
                                />
                              )}
                              PaperComponent={(props) => (
                                <Paper
                                  {...props}
                                  sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              )}
                              sx={{
                                "& .MuiAutocomplete-option": {
                                  "&:hover": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                  "&[aria-selected='true']": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                },
                              }}
                            />
                          </div>

                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Pincode"
                              name="pinCode"
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="container mt-3">
                    <div class="row justify-content-center">
                      <div class="col-12 d-flex justify-content-center">
                        <div class="d-flex flex-column flex-sm-row align-items-center">
                          <button
                            onClick={(e) => referUser(e)}
                            class="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-course-btn"
                          >
                            Add Vip
                          </button>
                          <button class="btn btn-outline-danger btn-sm cancel-btn">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
}

function ReferVipFranchise() {
  const { userRole, userToken, profileData } = useGlobalContext();
  const [data, setData] = useState({
    newForWorking: "",
    userType: "vip",
    workingOption: ["vipFranchise"],
    verified: false,
    password: "",
    otherDetails: {
      profileDataCompletion: false,
      isVip: "",
    },
    referredBy: {
      name: profileData.profileData.userName,
      referID: profileData.profileData._id,
    },
  });
  console.log(data);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [jobDataAll, setJobDataAll] = useState([]);
  const [jobData, setJobData] = useState({
    jobDescription: "",
    companyName: "",
  });

  const [allEducation, setAllEducation] = useState([]);

  // Function to handle adding education data
  const addEducationData = () => {
    if (education.degree === "" || education.collegeName === "") {
      // Show a warning if either field is empty
      alert("Please fill in both Degree and College Name fields.");
      return;
    }
    // Add the new education entry to the allEducation array
    setAllEducation([...allEducation, education]);

    // Clear the input fields after adding
    setEducation({
      degree: "",
      collegeName: "",
    });
  };

  // Function to remove education entry by degree
  const removeElementByConditionEducation = (degree) => {
    // Filter out the education entry that matches the given degree
    const filteredArray = allEducation.filter(
      (element) => element.degree !== degree
    );
    setAllEducation(filteredArray);
  };
  function removeElementByCondition(jobDescription) {
    // Create a new array with elements that do not match the specified company name
    const filteredArray = jobDataAll.filter(
      (element) => element.jobDescription !== jobDescription
    );

    console.log(filteredArray); // This will show the new array without the specified company

    setJobDataAll(filteredArray);
  }

  const [education, setEducation] = useState({
    degree: "",
    collegeName: "",
  });

  const addJobData = (dataJ) => {
    console.log(dataJ);
    if (dataJ.jobDescription == "" || dataJ.companyName == "") {
      enqueueSnackbar("Fill the detail job description and company name", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return null;
    }
    setJobDataAll([...jobDataAll, dataJ]);
    setData({
      ...data,
      jobDescription: [...jobDataAll, dataJ],
    });
    setJobData({
      jobDescription: "",
      companyName: "",
    });
  };
  const validateReferData = (data) => {
    if (!data.userName) {
      enqueueSnackbar("User Name is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    if (!data.phoneNumber) {
      enqueueSnackbar("Phone Number is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    if (!data.email) {
      enqueueSnackbar("Email is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
    if (!data.otherDetails.isVip) {
      enqueueSnackbar("Select Existing Vip or Not.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
    return true;
  };

  const referUser = async (e) => {
    e.preventDefault();
    if (validateReferData(data)) {
    try {
      if (selectedCity) {
        data.country = selectedCountry.name;
        data.state = selectedState.name;
        data.district = selectedCity.name;
      }
      let headers = {
        userRole: userRole,
        userToken: userToken,
      };
      let response = await postData(`${url}/vip/refer`, data, headers);
      console.log(response);
      setData({
        verified: false,
        userType: "vip",
        workingOption: ["vipFranchise"],
      });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar(`${error.response.data.message}`, { variant: "warning" });
      console.log(error);
    }}
  };
  const FormData = (e) => {
    e.preventDefault();

    if (e.target.name == "isVip") {
      console.log(e.target.value, e.target.name);
      setData({
        ...data,
        otherDetails: {
          ...data.otherDetails,
          isVip: e.target.value,
        },
      });
    } else if (
      e.target.name == "jobDescription" ||
      e.target.name == "companyName"
    ) {
      setJobData({ ...jobData, [e.target.name]: e.target.value });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };


  const handleClickGender = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#f00d88", // Pink color
      },
      text: {
        primary: "#ffffff", // White color for input text
      },
    },
    components: {
      MuiFilledInput: {
        styleOverrides: {
          root: {
            color: "#ffffff", // White color for input text
            backgroundColor: "black", // Background color for filled input
            "&:before": {
              borderColor: "white", // Initial border color white
            },
            "&:hover:before": {
              borderColor: "#f00d88", // Border color on hover
            },
            "&.Mui-focused:before": {
              borderColor: "#f00d88", // Border color when focused
            },
            "&.Mui-focused": {
              backgroundColor: "black", // Background color when focused
            },
          },
          input: {
            color: "#ffffff", // White color for input text
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "gray", // Gray color for label
            "&.Mui-focused": {
              color: "#f00d88", // Pink color for label when focused
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "white", // White color for the dropdown icon
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "#25272d", // Dark gray background for dropdown
            color: "#ffffff", // White text color for dropdown items
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "#ffffff",// Pink color for the dropdown icon
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: "#25272d", // Gray background
            color: "#ffffff", // White text color
            "&:hover": {
              backgroundColor: "#f00d88", // Pink background on hover
            },
          },
        },
      },
    },
  });
  
  return (
    <ThemeProvider theme={theme}>
      <div>
        <form
          onChange={FormData}
          class="container"
          style={{ position: "relative" }}
        >
          <div class="row d-md-flex flex-column-reverse flex-md-row">
            <div class="col col-12 col-md-9">
              <div id="form-container" class="m-1">
                <form
                  class="scroll"
                  style={{
                    height: "85vh",
                    overflowY: "scroll",
                    paddingTop: "1rem",
                    paddingBottom: "5rem",
                  }}
                  action=""
                >
                  <div class="container"></div>

                  <div className="container my-4">
                    <hr />
                    <span className="h4 d-block mb-4 pb-2">
                      Personal Information
                    </span>
                    <div className="row">
                      <div className="col-12">
                        <div className="row g-3">
                          <div className="col-12">
                            <TextField
                              fullWidth
                              name="userName"
                              label="Name"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="age"
                              label="Age"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="bloodGroup"
                              label="Blood Group"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              label="Date of Birth (dd-mm-yyyy)"
                              variant="filled"
                              id="filled-basic"
                              type="date"
                              name="dateOfBirth"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                style: {
                                  colorScheme: "dark", // For dark mode styling
                                  width: "100%",
                                },
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                          <FormControl
  fullWidth
  variant="filled"
>
  <InputLabel>Gender</InputLabel>
  <Select
    name="gender"
    value={data.gender}
    onChange={handleClickGender} // Ensure this updates the `data` state correctly
  >
    <MenuItem value="Male">Male</MenuItem>
    <MenuItem value="Female">Female</MenuItem>
    <MenuItem value="Other">Other</MenuItem>
  </Select>
</FormControl>
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="fatherName"
                              label="Father Name"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="motherName"
                              label="Mother Name"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>

                          <div>
                            <div className="mb-3">
                              {allEducation.map((education, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mb-2"
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#f00d88", // Adjust this to your preferred color
                                      color: "#fff",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      textAlign: "left",
                                      whiteSpace: "normal", // Allow text to wrap
                                      wordBreak: "break-word", // Break long words
                                      padding: "0.5rem 1rem", // Adjust padding as needed
                                    }}
                                  >
                                    <span style={{ flex: 1 }}>
                                      {education.degree} --{" "}
                                      {education.collegeName}
                                    </span>
                                    <Icon
                                      onClick={() =>
                                        removeElementByConditionEducation(
                                          education.degree
                                        )
                                      }
                                      style={{
                                        paddingLeft: "0.5rem",
                                        color: "white",
                                        cursor: "pointer",
                                      }} // White cross icon
                                      icon={cross}
                                    />
                                  </Button>
                                </div>
                              ))}
                            </div>

                            {/* Education input fields and Add button */}
                            <div className="row g-2">
                              <div className="col-12 col-md-5">
                                <TextField
                                  fullWidth
                                  name="degree"
                                  label="Degree"
                                  variant="filled"
                                  onChange={(e) =>
                                    setEducation({
                                      ...education,
                                      degree: e.target.value,
                                    })
                                  }
                                  style={{
                                    backgroundColor: "#1C1C1C",
                                    color: "#fff",
                                  }}
                                />
                              </div>
                              <div className="col-12 col-md-5">
                                <TextField
                                  fullWidth
                                  name="collegeName"
                                  label="College Name"
                                  variant="filled"
                                  onChange={(e) =>
                                    setEducation({
                                      ...education,
                                      collegeName: e.target.value,
                                    })
                                  }
                                  style={{
                                    backgroundColor: "#1C1C1C",
                                    color: "#fff",
                                  }}
                                />
                              </div>
                              <div className="col-12 col-md-2 d-flex align-items-center justify-content-center">
                                <Button
                                  variant="outlined"
                                  color="success"
                                  onClick={addEducationData}
                                  className="w-100"
                                  style={{
                                    height: "40px", // Adjust this to your preferred height
                                    padding: "0.5rem", // Smaller padding for smaller height
                                    fontSize: "0.875rem", // Smaller font size for the button text
                                  }}
                                >
                                  Add
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="container mt-4">
                    <div class="row">
                      <span class="col-md-3 col-12">Career Details</span>
                      <div className="col-md-9 col-12 d-flex flex-column px-3">
                        <div className="d-flex flex-column flex-md-row align-items-center mb-3">
                          <p className="mb-0 me-3">Currently Unemployed</p>
                          <div className="d-flex align-items-center me-3">
                            <input
                              style={{
                                margin: "1rem",
                                width: "1rem",
                                height: "1rem",
                                border: "1px solid black",
                              }}
                              className="me-2"
                              type="radio"
                              id="yes"
                              value="yes"
                              name="newForWorking"
                            />
                            <label htmlFor="yes" className="mb-0">
                              Yes
                            </label>
                          </div>
                          <div className="d-flex align-items-center me-3">
                            <input
                              style={{
                                margin: "1rem",
                                width: "1rem",
                                height: "1rem",
                                border: "1px solid black",
                              }}
                              className="me-2"
                              type="radio"
                              id="no"
                              value="no"
                              name="newForWorking"
                            />
                            <label htmlFor="no" className="mb-0">
                              No
                            </label>
                          </div>
                        </div>
                        {data.newForWorking == "no" && (
                          <>
                            <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
                              {jobDataAll?.map((job) => (
                                <div className="m-2" key={job.jobDescription}>
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#f00d88", // Adjust this to your preferred color
                                      color: "#fff",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      textAlign: "left",
                                      whiteSpace: "normal", // Allow text to wrap
                                      wordBreak: "break-word", // Break long words
                                      padding: "0.5rem 1rem", // Adjust padding as needed
                                    }}
                                  >
                                    <span className="flex-grow-1">
                                      {job.jobDescription} -- {job.companyName}
                                    </span>
                                    <Icon
                                      onClick={() =>
                                        removeElementByCondition(
                                          job.jobDescription
                                        )
                                      }
                                      style={{
                                        paddingLeft: "0.5rem",
                                        color: "white",
                                        cursor: "pointer",
                                      }} // White cross icon
                                      icon={cross}
                                    />
                                  </Button>
                                </div>
                              ))}
                            </div>
                            <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
                              <TextField
                                style={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  margin: "0.5rem",
                                  border: "1px solid black",
                                }}
                                variant="filled"
                                label="Job Description"
                                name="jobDescription"
                                fullWidth
                              />
                              <TextField
                                style={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  margin: "0.5rem",
                                  border: "1px solid black",
                                }}
                                variant="filled"
                                label="Company Name"
                                name="companyName"
                                fullWidth
                              />
                            </div>
                            <div className="d-flex justify-content-center mb-3">
                              <Button
                                onClick={() => addJobData(jobData)}
                                variant="outlined"
                                color="success"
                                size="small"
                                sx={{
                                  borderColor: "green",
                                  color: "green",
                                  padding: "2px 8px",
                                  minHeight: "24px",
                                  fontSize: "0.75rem",
                                  lineHeight: "1",
                                  "&:hover": {
                                    borderColor: "darkgreen",
                                    backgroundColor: "rgba(0, 128, 0, 0.1)",
                                  },
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="container mt-4">
                    <div class="row">
                      <span class="col-md-3 col-12">Existing Vip</span>
                      <div className="col-md-9 col-12 d-flex flex-column px-3">
                        <div className="d-flex flex-column flex-md-row align-items-center mb-3">
                          <div className="d-flex align-items-center me-3">
                            <label htmlFor="yes">Yes</label>
                            <input
                              style={{
                                margin: "1rem",
                                width: "1rem",
                                height: "1rem",
                                border: "1px solid black",
                              }}
                              class=" p-3 bg-black rounded-3"
                              type="radio"
                              id="yes"
                              value="yes"
                              name="isVip"
                            />
                          </div>
                          <div className="d-flex align-items-center me-3">
                            <label htmlFor="no">No</label>
                            <input
                              style={{
                                margin: "1rem",
                                width: "1rem",
                                height: "1rem",
                                border: "1px solid black",
                              }}
                              class="p-3 bg-black rounded-3"
                              type="radio"
                              id="no"
                              value="no"
                              name="isVip"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="container mt-4">
                    <div class="row">
                      <span class="col-3">Contact Details</span>
                      <div className="col-12 col-md-9 d-flex flex-column px-3">
                        <div className="d-flex flex-column flex-md-row align-items-center">
                          <TextField
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              margin: "0.5rem 0", // Adjusted margin for spacing
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="Contact Email"
                            name="email"
                            fullWidth
                          />
                        </div>

                        <div className="d-flex flex-column flex-md-row">
                          <TextField
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              margin: "0.5rem 0", // Adjusted margin for spacing
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="Contact Phone Number"
                            name="phoneNumber"
                            fullWidth
                          />
                        </div>
                        <div className="d-flex flex-column flex-md-row">
                          <TextField
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              margin: "0.5rem 0", // Adjusted margin for spacing
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="Whatsapp"
                            name="whatsappNumber"
                            fullWidth
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="container">
                    <div class="row">
                      <span class="col-3">Address</span>
                      <div className="col-12 col-md-9 d-flex flex-column px-3">
                        {/* First Row */}
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Door/Flat No"
                              name="doorFlatNumber"
                              fullWidth
                            />
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Street Name"
                              name="streetName"
                              fullWidth
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Land mark"
                              name="landMark"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <Autocomplete
                              options={Country.getAllCountries()}
                              getOptionLabel={(option) => option.name}
                              value={selectedCountry}
                              onChange={(event, newValue) =>
                                setSelectedCountry(newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="Country"
                                  placeholder="Country"
                                  InputLabelProps={{
                                    style: { color: "gray" },
                                  }}
                                  sx={{
                                    "& .MuiFilledInput-root": {
                                      backgroundColor: "black",
                                      color: "white",
                                    },
                                    "& .MuiFilledInput-root:hover:before": {
                                      borderColor: "#f00d88",
                                    },
                                    "& .MuiFilledInput-root.Mui-focused:before":
                                      {
                                        borderColor: "#f00d88",
                                      },
                                  }}
                                />
                              )}
                              PaperComponent={(props) => (
                                <Paper
                                  {...props}
                                  sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              )}
                              sx={{
                                "& .MuiAutocomplete-option": {
                                  "&:hover": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                  "&[aria-selected='true']": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                },
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <Autocomplete
                              options={
                                State?.getStatesOfCountry(
                                  selectedCountry?.isoCode
                                ) || []
                              }
                              getOptionLabel={(option) => option.name}
                              value={selectedState}
                              onChange={(event, newValue) =>
                                setSelectedState(newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="State"
                                  placeholder="State"
                                  InputLabelProps={{
                                    style: { color: "gray" },
                                  }}
                                  sx={{
                                    "& .MuiFilledInput-root": {
                                      backgroundColor: "black",
                                      color: "white",
                                    },
                                    "& .MuiFilledInput-root:hover:before": {
                                      borderColor: "#f00d88",
                                    },
                                    "& .MuiFilledInput-root.Mui-focused:before":
                                      {
                                        borderColor: "#f00d88",
                                      },
                                  }}
                                />
                              )}
                              PaperComponent={(props) => (
                                <Paper
                                  {...props}
                                  sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              )}
                              sx={{
                                "& .MuiAutocomplete-option": {
                                  "&:hover": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                  "&[aria-selected='true']": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <Autocomplete
                              options={
                                selectedState
                                  ? City.getCitiesOfState(
                                      selectedState?.countryCode,
                                      selectedState?.isoCode
                                    )
                                  : []
                              }
                              getOptionLabel={(option) => option.name}
                              value={selectedCity}
                              onChange={(event, newValue) =>
                                setSelectedCity(newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="District"
                                  placeholder="District"
                                  InputLabelProps={{
                                    style: { color: "gray" },
                                  }}
                                  sx={{
                                    "& .MuiFilledInput-root": {
                                      backgroundColor: "black",
                                      color: "white",
                                    },
                                    "& .MuiFilledInput-root:hover:before": {
                                      borderColor: "#f00d88",
                                    },
                                    "& .MuiFilledInput-root.Mui-focused:before":
                                      {
                                        borderColor: "#f00d88",
                                      },
                                  }}
                                />
                              )}
                              PaperComponent={(props) => (
                                <Paper
                                  {...props}
                                  sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              )}
                              sx={{
                                "& .MuiAutocomplete-option": {
                                  "&:hover": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                  "&[aria-selected='true']": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                },
                              }}
                            />
                          </div>

                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Pincode"
                              name="pinCode"
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="container mt-3">
                    <div class="row justify-content-center">
                      <div class="col-12 d-flex justify-content-center">
                        <div class="d-flex flex-column flex-sm-row align-items-center">
                          <button
                            onClick={(e) => referUser(e)}
                            class="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-course-btn"
                          >
                            Add VIP Franchise
                          </button>
                          <button class="btn btn-outline-danger btn-sm cancel-btn">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
}

function ReferFranchise() {
  const { userRole, userToken, profileData } = useGlobalContext();
  const [data, setData] = useState({
    newForWorking: "",
    userType: "franchise",
    workingOption: [],
    verified: false,
    password: "",
    otherDetails: {
      profileDataCompletion: false,
    },
    referredBy: {
      name: profileData.profileData.userName,
      referID: profileData.profileData._id,
    },
  });

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [jobDataAll, setJobDataAll] = useState([]);
  const [jobData, setJobData] = useState({
    jobDescription: "",
    companyName: "",
  });

  const [allEducation, setAllEducation] = useState([]);

  // Function to handle adding education data
  const addEducationData = () => {
    if (education.degree === "" || education.collegeName === "") {
      // Show a warning if either field is empty
      alert("Please fill in both Degree and College Name fields.");
      return;
    }
    // Add the new education entry to the allEducation array
    setAllEducation([...allEducation, education]);

    // Clear the input fields after adding
    setEducation({
      degree: "",
      collegeName: "",
    });
  };

  // Function to remove education entry by degree
  const removeElementByConditionEducation = (degree) => {
    // Filter out the education entry that matches the given degree
    const filteredArray = allEducation.filter(
      (element) => element.degree !== degree
    );
    setAllEducation(filteredArray);
  };
  function removeElementByCondition(jobDescription) {
    // Create a new array with elements that do not match the specified company name
    const filteredArray = jobDataAll.filter(
      (element) => element.jobDescription !== jobDescription
    );

    console.log(filteredArray); // This will show the new array without the specified company

    setJobDataAll(filteredArray);
  }

  const [education, setEducation] = useState({
    degree: "",
    collegeName: "",
  });

  const addJobData = (dataJ) => {
    console.log(dataJ);
    if (dataJ.jobDescription == "" || dataJ.companyName == "") {
      enqueueSnackbar("Fill the detail job description and company name", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return null;
    }
    setJobDataAll([...jobDataAll, dataJ]);
    setData({
      ...data,
      jobDescription: [...jobDataAll, dataJ],
    });
    setJobData({
      jobDescription: "",
      companyName: "",
    });
  };
  const validateReferData = (data) => {
    if (!data.userName) {
      enqueueSnackbar("User Name is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    if (!data.phoneNumber) {
      enqueueSnackbar("Phone Number is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    if (!data.email) {
      enqueueSnackbar("Email is required.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;
    }
  
    return true;
  };

  const referUser = async (e) => {
    e.preventDefault();
    if (validateReferData(data)) {
    try {
      if (selectedCity) {
        data.country = selectedCountry.name;
        data.state = selectedState.name;
        data.district = selectedCity.name;
      }
      let headers = {
        userRole: userRole,
        userToken: userToken,
      };
      let response = await postData(`${url}/vip/refer`, data, headers);
      console.log(response);
      setData({
        verified: false,
        userType: "franchise",
        workingOption: [],
      });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar(`${error.response.data.message}`, { variant: "warning" });
      console.log(error);
    }}
  };
  const handleFormDataChange = (e) => {
    e.preventDefault();
    if (e.target.name === "profileImage") {
      // Handle file input if needed
    } else if (
      e.target.name === "jobDescription" ||
      e.target.name === "companyName"
    ) {
      setJobData({ ...jobData, [e.target.name]: e.target.value });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };


  const handleClickGender = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };



  const theme = createTheme({
  palette: {
    primary: {
      main: "#f00d88", // Pink color
    },
    text: {
      primary: "#ffffff", // White color for input text
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: "#ffffff", // White color for input text
          backgroundColor: "black", // Background color for filled input
          "&:before": {
            borderColor: "white", // Initial border color white
          },
          "&:hover:before": {
            borderColor: "#f00d88", // Border color on hover
          },
          "&.Mui-focused:before": {
            borderColor: "#f00d88", // Border color when focused
          },
          "&.Mui-focused": {
            backgroundColor: "black", // Background color when focused
          },
        },
        input: {
          color: "#ffffff", // White color for input text
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "gray", // Gray color for label
          "&.Mui-focused": {
            color: "#f00d88", // Pink color for label when focused
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "white", // White color for the dropdown icon
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#25272d", // Dark gray background for dropdown
          color: "#ffffff", // White text color for dropdown items
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#ffffff",// Pink color for the dropdown icon
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "#25272d", // Gray background
          color: "#ffffff", // White text color
          "&:hover": {
            backgroundColor: "#f00d88", // Pink background on hover
          },
        },
      },
    },
  },
});


  return (
    <ThemeProvider theme={theme}>
      <div>
        <form
          onChange={handleFormDataChange}
          class="container"
          style={{ position: "relative" }}
        >
          <div class="row d-md-flex flex-column-reverse flex-md-row">
            <div class="col col-12 col-md-9">
              <div id="form-container" class="m-1">
                <form
                  class="scroll"
                  style={{
                    height: "85vh",
                    overflowY: "scroll",
                    paddingTop: "1rem",
                    paddingBottom: "5rem",
                  }}
                  action=""
                >
                  <div class="container"></div>

                  <div className="container my-4">
                    <hr />
                    <span className="h4 d-block mb-4 pb-2">
                      Personal Information
                    </span>
                    <div className="row">
                      <div className="col-12">
                        <div className="row g-3">
                          <div className="col-12">
                            <TextField
                              fullWidth
                              name="userName"
                              label="Name"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="age"
                              label="Age"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="bloodGroup"
                              label="Blood Group"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              label="Date of Birth (dd-mm-yyyy)"
                              variant="filled"
                              id="filled-basic"
                              type="date"
                              name="dateOfBirth"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                style: {
                                  colorScheme: "dark", // For dark mode styling
                                  width: "100%",
                                },
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                          <FormControl
  fullWidth
  variant="filled"
 
>
  <InputLabel>Gender</InputLabel>
  <Select
    name="gender"
    value={data.gender}
    onChange={handleClickGender} // Ensure this updates the `data` state correctly
  >
    <MenuItem value="Male">Male</MenuItem>
    <MenuItem value="Female">Female</MenuItem>
    <MenuItem value="Other">Other</MenuItem>
  </Select>
</FormControl>
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="fatherName"
                              label="Father Name"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <TextField
                              fullWidth
                              name="motherName"
                              label="Mother Name"
                              variant="filled"
                              id="filled-basic"
                            />
                          </div>

                          <div>
                            <div className="mb-3">
                              {allEducation.map((education, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mb-2"
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#f00d88", // Adjust this to your preferred color
                                      color: "#fff",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      textAlign: "left",
                                      whiteSpace: "normal", // Allow text to wrap
                                      wordBreak: "break-word", // Break long words
                                      padding: "0.5rem 1rem", // Adjust padding as needed
                                    }}
                                  >
                                    <span style={{ flex: 1 }}>
                                      {education.degree} --{" "}
                                      {education.collegeName}
                                    </span>
                                    <Icon
                                      onClick={() =>
                                        removeElementByConditionEducation(
                                          education.degree
                                        )
                                      }
                                      style={{
                                        paddingLeft: "0.5rem",
                                        color: "white",
                                        cursor: "pointer",
                                      }} // White cross icon
                                      icon={cross}
                                    />
                                  </Button>
                                </div>
                              ))}
                            </div>

                            {/* Education input fields and Add button */}
                            <div className="row g-2">
                              <div className="col-12 col-md-5">
                                <TextField
                                  fullWidth
                                  name="degree"
                                  label="Degree"
                                  variant="filled"
                                  onChange={(e) =>
                                    setEducation({
                                      ...education,
                                      degree: e.target.value,
                                    })
                                  }
                                  style={{
                                    backgroundColor: "#1C1C1C",
                                    color: "#fff",
                                  }}
                                />
                              </div>
                              <div className="col-12 col-md-5">
                                <TextField
                                  fullWidth
                                  name="collegeName"
                                  label="College Name"
                                  variant="filled"
                                  onChange={(e) =>
                                    setEducation({
                                      ...education,
                                      collegeName: e.target.value,
                                    })
                                  }
                                  style={{
                                    backgroundColor: "#1C1C1C",
                                    color: "#fff",
                                  }}
                                />
                              </div>
                              <div className="col-12 col-md-2 d-flex align-items-center justify-content-center">
                                <Button
                                  variant="outlined"
                                  color="success"
                                  onClick={addEducationData}
                                  className="w-100"
                                  style={{
                                    height: "40px", // Adjust this to your preferred height
                                    padding: "0.5rem", // Smaller padding for smaller height
                                    fontSize: "0.875rem", // Smaller font size for the button text
                                  }}
                                >
                                  Add
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="container mt-4">
                    <div class="row">
                      <span class="col-md-3 col-12">Career Details</span>
                      <div className="col-md-9 col-12 d-flex flex-column px-3">
                        <div className="d-flex flex-column flex-md-row align-items-center mb-3">
                          <p className="mb-0 me-3">Currently Unemployed</p>
                          <div className="d-flex align-items-center me-3">
                            <input
                              style={{
                                margin: "1rem",
                                width: "1rem",
                                height: "1rem",
                                border: "1px solid black",
                              }}
                              className="me-2"
                              type="radio"
                              id="yes"
                              value="yes"
                              name="newForWorking"
                            />
                            <label htmlFor="yes" className="mb-0">
                              Yes
                            </label>
                          </div>
                          <div className="d-flex align-items-center me-3">
                            <input
                              style={{
                                margin: "1rem",
                                width: "1rem",
                                height: "1rem",
                                border: "1px solid black",
                              }}
                              className="me-2"
                              type="radio"
                              id="no"
                              value="no"
                              name="newForWorking"
                            />
                            <label htmlFor="no" className="mb-0">
                              No
                            </label>
                          </div>
                        </div>
                        {data.newForWorking == "no" && (
                          <>
                            <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
                              {jobDataAll?.map((job) => (
                                <div className="m-2" key={job.jobDescription}>
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#f00d88", // Adjust this to your preferred color
                                      color: "#fff",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      textAlign: "left",
                                      whiteSpace: "normal", // Allow text to wrap
                                      wordBreak: "break-word", // Break long words
                                      padding: "0.5rem 1rem", // Adjust padding as needed
                                    }}
                                  >
                                    <span className="flex-grow-1">
                                      {job.jobDescription} -- {job.companyName}
                                    </span>
                                    <Icon
                                      onClick={() =>
                                        removeElementByCondition(
                                          job.jobDescription
                                        )
                                      }
                                      style={{
                                        paddingLeft: "0.5rem",
                                        color: "white",
                                        cursor: "pointer",
                                      }} // White cross icon
                                      icon={cross}
                                    />
                                  </Button>
                                </div>
                              ))}
                            </div>
                            <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
                              <TextField
                                style={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  margin: "0.5rem",
                                  border: "1px solid black",
                                }}
                                variant="filled"
                                label="Job Description"
                                name="jobDescription"
                                fullWidth
                              />
                              <TextField
                                style={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  margin: "0.5rem",
                                  border: "1px solid black",
                                }}
                                variant="filled"
                                label="Company Name"
                                name="companyName"
                                fullWidth
                              />
                            </div>
                            <div className="d-flex justify-content-center mb-3">
                              <Button
                                onClick={() => addJobData(jobData)}
                                variant="outlined"
                                color="success"
                                size="small"
                                sx={{
                                  borderColor: "green",
                                  color: "green",
                                  padding: "2px 8px",
                                  minHeight: "24px",
                                  fontSize: "0.75rem",
                                  lineHeight: "1",
                                  "&:hover": {
                                    borderColor: "darkgreen",
                                    backgroundColor: "rgba(0, 128, 0, 0.1)",
                                  },
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="container mt-4">
                    <div class="row">
                      <span class="col-3">Contact Details</span>
                      <div className="col-12 col-md-9 d-flex flex-column px-3">
                        <div className="d-flex flex-column flex-md-row align-items-center">
                          <TextField
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              margin: "0.5rem 0", // Adjusted margin for spacing
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="Contact Email"
                            name="email"
                            fullWidth
                          />
                        </div>

                        <div className="d-flex flex-column flex-md-row">
                          <TextField
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              margin: "0.5rem 0", // Adjusted margin for spacing
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="Contact Phone Number"
                            name="phoneNumber"
                            fullWidth
                          />
                        </div>
                        <div className="d-flex flex-column flex-md-row">
                          <TextField
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              margin: "0.5rem 0", // Adjusted margin for spacing
                              border: "1px solid black",
                            }}
                            variant="filled"
                            label="Whatsapp"
                            name="whatsappNumber"
                            fullWidth
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="container">
                    <div class="row">
                      <span class="col-3">Address</span>
                      <div className="col-12 col-md-9 d-flex flex-column px-3">
                        {/* First Row */}
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Door/Flat No"
                              name="doorFlatNumber"
                              fullWidth
                            />
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Street Name"
                              name="streetName"
                              fullWidth
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Land mark"
                              name="landMark"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <Autocomplete
                              options={Country.getAllCountries()}
                              getOptionLabel={(option) => option.name}
                              value={selectedCountry}
                              onChange={(event, newValue) =>
                                setSelectedCountry(newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="Country"
                                  placeholder="Country"
                                  InputLabelProps={{
                                    style: { color: "gray" },
                                  }}
                                  sx={{
                                    "& .MuiFilledInput-root": {
                                      backgroundColor: "black",
                                      color: "white",
                                    },
                                    "& .MuiFilledInput-root:hover:before": {
                                      borderColor: "#f00d88",
                                    },
                                    "& .MuiFilledInput-root.Mui-focused:before":
                                      {
                                        borderColor: "#f00d88",
                                      },
                                  }}
                                />
                              )}
                              PaperComponent={(props) => (
                                <Paper
                                  {...props}
                                  sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              )}
                              sx={{
                                "& .MuiAutocomplete-option": {
                                  "&:hover": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                  "&[aria-selected='true']": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                },
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <Autocomplete
                              options={
                                State?.getStatesOfCountry(
                                  selectedCountry?.isoCode
                                ) || []
                              }
                              getOptionLabel={(option) => option.name}
                              value={selectedState}
                              onChange={(event, newValue) =>
                                setSelectedState(newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="State"
                                  placeholder="State"
                                  InputLabelProps={{
                                    style: { color: "gray" },
                                  }}
                                  sx={{
                                    "& .MuiFilledInput-root": {
                                      backgroundColor: "black",
                                      color: "white",
                                    },
                                    "& .MuiFilledInput-root:hover:before": {
                                      borderColor: "#f00d88",
                                    },
                                    "& .MuiFilledInput-root.Mui-focused:before":
                                      {
                                        borderColor: "#f00d88",
                                      },
                                  }}
                                />
                              )}
                              PaperComponent={(props) => (
                                <Paper
                                  {...props}
                                  sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              )}
                              sx={{
                                "& .MuiAutocomplete-option": {
                                  "&:hover": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                  "&[aria-selected='true']": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <Autocomplete
                              options={
                                selectedState
                                  ? City.getCitiesOfState(
                                      selectedState?.countryCode,
                                      selectedState?.isoCode
                                    )
                                  : []
                              }
                              getOptionLabel={(option) => option.name}
                              value={selectedCity}
                              onChange={(event, newValue) =>
                                setSelectedCity(newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="District"
                                  placeholder="District"
                                  InputLabelProps={{
                                    style: { color: "gray" },
                                  }}
                                  sx={{
                                    "& .MuiFilledInput-root": {
                                      backgroundColor: "black",
                                      color: "white",
                                    },
                                    "& .MuiFilledInput-root:hover:before": {
                                      borderColor: "#f00d88",
                                    },
                                    "& .MuiFilledInput-root.Mui-focused:before":
                                      {
                                        borderColor: "#f00d88",
                                      },
                                  }}
                                />
                              )}
                              PaperComponent={(props) => (
                                <Paper
                                  {...props}
                                  sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              )}
                              sx={{
                                "& .MuiAutocomplete-option": {
                                  "&:hover": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                  "&[aria-selected='true']": {
                                    backgroundColor: "#f00d88",
                                    color: "white",
                                  },
                                },
                              }}
                            />
                          </div>

                          <div className="col-12 col-md-6 mb-3">
                            <TextField
                              style={{
                                width: "100%",
                                border: "1px solid black",
                              }}
                              variant="filled"
                              label="Pincode"
                              name="pinCode"
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="container mt-3">
                    <div class="row justify-content-center">
                      <div class="col-12 d-flex justify-content-center">
                        <div class="d-flex flex-column flex-sm-row align-items-center">
                          <button
                            onClick={(e) => referUser(e)}
                            class="btn btn-outline-success btn-sm mb-2 mb-sm-0 me-0 me-sm-2 add-course-btn"
                          >
                            Add Franchise
                          </button>
                          <button class="btn btn-outline-danger btn-sm cancel-btn">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
}

function ReferenceForm() {
  const [selectedView, setSelectedView] = useState("Vip");
  const { userRole } = useGlobalContext();
  const [filter, setFilter] = useState(false);
  const FilterOpen = () => {
    filter ? setFilter(false) : setFilter(true);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedView(newValue);
  };

  return (
    <Container style={{ height: "95vh", overflowY: "scroll" }}>
      <TopContainer
        heading={"REFERENCE FORMS"}
        options={["Tia mock", "Tia Brain", "Live Tutor", "Test hub"]}
        filter={filter}
        FilterOpen={FilterOpen}
      />

      <Box
        sx={{
          marginTop: "1rem",
          width: "100%",
          backgroundColor: "var(--navbar-dark-primary)", // Light gray background
          borderRadius: "8px", // Rounded corners
          boxShadow: 3, // Shadow effect
          padding: "1rem",
          marginBottom: "1rem",
          display: "flex", // Use Flexbox
          justifyContent: "center", // Center the tabs
        }}
      >
        <Tabs
          value={selectedView}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Franchise Navigation Tabs"
          textColor="inherit"
          indicatorColor="secondary"
          sx={{
            "& .MuiTab-root": {
              minWidth: "120px", // Minimum width for each tab
              fontWeight: "bold", // Bold text
              fontSize: "0.875rem", // Font size
              color: "white", // Default text color
              "&.Mui-selected": {
                color: "white", // Active tab text color
              },
            },
            "& .MuiTabs-indicator": {
              height: "4px", // Indicator height
              backgroundColor: "#f00d88", // Indicator color
            },
          }}
        >
          <Tab label="Vip" value="Vip" />
          <Tab label="Vip Franchise" value="vipFranchise" />
          <Tab label="Franchise" value="Franchise" />
        </Tabs>
      </Box>

      <div>
        {selectedView === "Vip" && <ReferVip />}
        {selectedView === "vipFranchise" && <ReferVipFranchise />}
        {selectedView === "Franchise" && <ReferFranchise />}
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  .btn {
    background-color: var(--navbar-dark-primary);
    padding: 1rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  #line {
    background-color: var(--icon-color);
    height: 3px;
    width: 100%;
  }

  margin-top: 2rem;
  #otp-container {
    position: absolute;
    top: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #313131;
    height: 20rem;
    width: 20rem;
    top: 30%;
    left: 40%;
  }
  .otp-input {
    color: black;
    border-radius: 8px;
  }
  #otp ::placeholder {
    color: black;
  }
  .verify-btn {
    background-color: lightgrey;
    color: #1d1e22;
    border-radius: 1rem;
    padding: 1rem;
  }
  input::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  #add-student-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: gray !important;
  }

  h2,
  p,
  div,
  h6 {
    color: white;
  }
  .add-course-btn:hover {
    background-color: green;
  }
  .cancel-btn:hover {
    background-color: red;
  }
  #form-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #25272d;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }

  #miniNav {
    display: none;
  }
  #searchfield {
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
  }

  #searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height: 20px;
    width: 10rem;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
  ::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #bf2f82c4;
  }

  .font {
    border: 0px solid;
    color: #bf2f82c4;
  }

  .icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
  }

  .icon {
    color: #bf2f82c4;
    border-radius: 5rem;
  }

  ::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }

  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }

  h5 {
    text-align: center;
    color: white;
  }

  input {
    color: white;
  }

  /* Status Section Styles */
  .status-section {
    margin-top: 1rem;
  }

  .status-label {
    color: white;
  }

  .status-options {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .active-dot {
    background-color: green;
  }

  .inactive-dot {
    background-color: red;
  }

  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #add-student-container {
      padding: 1rem;
    }

    #profile-container {
      margin-top: 1rem;
    }
  }
  /* Style for active radio button */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }
  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }
  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }
  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  .add-course-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }
  .mini-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
  }
  .checkbox-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #1d1e22;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to individual boxes within containers */
  }
  .checkbox-container {
    display: flex; /* Display checkboxes horizontally */
    justify-content: flex-start; /* Align checkboxes to the start */
    flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
    margin-bottom: 20px; /* Add margin at the bottom */
  }
  .checkbox-container div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px; /* Add margin at the bottom of each checkbox */
  }

  .checkbox-container div:last-child {
    margin-right: 0; /* Remove margin for the last checkbox */
  }
  .pink-btn {
    border-color: rgb(201, 66, 149); /* Change the outline color to pink */
    color: rgb(201, 66, 149); /* Change text color to ensure visibility */
  }
  .generate-btn:hover {
    background-color: green;
  }
  .pink-btn:hover {
    background-color: #313131;
    color: rgb(255, 255, 255);
  }
  .btn-verify {
    background-color: green;
    color: white;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    cursor: pointer;
  }
  @media (max-width: 575px) {
    .add-course-btn,
    .cancel-btn {
      align-items: center;
      width: 100%; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
    }
  }
`;
export { ReferenceForm };
