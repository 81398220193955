import React, { useState } from 'react';
import styled from 'styled-components';
import { uploadImg, postData } from "../../Api";
import { enqueueSnackbar } from "notistack";
import { URL as url } from "../../assets/mocData/config";
import { useGlobalContext } from '../../assets/contex/GlobalContext';

function MarketingUpdatesSection() {
  const [textAreaValue, setTextAreaValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false); // New state to track upload status
  const { userToken, userRole, profileData } = useGlobalContext();
  const headers = { userToken: userToken, userRole: userRole };
  const [formKey, setFormKey] = useState(0); // Key to force refresh

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setTextAreaValue(!isChecked ? 'Today I am Present !' : '');
  };

  const handleFileChange = (e) => {
    const filesArray = Array.from(e.target.files);
    setSelectedFiles(filesArray);
    setIsUploaded(false); // Reset upload status when new files are selected
  };

  const handleImageSubmit = async (e) => {
    e.preventDefault();

    // Validation: Ensure both "Upload Medium" and "Daily Activity" are filled
    if (mediums.length === 0) {
      enqueueSnackbar('Please select at least one upload medium.', { variant: 'warning' });
      return;
    }

    if (!isChecked && !textAreaValue.trim()) {
      enqueueSnackbar('Please mark your daily activity or enter activity details.', { variant: 'warning' });
      return;
    }

    const data = {
      imagekey: images,
      desc: textAreaValue,
      medium: mediums,
      user_mail: profileData.profileData.email,
    };

    try {
      await postData(`${url}/marketting/upload`, data, headers);
      enqueueSnackbar("Submitted successfully", { variant: "success" });
      resetForm(); // Reset the form after successful submission
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Submission failed", { variant: "error" });
    }
  };

  const resetForm = () => {
    setSelectedFiles([]);
    setMediums([]);
    setImages([]);
    setTextAreaValue('');
    setIsChecked(false);
    setIsUploaded(false); // Reset upload status

    // Force the upload section to refresh
    setFormKey((prevKey) => prevKey + 1);
  };

  const handleCancelClick = (e) => {
    e.preventDefault(); // Prevent form submission on cancel
    resetForm(); // Reset form fields and refresh the section
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    // Validation: Check if at least one file is selected
    if (selectedFiles.length === 0) {
      enqueueSnackbar('Please select at least one image to upload.', { variant: 'warning' });
      return;
    }

    try {
      const fileUrls = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const filee = selectedFiles[i];
        try {
          const objurl = await uploadImg(filee);
          enqueueSnackbar("Uploaded successfully", { variant: "success" });
          fileUrls.push(filee.name);
        } catch (err) {
          console.log(err);
        }
      }
      setImages(fileUrls);
      setIsUploaded(true); // Set upload status to true after successful upload
    } catch (err) {
      enqueueSnackbar(`${err.message}`, { variant: "warning" });
    }
  };

  const handleImageClick = (imageSrc) => {
    setPreviewImage(imageSrc);
  };

  return (
    <MarketingUpdatesContainer>
      <div id="media-container">
        <div id="media-top-section">
          <h2 style={{whiteSpace:"nowrap"}}>MARKETING UPDATES</h2>
        </div>

        {/* Use the formKey to force re-render of the upload section */}
        <div id="upload-section" className="container" key={formKey}>
          <div className="row">
            <div className="col-12 col-md-6">
              <div id="file-upload">
                <div className="file-drop-area">
                  <div className="d-flex flex-column align-items-center">
                    <i className="fa-solid fa-upload upload-icon"></i>
                    <span className="file-msg mt-4"><b>Drag and Upload the Proof</b></span>
                    <input className="file-input" type="file" multiple onChange={handleFileChange} />
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  {selectedFiles.length > 0 && (
                    <div id="image-preview">
                      {selectedFiles.map((file, index) => (
                        <img
                          key={index}
                          src={URL.createObjectURL(file)}
                          alt={`preview-${index}`}
                          className="image-thumbnail"
                          onClick={() => handleImageClick(URL.createObjectURL(file))}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center mt-4">
                  {/* Disable the upload button if files have been uploaded */}
                  <button className="upload-btn" onClick={handleUpload} disabled={isUploaded}>
                    {isUploaded ? "Uploaded" : "Upload"}
                  </button>
                </div>
              </div>
            </div>
           
            <div className="col-12 col-md-6">
              <form onSubmit={handleImageSubmit}>
                <div className="medium-section" >
                  <p>Upload Medium</p>
                  <div className="d-flex justify-content-between">
                    <div className="upload-medium">
                      <div className="checkbox-bx">
                        <label>
                          <input type="checkbox" onChange={(e) => e.target.checked ? setMediums([...mediums, "facebook"]) : setMediums(mediums.filter(m => m !== "facebook"))} />
                          <span></span>
                        </label>
                      </div>
                      <img className="medium-icon" src="https://www.freeiconspng.com/uploads/facebook-icon--basic-round-social-iconset--s-icons-7.png" alt="Facebook" />
                    </div>
                    <div className="upload-medium">
                      <div className="checkbox-bx">
                        <label>
                          <input type="checkbox" onChange={(e) => e.target.checked ? setMediums([...mediums, "whatsapp"]) : setMediums(mediums.filter(m => m !== "whatsapp"))} />
                          <span></span>
                        </label>
                      </div>
                      <img className="medium-icon" src="https://static.vecteezy.com/system/resources/thumbnails/031/737/222/small/whatsapp-logo-whatsapp-social-media-icon-free-png.png" alt="WhatsApp" />
                    </div>
                    <div className="upload-medium">
                      <div className="checkbox-bx">
                        <label>
                          <input type="checkbox" onChange={(e) => e.target.checked ? setMediums([...mediums, "instagram"]) : setMediums(mediums.filter(m => m !== "instagram"))} />
                          <span></span>
                        </label>
                      </div>
                      <img className="medium-icon" src="https://png.pngtree.com/png-clipart/20180626/ourmid/pngtree-instagram-icon-instagram-logo-png-image_3584853.png" alt="Instagram" />
                    </div>
                  </div>
                  <div className="pt-4">
                    <p>Daily Activity</p>
                    <div className="checkbox-wrapper-10">
                      <input className="tgl tgl-flip" id="cb5" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                      <label className="tgl-btn" data-tg-off="Nope" data-tg-on="Present" htmlFor="cb5"></label>
                    </div>
                    <div className="d-flex">
                      <textarea
                        className="activity-textarea"
                        value={textAreaValue}
                        rows="7"
                        onChange={(e) => setTextAreaValue(e.target.value)}
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-4 flex-row-reverse">
                  <button className="upload-btn" type="submit">Submit</button>
                  <button className="upload-btn" onClick={handleCancelClick}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {previewImage && (
        <ImagePreviewOverlay className="preview-modal">
          <img src={previewImage} alt="Preview" onClick={() => setPreviewImage(null)} />
          </ImagePreviewOverlay>
      )}
    </MarketingUpdatesContainer>
  );
}


const MarketingUpdatesContainer = styled.div`
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  color: white;

  .fa-solid {
    color: var(--icon-color);
  }

  #media-container {
    width: 100%;
    padding: 2rem;
    height: fit-content;
  }

  #media-top-section {
    padding-bottom: 2rem;
    @media (max-width:768px) {
    margin-left:30%;
 } 
      @media (max-width:400px) {
    margin-left:20%;
 } 
  }


h2{
 color: var(--var-text-color);
 font-size:1.8rem;
 @media (max-width:768px) {
 font-size:1.4rem;
 }

}
   h4, p, span {
    color: var(--var-text-color);
  }

  #upload-section {
    background-color: #25272d;
    padding: 2rem;
    width: 100%;
  }

  #file-upload {
    width: 100%;
    border-radius: 10px;
    background-color: black;
    padding: 1rem;
  }

  .file-drop-area {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 25px;
    border: 1px dashed rgba(255, 255, 255, 0.4);
    border-radius: 3px;
    transition: 0.2s;
  }

  .file-drop-area.is-active {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .upload-btn {
    flex-shrink: 0;
    background-color: #bf2f82c4;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    padding: 8px 15px;
    margin-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
  }
    .cancel-btn {
    flex-shrink: 0;
    background-color: #bf2f82c4;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    padding: 8px 15px;
    margin-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .file-msg {
    font-size: small;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
  }

  .file-input:focus {
    outline: none;
  }

  .medium-section {
    width: 100%;
  }

  .upload-medium {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 6rem;
    min-height: 6rem;
    background-color: black;
    border-radius: 10px;
  }

  .medium-icon {
    height: 3rem;
    width: 3rem;
  }

  .checkbox-bx {
    position: absolute;
    right: 5px;
    top: 2px;
  }

  label {
    position: relative;
    height: 24px;
    width: 24px;
  }

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }

  input[type=checkbox] {
    accent-color: green;
  }

  .checkbox-bx label span {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
    cursor: pointer;
    background-color: #25272d;
  }

  .checkbox-bx label span::after {
    content: "";
    position: absolute;
    left: 12px;
    bottom: 8px;
    width: 10px;
    height: 20px;
    border: solid #00ff0a;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: width 0.5s, height 0.5s, opacity 0.5s;
  }

  .checkbox-bx input:checked ~ span::after {
    width: 10px;
    height: 20px;
    opacity: 1;
  }

  .checkbox-wrapper-10 .tgl {
    display: none;
  }

  .checkbox-wrapper-10 .tgl,
  .checkbox-wrapper-10 .tgl:after,
  .checkbox-wrapper-10 .tgl:before,
  .checkbox-wrapper-10 .tgl *,
  .checkbox-wrapper-10 .tgl *:after,
  .checkbox-wrapper-10 .tgl *:before,
  .checkbox-wrapper-10 .tgl + .tgl-btn {
    box-sizing: border-box;
  }

  .checkbox-wrapper-10 .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 6em;
    height: 2.5em;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding: 0;
  }
.checkbox-wrapper-10 .tgl + .tgl-btn:after,
.checkbox-wrapper-10 .tgl + .tgl-btn:before {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 80%;
  text-align: center;
  font-weight: bold;
  font-size: 1rem; /* Base font size */
  top: 0;
  left: 0;
  color: #fff;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .checkbox-wrapper-10 .tgl + .tgl-btn:after,
  .checkbox-wrapper-10 .tgl + .tgl-btn:before {
    font-size: 1rem; /* Slightly smaller font size */
  }
}

@media (max-width: 992px) {
  .checkbox-wrapper-10 .tgl + .tgl-btn:after,
  .checkbox-wrapper-10 .tgl + .tgl-btn:before {
    height: 70%; /* Reduce height for smaller screens */
      width: 80%;
    font-size: 1rem; /* Smaller font size */
  }
}

@media (max-width: 768px) {
  .checkbox-wrapper-10 .tgl + .tgl-btn:after,
  .checkbox-wrapper-10 .tgl + .tgl-btn:before {
    height: 60%; /* Further reduce height for smaller devices */
      width: 80%;
    font-size: 0.9rem; /* Smaller font size */
  }
}

@media (max-width: 576px) {
  .checkbox-wrapper-10 .tgl + .tgl-btn:after,
  .checkbox-wrapper-10 .tgl + .tgl-btn:before {
    height: 60%; /* Height adjustment for mobile devices */
      width:80%;
    font-size: 0.8rem; /* Smaller font size */
  }
}

  .checkbox-wrapper-10 .tgl + .tgl-btn:before {
    background:transparent;
    content: attr(data-tg-off);
    border:1px solid #ff4400;
    border-radius:0.5em;
    transform: rotateY(-180deg);
    backface-visibility: hidden;
    transition: all 0.4s ease;
  }

  .checkbox-wrapper-10 .tgl + .tgl-btn:after {
    background:transparent;
    content: attr(data-tg-on);
    border:1px solid #008000;
    border-radius:0.5em;
    transform: rotateY(0deg);
    backface-visibility: hidden;
    transition: all 0.4s ease;
  }

  .checkbox-wrapper-10 .tgl:checked + .tgl-btn:before {
    transform: rotateY(0deg);
  }

  .checkbox-wrapper-10 .tgl:checked + .tgl-btn:after {
    transform: rotateY(180deg);
  }

  .activity-textarea {
    width: 100%;
    border-radius: 0.25rem;
    resize: none;
    background-color: black;
    color: white;
    padding: 0.5rem;
    border: none;
    outline: none;
  }

  // Media Queries
  @media (max-width: 480px) {
    #upload-section {
      padding: 1rem;
    }
    .file-drop-area {
      padding: 10px;
    }
    .medium-section p,
    .checkbox-wrapper-10 p {
      font-size: 0.8rem;
    }
    .medium-icon {
      height: 2rem;
      width: 2rem;
    }
    .upload-medium {
      min-width: 4rem;
      min-height: 4rem;
    }
    .upload-btn {
      font-size: 10px;
      padding: 6px 10px;
    }
    .activity-textarea {
      font-size: 0.8rem;
    }
  }

  #image-preview {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  .image-thumbnail {
    max-width: 100px;
    max-height: 100px;
    margin: 0.5rem;
    border-radius: 8px;
    object-fit: cover;
  }
`;
const ImagePreviewOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
  }
`;

export default MarketingUpdatesSection