import React from 'react'
import styled from 'styled-components';
import NavBar from './miniComponents/subcomponents/NavBar';
import MiniNavBar from './miniComponents/subcomponents/MiniNavBar';
import AccountsContainer from './secondaryContainersForPages/AccountsContainer';

function Accounts() {
  
    return (
      <AccountsContainers>
        <MiniNavBar selected={"accounts"}/>
        <NavBar selected={"accounts"} />
        <AccountsContainer />
      </AccountsContainers>
    );
  }
  const AccountsContainers=styled.div`
  display: flex;
  background-color: var(--background);
  `
  export default Accounts