import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { URL as url } from './assets/mocData/config';
import { useGlobalContext } from './assets/contex/GlobalContext';
import { useNavigate } from "react-router-dom";
import { postDataNew } from './Api';
import { useSnackbar } from "notistack";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Container = styled.div`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background: #15181e;
`;

const Heading = styled.h2`
    text-align: center;
    margin-bottom: 20px;
    color: white;
`;

const InputWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
    }
`;

const SubmitButton = styled.button`
    padding: 10px;
    border: none;
    border-radius: 4px;
    background: #f00d88;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
        background: #999;
    }
`;

const Message = styled.p`
    margin-top: 15px;
    color: ${props => (props.success ? '#28a745' : '#ff0000')};
    text-align: center;
`;

const ChangePasswords = ({ emailId }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { token } = useParams(); 
    const { userToken, profileData, userRole } = useGlobalContext();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    let header = {
        userRole: userRole,
        userToken: userToken
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[0-9]).{8,}$/; 
        return passwordRegex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validatePassword(newPassword)) {
            enqueueSnackbar("Password must be at least 8 characters long and contain at least one number.", { variant: "error" });
            return;
        }

        if (newPassword !== confirmPassword) {
            enqueueSnackbar("Passwords do not match.", { variant: "error" });
            return;
        }

        const data = { email: emailId, password: newPassword };

        try {
            const response = await postDataNew(`${url}/changePassword`, data, header);
            enqueueSnackbar(response.data.message || "Password has been changed successfully.", { variant: "success" });
            navigate("/");
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Error changing password. Please try again.', { variant: "error" });
        }
    };

    return (
        <Container>
            <Heading>Change Password</Heading>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label htmlFor="newPassword" style={{ whiteSpace: "nowrap" }}>New Password:</Label>
                    <InputWrapper>
                        <Input
                            type={showNewPassword ? "text" : "password"}
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                        <IconButton 
                            onClick={() => setShowNewPassword(!showNewPassword)} 
                            style={{ position: 'absolute', right: 10, top: 10 }}
                        >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputWrapper>
                    
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="confirmPassword" style={{ whiteSpace: "nowrap" }}>Confirm New Password:</Label>
                    <InputWrapper>
                        <Input
                            type={showConfirmPassword ? "text" : "password"}
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <IconButton 
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)} 
                            style={{ position: 'absolute', right: 10, top: 10 }}
                        >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputWrapper>
                </FormGroup>
                <SubmitButton type="submit">Change Password</SubmitButton>
            </Form>
        </Container>
    );
};

export default ChangePasswords;
