import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Avatar, Button, Paper } from '@mui/material';
import { styled } from '@mui/system';

import { URL as url } from '../assets/mocData/config';
import { useGlobalContext } from '../assets/contex/GlobalContext';
import { fetchData,postData} from '../Api';


/*const messages = [
  {
    text: 'Hello! This is a WhatsApp-like message.',
    time: '10:30 AM',
    date: 'May 11, 2024',
  },
  {
    text: 'Western Light was laid down as the steel-hulled, single-screw commercial cargo ship SS Western Light by the Northwest Steel Company in Portland, Oregon, under a United States Shipping Board contract. After her completion in 1918, the Shipping Board transferred her to the U.S.',
    time: '11:45 AM',
    date: 'May 11, 2024',
  },
];*/


const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: '#1c1c1c',
  width: '90%',
  maxWidth: '500px',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: theme?.shadows?.[5] || '0px 4px 20px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#1c1c1c',
  borderBottom: `1px solid ${theme?.palette?.divider || 'gray'}`,
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#1c1c1c',
  maxHeight: '60vh',
  overflowY: 'auto',
}));

const Message = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: '#a64d79',
  color: 'white',
  borderRadius: '10px',
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#1c1c1c',
  borderTop: `1px solid ${theme?.palette?.divider || 'gray'}`,
  textAlign: 'center',
}));

export const ModalExample = ({ open, handleClose }) => {
  const [messages, setMessages] = useState([]);
  const {userToken,userRole,profileData} = useGlobalContext();

let headers={
  userRole:userRole,
  userToken:userToken
}
const handleRead=async()=>{
  const id=profileData.profileData._id

  const data={viewed:id,shows:userRole}
  console.log(data)


try{
  await postData(`${url}/userMessages/read/`,data,headers)}
catch(err){
  console.log(err)
}  
 
}
const fetchDatas=async()=>{
  const response=await fetchData(`${url}/userMessages/view`,headers)

  const filteredData=response.filter(item => item.shows.includes(userRole))
 
  setMessages(filteredData)
}
useEffect(()=>{
  fetchDatas()
},[])
  return (
    <StyledModal open={open} onClose={handleClose}>
      <ModalContent>
        <Header>
          <Avatar src="/path-to-avatar.jpg" />
          <Typography variant="h6" sx={{ marginLeft: 2, color: 'white' }}>
            CEO
          </Typography>
        </Header>
        <MessagesContainer>
          {messages.map((msg, index) => (
            <Box key={index}>
              <Typography variant="caption" sx={{ color: 'gray' }}>
                {msg.createdAt}
              </Typography>
              <Message>
                <Typography>{msg.message}</Typography>
                <Typography variant="caption" sx={{ float: 'right', color: 'lightgray' }}>
                  {msg.createdAt}
                </Typography>
              </Message>
            </Box>
          ))}
        </MessagesContainer>
        <Footer>
        <div onClick={handleRead}>
          <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: '#a64d79', color: 'white' }}>
            Close
          </Button>
          </div>
        </Footer>
      </ModalContent>
    </StyledModal>
  );
};


const CeoMessageUser = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <div>
      <Button variant="contained" onClick={handleOpen} sx={{ backgroundColor: '#a64d79', color: 'white' }}>
        Open Modal
      </Button>
      <ModalExample open={open} handleClose={handleClose} />
    </div>
  );
};

export default CeoMessageUser;
