import React from "react";
import styled from 'styled-components';
import MiniNavBar from "./miniComponents/subcomponents/MiniNavBar";
import NavBar from "./miniComponents/subcomponents/NavBar";
import { useGlobalContext } from "../assets/contex/GlobalContext";
import MarketingUpdatesSection from "./secondaryContainersForPages/marketingUpdates";
function MarketingUpdates() {
   const { userRole } = useGlobalContext();
  return (
    <MarketingContainer>
    <MiniNavBar selected={"marketingupdates"} />
      <NavBar selected={"marketingupdates"} user={userRole} />
      <MarketingUpdatesSection />
    </MarketingContainer>
  );
}
const MarketingContainer = styled.div`
height:100vh;
  display: flex;
  background-color: var(--background);
`;
export default MarketingUpdates;