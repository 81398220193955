import { styled } from "styled-components";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { deleteData, fetchData } from "../../../Api";
import SelectAllIcon from '@mui/icons-material/SelectAll';
import { Icon } from "react-icons-kit";
import { bin2 } from "react-icons-kit/icomoon/bin2";
import { pencil } from "react-icons-kit/icomoon/pencil";
import BranchEditForm from "../EditForms/BranchEditForm";
import { useGlobalContext } from "../../../assets/contex/GlobalContext";
import { URL } from "../../../assets/mocData/config";
import { Pagination, Stack ,Button,Dialog, DialogTitle, DialogContent, DialogActions} from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import * as XLSX from 'xlsx';
 
import { ic_search } from "react-icons-kit/md/ic_search";
function BranchTable({ user, filterData, setFilterData }) {
  const [branchData, setData] = useState([]);
  const { userRole, userToken, setBranchData, branchDataAll } =
    useGlobalContext();
  const [editableContainer, setEditContainer] = useState(false);
  const [editData, setEditData] = useState([]);
  const [multipleDeletion, setMulipleDeletion] = useState(false);
  const [multipleDeletionID, setMultipleDeletionId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedBranchForDeletion, setSelectedBranchForDeletion] = useState(null);
  
  let header = {
    userRole: userRole,
    userToken: userToken,
  };
  const getTotalPages = (branchData) => {
    return Math.ceil(branchData?.length / itemsPerPage);
  };
  const handlePageChange = (event, value) => {
    console.log(value);
    setCurrentPage(value);
  };
  const getPagedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    if (branchData) {
      return branchData?.slice(startIndex, startIndex + itemsPerPage);
    }
    return [];
  };
  const DeleteMultipleCourse = async () => {
    let header = {
      userRole: userRole,
      userToken: userToken,
    };
    try {
      
      let res = await axios.post(
        `${URL}/admin/branch/delete/many`,
        { branchCodes: multipleDeletionID },
        {
          headers: {
            Authorization: `Bearer ${header.userToken}`,
            role: header.userRole,
          },
        }
      );
      let data = branchData?.filter((c) => {
        if (!multipleDeletionID.includes(c._id)) {
          return c;
        }
      });

      setData(data);
      enqueueSnackbar(`${res.data.message}`, { variant: "success" });
      console.log(res);
    } catch (error) {
      enqueueSnackbar(`${error.response.data.message}`, { variant: "success" });
    }
  };

  useEffect(() => {
    const filteredData = branchDataAll?.filter((element) => {
      const searchTerm = filterData.toLowerCase();
      return (
        element.branchName.toLowerCase().includes(searchTerm) ||
        element.branchCode.toLowerCase().includes(searchTerm) ||
        element.concept?.some((concept) => concept.toLowerCase().includes(searchTerm)) ||
        element.branchOwnerEmail?.toLowerCase().includes(searchTerm) ||
        element.pincode?.toString().includes(searchTerm)
      );
    });
  
    if (filterData.length > 0 && filteredData?.length !== 0) {
      setData(filteredData);
    } else {
      setData(branchDataAll);
    }
  }, [filterData, branchDataAll]);
  
  const fetchBranchData = async () => {
    let headers = {
      userToken: userToken,
      userRole: userRole,
    };
    try {
      let response = await fetchData(`${URL}/vip/branch`, headers);
      console.log(response);
      setData(response);
      setBranchData(response);
    } catch (error) {
      enqueueSnackbar("Failed Fetching data", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchBranchData();
  }, []);
  const DeleteBranchData = async (data) => {
    let headers = {
      userToken: userToken,
      userRole: userRole,
    };
    try {
      await deleteData(`${URL}/${userRole}/branch/${data._id}`, headers);
      enqueueSnackbar("Send Successfully", {
        autoHideDuration: 1000,
        variant: "success",
      });
      let dataBranch = branchData.filter((element) => element._id !== data._id);

      setData(dataBranch);
    } catch (error) {
      enqueueSnackbar(error, { autoHideDuration: 2000, variant: "error" });
      console.log(error);
    }
  };
  const selectionForDeletion = (id) => {
    if (multipleDeletionID.includes(id)) {
      let data = multipleDeletionID?.filter((del) => del !== id);
      setMultipleDeletionId(data);
      return;
    }
    setMultipleDeletionId([...multipleDeletionID, id]);
  };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [filtData, setFiltData] = useState();
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedBranchForDeletion(null);
  };
  const DeleteSingleBranch = async () => {
    try {
      await deleteData(
        `${URL}/${userRole}/branch/${selectedBranchForDeletion._id}`,
        header
      );
      enqueueSnackbar("Branch deleted successfully", { variant: "success" });
      let updatedData = branchData.filter(
        (branch) => branch._id !== selectedBranchForDeletion._id
      );
      setData(updatedData);
      setDialogOpen(false);
    } catch (error) {
      enqueueSnackbar("Failed to delete branch", { variant: "error" });
    }
  };
  const openDeleteDialog = (branch) => {
    setSelectedBranchForDeletion(branch);
    setDialogOpen(true);
  };
  const downloadExcel = () => {
    // Prepare the data for the Excel sheet
    const ws = XLSX.utils.json_to_sheet(branchData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Branches");

    // Export the file
    XLSX.writeFile(wb, "branches.xlsx");
  };

  return (
    <BranchTableContainer >
      {!editableContainer ? (
        <>
          
          <div className="d-flex row-reverse pb-4"
          style={{marginTop:"1rem"}}>
            {userRole === "admin" && (
        <>
          {!multipleDeletion ? (
            <Button
              variant="contained"
              color="success"
              startIcon={<SelectAllIcon />}
              onClick={() => setMulipleDeletion(true)}
              sx={{
                borderRadius: '10px',
                backgroundColor: 'var(--navbar-dark-primary)',
                '&:hover': {
                  backgroundColor: '#f00d88',
                },
              }}
            >
              Select Delete
            </Button>
          ) : (
            <>
              <button
                style={{
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "10px",
                }}
                onClick={DeleteMultipleCourse}
              >
                Delete
              </button>
              <button
                style={{ backgroundColor: "white", borderRadius: "10px" }}
                onClick={() => {
                  setMulipleDeletion(false);
                  setMultipleDeletionId([]);
                }}
              >
                Cancel
              </button>
            </>
          )}
          
          {/* Download Excel Button */}
          <Button
            variant="outlined"
             className="download-btn"
            onClick={downloadExcel} // Call your download function here
            sx={{
              borderRadius: '10px',
              marginLeft: '1rem',
              color:"#f00d88",
              borderColor:"#f00d88",
             // Customize color as needed
              
            }}
            disabled={getPagedData()?.length===0}
          >
            Download Excel
          </Button>
        </>
      )}
   
            </div> 
            
            <div id="table-container">
            <div className="scroll">
            <table class="table table-hover">
              <thead>
                {userRole == "admin" ? (
                  <>
                    {/* ['','Branch Name','Concept Name','Area/District','Pincode','Address','Location'] */}
                    <tr class="">
                      <th class="t-head rounded-start-3" scope="col">
                        S.no
                      </th>
                      <th class="t-head" scope="col">
                        Branch Name
                      </th>
                      <th class="t-head " scope="col">
                        Branch Code
                      </th>
                      <th class="t-head" scope="col">
                        Concept Name
                      </th>
                      <th class="t-head" scope="col">
                        Franchise Member Mail
                      </th>
                      <th class="t-head" scope="col">
                        Area/District
                      </th>
                      <th class="t-head" scope="col">
                        Pincode
                      </th>
                      <th class="t-head" scope="col">
                        Address
                      </th>
                      <th class="t-head" scope="col">
                        Location
                      </th>
                      <th class="t-head" scope="col">
                        Status
                      </th>
                      <th class="t-head rounded-end-3" scope="col">
                        Action
                      </th>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr class="">
                      <th class="t-head rounded-start-3" scope="col">
                        S.no
                      </th>
                      <th class="t-head " scope="col">
                        Branch Name
                      </th>
                      <th class="t-head " scope="col">
                        Branch Code
                      </th>
                      <th class="t-head" scope="col">
                        Concept Name
                      </th>
                      <th class="t-head" scope="col">
                        Franchise Member Mail
                      </th>
                      <th class="t-head" scope="col">
                        Area/District
                      </th>
                      <th class="t-head" scope="col">
                        Pincode
                      </th>
                      <th class="t-head" scope="col">
                        Address
                      </th>
                      <th class="t-head rounded-end-3" scope="col">
                        Location
                      </th>
                    </tr>
                  </>
                )}
              </thead>
              <tbody class="table-dark">
                {getPagedData()?.length !== 0 ? (
                  <>
                    {getPagedData()?.map((e, i) => {
                       const continuousIndex = i + 1 + (currentPage-1) * itemsPerPage;
                      return (
                        <tr>
                          <th scope="row" >
                            {multipleDeletion ? (
                              <>
                                <input
                                  type="checkbox"
                                  onClick={() => selectionForDeletion(e._id)}
                                  name=""
                                />
                              </>
                            ) : (
                              <>
                                <i class="fa-solid fa-chalkboard"></i>
                                <h6 className="p-1" style={{ width: "6rem" }}>
                                {continuousIndex}
                                </h6>
                                
                              </>
                            )}
                          </th>

                          <td>{e.branchName}</td>
                          <td>{e.branchCode}</td>
                          <td>{e.concept?.join(",")}</td>
                          <td>{e.branchOwnerEmail}</td>
                          <td>{e.area}</td>
                          <td>{e.pincode}</td>
                          <td>{e.addressName}</td>
                         {/* Location Column with Icon and Link */}
                         <td>
              <a
                href={e.location} // Assuming `e.location` contains a URL (like Google Maps link)
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
              >
                <LocationOnIcon style={{ color: '#f00d88' }} />
              </a>
            </td>
                          {userRole == "admin" && (
                            <>
                            <td>
                                {e.status === "active" && <span className="dot"></span>}
                                {e.status === "inactive" && <span className="dot-red"></span>}
                              </td>

                              <td
                                style={{ position: "relative",width:"6rem",whiteSpace: "nowrap" }}
                              >
                                <Icon
                                 style={{
                                  paddingRight: "1rem",
                                  cursor: "pointer",
                                }}
                                  onClick={() => openDeleteDialog(e)}
                                  icon={bin2}
                                />
                                <Icon
                                  onClick={() => {
                                    setEditContainer(true);
                                    setEditData(e);
                                  }}
                                  icon={pencil}
                                />
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
          </div>
        </>
      ) : (
        <div className="scroll">
          <BranchEditForm
            setEditContainer={setEditContainer}
            editData={editData}
            setEditData={setEditData}
            branchData={branchData}
            setData={setData}
          />
        </div>
      )}
      {!editableContainer && (
       
        <Stack alignItems={"center"} marginTop={"2rem"} marginBottom={"1rem"}>
          <Paginations
            count={getTotalPages(branchData)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="secondary"
          />
        </Stack>
      )}
  <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this branch?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={DeleteSingleBranch} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </BranchTableContainer>
  );
}

const Paginations = styled(Pagination)`
  color: white; // Set text color to white
  & .MuiPaginationItem-root {
    color: white; // Ensure pagination items have white text
  }
  & .MuiPaginationItem-root.Mui-selected {
    background-color: #333; // Optionally, set background for selected item
    color: white; // Ensure selected pagination item has white text
  }
`;
const BranchTableContainer = styled.div`
  h1 {
    color: white;
  }
.download-btn {
    margin-top: 1rem;
  }

  @media (max-width: 1024px) {
    .download-btn {
      display: none;
    }
  }
  tbody tr:hover {
    background-color: #333; /* Set the hover background color */
  }

  /* Dot indicators */
  .dot {
  width: 10px;
  height: 10px;
  background-color: green; /* Example: green for active status */
  border-radius: 50%;
  display: inline-block;
}

.dot-red {
  width: 10px;
  height: 10px;
  background-color: red; /* Example: red for inactive status */
  border-radius: 50%;
  display: inline-block;
}

  .dot-green {
    background-color: #219621;
  }

  /* Icon and text styles */
  .fa-solid {
    font-size: 1.3rem;
    color: var(--icon-color);
  }

  h4 {
    margin-left: 1em;
  }

  /* Scroll container */
  .scroll {
    overflow-y: scroll;
    height: 100%;
  }

  .scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scroll::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: transparent;
  }

  .scroll::-webkit-scrollbar-button {
    height: 16px;
  }

  /* Table and container styles */
  #table-container {
    background-color: #25272d;
    padding: 2rem;
    border-radius: 10px;
    height: 70vh;
    width: 100%;
    overflow-y: auto; /* Ensure scroll behavior if content overflows */
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    color: white;
  }
 .rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  .rounded-corners th,
  .rounded-corners td {
    border: 1px solid black;
  }
  th, td {
    text-align: center;
    padding: 1rem;
  }

  th {
    background-color: #18171b;
    color: white;
  }

  tbody tr {
    border-color: #25272d;
  }

  tbody tr:hover {
    background-color: #333;
  }

  /* Text input placeholder styles */
  input::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    color: gray; /* Edge 12-18 */
  }

  /* Form and button styles */
  #add-student-container,
  #profile-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #25272d;
    color: gray !important;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }

  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #25272d;
    opacity: 0.7;
  }

  h5 {
    text-align: center;
    color: white;
  }

  input {
    color: white;
  }

  /* Status Section Styles */
  .status-section {
    margin-top: 1rem;
  }

  .status-label {
    color: white;
  }

  .status-options {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .active-dot {
    background-color: green;
  }

  .inactive-dot {
    background-color: red;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #add-student-container {
      padding: 1rem;
    }

    #profile-container {
      margin-top: 1rem;
    }
  }

  /* Radio button styles */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }

  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }

  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }

  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }

  /* Button styles */
  .add-course-btn,
  .cancel-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }

  .add-course-btn:hover {
    background-color: green;
  }

  .cancel-btn:hover {
    background-color: red;
  }
`;

export default BranchTable;
