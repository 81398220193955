import React from 'react'
import { styled } from 'styled-components'
import MiniNavBar from './miniComponents/subcomponents/MiniNavBar'
import { useGlobalContext } from '../assets/contex/GlobalContext'
import CEOmessage from './ceomessage';
import NavBar from './miniComponents/subcomponents/NavBar'
function Ceo({user}) {
  const { userRole } = useGlobalContext();
  return (
    <ConceptDetailsContainer>
      <MiniNavBar user={userRole} selected={"message"} />
      <NavBar user={userRole} selected={"message"} />
      <CEOmessage/>
    </ConceptDetailsContainer>
  );
}
const ConceptDetailsContainer=styled.div`
    display: flex;
    background-color: var(--background);
    @media only screen and (max-width: 800px) {
    #miniNav{
        display: block !important;
    }
    #title{
        justify-content: center !important;
    }
    #searchfield{
      display: none !important;
    }
    }
  #miniNav{
    display: none ;
  } 
  #title{
        justify-content: space-between;
    }
    #searchfield{
      display: block ;
    }
`
export default Ceo