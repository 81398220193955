import React, { useState } from "react";
import { styled } from "styled-components";
//import ReferedStudentList from './ReferedStudentList';
import AddStudentForm from "../miniComponents/AddForms/AddStudentForm";
import TopContainer from "../miniComponents/subcomponents/TopContainer";
import { useGlobalContext } from "../../assets/contex/GlobalContext";
import CurrentStudyingStudent from "../miniComponents/Tables/CurrentStudyingStudent";
import PendingStudent from "../miniComponents/Tables/PendingStudent";
import {
  Studentadmission,
  Studentcompleted,
} from "../miniComponents/Tables/StudentsTable";
import { Tabs, Tab, Box,CircularProgress } from "@mui/material";

function StudentDetailContainer({ user, courseListTitles }) {
  const [filter, setFilter] = useState(false);
  const [studentData, setStudentData] = useState({});
  const { userRole } = useGlobalContext();
  const [filterData, setFilterData] = useState("");
  const [selectedView, setSelectedView] = useState("StudentReferrals");
  console.log(selectedView);
  const FilterOpen = () => {
    filter ? setFilter(false) : setFilter(true);
  };

  const [loading, setLoading] = useState(false);
  const handleChange = (event, newValue) => {
   
      setSelectedView(newValue);
     
  };
  console.log(selectedView);
  return (
    <VipTableContainer>
      <div id="course-list" style={{ height: "100vh", overflowY: "scroll" }}>
        {loading && (
          <LoadingOverlay>
            <CircularProgress sx={{ color: "#f00d88" }} />
          </LoadingOverlay>
        )}
        {/* <MiniNavBar selected={"vipcourselist"}/> */}
        <TopContainer
          heading={"STUDENTS DETAILS"}
          options={["Tia mock", "Tia Brain", "Live Tutor", "Test hub"]}
          filter={filter}
          FilterOpen={FilterOpen}
          setFilterData={setFilterData}
          showFields={{ search: true, filter: true }} // Search and Filter visible for all views
          // Setting search query in filterData
        />

        <Box
          sx={{
            width: "100%",
            backgroundColor: "var(--navbar-dark-primary)",
            borderRadius: "8px",
            boxShadow: 3,
            padding: "1rem",
            marginBottom: "1rem",
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={selectedView}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Student Details Tabs"
            textColor="inherit"
            indicatorColor="secondary"
            sx={{
              "& .MuiTab-root": {
                minWidth: "120px",
                fontWeight: "bold",
                fontSize: "0.875rem",
                color: "white",
                "&.Mui-selected": {
                  color: "white",
                },
              },
              "& .MuiTabs-indicator": {
                height: "4px",
                backgroundColor: "#f00d88",
              },
            }}
          >
            <Tab label="Student Referrals" value="StudentReferrals" />
            {userRole === "admin" && (
              <Tab label="Admission Student" value="AdmissionStudent" />
            )}
            {userRole === "admin" && (
              <Tab label="Completed Student" value="CompletedStudent" />
            )}
            {userRole === "hos" && (
              <Tab label="Admission Student" value="AdmissionStudent" />
            )}
            {userRole === "hos" && (
              <Tab label="Completed Student" value="CompletedStudent" />
            )}
            {userRole === "vip" && (
              <Tab label="Refer Student" value="ReferralsStudent" />
            )}
            {userRole === "franchise" && (
              <Tab label="Add Student" value="ReferralsStudent" />
            )}
            {userRole === "franchise" && (
              <Tab label="Admission Student" value="AdmissionStudent" />
            )}
            {userRole === "franchise" && (
              <Tab label="Completed Student" value="CompletedStudent" />
            )}
            {userRole === "branchstaff" && (
              <Tab label="Add Student" value="ReferralsStudent" />
            )}
            {userRole === "branchstaff" && (
              <Tab label="Admission Student" value="AdmissionStudent" />
            )}
            {userRole === "branchstaff" && (
              <Tab label="Completed Student" value="CompletedStudent" />
            )}
          </Tabs>
        </Box>

        {/* Selected View Components */}
        {selectedView === "StudentReferrals" ? (
          <PendingStudent
            setStudentData={setStudentData}
            setSelectedView={setSelectedView}
            filterData={filterData} // Passing search query
            setFilterData={setFilterData}
          />
        ) : selectedView === "ReferralsStudent" ? (
          <AddStudentForm studentData={studentData} />
        ) : selectedView === "CompletedStudent" ? (
          <Studentcompleted
            studentData={studentData}
            filterData={filterData} // Passing search query
            setFilterData={setFilterData}
          />
        ) : selectedView === "AdmissionStudent" ? (
          <Studentadmission
            studentData={studentData}
            filterData={filterData} // Passing search query
            setFilterData={setFilterData}
          />
        ) : null}
      </div>
    </VipTableContainer>
  );
}
const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;
const VipTableContainer = styled.div`
  width: 100%;

  #course-list {
    width: 76vw;
    padding: 2rem;
    position:relative;
     @media (min-width:800px) and (max-width:1000px) {
     width:100vw;
}
  }

  ::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  .btn {
    background-color: var(--navbar-dark-primary);
    padding: 1rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }
  #line {
    background-color: var(--icon-color);
    height: 3px;
    width: 100%;
  }
  h2 {
    color: white;
  }
  @media only screen and (max-width: 800px) {
    #miniNav {
      display: block !important;
    }
    h2 {
      margin-left: 3rem;
    }
    #course-list {
      width: 100%;
      padding: 2rem;
    }
  }
  #miniNav {
    display: none;
  }
  #searchfield {
    height: 3.5rem;
    padding-right: 25px;
    background-color: #36283a;
    padding: 1rem;
    background-size: 20px;
    border-radius: 5px;
    margin-right: 1rem;
  }

  #searchfield > input {
    border: 0px solid;
    background-color: #36283a;
    height: 20px;
    width: 10rem;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
  ::placeholder {
    color: #bf2f82c4;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #bf2f82c4;
  }

  .font {
    border: 0px solid;
    color: #bf2f82c4;
  }

  .icon-button {
    font-size: 2rem;
    padding: 0.2rem;
    background-color: #36283a;
    border-radius: 5rem;
    width: 3.5rem;
  }

  .icon {
    color: #bf2f82c4;
    border-radius: 5rem;
  }

  #table-container {
    background-color: #25272d;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 10px;
    height: 83vh;
  }

  #table-container thead th {
    position: sticky;
    top: 0;
  }

  .t-head {
    background-color: #18171b !important;
    color: white !important;
    padding: 1rem !important;
    text-align: center;
  }
  h4 {
    text-align: center;
  }

  table.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
  }

  table.rounded-corners th,
  table.rounded-corners td {
    border: 1px solid black;
  }

  thead tr {
    border-bottom: 1rem solid #25272d;
  }
  tr {
    border-color: #25272d;
  }

  .table > :not(caption) > * > * {
    background-color: #25272d;
  }


`;
export default StudentDetailContainer;
