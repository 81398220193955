import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { useGlobalContext } from "../assets/contex/GlobalContext";
import { handleImageSubmit, imageURL } from "../Api";
import { format, parseISO } from "date-fns";
import { URL as url } from "../assets/mocData/config";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import LoginNotSuccessful from "../pages/miniComponents/templatePages/LoginNotSuccessfullPage";
import { Button } from "@mui/material";
import Icon from "react-icons-kit";
import { cross } from "react-icons-kit/icomoon/cross";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputBase,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Autocomplete, Paper } from "@mui/material";
import { Country, State, City } from "country-state-city";
import CircularProgress from "@mui/material/CircularProgress";
function VipDetailsUpdatePortal({ newVipData }) {
  const [checked, setChecked] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [password, setPassword] = useState("");
  const [jobDataAll, setJobDataAll] = useState([]);
  const [jobData, setJobData] = useState({
    jobDescription: "",
    companyName: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [allEducation, setAllEducation] = useState([]);

  // Function to handle adding education data
  const addEducationData = () => {
    if (education.degree === "" || education.collegeName === "") {
      // Show a warning if either field is empty
      alert("Please fill in both Degree and College Name fields.");
      return;
    }
    // Add the new education entry to the allEducation array
    setAllEducation([...allEducation, education]);
    setData({
      ...data,
      educationDetails: [...allEducation, education],
    });
    // Clear the input fields after adding educationDetails
    setEducation({
      degree: "",
      collegeName: "",
    });
  };

  // Function to remove education entry by degree
  const removeElementByConditionEducation = (degree) => {
    // Filter out the education entry that matches the given degree
    const filteredArray = allEducation.filter(
      (element) => element.degree !== degree
    );
    setData({
      ...data,
      educationDetails: [...filteredArray],
    });
    setAllEducation(filteredArray);
  };
  function removeElementByCondition(jobDescription) {
    // Create a new array with elements that do not match the specified company name
    const filteredArray = jobDataAll.filter(
      (element) => element.jobDescription !== jobDescription
    );
    setData({
      ...data,
      jobDescription: [...filteredArray],
    });
    console.log(filteredArray); // This will show the new array without the specified company

    setJobDataAll(filteredArray);
  }

  const [education, setEducation] = useState({
    degree: "",
    collegeName: "",
  });

  const addJobData = (dataJ) => {
    console.log(dataJ);
    if (dataJ.jobDescription == "" || dataJ.companyName == "") {
      enqueueSnackbar("Fill the detail job description and company name", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return null;
    }
    setJobDataAll([...jobDataAll, dataJ]);
    setData({
      ...data,
      jobDescription: [...jobDataAll, dataJ],
    });
    setJobData({
      jobDescription: "",
      companyName: "",
    });
  };

  const { generateRandomPassword, userToken, userRole, profileData } =
    useGlobalContext();
  const [data, setData] = useState({
    ...newVipData,
    status: "completed",
    otherDetails: { isVip: false, profileDataCompletion: true },
    joinedDate: `${Date.now()}`,
    monthlySalary: " ",
  });
  console.log(data);
  const getData = (isoDate) => {
    if (isoDate == null) {
      return null;
    } else {
      const dateObject = parseISO(isoDate);
      const formattedDate = format(dateObject, "yyyy-MM-dd");
      return formattedDate;
    }
  };
  useEffect(() => {
    if (data.referralID == null) {
      setData({ ...data, refferalID: "none" });
    }
  }, []);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const objectUrl = URL.createObjectURL(file);
      console.log(objectUrl);
      setPreviewUrl(objectUrl);
    }
    await handleImageSubmit(file, setData, data);
  };
  const navigate = useNavigate();
  const Checked = (event) => {
    setChecked(event.target.value);
    console.log(event.target.value);
  };
  const FormData = (e) => {
    e.preventDefault();
    console.log(e.target.name, e.target.value);
    if (e.target.name == "profileImage") {
    } else if (
      e.target.name == "jobDescription" ||
      e.target.name == "companyName"
    ) {
      setJobData({ ...jobData, [e.target.name]: e.target.value });
    } else if (e.target.name == "degree" || e.target.name == "collegeName") {
      setEducation({ ...education, [e.target.name]: e.target.value });
    } else if (e.target.name == "") {
      return;
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#f00d88", // Pink color
      },
      text: {
        primary: "#ffffff", // White color for input text
      },
    },
    components: {
      MuiFilledInput: {
        styleOverrides: {
          root: {
            color: "#ffffff", // White color for input text
            backgroundColor: "black", // Background color for filled input
            "&:before": {
              borderColor: "white", // Initial border color white
            },
            "&:hover:before": {
              borderColor: "#f00d88", // Border color on hover
            },
            "&.Mui-focused:before": {
              borderColor: "#f00d88", // Border color when focused
            },
            "&.Mui-focused": {
              backgroundColor: "black", // Background color when focused
            },
          },
          input: {
            color: "#ffffff", // White color for input text
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "gray", // Gray color for label
            "&.Mui-focused": {
              color: "#f00d88", // Pink color for label when focused
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "white", // White color for the dropdown icon
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "#25272d", // Dark gray background for dropdown
            color: "#ffffff", // White text color for dropdown items
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "#ffffff",// Pink color for the dropdown icon
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: "#25272d", // Gray background
            color: "#ffffff", // White text color
            "&:hover": {
              backgroundColor: "#f00d88", // Pink background on hover
            },
          },
        },
      },
    },
  });


  const handleClickGender = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const validateFormFranchiseMemberData = (data) => {
    // Validation for Staff Name
    if (!data.userName || data.userName.trim() === "") {
      enqueueSnackbar("Staff Name is required.", { variant: "warning" });
      return false;
    }
  
    // Validation for Age
    const age = parseInt(data.age, 10);
    if (isNaN(age) || age < 18 || age > 100) {
      enqueueSnackbar("Please enter a valid age between 18 and 100.", { variant: "warning" });
      return false;
    }
  
    // Validation for Blood Group
    const validBloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
    if (!validBloodGroups.includes(data.bloodGroup)) {
      enqueueSnackbar("Please enter a valid blood group.", { variant: "warning" });
      return false;
    }
  
    // Validation for Date of Birth
    if (!data.dateOfBirth || isNaN(new Date(data.dateOfBirth).getTime())) {
      enqueueSnackbar("Please enter a valid Date of Birth.", { variant: "warning" });
      return false;
    }
  
    const validGenders = ["Male", "Female", "Other"];

  // Validation for Gender
  if (!data.gender || data.gender === "" || !validGenders.includes(data.gender)) {
    enqueueSnackbar("Please select a valid gender.", { variant: "warning" });
    return false;
  }
    // Validation for Father Name
    if (!data.fatherName || data.fatherName.trim() === "") {
      enqueueSnackbar("Father's Name is required.", { variant: "warning" });
      return false;
    }
  
    // Validation for Mother Name
    if (!data.motherName || data.motherName.trim() === "") {
      enqueueSnackbar("Mother's Name is required.", { variant: "warning" });
      return false;
    }
  
    // Validation for Degree
    if ((education.degree === "" || education.collegeName === "")&&allEducation.length === 0) {
      enqueueSnackbar("Please fill in both Degree and College Name fields.", {
        variant: "warning",
        autoHideDuration: 1000,
      });
      return false;  // Stop validation if fields are empty
    }
  
    // Ensure at least one education entry is added
    if (allEducation.length === 0) {
      enqueueSnackbar("Please add at least one education entry.", {
        variant: "warning",
        autoHideDuration: 1500,
      });
      return false;  // Prevent form submission if no education entry exists
    }
    
    if (!data.newForWorking) {
      enqueueSnackbar("Please select whether the person is new for working.", { variant: "warning" });
      return false;
    }
  
    const { jobDescription, companyName } = jobData;  // If the user is not new for working, validate job details
   // Validation for Job Details (if the user is not new for working)
if (data.newForWorking === "no") {
  if ((jobDescription.trim() === "" || companyName.trim() === "") && jobDataAll.length === 0) {
    enqueueSnackbar("Please fill in both Job Description and Company Name fields.", {
      variant: "warning",
      autoHideDuration: 1000,
    });
    return false; // Stop further execution
  }
  if (jobDataAll.length === 0) {
    enqueueSnackbar("Please add at least one job description entry.", {
      variant: "warning",
      autoHideDuration: 1500,
    });
    return false;  // Prevent form submission if no education entry exists
  }
    
}
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    if (!data.email || !emailRegex.test(data.email)) {
      enqueueSnackbar("Please enter a valid email address.", { variant: "warning" });
      return false;
    }
  
    // Contact Phone Number validation
    const phoneRegex = /^[0-9]{10}$/; // Adjust as per your requirement
    if (!data.phoneNumber || !phoneRegex.test(data.phoneNumber)) {
      enqueueSnackbar("Please enter a valid phone number.", { variant: "warning" });
      return false;
    }
  
    // Whatsapp Number validation
    if (!data.whatsappNumber || !phoneRegex.test(data.whatsappNumber)) {
      enqueueSnackbar("Please enter a valid WhatsApp number.", { variant: "warning" });
      return false;
    }
    if (!data.doorFlatNumber) {
      enqueueSnackbar("Please enter your Door/Flat number.", { variant: "warning" });
      return false;
    }
  
    // Street Name validation
    if (!data.streetName) {
      enqueueSnackbar("Please enter the street name.", { variant: "warning" });
      return false;
    }
  
    // Landmark validation (Optional)
    // if (!data.landMark) {
    //   enqueueSnackbar("Please enter a landmark.", { variant: "warning" });
    //   return false;
    // }
  
    // Country validation
    if (!data.country) {
      enqueueSnackbar("Please select a country.", { variant: "warning" });
      return false;
    }
  
    // State validation
    if (!data.state) {
      enqueueSnackbar("Please select a state.", { variant: "warning" });
      return false;
    }
  
    // District validation
    if (!data.district) {
      enqueueSnackbar("Please select a district.", { variant: "warning" });
      return false;
    }
  
    // Pincode validation
    const pinCodeRegex = /^[0-9]{6}$/; // Adjust the regex based on country-specific formats
    if (!data.pinCode || !pinCodeRegex.test(data.pinCode)) {
      enqueueSnackbar("Please enter a valid pincode.", { variant: "warning" });
      return false;
    }
    if (!data.bankAccountName) {
      enqueueSnackbar("Please enter your Bank Account Name.", { variant: "warning" });
      return false;
    }
  
    // Branch Name validation
    if (!data.bankBranchName) {
      enqueueSnackbar("Please enter your Branch Name.", { variant: "warning" });
      return false;
    }
  
    // IFSC Code validation
    const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/; // Adjust based on actual IFSC code format
    if (!data.ifscCode ) {
      enqueueSnackbar("Please enter a valid IFSC Code.", { variant: "warning" });
      return false;
    }
  
    // Bank Account Number validation
    if (!data.bankAccountNumber) {
      enqueueSnackbar("Please enter your Bank Account Number.", { variant: "warning" });
      return false;
    }
  
    // PAN Card Number validation
    const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // Adjust based on actual PAN card format
    if (!data.panCardNumber ) {
      enqueueSnackbar("Please enter a valid PAN Card Number.", { variant: "warning" });
      return false;
    }
   
   
  
    // Check for duplicates in workingOption
    const uniqueOptions = new Set(data.workingOption);
    if (uniqueOptions.size !== data.workingOption.length) {
      enqueueSnackbar("Duplicate concepts are not allowed.", { variant: "warning" });
      return false;
    }


   

    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?([\\w\\d\\-]+\\.)+[a-z]{2,}(\\/[^\\s]*)?$", 
      "i"
    );
  
    const invalidUrls = [];
    if (data.instagram && !urlPattern.test(data.instagram)) {
      invalidUrls.push("Instagram");
    }
    if (data.facebook && !urlPattern.test(data.facebook)) {
      invalidUrls.push("Facebook");
    }
    if (!data.instagram) {
      enqueueSnackbar("Please provide a instagram link.", { variant: "warning" });
      return false;
    }
    if (!data.facebook) {
      enqueueSnackbar("Please provide a facebook link.", { variant: "warning" });
      return false;
    }
  
    if (invalidUrls.length > 0) {
      enqueueSnackbar(`Invalid URL(s) for: ${invalidUrls.join(", ")}`, { variant: "warning" });
      return false;
    }

 

  
    return true;
  };


 
  const updateVipData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    // Update data with selected country, state, and district
    if (selectedCountry && selectedState && selectedCity) {
      data.country = selectedCountry.name;
      data.state = selectedState.name;
      data.district = selectedCity.name;
    }
    
    // Validate form data
    const isValid = validateFormFranchiseMemberData(data);
    
    if (!isValid) {
      setIsLoading(false);
      return; // Exit if the form data is not valid
    }
    
    // Ensure default values for certain fields
    data.joinedDate = data.joinedDate || Date.now();
    data.monthlySalary = data.monthlySalary || "0";
    data.newForWorking = data.newForWorking || "no";
    
    try {
      console.log(data);
      
      // Send the data to the server
      let response = await axios.put(`${url}/refer/${data._id}`, data);
      console.log(response);
      
      // Reset form data
      setData({
        verified: false,
        userType: "franchise",
        workingOption: [],
        imageACCESSKEY: "",
        jobDescription: "",
        referredBy: {},
        joinedDate: Date.now(),
        monthlySalary: " ",
        otherDetails: {
          collection: "",
          percentage: "",
        },
      });
  
      // Navigate after successful update
      navigate("/waitpage");
    } catch (error) {
      // Handle errors from the server
      if (error.response && error.response.data && error.response.data.error) {
        const errorMessage = error.response.data.error;
        
        // Handle specific error cases
        if (errorMessage.includes("E11000")) {
          if (errorMessage.includes("email")) {
            enqueueSnackbar("Email already exists.", { variant: "warning" });
          } else {
            enqueueSnackbar("Duplicate data entry error.", { variant: "warning" });
          }
        } else {
          enqueueSnackbar(`${errorMessage}`, { variant: "warning" });
        }
      } else {
        enqueueSnackbar("An unexpected error occurred.", { variant: "warning" });
      }
    } finally {
      setIsLoading(false);
    }
  };
  


  if (data.userName !== undefined) {
    return (
      <>
        {isLoading ? (
          <div
            style={{
              width: "100vh",
              height: "100vh",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
              backgroundColor: "rgb(0,0,0)",
              backgroundColor: "rgba(0,0,0, 0.4)",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <VipUpdatePortalFormContainer>
            <ThemeProvider theme={theme}>
              <main>
                <div className="section-container py-5 text-center container ">
                  <div className="row py-lg-5">
                    <div className="col-lg-6 col-md-8 mx-auto">
                      <h1 className="title fw-light text-white">
                        TALENT INITIATORS & ACCELERATORS
                      </h1>
                      <p className="lead-text lead text-white">
                        EMPOWERING ENTREPRENEURS TO BRING IDEAS ALIVE
                      </p>
                    </div>
                  </div>
                </div>
              </main>

              <div className="album-section album py-5">
                <div className="container card-container">
                  {/* <MiniNavBar selected={'vipaddvip'}/> */}
                  <h2 class="text-center">VIP PROFILE UPDATE</h2>
                  <hr />

                  <form
                    onChange={FormData}
                    class="container"
                    style={{ position: "relative" }}
                    className="scroll"
                  >
                    <div class="row d-md-flex flex-column-reverse flex-md-row">
                      <div class="col col-12 col-md-9 col-lg-9">
                        <div id="form-container" class="m-1">
                          <form
                            class="scroll"
                            style={{
                              height: "85vh",
                              overflowY: "scroll",
                              paddingTop: "1rem",
                              paddingBottom: "5rem",
                            }}
                            action=""
                          >
                            <div class="container"></div>

                            <div className="container my-4">
                              <hr />
                              <span className="h4 d-block mb-4 pb-2">
                                Personal Information
                              </span>
                              <div className="row">
                                <div className="col-12">
                                  <div className="row g-3">
                                    <div className="col-12">
                                      <TextField
                                        fullWidth
                                        name="userName"
                                        label="Staff Name"
                                        value={data.userName}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                       
                                        name="age"
                                        label="Age"
                                        value={data.age}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                       
                                        name="bloodGroup"
                                        label="Blood Group"
                                        value={data.bloodGroup}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                    <TextField
                              label="Date of Birth (dd-mm-yyyy)"
                              variant="filled"
                              id="filled-basic"
                              type="date"
                              name="dateOfBirth"
                         
                              
                              InputProps={{
                                style: {
                                  colorScheme: "dark", // For dark mode styling
                                  width: "100%",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              className="form-field"
                            />
                                    </div>
                                    <div className="col-12 col-md-6">
                                    <FormControl
  fullWidth
  variant="filled"

>
  <InputLabel>Gender</InputLabel>
  <Select
    name="gender"
    value={data.gender}
    onChange={handleClickGender} // Ensure this updates the `data` state correctly
  >
    <MenuItem value="Male">Male</MenuItem>
    <MenuItem value="Female">Female</MenuItem>
    <MenuItem value="Other">Other</MenuItem>
  </Select>
</FormControl>

</div>
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                      
                                        name="fatherName"
                                        label="Father Name"
                                        value={data.fatherName}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                       
                                        name="motherName"
                                        label="Mother Name"
                                        value={data.motherName}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>

                                    <div>
                                      {/* Display list of added education entries */}
                                      <div className="mb-3">
                                        {allEducation.map(
                                          (education, index) => (
                                            <div
                                              key={index}
                                              className="d-flex align-items-center mb-2"
                                            >
                                              <Button
                                                variant="contained"
                                                style={{
                                                  backgroundColor: "#f00d88", // Adjust this to your preferred color
                                                  color: "#fff",
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                  textAlign: "left",
                                                  whiteSpace: "normal", // Allow text to wrap
                                                  wordBreak: "break-word", // Break long words
                                                  padding: "0.5rem 1rem", // Adjust padding as needed
                                                }}
                                              >
                                                <span style={{ flex: 1 }}>
                                                  {education.degree} --{" "}
                                                  {education.collegeName}
                                                </span>
                                                <Icon
                                                  onClick={() =>
                                                    removeElementByConditionEducation(
                                                      education.degree
                                                    )
                                                  }
                                                  style={{
                                                    paddingLeft: "0.5rem",
                                                    color: "white",
                                                    cursor: "pointer",
                                                  }} // White cross icon
                                                  icon={cross}
                                                />
                                              </Button>
                                            </div>
                                          )
                                        )}
                                      </div>

                                      {/* Education input fields and Add button */}
                                      <div className="row g-2">
                                        <div className="col-12 col-md-5">
                                          <TextField
                                            fullWidth
                                           
                                            name="degree"
                                            label="Degree"
                                            value={education.degree}
                                            variant="filled"
                                            onChange={(e) =>
                                              setEducation({
                                                ...education,
                                                degree: e.target.value,
                                              })
                                            }
                                            style={{
                                              backgroundColor: "#1C1C1C",
                                              color: "#fff",
                                            }}
                                          />
                                        </div>
                                        <div className="col-12 col-md-5">
                                          <TextField
                                            fullWidth
                                          
                                            name="collegeName"
                                            label="College Name"
                                            value={education.collegeName}
                                            variant="filled"
                                            onChange={(e) =>
                                              setEducation({
                                                ...education,
                                                collegeName: e.target.value,
                                              })
                                            }
                                            style={{
                                              backgroundColor: "#1C1C1C",
                                              color: "#fff",
                                            }}
                                          />
                                        </div>
                                        <div className="col-12 col-md-2 d-flex align-items-center justify-content-center">
                                          <Button
                                            variant="outlined"
                                            color="success"
                                            onClick={addEducationData}
                                            className="w-100"
                                            style={{
                                              height: "40px", // Adjust this to your preferred height
                                              padding: "0.5rem", // Smaller padding for smaller height
                                              fontSize: "0.875rem", // Smaller font size for the button text
                                            }}
                                          >
                                            Add
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="container mt-4">
                              <div className="row">
                                <span className="col-md-3 col-12 mb-2">
                                  <strong>Career Details</strong>
                                </span>
                                <div className="col-md-9 col-12 d-flex flex-column px-3">
                                  <div className="d-flex flex-column flex-md-row align-items-center mb-3">
                                    <span
                                      style={{ width: "100%" }}
                                      class="d-flex justify-content-between"
                                    >
                                      <p>Currently Unemployed</p>
                                      <label htmlFor="yes">Yes</label>
                                      <input
                                        className="p-2 bg-black rounded-3 me-4"
                                        type="radio"
                                        id="yes"
                                        value="yes"
                                        name="newForWorking"
                                        style={{
                                          width: "5%",
                                          border: "1px solid black",
                                        }}
                                      />
                                      <label htmlFor="no">No</label>
                                      <input
                                        className="p-2 bg-black rounded-3"
                                        type="radio"
                                        id="no"
                                        value="no"
                                        name="newForWorking"
                                        style={{
                                          width: "5%",
                                          border: "1px solid black",
                                        }}
                                      />
                                    </span>
                                  </div>
                                  {data.newForWorking == "no" && (
                                    <>
                                      <div className="mb-3">
                                        {jobDataAll?.map((job) => (
                                          <div
                                            className="d-flex align-items-center mb-2"
                                            key={job.jobDescription}
                                          >
                                            <Button
                                              variant="contained"
                                              style={{
                                                backgroundColor: "#f00d88", // Adjust this to your preferred color
                                                color: "#fff",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                textAlign: "left",
                                                whiteSpace: "normal", // Allow text to wrap
                                                wordBreak: "break-word", // Break long words
                                                padding: "0.5rem 1rem", // Adjust padding as needed
                                              }}
                                            >
                                              <span className="flex-grow-1">
                                                {job.jobDescription} --{" "}
                                                {job.companyName}
                                              </span>
                                              <Icon
                                                onClick={() =>
                                                  removeElementByCondition(
                                                    job.jobDescription
                                                  )
                                                }
                                                style={{
                                                  paddingLeft: "0.5rem",
                                                  color: "white",
                                                  cursor: "pointer",
                                                }} // White cross icon
                                                icon={cross}
                                              />
                                            </Button>
                                          </div>
                                        ))}
                                      </div>

                                      <div className="d-flex flex-column flex-md-row flex-wrap mb-3">
                                        <TextField
                                          style={{
                                            width: "100%",
                                            maxWidth: "100%",
                                            margin: "0.5rem",
                                            border: "1px solid black",
                                          }}
                                          variant="filled"
                                          label="Job Description"
                                          name="jobDescription"
                                          value={jobData.jobDescription}
                                          fullWidth
                                        />
                                        <TextField
                                          style={{
                                            width: "100%",
                                            maxWidth: "100%",
                                            margin: "0.5rem",
                                            border: "1px solid black",
                                          }}
                                          variant="filled"
                                          label="Company Name"
                                          name="companyName"
                                          value={jobData.companyName}
                                          fullWidth
                                        />
                                      </div>

                                      <div className="d-flex justify-content-center mb-3">
                                        <Button
                                          onClick={() => addJobData(jobData)}
                                          variant="outlined"
                                          color="success"
                                          size="small"
                                          sx={{
                                            borderColor: "green",
                                            color: "green",
                                            padding: "2px 8px",
                                            minHeight: "24px",
                                            fontSize: "0.75rem",
                                            lineHeight: "1",
                                            "&:hover": {
                                              borderColor: "darkgreen",
                                              backgroundColor:
                                                "rgba(0, 128, 0, 0.1)",
                                            },
                                          }}
                                        >
                                          Add
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="container mt-4">
                              <div className="row">
                                <span className="col-md-3 col-12 mb-2">
                                  <strong>Contact Details</strong>
                                </span>
                                <div className="col-md-9 col-12 d-flex flex-column px-3">
                                  <div className="row g-3">
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                       
                                        name="email"
                                        label="Contact Email"
                                        type="email"
                                        value={data.email}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                        name="phoneNumber"
                                        label="Contact Phone Number"
                                        type="tel"
                                        value={data.phoneNumber}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12">
                                      <TextField
                                        fullWidth
                                        name="whatsappNumber"
                                        label="Whatsapp"
                                        type="tel"
                                        value={data.whatsappNumber}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="container mt-4">
                              <div className="row">
                                <span className="col-md-3 col-12 mb-2">
                                  <strong>Address</strong>
                                </span>
                                <div className="col-md-9 col-12 d-flex flex-column px-3">
                                  <div className="row g-3">
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                        name="doorFlatNumber"
                                        label="Door/Flat No"
                                        value={data.doorFlatNumber}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                        name="streetName"
                                        label="Street Name"
                                        value={data.streetName}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12">
                                      <TextField
                                        fullWidth
                                        name="landMark"
                                        label="Landmark"
                                        value={data.landMark}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>

                                    <div className="col-12 col-md-6 mb-3">
                                      <Autocomplete
                                        options={Country.getAllCountries()}
                                        getOptionLabel={(option) => option.name}
                                        value={selectedCountry}
                                        onChange={(event, newValue) =>
                                          setSelectedCountry(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="filled"
                                            label="Country"
                                            placeholder="Country"
                                            InputLabelProps={{
                                              style: { color: "gray" },
                                            }}
                                            sx={{
                                              "& .MuiFilledInput-root": {
                                                backgroundColor: "black",
                                                color: "white",
                                              },
                                              "& .MuiFilledInput-root:hover:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                              "& .MuiFilledInput-root.Mui-focused:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                              "& .MuiSvgIcon-root": {
                                                color: "white", // This sets the color of the dropdown icon to white
                                              },
                                            }}
                                          />
                                        )}
                                        PaperComponent={(props) => (
                                          <Paper
                                            {...props}
                                            sx={{
                                              backgroundColor: "white",
                                              color: "black",
                                            }}
                                          />
                                        )}
                                        sx={{
                                          "& .MuiAutocomplete-option": {
                                            "&:hover": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                            "&[aria-selected='true']": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                          },
                                        }}
                                      />
                                    </div>

                                    <div className="col-12 col-md-6 mb-3">
                                      <Autocomplete
                                        options={
                                          State?.getStatesOfCountry(
                                            selectedCountry?.isoCode
                                          ) || []
                                        }
                                        getOptionLabel={(option) => option.name}
                                        value={selectedState}
                                        onChange={(event, newValue) =>
                                          setSelectedState(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="filled"
                                            label="State"
                                            placeholder="State"
                                            InputLabelProps={{
                                              style: { color: "gray" },
                                            }}
                                            sx={{
                                              "& .MuiFilledInput-root": {
                                                backgroundColor: "black",
                                                color: "white",
                                              },
                                              "& .MuiFilledInput-root:hover:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                              "& .MuiFilledInput-root.Mui-focused:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                              "& .MuiSvgIcon-root": {
                                                color: "white", // This sets the color of the dropdown icon to white
                                              },
                                            }}
                                          />
                                        )}
                                        PaperComponent={(props) => (
                                          <Paper
                                            {...props}
                                            sx={{
                                              backgroundColor: "white",
                                              color: "black",
                                            }}
                                          />
                                        )}
                                        sx={{
                                          "& .MuiAutocomplete-option": {
                                            "&:hover": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                            "&[aria-selected='true']": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                          },
                                        }}
                                      />
                                    </div>

                                    <div className="col-12 col-md-6 mb-3">
                                      <Autocomplete
                                        options={
                                          selectedState
                                            ? City.getCitiesOfState(
                                                selectedState?.countryCode,
                                                selectedState?.isoCode
                                              )
                                            : []
                                        }
                                        getOptionLabel={(option) => option.name}
                                        value={selectedCity}
                                        onChange={(event, newValue) =>
                                          setSelectedCity(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="filled"
                                            label="District"
                                            placeholder="District"
                                            InputLabelProps={{
                                              style: { color: "gray" },
                                            }}
                                            sx={{
                                              "& .MuiFilledInput-root": {
                                                backgroundColor: "black",
                                                color: "white",
                                              },
                                              "& .MuiFilledInput-root:hover:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                              "& .MuiFilledInput-root.Mui-focused:before":
                                                {
                                                  borderColor: "#f00d88",
                                                },
                                              "& .MuiSvgIcon-root": {
                                                color: "white", // This sets the color of the dropdown icon to white
                                              },
                                            }}
                                          />
                                        )}
                                        PaperComponent={(props) => (
                                          <Paper
                                            {...props}
                                            sx={{
                                              backgroundColor: "white",
                                              color: "black",
                                            }}
                                          />
                                        )}
                                        sx={{
                                          "& .MuiAutocomplete-option": {
                                            "&:hover": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                            "&[aria-selected='true']": {
                                              backgroundColor: "#f00d88",
                                              color: "white",
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                        name="pinCode"
                                        label="Pincode"
                                        value={data.pinCode}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="container mt-4">
                              <div className="row">
                                <span className="col-md-3 col-12 mb-2">
                                  <strong>Account Details</strong>
                                </span>
                                <div className="col-md-9 col-12 d-flex flex-column px-3">
                                  <div className="row g-3">
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                        name="bankAccountName"
                                        label="Bank Account Name"
                                        value={data.bankAccountName}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                        name="bankBranchName"
                                        label="Branch Name"
                                        value={data.bankBranchName}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                        name="ifscCode"
                                        label="IFSC Code"
                                        value={data.ifscCode}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12 col-md-6">
                                      <TextField
                                        fullWidth
                                        name="bankAccountNumber"
                                        label="Bank Account Number"
                                        value={data.bankAccountNumber}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                    <div className="col-12">
                                      <TextField
                                        fullWidth
                                        name="panCardNumber"
                                        label="PAN Card Number"
                                        value={data.panCardNumber}
                                        variant="filled"
                                        id="filled-basic"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                          

                            <div class="container">
                              <div class="row">
                                <span class="col-3">Media Link</span>
                                <div class="col-9 d-flex flex-column px-3">
                                  <span
                                    style={{ width: "100%" }}
                                    class="d-flex justify-content-between"
                                  >
                                    <TextField
                                      fullWidth
                                      name="instagram"
                                      label="Instagram"
                                      value={data.instagram}
                                      sx={{
                                        width: "100%", // Matches the width of the original input
                                        borderRadius: "8px", // Matches the rounded corners ('rounded-3')
                                      }}
                                      variant="filled"
                                      id="filled-basic"
                                    />
                                  </span>
                                </div>
                              </div>
                              <br />
                              <div class="row">
                                <span class="col-3"></span>
                                <div class="col-9 d-flex flex-column px-3">
                                  <span
                                    style={{ width: "100%" }}
                                    class="d-flex justify-content-between"
                                  >
                                    <TextField
                                      fullWidth
                                      name="facebook"
                                      label="Facebook"
                                      value={data.facebook}
                                      sx={{
                                        width: "100%", // Matches the width of the original input
                                      }}
                                      variant="filled"
                                      id="filled-basic"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr />

                            <div class="container">
                              <div class="row">
                                <div class="col-12 d-flex flex-column px-3">
                                  <span
                                    style={{ width: "100%" }}
                                    class="d-flex justify-content-between"
                                  >
                                    <button
                                      onClick={(e) => updateVipData(e)}
                                      class="btn btn-outline-success btn-sm me-2 add-course-btn"
                                    >
                                      Update Details
                                    </button>
                                    <button class="btn btn-outline-danger btn-sm cancel-btn left:10rem">
                                      Cancel
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div class="col col-12 col-md-3 col-lg-3">
                        <div id="profile-container" class="m-1">
                          <div id="profile-img">
                            <input
                              type="file"
                              onChange={handleFileChange}
                              accept="image/*"
                              name="profileImage"
                            />
                            {data.imageACCESSKEY == "" ? (
                              <>
                                {previewUrl !== "" ? (
                                  <img id="image" src={previewUrl} alt="" />
                                ) : (
                                  <img
                                    src="https://www.pngkey.com/png/full/72-729716_user-avatar-png-graphic-free-download-icon.png"
                                    alt=""
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <img
                                  src={imageURL(data.imageACCESSKEY)}
                                  alt=""
                                />
                              </>
                            )}
                          </div>
                          <div class="upld">
                            <b>Upload</b>
                          </div>
                          <h5>Rakesh</h5>
                          <h5 style={{ textAlign: "center" }}>
                            <i class="fa-solid fa-id-badge"></i> Set as{" "}
                            <b>Active</b>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <footer className="text-body-secondary py-5">
                <div className="container">
                  <p className="float-end mb-1">
                    <a href="#">Back to top</a>
                  </p>
                  <p className="mb-1"> </p>
                  <p className="mb-0">
                    Tia <a href="https://tiaedu.in/">Visit the homepage.</a>
                  </p>
                </div>
              </footer>
            </ThemeProvider>
          </VipUpdatePortalFormContainer>
        )}
      </>
    );
  }
  return <LoginNotSuccessful />;
}
const VipUpdatePortalFormContainer = styled.div`
  background-color: #25272d;
  padding-top: 2rem;

  .album-section {
    background-color: #18171b;
    padding: 3rem 0;
    width: 80%;
    margin-left: 10%;
  }

  .container {
    max-width: 1140px;
    margin: 0 auto;
  }

  a {
    text-decoration: none !important;
    color: #aaa !important;
  }
  h2 {
    text-align: center;
    color: white;
  }

  #image {
    object-fit: cover !important;
  }
  #otp-container {
    position: absolute;
    top: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #313131;
    height: 20rem;
    width: 20rem;
    top: 10%;
    left: 20%;
  }
  .otp-input {
    color: black;
    border-radius: 8px;
  }
  #otp ::placeholder {
    color: black;
  }
  .verify-btn {
    background-color: lightgrey;
    color: #1d1e22;
    border-radius: 1rem;
    padding: 1rem;
  }
  input::placeholder {
    color: gray;
    opacity: 1; /* Firefox */
  }
  ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: gray;
  }
  #add-student-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: gray !important;
  }

  h2,
  p,
  div,
  h6 {
    color: white;
  }
  .add-course-btn:hover {
    background-color: green;
  }
  .cancel-btn:hover {
    background-color: red;
  }
  #form-container {
    border-radius: 10px;
    background-color: #18171b;
    padding: 1rem;
  }

  #profile-container {
    border-radius: 10px;
    background-color: #18171b;
    padding: 1rem;
  }

  hr {
    border: 1.4px solid black;
  }

  #profile-img {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    width: 100%;
  }

  #profile-img input {
    position: absolute;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    opacity: 0;
  }

  #profile-img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .upld {
    position: relative;
    color: white;
    text-align: center;
    top: -2rem;
    height: 2rem;
    background-color: #18171b;
    opacity: 0.7;
  }

  h5 {
    text-align: center;
    color: white;
  }

  input {
    color: white;
  }

  /* Status Section Styles */
  .status-section {
    margin-top: 1rem;
  }

  .status-label {
    color: white;
  }

  .status-options {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .active-dot {
    background-color: green;
  }

  .inactive-dot {
    background-color: red;
  }

  @media (max-width: 768px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #add-student-container {
      padding: 1rem;
    }

    #profile-container {
      margin-top: 1rem;
    }
  }
  /* Style for active radio button */
  .form-check-input.active-radio:checked {
    background-color: green;
    border-color: green;
  }
  .form-check-input.active-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
  }
  .form-check-input.inactive-radio:checked {
    background-color: red;
    border-color: red;
  }
  .form-check-input.inactive-radio:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  .add-course-btn,
  .cancel-btn,
  .generate-btn {
    margin-right: 2rem; /* Increase the space between buttons */
    font-size: 1.2rem; /* Increase button font size */
    padding: 0.6rem 2.5rem; /* Increase button padding */
    border-radius: 20px; /* Make buttons rounded */
  }
  .mini-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
  }
  .checkbox-container {
    border: 2px solid #313131;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #1d1e22;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to individual boxes within containers */
  }
  .checkbox-container {
    display: flex; /* Display checkboxes horizontally */
    justify-content: flex-start; /* Align checkboxes to the start */
    flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
    margin-bottom: 20px; /* Add margin at the bottom */
  }
  .checkbox-container div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px; /* Add margin at the bottom of each checkbox */
  }

  .checkbox-container div:last-child {
    margin-right: 0; /* Remove margin for the last checkbox */
  }
  .pink-btn {
    border-color: rgb(201, 66, 149); /* Change the outline color to pink */
    color: rgb(201, 66, 149); /* Change text color to ensure visibility */
  }
  .generate-btn:hover {
    background-color: green;
  }
  .pink-btn:hover {
    background-color: #313131;
    color: rgb(255, 255, 255);
  }
  .btn-verify {
    background-color: green;
    color: white;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    cursor: pointer;
  }
  @media (max-width: 575px) {
    .add-course-btn,
    .cancel-btn,
    .generate-btn {
      align-items: center;
      width: 100%; /* Ensure buttons take full width on screens under 425px */
      margin: 0.5rem 0; /* Add vertical margin between buttons */
      font-size: 0.8rem; /* Adjust button font size for screens under 425px */
      padding: 0.4rem 0.8rem; /* Adjust button padding for screens under 425px */
    }
  }
`;
export default VipDetailsUpdatePortal;
